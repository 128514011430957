.checkbox-group {
  flex-wrap: wrap;
  .approver {
    border: 1px solid #afaeae;
    padding: 0.3rem 1rem 0.3rem 0;
    margin: 0rem 1rem 0 0;
    border-radius: 3px;
    margin-bottom: 1rem;
    max-width: 250px;
    // animation: slide-down .5s both ;
    animation-timing-function: linear;
    // animation-timing-function: cubic-bezier(.34,1.06,.7,.46);;
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    // animation-duration: 0.85s;
    // -webkit-animation-duration: 0.85s;
    animation-name: fade-in;
    -webkit-animation-name: fade-in;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    // animation-delay: 0.1s;
    // -webkit-animation-delay: 0.1s;

    // @include respond(phone) {
    //   width: 100%;
    // }
    // @include respond(tab) {
    //   width: 80%;
    // }
    // @include respond(desktop) {
    //   width: 80%;
    // }
    // @include respond(med-desktop) {
    //   width: 60%;
    // }
    // @include respond(big-desktop) {
    //   width: calc(100% / 2 - 20px);
    // }
    // width: 30%;
    width: 100%;
    &:hover {
      background: #f1f1f1;
    }
    // &:not(:first-child):nth-child(odd) {
    //   margin-top: 1rem;
    // }
    .group-icon {
      font-size: 25px;
      margin: 0.5rem;
      color: #848484;
    }
    .checked {
      color: #0062d9;
    }
    .approver-check {
      padding: 0;
      margin: 0;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin: 0;
      }
      .checkmark {
        border-radius: 50%;
      }
    }
    .emp-details {
      width: calc(100% - 40px);
      .desg-depart {
        max-width: calc(100% - 40px);
        padding-left: 0.5rem;
        .emp-name {
          font-size: 0.9rem;
          font-weight: 500;
          color: #2266e3;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .desg {
          font-size: 0.9rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.check-group {
  &:empty {
    display: none;
  }
  .genericForm-group__message {
    margin-top: -0.8rem !important;
  }
  .disabled {
    .approver {
      background: #ececec;
      border: 1px solid #e4e4e4;
      .group-icon {
        color: #d2d2d2;
      }
    }
  }
}

.error {
  .approver {
    border: 1px solid #{$color-red};
  }
}
