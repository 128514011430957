@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

$PlaceholderBackgroundColor: #f8f8f8;
$PlaceholderColor: rgba(0, 0, 0, 0.54);
$Black: #444;

.sb-avatar {
  img {
    object-fit: cover;
  }
}

.documents {
  width: 100%;
  padding: 15px;
  &-image {
    width: 400px;
    height: 400px;
    margin: 10px auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-pdf {
    width: 100%;
    height: 400px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.date-error {
  font-size: 17px;
  font-weight: 500;
  color: #{$rigo-white-color};
  background: #ff0000;
  padding: 20px;
  border-radius: 4px;
}

.common {
  &-container {
    padding: 1rem;
    // width: 1600px; //1300px;
    max-width: 100%;
  }

  &-route {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-title {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;

      // .common-route-title {
      //   padding: 5px 0 10px 0;
      // margin-bottom: 25px;
      span {
        display: flex;
        align-items: center;
      }
      .module {
        font-size: 13px;
        color: #0052ab;
        cursor: pointer;
      }
      .path-name {
        font-weight: 500;
        font-size: 13px;
      }
      // }
      .btn-back {
        background: transparent;
        color: #0052ab;
        border: 2px solid #0052ab;
        border-radius: 3px;
        padding: 2px;
        height: unset;
        font-size: 17px;
      }
      .left-arrow {
        font-size: 2rem;
        color: #0052ab;
      }
      .right-arrow {
        color: #0052ab;
      }
      span {
        padding: 0 0.5rem;
      }
    }

    &-buttons {
      display: flex;
    }
  }
}

.approved-icon {
  color: #63c943;
  fill: #63c943;
}
.rejected-icon,
.cancelled-icon {
  color: #e20000;
  fill: #e20000;
}

.comp-leave-plus-icon,
.comp-leave-minus-icon {
  font-size: 1.5rem;
}
.comp-leave-plus-icon {
  color: #06b703;
}
.comp-leave-minus-icon {
  color: #ff0086;
}

.request {
  &-drop {
    width: 100%;
    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }
    &__item {
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;
      padding: 15px 10px;
      background-color: #{$rigo-white-color};
      &:hover {
        background-color: #f2f4f7;
      }
      &-icon {
        width: 25px;
        text-align: center;
        margin-right: 15px;
        line-height: 0;
        img {
          width: 18px;
          height: 18px;
        }
      }
      &-name {
        font-size: 14px;
        font-weight: 500;
        color: #1f262e;
      }
    }
  }
}
