@import '../../../../scss/abstracts/_variables';
.leave-request-form {
  &__body {
    padding: 1rem;
    .form-row {
      padding: 1rem;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      .leave-period {
        margin: 0;
        width: auto;
        .inline-form-group {
          width: 100%;
          .badges {
            white-space: nowrap;
            padding: 0.4rem 0.5rem;
            height: auto;
            font-size: 0.7rem;
            margin-right: 0.5rem !important;
            border-radius: 20px;
            &:last-child {
              margin: 0 !important;
            }
          }
        }
      }
      .daterangepicker__range {
        transform: translateX(-36%);
      }
      .genericForm-group {
        margin: 0;
      }
      .check-group.error {
        padding-bottom: 1rem;
      }
      .no-approver {
        color: #de2e21;
        .genericForm-group__label {
          color: #de2e21;
        }
        .info-icon {
          height: 20px;
          width: 29px;
        }
      }
    }
  }
  &__footer {
    padding: 1rem;
    border-top: 1px solid #cfcfcf;
    background: #{$rigo-white-color};
  }
  .modal__content {
    background: #{$rigo-white-color};
    width: 50%;
    .modal__header {
      border-bottom: 1px solid #cfcfcf;
      h2 {
        font-size: 0.9rem;
        font-weight: 500;
        color: #060606;
      }
    }
  }
}
