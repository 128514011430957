.modal-reject-candiate {
  .modal__header {
    border-bottom: 1px solid #e0e0e0 !important;
    height: 55px;
  }
  .modal__content {
    background: #{$rigo-white-color};
    .modal__header {
      border: 0;
    }
    .reject-candidate {
      .item {
        margin-top: 10px;
        padding: 5px 10px 5px 10px;
        &-label {
          font-size: 15px;
        }
        &-content {
          margin-top: 10px;
          &-box {
            border: 2px solid #dfe1e6;
            padding: 10px;
          }
        }
        .left-margin {
          margin-left: 15px;
        }
        .action {
          cursor: pointer;
          color: #0074e8;
        }
        .icon{
          height: 10px;
        }
      }
      .email {
        &-section {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px 5px 10px;
          border-bottom: 1px solid #eee;

          .genericForm-group {
            margin-right: unset;
          }
          .ck.ck-editor {
            width: 100% !important;
          }
          button {
            height: 32px;
          }
        }
      }
    }
  }
}
