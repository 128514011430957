.ql {
  &-editor {
    //line-height: 2;
    font-size: 14px !important;
    padding: 0px;
  }
  &-mention-list {
    z-index: 999999;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    border: 1px solid #cfcfcf;
    min-width: 300px;
    background-color: #fff;
    li {
      padding: 10px;
      &.selected {
        background-color: #0875e1;
        color: #fff;
      }
    }
  }
}
.mention {
  background: #dcedff;
  color: #005bc0;
  padding: 2px 3px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  margin: 2px;
}

.icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  border-color: red;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1em 1em;
  font-size: 20px;
}

.icon-smiley {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f603.svg");
  // background: url("https://twemoji.maxcdn.com/2/svg/1f603.svg") no-repeat center
  //   center fixed;
  background-size: cover;
}
