@import "../../../scss/abstracts/_variables";

.personal-loan-block {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  background-color: var(--color-white);
  border-radius: 3px;

  &-heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110%;
    color: $rigo-primary-color;
    padding-bottom: 18px;
  }

  .general-setting-form {
    display: flex;
    padding: 20px 0 10px;
    gap: 50px;
  }

  .right-side-label {
    padding-left: 10px;
    margin-top: 8px;
  }

  &-grids {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  &-subHeading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: $black-text-color;
    margin-bottom: 10px;
  }

  .backicons {
    margin-top: 10px;
  }

  .flex {
    .css-2b097c-container {
      width: 101px;
      margin-right: 10px;
    }
  }
}
