@import "../../../../scss/abstracts/mixins";
@import "../../../../scss/abstracts/variables";

.add {
  &-modal {
    // padding: 10px;
    &__content {
      padding: 20px;
      // background-color: #fff;
    }

    .form-group__input {
      height: 2.2rem !important;
      // &:focus {
      //   border: unset !important;
      // }
    }

    .form-group__message {
      margin-left: unset !important;
    }
    .error__message {
      margin-top: 0.2rem;
      margin-left: unset;
    }

    &-section {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      margin-bottom: 15px;
      grid-gap: 20px;

      &-content {
        margin-right: 20px;

        // .MuiFormControl-fullWidth {
        //   width: 300px;
        // }
      }
    }

    &-full_content {
      margin-right: 20px;

      .MuiFormControl-fullWidth {
        width: 500px;
      }
    }

    &-footer {
      padding: 10px 20px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      background-color: #f5f5f5;
    }
  }
}

.ksa-modal {
  .modal__header {
    background: #{$rigo-white-color};
  }
  .modal__content {
    min-width: 50%;
    overflow: visible;
    min-height: unset;
    &-body {
      overflow: visible;
    }
  }
}

.library {
  .button-white span {
    svg {
      color: #0065ff !important;
    }
    color: #0065ff !important;
  }
}

// .common-container {
//   .tabs {
//     background: transparent;
//     box-shadow: none;
//     &-list {
//       background: #fff;
//     }
//     &-content {
//       padding: 0 !important;
//       .add-content {
//         margin: 1rem 0;
//       }
//     }
//   }
// }
