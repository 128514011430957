@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.overtime-request {
  &-title {
    margin-bottom: 10px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    padding: 15px;

    // th {
    // font-weight: 400;
    //   padding: 0 20px 10px 20px;
    // }

    // td {
    //   padding: 0 20px 5px 20px;
    // }
    .ml-sm {
      margin-left: 0px !important;
    }
    .genericForm-group__date {
      min-width: 140px;
    }
    .genericForm-group__datepicker {
      top: 33px;
      left: 130%;
    }
    .genericForm-group__date-input {
      width: 100px;
    }
  }
  &-footer{
    padding:15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: 1px solid #cfcfcf;
  }
  &-container{
    background-color: #{$rigo-white-color};
    padding:8px;
    border-radius: 3px;
  }
  &-forms {
    display: grid !important;
    grid-template-columns: calc(14% - 1px) calc(14% - 1px) calc(14% - 1px) calc(14% - 1px) calc(14% - 1px) calc(9% - 1px) calc(19% - 1px) 50px !important;
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #444;
    background-color: #{$rigo-white-color};
    border-radius: 5px;
    display: grid;
    grid-template-columns: calc(14% - 1px) calc(14% - 1px) calc(14% - 1px) calc(14% - 1px) calc(9% - 1px) calc(14% - 1px) 50px;

    .heading {
      font-weight: 400;
      padding: 5px 11px;
      font-size: 13px;
      // &_date {
      //   width: 20%;
      // }
      // &_starttime {
      //   width: 16.5%;
      // }
      // &_endtime {
      //   width: 16%;
      // }
      // &_gap {
      //   width: 14%;
      // }
      // &_reason {
      //   width: 20%;
      // }
    }
    .desc {
      padding: 5px 11px;
      font-size: 13px;
      font-weight: 400;
      &.note{
        width: 100%;
      }
      .genericForm-group__custom-time-box{
        width: 30px;
        margin-right: 0;
      }
      .genericForm-group__custom-time-meridiem-name{
        font-size: 13px;
        height: 30px;

        &:first-child{
          border-radius: 3px 0px 0px 3px;
        }
        &:last-child{
          border-radius: 0px 3px 3px 0px;
        }
      }
      .genericForm-group__custom-time-colon{
        margin:0 5px;
      }
      // &_date {
      //   width: 22%;
      // }
      // &_starttime {
      //   width: 22%;
      // }
      // &_endtime {
      //   width: 22%;
      // }
      // &_gap {
      //   width: 18%;
      // }
      // &_reason {
      //   width: 20%;
      // }
    }
    .date {
      min-width: 180px;
      padding: 5px 10px;
    }
    .time {
      width: 200px;
      padding: 5px 10px;
    }
    .device-time {
      width: 150px;
      display: block;
      text-align: left;
      padding: 5px 10px;
    }
    .note {
      width: 100%;
      // width: 350px;
      padding: 5px 10px;
    }
    .timegap {
      width: 125px;
      padding: 5px 10px;
    }
    
    .delete-time-attendance {
      color: #777;
      padding: 0 5px 0 0;
      width: 30px;
      height: 30px;

      display: flex;
      background: #f0f2f5;
      margin-right: 10px;
      padding: 5px;
      cursor: pointer;
      &:hover{
        background: darken(#f0f2f5,10);
        color: darken(#777,10);
      }
    }
  }

  &-add {
    margin-top: 15px;
  }

  &-approvers {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    &-title {
      margin-bottom: 10px;
    }

    &-list {
      display: flex;
      flex-direction: row;
      font-size: 20px;
    }
    &-approver {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 15px;
      padding: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      max-width: 300px;

      .radio__btn {
        width: 20px;
        margin-right: 10px;
      }
      .name-designation {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        &__name {
          margin-bottom: 5px;
        }
        &__designation {
          color: grey;
        }
      }
    }
  }
  &-header {
    margin-bottom: 20px;
    width: 67%;
  }
  // &-footer {
  //   padding: 1rem;
  //   border-top: 1px solid #cfcfcf;
  // }
}
.over-time {
  .modal__content {
    // width: 70% !important;
    // width: 1000px !important;
    // max-width: 100% !important;
    &-body {
      padding: 0 !important;
    }
  }
}

.body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // min-width: 90px;
  padding: 5px 10px 5px 5px;

  .genericForm-group {
    margin-right: unset;
  }
}
.device-time {
  width: 95px;
  min-width: 85px;
  display: flex;
  justify-content: center;
  &-heading {
    width: 10%
  }
}

.allowance-noapprover {
  margin-top: 10px;
  color: #de2e21;
  svg {
    height: 20px;
    width: 29px;
  }
}