@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

$PlaceholderBackgroundColor: #{$rigo-white-color};
//$PlaceholderBackgroundColor: #f8f8f8;
$PlaceholderColor: rgba(0, 0, 0, 0.54);

.travel {
  &-create {
    width: 100% !important;
    /* border: 1px solid #cecece; */
    // background: whitesmoke;
    // background-color: #e9eff8;
    box-shadow: unset !important;

    .delegation-table {
      padding: 20px 0;
      .custom-grid.e-grid .e-gridheader {
        display: none;
      }
      .e-table {
        border-top: 2px solid #eaeaea;
      }
    }
    .e-table {
      //   border-top: 2px solid #eaeaea;
      border-bottom: 20px solid #eaeaea;

      th {
        background: #faf9fa;
      }
    }

    &__title {
      padding: 15px 15px;
      border-bottom: 2px solid #d8d8d8;
      margin-bottom: 10px;

      h3 {
        font-size: 17px;
        color: #565656;
        font-weight: 400;
      }
    }
    &__body {
      padding: unset;
      display: flex;
      flex-direction: column;

      .MuiTableCell-root {
        border-bottom: unset !important;
        padding: 10px;
      }
      .MuiTableFooter-root {
        //display: block !important;
        background: #f0f2f5;
      }

      .MuiPaper-root {
        box-shadow: unset !important;
      }

      .tabs {
        &-list {
          border-bottom: 2px solid $blue-background;
          &__item {
            .tab-title {
              display: flex;
              align-items: center;

              &__count {
                margin-right: 8px;
                // margin-right: 1rem;
                display: flex;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background: #e4e4e4;
                justify-content: center;
                align-items: center;
                color: #6e6d6d;
              }
            }
          }
        }
        &-block__list {
          .tab-active {
            .tab-title {
              &__count {
                // border: 2px solid #006df9;
                // color: #006df9;
                background-color: #{$rigo-primary-color};
                color: #{$rigo-white-color};
              }
            }
          }
        }
        &-content {
          padding: 0 !important;
          .travel-info-create,
          .travel-delegation-create,
          .travel-group-create,
          .travel-advance-expense-create,
          .travel-document-create {
            background: #{$rigo-white-color};
            padding: 0 20px;
          }
          .travel-create__title {
            padding: 15px 0;
          }
        }
      }
      .badges-group {
        .badges-primary {
          background: #2680eb;
          color: #f0f4f8;
        }
      }
      .comment-history {
        background: #{$rigo-white-color};
        padding: 20px;

        .MuiInputBase-input {
          background: $PlaceholderBackgroundColor;
        }
      }
      .button-group {
        background: #{$rigo-white-color};
      }
    }

    .badges-group {
      display: flex;
      flex-direction: row;
      margin: 10px 0;
      .badges {
        // width: 150px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    .employee {
      padding-bottom: 1rem;
    }
  }

  &-field {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    min-width: 250px;

    &:not(:last-child) {
      margin-right: 10px;
    }

    label {
      color: rgba(0, 0, 0, 0.9);
      padding: 0;
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 2;
      letter-spacing: 0.00938em;
    }
    & > div {
      min-width: 250px;
      max-width: 250px;
      margin-right: 1rem;
    }

    &.small-input {
      min-width: 90px;
      width: 90px;
    }
    .small-input,
    .small-input {
      input {
        min-width: 75px;
        width: 75px;
        border: 1px solid $rigo-input-border-color !important;
      }
    }
    // input {
    //   min-width: 250px;
    //   margin-top: 0 !important;
    //   background: $PlaceholderBackgroundColor;
    // }
    .rdrInputRangeInput,
    .rdrDateDisplayItem input {
      min-width: unset;
      background: unset;
    }
    .genericForm-group {
      max-width: unset;
    }
    .css-yk16xz-control {
      background-color: $PlaceholderBackgroundColor !important;
    }
    .css-g1d714-ValueContainer {
      height: 100%;
    }
    .daterangepicker {
      background: $PlaceholderBackgroundColor;
    }

    .MuiInputBase-input {
      background: $PlaceholderBackgroundColor !important;
      padding: 5px 10px 5px 10px;
    }
    .MuiInputBase-root {
      background: $PlaceholderBackgroundColor !important;
    }
    // .css-2b097c-container{
    //   max-width: 250px;
    // }
    // .css-1wa3eu0-placeholder {
    //   padding: 0 10px;
    // }
    .form-group {
      max-width: 250px;
      &__input {
        padding: 10px 12px !important;
      }
      &.no-margin {
        margin: 0 !important;
      }
    }
    .MuiFormControl-root input {
      min-width: unset;
    }
    textarea {
      width: 100%;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      min-height: 100px;
      padding: 10px;
      font-size: 14px;
      color: #333;
      background: $PlaceholderBackgroundColor;
      padding: 5px 10px 5px 10px;
    }
  }

  &-detail-information {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    margin: 20px 0 5px;
    padding: 0 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    position: relative;

    .travel-field {
      margin: 10px 5px;
    }
    .travel-detail-row {
      align-items: flex-start;
    }

    .row-num {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .icon-btn {
      font-size: 1.8rem;
      color: #d64545;
      margin-left: 10px;
      cursor: pointer;
      &:hover {
        color: lighten(#d64545, 7);
      }
    }
  }

  &-detail-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  &-save {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 10px 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .travel-month {
    margin: 0 4px;
    padding: 10px 25px;
    //background: #e7edf4;
    background: #fcf9f9;
    border: 1px solid #e6e6e6;
    color: #4e4e4e;

    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease-out;

    svg,
    i {
      font-size: 18px;
    }

    &:hover {
      background: #d4e2ec;
    }

    &.current {
      background: #097dd3;
      color: #{$rigo-white-color};

      &:hover {
        background: lighten(#097dd3, 7);
      }
    }
  }
  .autocomplete {
    input {
      &::placeholder {
        color: #8e8e8e;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #8e8e8e;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #8e8e8e;
      }
    }
  }
}

a.change-link {
  color: #4280eb;
  padding: 0 8px;

  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid #d6d6d6;
  }
}

.selected-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  .avatar {
    border: 1px solid #c3c3c3;
  }

  &__name {
    font-size: 14px;
    color: #6d6d6d;
    margin: 0 10px;
    font-weight: 500;
  }

  i,
  svg {
    font-size: 20px;
    color: #e07d7d;
    cursor: pointer;
  }
}

.document {
  margin: 20px 0;
  flex-wrap: wrap;

  &-no {
    font-size: 16px;
    color: #9e9e9e;
  }

  .flex {
    margin-right: 15px;
  }
  &-name {
    font-size: 14px;
    color: #565656;
    margin-right: 12px;
    margin-left: 4px;
  }
  &-icon {
    color: #656565;
    font-size: 16px;

    &.cancel-icon {
      color: #e07d7d;
      font-size: 18px;
      cursor: pointer;
    }
  }
}

.daterangepicker {
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: $default-border-radius;
  border-width: 1px;
  border-style: solid;
  border-color: $rigo-input-border-color;
  background-color: $rigo-input-bg-color;
  align-items: center;
  border-radius: 4px;
  // background: #{$rigo-white-color};
  padding: 8px;
  height: $input-height;
  width: 100%;

  .daterangepicker__icon {
    position: absolute !important;
    right: 0;
  }

  .placeholder {
    color: rgba(0, 0, 0, 0.54);
    font-size: inherit;
  }

  &.active {
    @include inputFocusStyle;
    .daterangepicker__range {
      display: block;
    }
    .daterangepicker__icon,
    .daterangepicker__date {
      color: lighten($btn-primary-color, 15);
    }
  }
  &__date {
    flex-grow: 1;
  }
  &__input {
    border: none;
    flex-grow: 1;
    height: 100%;
    font-size: 14px;
    height: 0;
    outline: none;
    &:focus {
      outline: none;
    }
  }
  &__date {
    font-size: 14px;
    color: #444;
    span {
      // margin: 5px 7px;
      padding: 5px;
    }
  }
  &__range {
    display: none;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1111;
    box-shadow: 0 6px 12px 1px rgba(0, 0, 0, 0.2);
    .rdr-DateRange {
      display: flex !important;
      flex-direction: row;

      .is-inRange {
        background-color: #2680eb !important;
        color: #{$rigo-white-color} !important;
      }
      .is-selected {
        background-color: #d64545 !important;
      }
    }
  }
  &__icon {
    width: 30px;
    margin: 0 5px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7c7c7c;
    font-size: 18px;
  }
}

.expand-icon {
  transform: rotate(0deg);
  transition: transform 0.25s linear;
  &.collapse-icon {
    transform: rotate(-180deg);
  }
}
.second-row-expanded {
  max-height: 150px;
  height: auto;
  visibility: visible;
  opacity: 1;
  line-height: 1;
  transition: height 0.2s linear, line-height 0.2s linear, opacity 0.4s !important;
  //background: #d4d4d4;
  div {
    transition: height 0.2s ease-in-out;
    height: auto;
  }
}
.second-row-collapsed {
  max-height: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  line-height: 0 !important;
  border: none;
  visibility: collapse;

  td {
    padding: 0;
    border: none;
  }
  div {
    height: 0 !important;
    padding: 0;
  }
  // transition:  visibility 0.5s, opacity 0.75s linear;
}
.row-expanded {
  transition: all 0.2s;
  //background:#ececec !important;

  td {
    border-bottom: none;
    padding: 10px 15px 5px !important;
  }
  & + tr {
    transition: all 0.2s;
    //background: #ececec !important
  }
}
.bottom {
  &-td {
    padding: 0px 15px 10px;
  }
  &-row {
    display: flex;
    flex-direction: column;
  }
  &-col {
    display: flex;
    padding: 5px 0 5px 0;
    font-size: 14px;
    color: #454545;

    span {
      margin-right: 25px;

      &.light-text {
        color: #757575;
      }
    }
  }
}

.selected-row {
  background: #ececec !important;
}
.filter-top {
  .search {
    input {
      padding: 7px 12px !important;
      border-radius: 4px !important;
    }
  }
}
.placeholder {
  input {
    ::placeholder {
      color: #333 !important;
    }
    &::placeholder {
      color: #333 !important;
    }
  }
}

.edit-panel {
  background: #{$rigo-white-color};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  .panel {
    button {
      &:hover {
        transform: translateX(-6px) !important;
        box-shadow: unset !important;
      }
    }
  }

  .payroll-body {
    background: unset !important;
    .travel-create {
      background: #f5f5f5 !important;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !important;
    }
  }
}

.active-btn {
  button {
    background-color: $blue-background !important;

    svg,
    span {
      color: #{$rigo-white-color} !important;
    }
  }
}

.approve-container {
  width: 50%;
  // height: 100px;
  @include respond(med-desktop) {
    width: 45%;
  }
  @include respond(big-desktop) {
    width: 40%;
  }
}

.comments-error {
  position: relative;
  // &::after {
  //   content: "" attr(title) "";
  //   position: absolute;
  //   top: 50%;
  //   z-index: 11;
  //   left: 102%;
  //   background: rgba(255, 20, 20, 0.8);
  //   color: #{$rigo-white-color};
  //   padding: 6px;
  //   border-radius: 12rem;
  //   font-size: 12px;
  //   transform: translateY(-50%);
  //   width: 150px;
  //   text-align: center;
  // }
}
.comments {
  width: 100%;
  //height: 100%;
  // border: 1px solid #d7d7d7;
  border-radius: 8px;
  &-header {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #444;
    background-color: #efefef;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &-body {
    padding: 5px 15px;
    // min-height: 150px;
    // height: 150px;
    // max-height: 250px;
    // overflow-y: auto;

    &.no-comments {
      display: flex;
      justify-content: center;
      align-items: center;

      //added to hide div on no comment case
      //height: 0;
      padding: 0;
      span {
        font-size: 20px;
        color: #969696;
      }
    }

    // @include respond(med-desktop) {
    //   max-height: 300px;
    //   height: 175px;
    // }
    // @include respond(big-desktop) {
    //   height: 200px;
    //   max-height: 350px;
    // }

    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  &-list {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;

    &__item {
      width: 100%;
      padding: 5px 0;
      margin: 5px 0;
      border-bottom: 2px solid #dedede;
      &:last-child {
        border-bottom: unset;
        margin: 5px 0 0px;
        padding: 5px 0 0px;
      }
    }
    &__body {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    &__date {
      width: 100%;
      text-align: center;
      position: relative;
      // padding: 5px;
      font-size: 12px;
      color: #666;
      height: 2px;
      background-color: #dcdcdc;
      margin-bottom: 15px;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #{$rigo-white-color};
        padding: 5px 10px;

        //     &::after,
        //     &::before {
        //         content: "";
        //         position: absolute;
        //         top: 50%;
        //         transform: translateY(-50%);
        //         right: 110%;
        //         width: 100px;
        //         height: 1px;
        //         border-radius: 4px;
        //         background-color: #888;
        //     }
        //     &::before {
        //         left: 110%;
        //     }
      }
    }
    &__user {
      display: flex;
      // align-items: center;
      flex-direction: column;
      margin-right: 15px;
    }
    &__img {
      margin-right: 10px;

      & > div {
        width: 30px;
        height: 30px;
      }
    }
    &__name {
      font-size: 13px;
      font-weight: 500;
      color: #444;

      .date-time {
        display: block;
        margin-top: 4px;
        .time {
          font-size: 11px;
          color: rgba(0, 0, 0, 0.4);
          margin-left: 6px;
        }
        .date {
          font-size: 11px;
          color: rgba(0, 0, 0, 0.75);
        }
      }
    }
    &__details {
      padding: 5px 0px;
      // margin-left: 35px;
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.8;
        word-spacing: 1.5;
        color: #444;
      }
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 4px;
    // border-top: 2px solid #dedede;
    // padding-top: 2rem;

    &__textarea {
      textarea {
        width: calc(100% - 8px);
        padding: 8px 15px;
        font-size: 12px;
        color: #555;
        border: none;
        outline: none;
        resize: none;
        height: 60px;
        @include placeholder {
          @include placeholderText;
        }
      }
    }
    &__input {
      flex-grow: 1;
      position: relative;

      & > div {
        width: 100%;

        .MuiInputBase-root {
          &:before {
            content: unset;
            border: none !important;
            &:hover {
              border: none !important;
            }
          }
        }
        .MuiInputBase-input {
          border: none !important;
        }
      }
    }
  }
  &-inputs {
    padding: 5px;
    border: 1px solid #e6e6e6;
    &:empty {
      border: none;
    }
  }
  &-remarks {
    border-top: 1.5px solid #d7d7d7;
    border-radius: 5px;
    border: 0.5px solid #d7d7d7;
    margin: 10px;
    textarea {
      width: 100%;
      padding: 8px 15px;
      font-size: 14px;
      color: #555;
      border: none;
      outline: none;
      resize: none;
      height: 60px;
      border-radius: 8px;
      @include placeholder {
        @include placeholderText;
      }
    }
  }
}

.file-view {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.status {
  display: flex;
  flex-direction: column;
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 0.9;
    padding: 10px 0;

    &.approved {
      .status-status,
      .status-icon {
        color: #5d985b !important;
      }
    }
    &.pending {
      .status-status,
      .status-icon {
        color: #bf7cb5 !important;
      }
    }
  }
  &-name,
  &-status {
    font-size: 14px;
    // font-weight: 500;
    color: #333;
  }
  &-icon {
    font-size: 16px;
    color: #666;
    margin: 0 5px;
  }
  &-time {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    margin-left: 5px;
  }
}

.modal-travel-request {
  z-index: 99999999999999;
  .confirm-buttons {
    padding: 1rem 0;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    .button {
      width: 30%;
    }
  }
}

.modal-travel-reject {
  .form-row {
    .comments {
      border: none;
      &-body {
        padding: 0;
        .comments-remarks {
          border: none;
          textarea {
            height: 80px;
          }
        }
        .comments-error {
          textarea {
            border: 1px solid rgba(255, 20, 20, 0.8);
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .form-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border: 1px solid #e4e4e4;
  }
}

.travel-request-document {
  z-index: 999999999999999999999;
}
