.approval-table {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  .table-contain {
    overflow: visible;
    .table-container .table-table.sticky {
      overflow: visible;

      .pointers {
        cursor: pointer;
        font-weight: bold;
      }
    }
  }

  .ag-status-bar {
    border: none !important;
  }

  .ag-center-cols-container .MuiInput-underline:before {
    display: none !important;
  }

  .ag-status-bar {
    height: 40px;
  }

  .ag-input-field-input.ag-text-field-input {
    height: 40px;
    font-size: 1rem !important;
  }
}

.modal_employee_open {
  .modal__content-body {
    min-height: 300px;
    padding: 20px;
    z-index: 15;
  }
}

/* ========= Grid Styles  ========= */
