.badges {
  //background: #666;
  //color: #{$color-white};
  background: #dedede;
  color: #333232;
  padding: 0.25rem 1rem;
  height: 2rem;
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: default;
  &-blue {
    background: #{$color-blue};

    svg,
    i {
      color: #{$color-blue-fade} !important;
    }
  }
  &.left-icon {
    padding: 0.25rem 1rem 0.25rem 0.25rem;
    svg,
    i {
      font-size: 1.4rem;
      margin-right: 0.2rem;
    }
  }
  &.right-icon {
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    svg,
    i {
      font-size: 1.4rem;
      margin-left: 0.2rem;
    }
  }
  &-default {
    background: #dedede;
    color: #333232;
  }
  &-file {
    display: inline-flex;
    padding: 0.5rem 0.75rem !important;
  }
  &-rounded {
    border-radius: 2.2rem !important;
  }
  &-primary {
    //background: #{$color-primary};
    background: $blue-background;
    color: #f0f4f8;
  }
  &-green {
    background: #{$btn-green-color};
    color: #f0f4f8;
  }
  &-success {
    background: #{$rigo-success-color};
    color: #{$rigo-white-color};
  }
  &-warning {
    background: #{$rigo-warning-color};
    color: #{$rigo-white-color};
  }
  &-red {
    background: #{$color-red};
  }
  &-yellow {
    background: #{$color-yellow};
  }
}

.badge {
  width: 1.5rem;
  display: inline-block;
  height: 1.5rem;
  background-color: #dfdfdf;
  color: #555;
  border-radius: 50%;
  font-size: 12px;
  margin-right: 0.5rem;
  // font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  &.sm {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.8rem;
  }
  &.md {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1rem;
  }
  &.lg {
    width: 2rem;
    height: 2rem;
    font-size: 1.1rem;
  }
  &-dark-light {
    background: #6f6f6f;
    color: #{$rigo-white-color};
  }

  &-primary {
    background: #{$rigo-primary-color};
    color: #{$rigo-white-color};
  }
  &-warning {
    background: #{$rigo-warning-color};
    color: #{$rigo-white-color};
  }
  &-success {
    background: #{$rigo-success-color};
    color: #{$rigo-white-color};
  }
  &-danger {
    color: #{$rigo-white-color};
    background: #{$rigo-danger-color};

    &-fade {
      background: #{$btn-danger-light-color};
      color: #{$rigo-danger-color};
    }
  }
  &-blue {
    color: #{$rigo-white-color};
    background: #{$color-blue};
  }
}

.panel {
  width: 100%;
  padding: 8px 15px;
  background: #d3d3d3;
  color: #444;
  margin: 1rem 0;

  @include respond(med-desktop) {
    padding: 10px 15px;
  }
  @include respond(big-desktop) {
    padding: 12px 15px;
  }

  &.blue {
    background-color: #{$color-blue};
    color: #{$rigo-white-color};
  }
  &.green {
    background-color: #{$color-green};
    color: #{$rigo-white-color};
  }
  &.white {
    background-color: #{$rigo-white-color};
    color: #666;
  }
}
