@import "../../../scss/abstracts/variables";
@import "../../../scss/abstracts/mixins";

@mixin gridColumn {
  grid-template-columns: 27.5% 12.5% 10% 10% 10% 10% 10% 10%;
}

.permission {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;

  &-modal {
    .modal {
      &__content {
        width: 70%;
        &-body {
          @include customScroll;
        }
      }
    }
  }

  &-header {
    width: 100%;
    padding: 10px 0;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #2c2d2c;
      margin-right: 10px;
      letter-spacing: 0.025;
    }

    a {
      font-size: 17px;
      color: #0068d4;
      font-weight: 500;
      cursor: pointer;
      letter-spacing: 0.005;
    }
  }

  &-body {
    flex-grow: 1;
    padding: 10px 30px;
    overflow-y: auto;
  }
  &-modules {
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.17);
    &__header {
      // padding:15px;
      width: 100%;
      border-radius: 3px 3px 0 0;
      display: grid;
      // grid-template-columns: 25% 15% 10% 10% 10% 10% 10% 10%;
      @include gridColumn;
      background-color: #0068d4;
      text-align: center;

      span {
        padding: 15px 10px;
        font-size: 13px;
        font-weight: 400;
        color: #f1f1f1;
        border-right: 1px solid lighten(#0068d4, 20);
        width: 100%;
        overflow: hidden;
        height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
          text-align: left;
        }
      }
    }

    &__body {
      width: 100%;
    }
  }
  &-block {
    margin: 10px 0 30px;
    .form-group {
      margin-bottom: 20px;
      label {
        color: #2c2d2c;
        font-size: 14px;
        letter-spacing: unset;
      }
    }
    .genericForm-group {
      margin-bottom: 20px;
    }
    input {
      height: 34px;
    }
    textarea {
      height: 100px;
      max-width: 100%;
    }
    input,
    textarea {
      background-color: #f4f5f7;
      border: 1px solid #d7d7d7;
    }
  }
  &-title {
    font-size: 17px;
    font-weight: 600;
    color: #2c2d2c;
    margin-bottom: 4px;
  }
  &-subtitle {
    font-size: 14px;
    font-weight: 400;
    display: block;
    color: #2c2d2c;
    margin-bottom: 7px;
  }
  &-btn {
    margin: 10px 0 20px;
    button {
      margin: 0;
    }
  }
  &-footer {
    width: 100%;
    background-color: #f5f7fa;
    .button {
      &-group {
        margin-top: 0;
      }
      &-primary {
        padding: 8px 36px;
      }
    }
    // padding: 10px;
  }
}

.modules {
  width: 100%;

  &-btn {
    border: none;
    outline: none;
    background-color: #f7f8fa;
    font-size: 25px;
    border-right: 1px solid #e1e1e1;
    cursor: pointer;
    text-align: center;
    color: #bebebe;
    &:hover {
      filter: brightness(0.9);
    }
    &.color-green {
      color: #4cb400;
    }
    &.bg-white {
      background-color: #fafafa;
    }
    svg {
      margin: 0 auto;
    }
  }

  &-header {
    display: grid;
    @include gridColumn;

    .modules-control {
      font-size: 14px;
      font-weight: 500;
      padding: 15px;
      background-color: #f7f8fa;
      border-right: 1px solid #e1e1e1;
      color: #3a3d43;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
      &.collapse {
        .icon {
          transition: transform 0.2s;
          transform: rotate(180deg);
        }
      }
      .icon {
        font-size: 22px;
        transition: transform 0.2s;
        color: #3a3d43;
      }
    }
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;

    &__item {
      width: 100%;
      background-color: #fff;
      color: #006df9;
      display: grid;
      max-height: 45px;
      height: 45px;
      overflow: hidden;
      @include gridColumn;

      button {
        background-color: #fff;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        padding: 15px;
        height: 100%;
        border-right: 1px solid #e1e1e1;
      }
    }
  }
}

.collapse {
  &-enter {
    max-height: 0;
    opacity: 0;
    transform: scaleY(0);
    transition: all 300ms;
    &-done {
      max-height: 45px;
      opacity: 1;
      transform: scaleY(1);
    }
    &-active {
      max-height: 45px;
      opacity: 1;
      transform: scaleY(1);
      transition: all 300ms;
    }
  }
  &-exit {
    max-height: 45px;
    opacity: 1;
    transform: scaleY(1);
    &-done {
      max-height: 0;
      opacity: 0;
      transform: scaleY(0);
      transition: all 300ms;
    }
    &-active {
      max-height: 0;
      opacity: 0;
      transform: scaleY(0);
      transition: all 300ms;
    }
  }
}

.assign {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  &-block {
    width: 100%;
    h3 {
      color: #2c2d2c;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0px;
    }
    &__item {
      display: grid;
      grid-template-columns: 50px calc(100% - 50px);
      padding: 15px 0;
    }
    &__img {
      width: 100%;
      text-align: center;

      img {
        width: 35px;
      }
    }
    &__fields {
      padding: 0 15px;
    }
  }
  &-select {
    margin-bottom: 15px;
    &-sm {
      margin: 0px 0 10px;
      margin-left: 25px;

      .MuiInputBase-input {
        background: #f4f5f7;
        border: 1px solid #d7d7d7;
      }
    }
    .MuiInputBase-input {
      background: #f4f5f7;
      border: 1px solid #d7d7d7;
    }
  }
  &-radio-group {
    width: 100%;
    .form__radio {
      &-group {
        margin-bottom: 5px;
        width: 100%;
      }
    }
  }
  &-btn {
    padding: 15px 0;
    button {
      margin-left: 25px;
    }
  }
  &-check-group {
    padding: 10px 0;
    label {
      color: #2c2d2c;
      font-size: 14px;
    }
  }
}
