.filters {
  width: 100%;
  // border: 1px solid #e5e5e5;
  // background-color: #f5f5f5;
  // padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0 0 15px 0;
  // border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  &-grid {
    width: 250px;
    color: $color-grey;
    margin: 10px 20px 0 0;
  }
  //   .MuiInput-underline:before {
  //     content: unset;
  //   }
  //   .MuiInput-underline:after {
  //     border-bottom: 2px solid #{$color-blue};
  //   }
}
// .filter {
//   &-group {
//     width: 150px;
//     margin-right: 15px;
//     margin-top: 15px;

//     &__label {
//       display: block;
//       font-size: 0.75rem;
//       color: #464646;
//       margin-bottom: 0.35rem;
//       margin-left: 0.15rem;
//     }
//     &__select {
//       width: 100%;
//       // height: 2rem;
//       padding: 0.35rem;
//       border-radius: 3px;
//       background: #fff;
//       outline: none;
//       border: 1px solid #d9d9d9;
//     }
//   }
// }
