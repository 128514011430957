@import '../../../scss/abstracts/_variables';

@mixin count {
  width: 25px;
  height: 25px;
  background: rgb(231, 231, 231);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.job-pages {
  padding: 1rem;

  .filters {
    &-grid {
      min-width: 220px;
      width: unset;
    }

    .button {
      padding: 0 10px;
    }
  }
  .custom-grid {
    .button {
      padding: 0 10px;
    }
  }
}
.job-profile-form {
  background: #{$rigo-white-color};
  margin: 1rem;
  &__header {
    padding: 1rem;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close-icon {
      svg {
        font-size: 1.5rem;
        &:hover {
          color: #bb0505;
        }
      }
    }
  }
  &__body {
    padding: 1rem;
    .form-row {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 20px;
      &:first-of-type {
        padding: 0;
      }
      .profile-block {
        margin: 0 0.75rem;
        width: 100%;
        box-shadow: 0 0 5px 0 #c0c0c0;
        border-radius: 3px;
        &__header {
          padding: 0.5rem 1rem;
          background: #0072c6;
          color: #{$rigo-white-color};
          display: flex;
          align-items: center;
          display: flex;
          justify-content: space-between;
          border-radius: 3px 3px 0 0;
          height: 50px;
          .action-bar {
            flex: 1;
          }
          .profile-text {
            width: 50%;
            font-size: 17px;
          }
          .arrow-grp {
            display: flex;
            width: 45px;
            justify-content: center;
            &__down,
            &__up {
              display: grid;
              justify-items: center;
              cursor: pointer;
              svg {
                font-size: 1.5rem;
                color: #c9c9c9;
              }
              .icon-line {
                width: 18px;
                height: 2px;
                background: #c9c9c9;
                color: #c9c9c9;
              }
            }
          }
          .button {
            background: transparent;
            border: none;
            outline: none;
            color: #c9c9c9;
            svg {
              color: #c9c9c9;
            }
          }
          .profile-type {
            display: block;
            color: #c9e5fa;
            font-size: 14px;
            margin-top: 0.3rem;
          }
          .genericForm-group__message {
            display: none;
          }
        }
        &__body {
          padding: 18px 20px;
          display: block;
          .form-group {
            display: grid;
            grid-row-gap: 0.5rem;
          }
          .edit-link,
          .del-link {
            color: rgb(38, 132, 255);
            margin-left: 1rem;
            cursor: pointer;
            padding: 0.3rem 0;
          }
          .ck.ck-editor {
            width: 100%;
            .ck-content {
              height: 150px;
            }
          }
          .edu-qual-li,
          .ksa-li {
            &:not(:last-child) {
              padding-bottom: 0.5rem;
            }
            .edu-qual-count,
            .ksa-count {
              width: 20px;
              height: 20px;
              background: #e4e4e4;
              border-radius: 50%;
            }
            .close-icon {
              font-size: 21px;
              transition: all 0.1s;
              &:hover {
                background: #feebeb;
                border-radius: 3px;
                path {
                  stroke: #f87474;
                }
              }
              path {
                stroke: #726969;
                transition: all 0.1s;
              }
            }
            .edu-qual-text,
            .ksa-text {
              color: #2684ff;
            }
          }
          .button {
            margin: 0;
          }
          .insert-btn {
            background: #eef7ff;
            color: #2684ff;
          }
          .component-header {
            font-size: 0.9rem;
          }
          .form-row {
            .subtitle {
              font-weight: 500;
              font-size: 16px;
              color: #4a4343;
            }
            .subtitle-type {
              color: #656363;
            }
          }
        }
      }
      .form-group__input {
        margin: 0;
      }
      .edit-box-title {
        padding: 0.6rem;
        background: #{$rigo-white-color};
        border-radius: 2px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: #e4e4e4;
        }
      }
      .edit-box-subtitle,
      .remove-box-subtitle {
        padding: 0.6rem;
        background: #{$rigo-white-color};
        border-radius: 2px;
        display: flex;
        align-items: center;
        cursor: pointer;
        box-shadow: 0 0 7px 2px #e4e4e4;
        &:hover {
          background: #e4e4e4;
        }
      }
    }
    .section {
      padding-bottom: 10px;
      // padding-bottom: 50px;
    }
  }
  &__footer {
    padding: 1rem;
    border-top: 1px solid #e4e4e4;
    .button-orange {
      background: rgb(255, 153, 31);
      color: #{$rigo-white-color};
    }
  }
}
.modal-add-section {
  .add-section-form {
    &__body {
      padding: 1rem;
      .form-row {
        padding: 0.5rem;
      }
    }
    &__footer {
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid #e4e4e4;
    }
  }
}

.modal-insert-edu-qual,
.modal-insert-ksa {
  .modal__content {
    background: #{$rigo-white-color};
    .insert-ksa-form {
      padding: 1rem;
    }
    .insert-edu-qual-form,
    .insert-ksa-form {
      .add-section-form__body {
        padding: 0.5rem 2rem;
        .form-row {
          padding: 1rem 0;
          .edu-qual {
            border: 1px solid #e4e4e4;
            border-radius: 5px;
            &__li {
              padding: 0 1rem;
              &:not(:last-child) {
                border-bottom: 1px solid #e4e4e4;
              }
              .btn-grp {
                width: 150px;
                padding: 0.5rem 0;
                .selected-btn {
                  background: rgb(109, 212, 0);
                  color: #{$rigo-white-color};
                  outline: none;
                }
                // .select-btn {
                //   //background: rgb(0, 101, 255);
                //   color: #{$rigo-white-color};
                //   outline: none;
                // }
              }
            }
          }
          .ksa-count {
            @include count();
          }
          .ksa-name {
            padding-left: 1rem;
          }
        }
      }
      .add-section-form__footer {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid #e4e4e4;
      }
    }
    .insert-ksa-form {
      .add-section-form__body {
        padding: 0;
        font-size: 0.85rem;
        border: 1px solid #e1e1e1;
        .form-row {
          padding: 1rem;
          .form-group__input {
            margin: 0;
          }
          .error-message {
            color: #{$rigo-white-color};
            background: #ca0d0d;
            border-radius: 3px;
            padding: 0.5rem 1rem;
          }
          .level-count {
            @include count();
          }
        }
        .ksa-slider-row {
          background: #f3f7ff;
          &:not(:last-of-type) {
            border-bottom: 1px solid #e1e1e1;
          }
        }
      }
      .add-section-form__footer {
        padding: 1rem 0 0 0;
        justify-content: space-between;
      }
    }
  }
}

.jd-form {
  .form-row {
    .profile-block {
      &__header {
        justify-content: flex-start;
        .profile-text {
          width: unset;
        }
        .profile-type {
          margin-left: 0.75rem;
          color: #c1c1c1;
          color: #c9e5fa;
          font-size: 15px;
        }
      }
    }
  }
}
