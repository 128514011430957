@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.drop {
  z-index: 1111;
  &-fixed {
    position: relative;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);

    .drop__content {
      top: 50px;
      left: 180% !important;
      transform: translateX(-50%);
      min-width: 50vw;

      &::after {
        @include respond(desktop) {
          left: 20% !important;
        }
        @include respond(med-desktop) {
          left: 25% !important;
        }
        @include respond(big-desktop) {
          left: 30% !important;
        }
        // transform: translateX(-50%);
      }
    }
  }

  .drop__content {
    visibility: hidden;
  }
  &.active {
    .drop__content {
      opacity: 1;
      visibility: visible;
      width: 50%;
      position: absolute;
      left: 5;
      right: unset;
      &::after {
        position: absolute;
      }
    }
  }

  &__content {
    transition: none !important;
    @include dropDownContent;
    top: 50px;
    right: -7px;
    width: 250px;
    padding: 15px;

    &::after {
      top: -11px;
      width: 20px;
      height: 20px;
      left: 10%;
    }
  }
}

.btn-start {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_blue;
  padding: 0 5px;
  font-size: 18px;
  span {
    margin: 0 10px;
  }
}

.time-function {
  &-activity {
    display: block;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    // border-radius: 10px;
    // padding: 20px 10px;
    // background: $color-white;

    &-header {
      margin-bottom: 10px;
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-left {
        display: inline-flex;
        align-items: center;

        &-action {
          display: inline-flex;
          margin-right: 10px;
          // font-size: 50px;

          .group {
            cursor: pointer;
            padding: 2px 10px 2px 5px;
            border-radius: 5px;
            color: #{$rigo-white-color};
          }
          .start {
            background: #6dd400;
          }
          .complete {
            background: #0098ff;
            margin-right: 5px;
          }
          .pause {
            background: #32c5ff;
          }
        }
        &-detail {
          display: block;
          &-client {
            text-transform: capitalize;
            font-size: $default-fontSize;
            font-weight: 500;
          }
          &-project {
            font-size: $default-fontSize;
          }
        }
      }
      &-right {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        font-size: $default-fontSize;
        &-clock {
          color: $rigo-primary-color;
          font-size: $default-fontSize;
          margin-top: 4px;
        }
      }
    }

    &-selection {
      margin: 10px;
      .MuiFormControl-root {
        width: 570px;
      }
      .MuiPaper-root {
        width: 570px;
      }
      .MuiInputBase-root {
        margin-top: 20px;
      }
    }

    &-top_three {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin: 10px 10px 5px 10px;

      .active {
        background: #d6e6f6;
      }
      &-project {
        color: $color-blue;
        font-weight: 500;
      }
      &-client {
        color: $color-grey;
      }
    }

    &-list {
    }
  }
}

.project-time {
  display: block;
  margin-bottom: 10px;
  &-section {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    &-activity {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 20px 10px;
      background: $color-white;

      &-header {
        margin-bottom: 10px;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &-left {
          &-icon {
            margin-right: 10px;
          }
          &-text {
            font-size: larger;
            font-weight: 600;
          }
        }
        &-right {
        }
      }
    }
    &-description {
      padding: 20px 20px 20px 40px;
    }
  }
}
