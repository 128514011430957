@import "../../../../scss/abstracts/_variables";

@mixin box-shadow {
  -webkit-box-shadow: 0px 0px 2px 0px #d6d6d6;
  -moz-box-shadow: 0px 0px 2px 0px #d6d6d6;
  box-shadow: 0px 0px 2px 0px #d6d6d6;
}
@mixin block {
  background: #{$rigo-white-color};
  //padding: 1rem;
  @include box-shadow();
}
@mixin emp-name {
  font-weight: 500;
  color: #4390df;
  padding: 0;
}
@mixin header {
  padding: 1rem;
  background: #f5f7fa;
  .header-title {
    font-weight: 500;
  }
}
.modal-leave-details {
  .modal__content {
    background: #f5f7fa;
    width: 85%;
    .leave-details {
      padding: 2rem;
      &__header {
        .emp-name {
          @include emp-name();
        }
      }
      &__body {
        font-size: 0.9rem;
        .my-request {
          &__header {
            padding: 1rem 0 0 1rem;
            .request-type {
              font-size: 17px;
              color: #2f2e2e;
              font-weight: 500;
            }
            .attachments {
              &__files {
                .attachment-file {
                  padding: 0.2rem;
                  color: #4390df;
                  cursor: pointer;
                  &__text {
                    margin-left: 0.2rem;
                  }
                }
              }
            }
          }
          @include block();
          margin: 1rem 0 1rem 0;
          &__body {
            padding: 1rem;
            border-top: 1px solid #ececec;
            .emp-details {
              padding-left: 1.5rem;
              .desg-depart {
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .my-leave-balance {
          &__header {
            padding: 1rem;
            @include header();
          }
          &__body {
            padding: 1rem;
            font-size: 13px;
          }
          @include block();
          margin: 0 0 1rem 0;
        }
        .emp-onleave {
          &__header {
            padding: 1rem;
            @include header();
            .tab-title {
              cursor: pointer;
              font-weight: 500;
            }
            .active {
              color: #4390df;
            }
          }
          &__body {
            padding: 1rem;
            .date {
              padding-top: 0.1rem;
              color: #6f6f6f;
              font-size: smaller !important;
            }
            .thump-up {
              padding: 0.8rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f1f1f1;
              border-radius: 50%;
              svg {
                color: #b9b8b8;
              }
            }
            &-row {
              display: grid;
              grid-template-columns: 33% 33% 33%;
              align-items: flex-start;
              grid-gap: 10px;
              margin-bottom: 20px;

              .flex-column {
                span {
                  margin-bottom: 5px;
                  font-size: 13px;
                }
              }
            }
          }
          @include block();
          // margin: 1rem 0 1rem 1rem;
        }
        .emp-details {
          .emp-name {
            @include emp-name();
            font-size: 14px;
          }
          .desg {
            font-size: 14px;
            font-weight: 400;
          }
          .desg-depart {
            padding-left: 0.5rem;
          }
        }
        .audit-history {
          margin-bottom: 1rem;
        }
        .right-col {
          margin: 1rem 0 1rem 1rem;
          &:empty {
            display: none;
          }
        }
      }
    }
  }
}

.modal-leave-details.sm-modal {
  .modal__content {
    width: 50%;
  }
}
