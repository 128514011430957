@import "../../../scss/abstracts/_variables";
.personal {
  width: 1300px;
  max-width: 100%;
  // width: 100%;
  // padding: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333;

  &-container {
    // width: calc(100% - 40px);
    // background: #e9eff8;
    // padding-top: 25px;
    padding: 10px 20px;
    // padding: 20px 30px;
  }
  &-row {
    display: grid;
    grid-gap: 15px;
    // grid-template-columns: repeat(2,1fr);
    grid-auto-columns: 1fr 1fr;
    // margin-right: 21px;
  }
  &-column {
    width: 100%;
    // padding: 35px;
    padding-bottom: 15px;
    padding-right: 15px;
    // padding: 10px;
    // margin-right: 10px;

    &:nth-child(1) {
      grid-column: 1;
    }
    &:nth-child(2) {
      grid-column: 2;
    }
  }
  &-block {
    display: flex;
    flex-direction: column;

    &__title {
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 14px;
        color: #2c2d2c;
        font-weight: 500;
      }
    }
    &__address {
      &-location {
        position: absolute;
        top: 50px;
        right: 10px;
        width: 250px;
        height: 200px;

        & > div {
          width: 100%;
          height: 100%;
        }
      }
      &-map {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-image {
          width: 140px;
          height: 140px;
          margin-bottom: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-geo {
          width: 100%;
          height: 80vh;
          position: relative;
        }
      }
    }
    &__body {
      background-color: #{$rigo-white-color};
      // padding: 15px 20px;
      position: relative;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
    }
    &__btn {
      height: 30px;
      color: #8691a2;
      font-size: 13px;
    }
    &__icon {
      // position: absolute;
      // top: 15px;
      // right: 15px;

      svg,
      i {
        font-size: 20px;
        color: #eee;
      }
    }

    &__div {
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin-bottom: 5px;
      color: #172b4d;
      width: 100%;
      font-size: 13px;
      padding: 0px 15px;
      height: 40px;
      &:not(:last-child) {
        border-bottom: 1px solid #b6bec980;
      }
      .value {
        font-weight: 500;
      }

      .personal-label {
        margin-right: 10px;
        // width: 200px;
        width: 120px;
        font-weight: 400;
        // color: rgba(#2C2D2C,.75);

        &-health {
          width: 200px;
        }
      }
      .graduation-icon {
        font-size: 25px;
        margin: 0 12px 0;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .document-icon {
        color: #707070;
        margin: 0 15px 0;
        font-size: 18px;
      }
      .document-expires {
        color: #e25858;
      }
      .value1 {
        font-weight: 500;
        color: rgba(#000000, 0.75);
        padding: 5px;
      }
      .value2 {
        color: #707070;
        padding: 3px 10px;
      }
      .badges-group {
        .badges-secondary {
          background: #485160;
          color: #f0f4f8;
          font-size: 14px;
          height: 30px;
        }
        display: flex;
        flex-direction: row;
        margin: 15px 0;

        .badges {
          // width: 150px;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
  }

  // &-header {
  //     &-row {
  //         background: #c1d2b626;
  //         display: grid;
  //     }
  //     &-image {
  //         margin-right: 30px;
  //         margin-left: 20px
  //     }
  //     &-options {
  //         display: flex;
  //         position: absolute;
  //         right: 0;
  //     }
  //     &-column {
  //         display: flex;
  //         width: 100%;
  //         padding: 10px;
  //         &:nth-child(1) {
  //             margin-right: 30px;
  //             grid-column: 1;
  //         }
  //         &:nth-child(2){
  //             grid-column: 2;
  //         }
  //     }
  //     &-block {
  //         display: flex;
  //         flex-direction: column;
  //     }
  //     &-position {
  //         margin-bottom: 10px;
  //         font-weight: 600;
  //         color: #666;
  //     }
  //     &-name {
  //         margin-bottom: 5px;
  //         h1 {
  //             color: #0068d4;
  //         }
  //     }

  // }
}
// .btnTravelRequest {
//     display: flex
// }

.personal-column__address {
  .personal-block__body {
    height: 270px;
    padding: 20px;
    .personal-block__div {
      border-bottom: none;
      padding: 0;
    }
  }
}
