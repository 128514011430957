.update{
    &-container{
        position: fixed;
        // width: 200px;
        // padding: 4px;
        width:240px;
        background: #{$rigo-white-color};
        border-radius: 6px;
        // transition:all .2s;
        display: flex;
        flex-direction: column;
        border: 1px solid #cccccc;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);

        // &:before{
        //     content:'';
        //     position: absolute;
        //     width: 0;
        //     height: 0;
        //     bottom: calc(100% - 14px);
        //     left: calc(50% - 7px);
        //     border: 10px solid black;
        //     border-color: white #ffffff transparent transparent;
        //     transform-origin: 0 0;
        //     transform: rotate(135deg) translateX(-50%);
        //     box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.4);
        // }
        &::after{
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 5px;
            bottom: calc(100% - 20px);
            left: 50%;
            box-sizing: border-box;
            border: 10px solid #c3c3c3;
            border-color: #dadada00 #fff0 #ffffff #ffffff;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: -2px 1px 2px 0px rgba(0, 0, 0, 0.12);
            z-index: -9;
        }
    }
    &-group{
        padding: 10px 10px 2px;
        
        &__input{
            display: block;
            margin: 4px 4px 8px;
            width: calc(100% - 8px);
            input{
                width: 100%;
                border:1px solid #dfdfdf;
                padding:8px 10px;
                font-size: 14px;
                color:#555;
                border-radius: 4px;

                &:focus{
                    border:1px solid #{$color-blue};
                    outline:none;
                }
            }
        }
        &__btn{
            // margin:2px;
            // width: calc(100% - 4px);
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            padding: 10px;
            border-top: 1px solid #ededed;

            button{
                padding: 6px 15px;
                text-align: center;
                cursor: pointer;
                text-transform: capitalize;
                color:#666;
                display: flex;
                justify-content: center;
                align-items: center;
                border:none;
                border-radius: 4px;
            }
            .update-close-btn{
                background: #fafafa;
                // border-top-left-radius: 4px;
                // border-bottom-left-radius: 4px;
                margin-right: 15px;
                svg{
                    font-size: 20px;
                }

                &:hover{
                    background: #efefef;
                }
            }
            .update-primary-btn{
                flex-grow: 1;
                text-align: center;
                color:#{$rigo-white-color};
                // border-top-right-radius: 4px;
                // border-bottom-right-radius: 4px;
                background: #{$color-blue};

                &:hover{
                    background:lighten($color-blue,5);
                }
                
                svg{
                    font-size: 20px;
                    margin-right: 10px;

                }
            }
        }
    }
}