.comment {
  &__header {
    span {
      font-weight: 500;
      color: #0e86ff;
    }
  }
  &__body {
    .comment-li {
      &:not(:first-child) {
        padding-top: 0.5rem;
      }
      .comment-details {
        &:not(.last-child) {
          padding-bottom: 0.5rem;
          border-bottom: 1px solid #ececec;
        }

        .commenter {
          font-weight: 500;
          color: #3c3c3c;
        }
        .comment-date {
          color: #868282;
          font-size: 0.8rem;
        }
        .comment-text {
          padding-top: 0.1rem;
          color: #464444;
        }
      }
    }
    .my-comment {
      &:not(.no-comment) {
        margin: 0px 0px 0px 2rem;
        border-top: 1px solid #ececec;
        padding-top: 0.5rem;
      }

      &__header {
        font-weight: 500;
      }
      .close-icon {
        font-size: 1rem;

        margin-top: 0.2rem;
      }
    }
    .comment-input {
      &:not(.no-comment) {
        padding-left: 2rem;
      }
      .genericForm-group{
        margin-right: 0;
        input{
          border-radius: 3px 0px 0 3px;
          border-right: none;
        }
  
      }
      &:focus-within{
        .check-button{
          outline: none;
          border-color: #0074e9;
          border-width: 0.5px;
          box-shadow: 0px 0 0 0.2px #0074e9, 1px 0 0 1.25px rgba(0, 116, 233, 0.4);
        }
      }
      .check-button {
        color: #fafafa;
        background-color: #0e86ff;
        padding: 0.3rem;

        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 3px 3px 0;
        span {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
