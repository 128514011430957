@import '../../../../scss/abstracts/_variables';
.travel-category {
  .e-templatecell {
    vertical-align: top;
    .description {
      margin: 10px;
    }
    .btn-col {
      padding: 5px;
    }
  }
  .e-grid .e-content {
    overflow: hidden !important;
  }
}
.travel-category-form {
  .tabs-card {
    box-shadow: none;
    background-color: unset;
    padding-top: 15px;
    .form-row {
      label {
        color: #5c5454;
      }
      label + .MuiInput-formControl {
        margin-top: 25px;
      }
    }
  }
  .tabs-block__list {
    border-bottom: 2px solid #0a7ed3;
    background-color: unset;
    flex-wrap: wrap;
    .tab-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        font-size: 1.2rem;
        margin-left: 0.5rem;
      }
    }
  }
  .tabs-block__item {
    padding: 10px;
    background: #{$rigo-white-color};
    margin: 0px 2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 0.7rem;
  }
  .tabs-block .tab-active {
    color: #{$rigo-white-color};
    font-weight: unset;
    border-bottom: unset;
    background: #0a7ed3;
  }
  textarea {
    margin: 10px 0px 0px;
    border: 1px solid rgb(217, 215, 215);
    width: 100%;
    padding: 1rem;
  }
  .form-label {
    span {
      font-size: 0.8rem;
      color: #5c5454;
      letter-spacing: 0.5;
    }
  }
  .position-grid {
    display: grid;
    width: 65%;
    font-size: 0.8rem;
    align-items: center;
    &__header {
      display: grid;
      grid-template-columns: 70% 10% 5% 5%;
      grid-column-gap: 10px;
      justify-content: space-between;
      padding: 10px;
      background: #{$rigo-white-color};
      border-bottom: 2px solid #c3c3c3;
      color: #515151;
    }
    &__body {
      background: #{$rigo-white-color};
      border-bottom: 1px solid#c3c3c3;
      .grid-row {
        display: grid;
        grid-template-columns: 70% 10% 5% 5%;
        grid-column-gap: 10px;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border-bottom: 1px solid #e1e1e1;
        input {
          width: 60px;
          border: 1px solid #e4e4e4;
          padding: 0.2rem;
          /* border-color: #e0e0e0; */
          border-radius: 5px;
        }
        &__amount {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .level-section {
    .flex {
      justify-content: space-between;
      padding: 15px 0;
    }
    .level-select {
      width: 65%;
      .multiselect {
        .css-g1d714-ValueContainer {
          height: 100%;
        }
      }
      .level-input {
        .genericForm-group {
          margin-right: 0.5rem;
        }
      }
    }
    .level-input {
      width: 30%;
      display: flex;
      align-items: flex-end;
    }
    .btn-grey {
      background-color: #e5e9ed;
      color: #5d5d5d;
      font-weight: unset;
    }
    .form-group {
      width: 110px;
      margin-right: 0;
      .form-group__label {
        display: block;
      }
      .form-group__input {
        width: 5.5vw;
        padding: 0.3rem 0.2rem;
      }
    }
  }
}

.modal {
  &-travel-category {
    .modal__content {
      width: 46%;
    }
  }
  &-warn {
    .modal__content-body {
      padding: 10px;
      .confirm-title {
        text-align: center;
      }
      p {
        padding: 1rem 0;
        color: #5c5c5c;
        text-align: center;
      }
    }
  }
}
