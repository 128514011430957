.job-requisition-form {
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    width: 75vw;
    border: 1px solid #d3d3d3;
    &__header {
      padding: 1rem;
      background: #d5e3f7;
      span {
        font-weight: 500;
      }
    }
    &__body {
      padding: 2rem 8vw;
      //background: #f0f2f5;
      background: #fff;
      .section {
        &__btnGrp {
          display: flex;
          .job-type {
            margin-right: 10px;
            width: 160px;
            padding: 8px 5px;
            color: #fff;
            border-radius: 20px;
            font-size: 13px;
            background-color: #aaaaaa;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              margin-right: 6px;
            }

            &-active {
              background-color: #0065ff;
            }
          }
        }
        &__header {
          padding: 1rem 0;
          border-bottom: 2px solid lightgrey;
          span {
            font-weight: 500;
          }
        }
        &__body {
          padding: 1rem 0;
          .form-row {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 1rem;
            .form-label {
              width: 150px;
              font-size: 0.9rem;
              text-align: right;
            }
            .input-grp {
              padding-left: 2rem;
              //width: 500px;
              .form-group {
                margin: 0;
              }
              .form-group__input {
                padding: 7px 12px;
              }
              .pickers {
                margin: 0;
                width: 300px;
                .MuiFormControl-root {
                  width: 100%;
                }
              }
              .custom-check {
                margin: 0;
                span {
                  white-space: nowrap;
                }
              }
              .job-summary,
              .ck-editor {
                width: 35vw;
              }
              .makeStyles-root-1,
              .makeStyles-root-230 {
                margin: 0;
              }
            }
            .error__message {
              white-space: nowrap;
            }
            .genericForm-group__message {
              display: none;
            }
            .btn-grp {
              padding-left: 1rem;
              button {
                padding: 0.5rem 0.7rem;
                border-radius: 5px;
                border: none;
                background: #b3d2f8;
              }
            }
          }
        }
      }
    }
  }
  .approver {
    margin-top: 1rem;
    .form-row {
      padding: 1rem;
    }
  }
  .footer {
    position: unset;
    .form-row {
      padding: 1rem 0;
      justify-content: flex-end;
      display: flex;
      align-items: center;
    }
  }
}

.requisition-filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 1rem 0;
}

.requisition-request-grid {
  .approver {
    align-items: center;
    svg {
      margin: 0;
    }
    span {
      white-space: nowrap;
    }
  }
}
