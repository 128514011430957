@keyframes slide-down {
  0% {
    transform: translate3d(0, -200%, 0);
  }
  100% {
    transform: translateZ(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes fade-in {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
}

@keyframes leaf-right {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(-15deg);
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.4);
  }
}

@keyframes leaf-left {
  0% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(15deg);
  }
}

@keyframes leaf {
  0% {
    transform: rotateX(-45deg);
  }
  100% {
    transform: rotateX(45deg);
  }
}

@keyframes hand {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

// transform: translate3d(0, -120%, 0);
// background: #F4F5F8;
// overflow: hidden;

// flex: 0 0 auto; header

// flex: 1;
// overflow: auto;
.animation-overlay {
  position: absolute;
  top: 0;
  z-index: 111;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(228, 228, 228, 0.5);
  // overflow-y: auto;
  //z-index: 999999999999;
}

@keyframes fade-icon-in {
  0% {
    transform: rotate(60deg) scale(0.5);
    // opacity: 0;
  }
  100% {
    transform: rotate(0deg) scale(1);
    // opacity: 1;
  }
}

@keyframes spin-hard {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@keyframes spins {
  0% {
    transform: rotate(0);
    //   animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spins-reverse {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes background-dark {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(26, 26, 26, 0.9);
  }
}

@keyframes background-light {
  0% {
    background-color: rgba(26, 26, 26, 0.9);
  }
  100% {
    background-color: transparent;
  }
}

.fade-in {
  // animation: slide-down .5s both ;
  animation-timing-function: linear;
  // animation-timing-function: cubic-bezier(.34,1.06,.7,.46);;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  // animation-duration: 0.85s;
  // -webkit-animation-duration: 0.85s;
  animation-name: fade-in;
  -webkit-animation-name: fade-in;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  // animation-delay: 0.1s;
  // -webkit-animation-delay: 0.1s;
  transition: width 0.25s ease-in-out, visibility 0s 0.25s ease-in-out,
    height 0.25s ease-in-out;
}
.fade-out {
  // animation: slide-down .5s both ;
  animation-timing-function: linear;
  // animation-timing-function: cubic-bezier(.34,1.06,.7,.46);;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  // animation-duration: 0.85s;
  animation-name: fade-out;
  -webkit-animation-name: fade-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes wk-rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
