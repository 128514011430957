.recruit {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
}
.search-jobtitle {
  border-bottom: 1px solid rgba(0, 0, 0, 0.065);

  padding: 15px;
  @media screen and (min-width: 960px) {
    display: flex;
  }

  .jobfiltertitle {
    flex: 0 0 60%;
    margin-bottom: 15px;
    @media screen and (min-width: 960px) {
      margin-bottom: 0;
    }
    + .inputWidth {
      margin-bottom: 0;
      @media screen and (min-width: 960px) {
        margin-bottom: 0;
        margin-left: 15px;
        margin-right: 0;
      }

      .react-date-picker__wrapper {
        height: 36px;
      }
    }
  }
  .jobfilterdate {
    flex: 0 0 40%;
  }
}
.genericForm-group__search {
  max-width: 100%;

  background: #ececec;
  &:focus {
    background: #fff;
  }
  input {
    background: #ececec;
    &:focus {
      background: #fff;
    }
    &::placeholder {
      font-weight: 300;
      font-size: 14px;
    }
  }
}
.recruitment_title {
  padding-left: 5px;
}
@media (max-width: 800px) {
  .recruitment-tabs {
    order: 2;
  }
  .recruitment-btns {
    order: 1;
    flex-wrap: wrap;
    margin-bottom: 15px;
    > .button {
      margin: 0 15px 10px 0;
    }
  }
}

.dashboard main {
  &.recruitment_top_section {
    padding: 0;
    margin-top: 5pxpx;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
  }
  &.dashboard__body {
    padding: 20px 5px 0 5px;
  }
}
.recruitmentinfo {
  &-blocks {
    padding: 0 15px;
    &.joblists {
      flex: 1 0 23%;
      min-width: 23%;
      padding: 0;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        flex: 1 0 50%;
        min-width: 50%;
      }
      @media (max-width: 768px) {
        margin-bottom: 25px;
      }
    }
    &.applicantlists {
      min-width: 25%;
    }
    &.secondarylists {
      flex: 1;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        flex: 1 0 50%;
        min-width: 50%;
      }
      .flex {
        margin: 0 -15px;
        align-items: flex-start;
        flex-wrap: wrap;
      }
    }
    &.applicantlists {
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        flex: 1 0 50%;
        min-width: 50%;
        margin-top: 25px;
      }
    }
  }

  &-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1 0 35%;

    > div {
      flex: 1;
      margin-bottom: 25px;
      display: flex;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        flex-basis: calc(50% - 15px);
        min-width: calc(50% - 15px);

        justify-content: flex-start;
      }
    }

    > div:not(:last-child) {
      margin-right: 15px;
    }
    .recruitment__dashboard-item {
    }
  }
}
.percentvalue {
  display: flex;
  align-items: center;
  .datavalue {
    font-size: 18px;
    font-weight: 400;
    padding-left: 25px;
    &.shortlist {
      color: #43c463;
    }
    &.hired {
      color: #0074e9;
    }
  }
}
.recruitment_top_section {
  display: flex;
  justify-content: space-between;

  .recruitment-select {
    grid-column: 1 / 4;
    width: 100%;
    min-width: 250px;
    padding-left: 10px;
  }

  .recruitment-btns {
    display: flex;
    .button {
      @media (max-width: 800px) {
        width: 135px !important;
        height: 38px !important;
      }
    }
    .button-white {
      color: #0065ff !important;
      width: 156px;
      height: 44px;
      border-radius: 3px;
      position: relative;
      border-radius: 4px;
      &:hover {
        background: #0065ff;
        color: #fff !important;
      }
    }
  }
}
.joblistwrapper {
  @media (max-width: 768px) and (min-width: 575px) {
    display: flex;
    flex-wrap: wrap;
  }
  .paginate {
    > div {
      border: none;
      margin-top: 15px;
      width: 100%;
      flex-direction: column;
      .MuiTablePagination-root {
        order: 2;
      }
    }
  }
}
.recruitment {
  @media (max-width: 768px) {
    display: block;
  }
  border-radius: 3px;
  margin: 0 -15px;

  display: flex;
  flex-wrap: wrap;
  &-info {
    flex: 1;
  }
  .recruitment-funnelchart {
    .recruitment__dashboard-item {
      background: none;
      padding: 0;
    }
  }
  &-hiringstages {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 25px;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      flex: 1 0 100%;
      min-width: 100%;
      margin-bottom: 25px;
    }
    @media (max-width: 768px) {
      flex: 1 0 100%;
      min-width: 100%;
      margin-bottom: 25px;
    }
  }
  // display: grid;
  // grid-template-rows: 142px minmax(396px, auto);
  // grid-template-columns: repeat(10, 1fr);
  // row-gap: 26px;
  // column-gap: 30px;
  &-navigation {
    padding: 0 15px;
    flex: 1 0 45%;
  }
  &-panel {
    display: flex;
    flex: 1 0 55%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  &-tabs {
    ul {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #006df9;
      }
    }
    li {
      display: inline-block;
      margin-right: 10px;

      span {
        @media (max-width: 800px) {
          padding: 10px 30px;
        }
        cursor: pointer;
        padding: 10px 40px;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 2px solid transparent;
        border-radius: 5px 5px 0 0;
        color: #8d8d8d;
        background: #e9e9e9;
        display: block;
        transition: 0.3s ease;

        &:hover {
          background: #006df9;
          color: #fff;
        }
      }
      &.active {
        span {
          border-bottom: 2px solid #006df9;
          background: #006df9;
          color: #fff;
          border-radius: 5px 5px 0 0;

          font-weight: 500;
        }
      }
    }
  }
  .recruitment__dashboard-item {
    width: auto;
    padding: 15px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;

    align-items: center;
    p {
      color: #595c72;
      text-align: left;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 400;
    }
    h1 {
      font-size: 26px;
      font-weight: 600;
      color: #3a3a3a;
    }
    &_review {
      margin-right: 1.5rem;
      background-color: #fff;
      min-width: 539px;
      height: auto;
      display: flex;
      border-radius: 3px;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
      flex-direction: column;
      &-top {
        padding: 15px 20px;
        border-radius: 5px 5px 0 0;
        font-size: 16px;
        font-weight: 500;
        color: #172b4c;
        border-bottom: 1px solid #ececec;
        background-color: rgba(0, 0, 0, 0.025);
        display: flex;
        justify-content: space-between;
      }

      &-body {
        @media (max-width: 768px) {
          flex: 1 0 100%;

          &:nth-child(n) {
            border-right: 1px solid rgba(0, 0, 0, 0.065);
          }
        }
        display: flex;
        justify-content: space-between;

        cursor: pointer;

        .joblink {
          padding: 10px 20px;
          font-size: 15px;
          color: #2c2c2c;
          transition: 0.3s;
          &:hover {
            background-color: #f5f5f5;
          }
          &.active {
            background-color: rgba(0, 109, 249, 0.15);
            .ats-btn {
              background: #fff;
              &:hover {
                background: #2680eb;
              }
            }
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.065);
        }

        .titles {
          color: #2680eb;
          font-weight: 400;
          padding-right: 20px;
        }
      }
    }
  }
  .item1,
  .item2,
  .item3,
  .item4 {
    border: 1px solid transparent;
    transition: 0.3s ease;
    &:hover {
      box-shadow: 0px 10px 25px rgb(0 0 0 / 3%);
      border: 1px solid rgba(0, 0, 0, 0.05);
      transform: translateY(-2px);
    }
  }
  .item1 {
    grid-column: 1 / 3;

    &-opening-link {
      color: #2c2c2c;
    }
  }

  .item2,
  .item3,
  .item4 {
    display: flex;

    justify-content: space-between;
  }
  .item2 {
    grid-column: 3/5;
    position: relative;
    .blue-text {
      color: #006df9;
      font-size: 28px;
      position: absolute;
      bottom: 0;
      right: 25px;
    }
  }

  .item3 {
    grid-column: 5/6;
  }

  .item4 {
    grid-column: 6/8;
  }

  .item5 {
    grid-column: 8 / 11;
    grid-row: 1 / 3;
    padding: 0;
  }

  .item6 {
    grid-column: 1/5;
    padding: 0;
  }

  .item7 {
    grid-column: 5/8;
    padding: 0;
  }
}
.ats-btn {
  border: 1px solid #dfdfdf;
  color: #2680eb;
  font-weight: 400;
  border-radius: 3px;
  padding: 6px 18px;
  transition: 0.3s ease;
  &:hover {
    background: #2680eb;
    color: #fff;
    border: 1px solid transparent;
  }
}
.joblink {
  align-items: baseline;
  width: 100%;
  .jobapplicants {
    margin-left: auto;
    padding-right: 35px;
    font-weight: 500;
  }
}
.test {
  padding: 10px;
}

.recruitment-hiring-stage {
  display: grid;
  align-items: center;
  padding: 10px 20px;
  grid-template-columns: 4fr 1fr 1fr;
  font-size: 15px;
  &:hover {
    background-color: #f5f5f5;
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
  }
  .center-span {
    text-align: center;
  }
  .ShortListed,
  .Interview {
    width: 56px;
    background: #e7daff 0% 0% no-repeat padding-box;
    border-radius: 75px;
    position: relative;
    vertical-align: middle;
    font-size: 12px;
    line-height: 26px;
    top: 0px;
    text-align: center;
  }

  .Interview {
    background: #e9f5ea 0% 0% no-repeat padding-box;
  }
}

@media screen and (max-width: 1220px) {
  .recruitment-hiring-stage {
    grid-template-columns: 2fr 1fr 1fr;
  }
}

.recruitment-container {
  width: 94vw;
}

.employees-list {
  @media (max-width: 768px) and (min-width: 575px) {
    flex-direction: row;
    flex-wrap: wrap;
    .employee {
      flex: 1 0 50%;
      padding: 10px 18px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.065);
    }
  }
  .employee {
    padding: 10px 18px;
    &-value {
      font-weight: 400;
      font-size: 13px;
      padding: 0 15px;
      > .branch {
        margin-bottom: 3px;
        font-size: 14px;
      }
    }
    &-avatar {
      margin-right: 0;
    }
  }
}
.employee-row {
  display: flex;
  align-items: center;
  padding: 8px 0;
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
  }

  .employee-value {
    padding: 0 15px;
    font-size: 14px;
    flex: 1;
    &.employee-title {
      font-size: 15px;
      flex: 1 0 20%;
    }
    &.employee-subtitle {
      flex: 1 0 15%;
    }
  }
}
.value-avatar {
  padding-left: 15px;
}
.employee-container {
  padding: 10px 0;
}
.job-empty {
  text-align: center;
  display: block;
  margin: 25px 0;
  color: #ccc;
  font-size: 16px;
  img {
    max-width: 120px;
    display: block;
    margin: 0 auto 15px;
  }
}
.emptystate-jobs {
  min-width: 100%;
  text-align: center;
  .emptyimage {
    width: 340px;
    margin: 15% auto 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  p {
    font-size: 21px;
    color: #c1c1c1;
    margin-top: 25px;
  }
}
.react-date-picker {
  &__inputGroup {
    background: #ececec;
    &__input:focus-within {
      outline: none;
      border: none;
    }
  }
  &__button {
    background: #ececec;
  }
}
