.roles {
  .admin-title {
    h1 {
      margin: 0;
    }
    margin: 10px 0;
    button {
      padding: 8px 36px;
      height: 36px;
    }
  }
  &-search {
    margin-bottom: 20px;
    .form-group.search {
      max-width: 300px;
      input {
        margin-top: 0;
        border-radius: 0;
      }
    }
  }
}
.roles-table {
  padding: 15px 0;

  .tabs {
    background: transparent;
    box-shadow: unset;
    &-list {
      background: #{$rigo-white-color};
    }
    &-content {
      background: transparent;
      padding: 15px 0 !important;
    }
  }
}

.roles-modal {
  .modal {
    &__header {
      background-color: #0068d4;

      h2 {
        color: #{$rigo-white-color};
      }
      &-close {
        color: #{$rigo-white-color};
      }
    }
    &__content {
      width: 50%;
      // background-color: #{$rigo-white-color};
      // &-body {
      //   background-color: #{$rigo-white-color};
      // }
    }
  }
}
