@import "../../../scss/abstracts/_variables";

.boardslist {
  width: 100%;

  &-board {
    width: 100%;
    padding: 10px;
  }
  &-title {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 10px;
    .tasks-component__primary-icon {
      margin-right: 5px;
    }

    &-title {
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      margin-right: 10px;
    }
  }
  &-list {
    width: 100%;
    display: grid;
    // display: flex;
    // flex-wrap: wrap;
    // grid-template-columns: repeat(4, 225px);
    grid-template-columns: repeat(auto-fit, minmax(220px, 250px));
    // grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 15px;
    padding: 10px 0;
  }
  &-item {
    width: 100%;
    // width: 265px;
    // max-height: 300px;
    &.to-do {
    }
  }
  &-card {
    width: 100%;
    background: #{$rigo-white-color};
    box-shadow: 0px 1px 3px 1px rgba(30, 30, 30, 0.2);
    border-radius: 3px;
    padding: 10px;
    border: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;

    &__title {
      font-size: 14px;
      color: #313c49;
      margin-bottom: 5px;
      font-weight: 500;
    }
    &__description {
      font-size: 13px;
      color: #a2a5b0;
      padding: 0px 0 5px;
    }
    &__teams {
      padding: 5px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      & > div {
        margin-right: 5px;
        margin-top: 5px;
      }
    }
    &__progress {
      padding-bottom: 10px;
    }
    &__progress-pp {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #405471;
    }
    &__time {
      //  padding: 5px 0 0;
    }
    &__due {
      display: inline-block;
      padding: 5px 10px;
      background: #eff3f2;
      border-radius: 3px;
      font-size: 13px;
      color: #98a0ac;
      &.success {
        background: #e0fff2;
        color: #02af67;
      }
      &.danger {
        background: #feeaea;
        color: #df8385;
      }
      &.warning {
        background: #fff0e1;
        color: #f2c085;
      }

      svg {
        font-size: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
      span {
        line-height: 1;
        vertical-align: middle;
      }
    }
    &__things {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 5px 0;
      h5 {
        margin-bottom: 6px;
        font-weight: 400;
        color: #454545;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        gap:8px;

        span {
          padding: 3px 5px;
          background: #0875e1;
          border-radius: 3px;
          color: #fff;
          font-size: 12px;
        }
      }
    }
    &__done {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;

      h5 {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        p {
          text-transform: uppercase;
          font-size: 12px;
        }
        span {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
}
