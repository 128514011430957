@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/variables';

.ksa{
    width: 100%;

    &-container{
        width: 100%;
        
    }
    &-legend{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &__item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 15px;
            @include bodyText(1);
        }
        &__circle{
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #333;
            margin-right: 10px;
        }
    }
    &-radar{
        width: 100%;
        padding: 20px;
        background: #{$rigo-white-color};
        .recharts-wrapper{
            // width: 100%;
        }

        svg{
            width: 120%;
            height: 100%;
        }

    }
    &-block{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        &__data{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 20px;
        }
        &__left{
            width: 100%;

        }
        &__list{
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: #{$rigo-white-color};
            
        }
        &__item{
            width: 100%;
            background-color: #{$rigo-white-color};
            position: relative;
            
        }
        &__right{

        }
    }  
    &-title{
        margin-bottom: 10px;
        h3{
            @include heading(4,#333)
        }
    }     
    &-item{
        display: grid;
        grid-template-columns: 40px 170px calc(100% - 250px) 40px;
        padding: 15px 20px;
        &:not(:last-child){
            border-bottom: 1px solid #e6e6e6;
        }
        &__num{
            margin: 5px;
        }
        &__edit{
         margin: 5px auto;       
        }
        &__name{
            @include bodyText(1);
            margin:10px 5px;
        }
    }
}
.edit-icon{
    width: 22px;
    height: 22px;
    text-align: center;
    background-color: #{$rigo-icon-color};
    color:#{$rigo-white-color};
    display: block;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
        background-color: #{$rigo-icon-hover};
    }
}


.score{
    width: 100%;
    padding: 5px 10px;

    &-back{
        width: 100%;
        border-radius:20px;
        background-color: #f4f4f4;
        height: 20px;
        position: relative;
        &-score{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #333;
            border-radius:20px;
            min-width: 20px;
            color:#{$rigo-white-color};
            text-align: right;
            padding: 2px 10px;
            font-size: 13px;
        }
        &-required{
            position: absolute;
            height: 25px;
            width: .1em;
            top: 50%;
            transform: translateY(-50%);
            background: #aaa;
        }
    }
    &-orange{background-color: #{$rigo-warning-color};text-align: center;padding:2px 0;}
    &-blue-light{background-color: #{$btn-primary-light-color}}
    &-blue{background-color: #{$btn-primary-color}}
    &-blue-dark{background-color: #{$btn-primary-dark-color}}
    &-dark{background-color: #666}
    &-green-light{background-color: #bcef73}
    &-green{background-color:#91d430}
}


.ksa{
    &-popup{
        position: absolute;
        top: 10px;
        right: 20px;
        left: 0px;
        width: 650px;
        z-index: 111;
        border-radius: 4px;

        @include overlayBefore(rgba(0,0,0,0.3));
        &:before{
            z-index: -1;
            position: fixed;
        }

        &__body{
            width: 100%;
            display: flex;
            flex-direction: column;
            box-shadow: 0 4px 7px rgba(0,0,0,0.25);
            border-radius: 4px;

        }
        &__header{
            width: 100%;
            background-color: #{$rigo-hover-bg-color};
            border-bottom: 1px solid #c3c3c3;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            .ksa-item{
                padding: 10px;
            }
        }   
        &__content{
            flex-grow:1;
            background-color: #{$rigo-white-color};

        }
        &__container{
            width: 100%;
            padding: 10px;
        }
        
        &__footer{
            width: 100%;
            background-color: #f1f1f1;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            .button-group{
                margin-top: 0;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
    &-scoreType{
        width: 100%;
        padding: 10px;
        
        &__list{
            list-style: none;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        &__item{
            margin-right: 15px;
            padding: 5px;
            cursor: pointer;

            &.active{
                .ksa-scoreType{
                    &__score{
                        .badge{
                            background-color: #{$btn-primary-dark-color};
                            color: #{$rigo-white-color};
                        }
                    }
                }
                
            }

            &:hover{
                .ksa-scoreType{
                    &__name{
                        color: #888;
                    }
                    &__score{
                        .badge{
                            background-color: #{$btn-primary-dark-color};
                            color: #{$rigo-white-color};
                        }
                    }
                }
            }
        }
        &__content{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__name{
            @include bodyText(1);
            font-weight: 500;
            margin-top: 5px;
        }
    }
}