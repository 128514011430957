.title-bar{
    background-color: #{$color-primary};
    color:#{$rigo-white-color};
    padding:8px;
    font-size: 1.2rem;
    text-align: center;
}

.tertiary-title{
    // font-size: 17px;
    color:#333;
    margin: 0;
    padding: 0;
    font-weight: 500;
    h3,h4,h5,h2{
        font-weight: 500;
        font-size: $default-fontSize;
    }
}