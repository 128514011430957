@import "../abstracts/variables";
.travel {
  padding: 10px 20px;
  // padding: 1rem;
  // background: whitesmoke;

  .e-grid {
    .e-headercelldiv {
      padding: 0 !important;
    }
    .btn-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        padding: 0.4rem 1rem;
        border: none;
        color: #{$rigo-white-color};
        border-radius: 15px;
        text-align: center;
      }
      &__edit {
        background: #2680eb;
      }
      &__del {
        background: #707070;
      }
      .btn-arrow {
        cursor: pointer;
        font-size: 1.3rem;
        transition: 0.8s ease-in-out;
      }
      .expand {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        overflow: visible;
      }
      .collapse {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    }
  }
  .btn-add {
    padding: 0.3rem 1rem;
    background: #67a5ef;
    border: none;
    color: #{$rigo-white-color};
    border-radius: 15px;
    cursor: pointer;
    transition: all ease 0.1s;
    &:hover {
    }
    &:focus {
      outline: none;
    }
  }
  .description {
    height: 20px;
    transition: height 350ms ease-in-out;
    &__text {
      height: 20px;
    }
    .travel-details {
      padding-top: 1rem;
      height: 0;
      transition: all 350ms ease-in-out;
      opacity: 0;
      &__li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 250px;
        padding-top: 0.5rem;
      }
    }
  }
  .expand-col {
    .travel-details {
      opacity: 1;
    }
  }
  &-header {
    // padding: 0 1rem;
    width: 1300px;
    max-width: 100%;
    .travel-route-title {
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;
      .btn-back {
        background: transparent;
        color: #2680eb;
        border: 2px solid #2680eb;
        border-radius: 3px;
        padding: 2px;
        font-size: 17px;
      }
      .left-arrow {
        font-size: 2rem;
        color: $blue-background;
      }
      .right-arrow {
        color: $blue-background;
      }
    }
  }
  &-requestMainHeading {
    // overflow-x: hidden;
    display: flex;
    // -ms-flex-direction: column;
    flex-direction: column;
    // -ms-flex: auto;
    // flex: auto;
    background: transparent;
    // background: #f0f2f5;
    // min-height: 0;
    padding: 10 0px; //verticle 0 and horizontal 25
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

    // @include respond(med-desktop){
    //   padding:10px 15px;
    // }
    // @include respond(big-desktop){
    //   padding:10px 25px;
    // }

    button {
      max-height: 40px;
    }

    .btnTravelRequest {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #565656 !important;
      padding: 12px 20px;
      background: #fcf9f9;
      span {
        padding-left: 10px;
        white-space: nowrap;
      }
      &:hover {
        transform: unset !important;
      }
    }

    .top-bar {
      align-items: flex-start;
    }

    .filters-input {
      //padding-left: 10px;
      flex-direction: row;
    }
    .travel-header-calendar {
      display: flex;
      flex: 1;
      align-items: center;
      .genericForm-group,
      .daterangepicker {
        width: 250px;
      }
    }
  }
  &-tabs {
    display: block;

    &__list {
      list-style: none;
      display: flex;
      flex-direction: row;
      margin-left: unset !important;
      //margin-left: 35px;
      flex-wrap: wrap;
      // @include respond(med-desktop){
      //   margin-left: 65px;
      // }
      // @include respond(big-desktop){
      //   margin-left: 90px;
      // }
    }
    &__item {
      padding: 8px 10px;
      color: #444;
      font-size: 13px;
      //background: #f5f7f6;
      background: #fcf9f9;
      margin-right: 10px;
      display: flex;
      align-items: center;
      //border-bottom: 4px solid #777;
      border-bottom: 4px solid #bfbfbf;
      margin-bottom: 10px;
      transition: 0.2s all ease-in-out;
      @include respond(med-desktop) {
        padding: 8px 15px;
        font-size: 14px;
      }

      &.selectedBorderBottom {
        //border-bottom: 4px solid #407bbe;
        background: #2680eb;
        border-bottom: 4px solid #0055ba;
        color: #{$rigo-white-color};
      }
      .badge {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 10px;
        font-size: 0.7rem;
        margin-right: unset;
        @include respond(med-desktop) {
          margin-right: 0.5rem;
        }
      }
      .badge-primary {
        background: #1890ff;
        //background: $blue-background;
      }
      &:hover:not(.selectedBorderBottom) {
        background: rgba(38, 128, 235, 0.8);

        color: #{$rigo-white-color};
      }
    }
  }

  &-requestMainBody {
    display: flex;
    flex-direction: column;
    //background: #f0f2f5;
    margin-top: 5px;
    // margin-top: 20px;
    // padding: 10px 0px; //verticle 0 and horizontal 25
    //  border-width: 1px;

    .bodyTitle {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 10px;
      color: red;
    }

    .tabs-content {
      padding: 35px 0px !important;
    }
  }
  // &-create {
  //   @include respond(big-desktop) {
  //     width: 75% !important;
  //   }
  //   @include respond(med-desktop) {
  //     width: 90% !important;
  //   }
  // }
}

.modal {
  .MuiRadio-colorSecondary.Mui-checked {
    color: #1890ff;
  }
}

.multiselect {
  .css-1hwfws3 {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }
  .css-yk16xz-control,
  .css-1hwfws3 {
    height: 60px;
    display: flex;
    align-items: flex-start;
    .css-1rhbuit-multiValue {
      border-radius: 17px;
      padding: 5px;
    }
    .css-1wa3eu0-placeholder {
      height: 50px;
      font-size: 0.84rem;
    }
  }
  .css-1g48xl4-IndicatorsContainer {
    display: flex;
    align-items: flex-start;
  }
}

.travel-fixed {
  transform: translate3d(0, -120%, 0);
  background: #f4f5f8;
  overflow: hidden;
  z-index: 9999999;
  &-show {
    .travel-fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      // animation: slide-down .5s both ;
      animation-timing-function: linear;
      // animation-timing-function: cubic-bezier(.34,1.06,.7,.46);;
      animation-duration: 0.7s;
      -webkit-animation-duration: 0.7s;
      // animation-duration: 0.85s;
      // -webkit-animation-duration: 0.85s;
      animation-name: slide-down;
      -webkit-animation-name: slide-down;
      animation-fill-mode: both;
      -webkit-animation-fill-mode: both;
      // animation-delay: 0.1s;
      // -webkit-animation-delay: 0.1s;

      transition: width 0.25s ease-in-out, visibility 0s 0.25s ease-in-out;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .slide-up {
      animation-name: slide-up;
      -webkit-animation-name: slide-up;
    }
  }

  &-body {
    width: 100%;
    height: 100%;
  }
  &-column {
    margin: 0;
    min-width: 1020px;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    // height: 100%;
    transition: all 1s ease;
  }

  &-header {
    flex: 0 0 auto;
    width: 100%;
    box-sizing: border-box;
    background: #e3e7f1e3;
  }
  &-main {
    flex: 1;
    // overflow: auto;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    .header-employee {
      padding: 25px 20px 12px;
      .MuiAvatar-root {
        width: 78px;
        height: 78px;
      }
    }
  }
  &-footer {
    // padding: 15px 20px;
    // background-color: #{$color-primary};
    // width: 100%;
    margin-bottom: 20px;
    max-width: 100%;
    width: 1300px;
    margin-top: 20px;
    padding: 10px 0;
    -webkit-font-smoothing: antialiased;
    display: block;
  }
}

.travel {
  &-header {
    &-div {
      padding: 10px;
      width: 97.5%;
      margin: 0 auto;
    }
    &-title {
      font-size: 17px;
      color: #454545;
    }
    &-icon {
      color: #656565;
      font-size: 28px;
      cursor: pointer;
      line-height: 1;
      span {
        display: block;
        line-height: 1;
      }
      &:hover {
        color: #222;
      }
    }
    .common-travel-tab {
      background: #{$rigo-white-color};
      //border-bottom: 2.5px solid #e4e4e4;
      .toptab {
        display: flex;
        align-items: center;
        &-item {
          padding: 0.8rem 1rem;
          height: 40px;
          border-bottom: 2.5px solid transparent;
          // border-bottom: 2.5px solid #e4e4e4;
          &:hover {
            border-bottom: 2.5px solid #609fec;
          }
        }
        &-title {
          font-size: 14px;
          font-weight: 400;
          color: #5a5a5a;
        }
        .selected {
          border-bottom: 2.5px solid #{$rigo-primary-color};
          font-weight: 500;
          .toptab-title {
            font-weight: 500;
          }
          .travel-list-count {
            background-color: #{$rigo-primary-color};
            color: #{$rigo-white-color};
          }
          .toptab-title {
            color: #{$rigo-primary-color};
          }
        }
        .unallocated-space {
          height: 40px;
          // border-bottom: 2.5px solid #e4e4e4;
          border-bottom: 2.5px solid transparent;
          flex: 1;
        }
        .travel-list-count {
          width: 27px;
          height: 27px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #e4e4e4;
          border-radius: 50%;
          margin-left: 0.5rem;
          font-weight: 500;
          font-size: 13px;
          color: #6c6969;
        }
      }
    }
    .travel-route {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.5rem;
      .path-name {
        font-size: 1rem;
        font-weight: 500;
        // margin: 30px 0 10px;
        margin: 10px 0 0 0;
      }
      .btnTravelRequest {
        display: flex;
        align-items: center;
        padding: 0.7rem;
        .btn__text {
          padding-left: 0.5rem;
        }
      }
    }
  }
  &__filter-body {
    // padding: 0 1rem;
  }
}
.travel-create__body {
  padding: 5px 2px !important;
  .filters-input {
    padding: 0 !important;
  }
  .tabs-card {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  }
  .tabs-block__list {
    background: #fff;
  }
  .travel-info-create {
    background: #f5f7fa;
  }
}

.pdf-modal {
  .modal__content {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100vh;
    overflow: hidden;
    &-body {
      max-width: 100%;
      height: 100%;
      max-height: 100vh;
      overflow: hidden;
    }
  }
  .modal__header-close {
    right: 40px;
    top: 25px;
    color: #{$rigo-white-color};
    background: rgba(255, 161, 38, 1);
    line-height: 0;
    border-radius: 50%;
    box-shadow: -2px -2px 7px rgba(255, 161, 38, 0.27);
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;

    &:hover {
      filter: brightness(0.7);
    }
  }
}
