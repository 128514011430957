@import "../../../../scss/abstracts/mixins";
@import "../../../../scss/abstracts/variables";

.placement {
  padding: 15px 0;
  &-job {
    margin-bottom: 30px;
  }
  &-roles {
    margin-bottom: 30px;
  }
}

.reporting {
  &-tab {
    .tabs-content {
      padding: 0 !important;
    }
  }
  &-input {
    width: 220px;
    margin-bottom: 15px;
  }
  &-active {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__row {
      width: 100%;
      // display: grid;
      // grid-template-columns: repeat(3,1fr);
      display: flex;
      flex-direction: row;
      padding: 5px;
      align-items: flex-start;

      &:not(:last-child) {
        border-bottom: 1px solid #cfcfcf;
      }
    }
    &__column {
      min-width: 300px;
      padding: 10px;
      font-size: $default-new-fontSize;
    }
    &__title {
      font-size: $default-new-fontSize;
      font-weight: 500;
      color: #2d2c2e;
    }
  }
  &-position {
    display: flex;
    flex-direction: column;
    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-bottom {
    }
    &__radio {
      width: 100px;
    }
    &__select {
      position: relative;
      a {
        color: #0052ab;
        cursor: pointer;
        border-bottom: 1px solid #0052ab;
        font-weight: 500;
      }
    }
    &__from {
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin-left: 25px;
      font-size: $default-new-fontSize;
      color: #405471;
      .from {
        margin-right: 20px;
      }
    }
  }
  &-history {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    &__block {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    &__title {
      font-size: $default-new-fontSize;
      color: #000000;
      margin-bottom: 12px;

      h4 {
        font-weight: 500;
      }
    }
    &__row {
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      margin-bottom: 7px;
    }
    &__col {
      min-width: 150px;
      font-size: 13px;
      // font-weight: 400;
      font-weight: 500;
      color: #3a3d43;
    }
  }
}

.reported {
  &-block {
    width: 100%;
    display: block;
    background-color: #{$rigo-white-color};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    padding: 20px;
    min-height: 150px;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  &-by {
    margin-top: 20px;
  }
}

.tree {
  width: 100%;
  max-width: 900px;
  &-row {
    width: 100%;

    &.root {
      position: relative;
      .tree-column {
        &:after {
          content: "";
          position: absolute;
          top: 72px;
          left: 50px;
          width: 1px;
          height: calc(100% - 107px);
          background: #b7c4d5;
        }
      }
    }
    &__open {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 72px;
        left: 50px;
        width: 1px;
        height: calc(100% - 140px);
        background: #b7c4d5;
      }
    }
  }
  &-column {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 72px;
  }
  &-child {
    width: 90%;
    margin-left: auto;
    margin-top: 15px;

    .tree {
      &-column {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          height: 1px;
          width: 40px;
          right: 100%;
          background: #b7c4d5;
        }
      }
    }
    &-last {
      margin-bottom: 15px;
      .tree-column {
        height: auto;
        max-height: 140px;

        &::before {
          width: 30px;
        }
      }
      .tree-block {
        width: 100%;

        &__grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 15px;
        }
        &__person {
        }
        &__name {
          margin-left: 15px;
          h5 {
            color: #262626;
          }
        }
      }
    }
  }
  &-icon {
    font-size: 20px;
    color: #b7c4d5;
    cursor: pointer;
    &:hover {
      color: darken(#b7c4d5, 10);
    }
  }
  &-block {
    width: calc(100% - 20px);
    background-color: #f5f5f8;
    border: 1px solid #b7c4d5;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 3px;

    &__avatar {
      margin-right: 15px;
    }
    &__details {
      font-size: $default-new-fontSize;
      font-weight: 400;
    }
    &__name {
      margin-bottom: 3px;
      h5 {
        font-weight: 500;
        color: #0364a5;
        font-size: $default-new-fontSize;
      }
    }
    &__report {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__position {
      font-size: $default-new-fontSize;
      font-weight: 500;
    }
    &__number {
      font-size: $default-new-fontSize;
      font-weight: 400;
      margin-left: 15px;
    }
  }
}
.placement {
  &-current {
    padding: 20px;
  }
  &-details {
    display: grid;
    grid-template-columns: repeat(3, 400px);
    grid-gap: 20px;

    &__block {
      width: 100%;

      .no-data{
        margin-top: 0;
      }
    }
    &__table {
      width: 100%;
      border: 1px solid #b6bec9;
      display: flex;
      flex-direction: column;
    }
    &__row {
      display: grid;
      grid-template-columns: 35% 65%;

      &:not(:last-child) {
        border-bottom: 1px solid #b6bec9;
      }
    }
    &__col {
      padding: 10px 15px;
      font-size: $default-new-fontSize;
      color: #172b4d;
      &.placement-title {
        font-weight: 400;
        border-right: 1px solid #bec5cf;
        background-color: #f1f3f5;
        color:#28417b;
      }
      &.placement-val {
        font-weight: 500;
        padding: 10px 20px;
      }
    }
  }
  &__footer {
    padding: 10px 0;
    margin-top: 15px;
  }
}
.placement-block {
  width: 100%;
  display: block;
  background-color: #{$rigo-white-color};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  padding: 20px;
  min-height: 150px;
  margin-bottom: 15px;
  border-radius: 3px;
}
.tertiary-title {
  margin-bottom: 10px;
}

.tree {
  &-enter {
    max-height: 0;
    opacity: 0;
    transform: scaleY(0);
    transition: all 300ms;
    &-done {
      max-height: 75px;
      opacity: 1;
    }
    &-active {
      max-height: 75px;
      opacity: 1;
      transform: scaleY(1);
      transition: all 300ms;
    }
  }
  &-exit {
    max-height: 75px;
    opacity: 1;
    transform: scaleY(1);
    &-done {
      max-height: 0px;
      opacity: 0;
    }
    &-active {
      max-height: 0;
      opacity: 0;
      transform: scaleY(0);
      transition: all 300ms;
    }
  }
}

.treeChild {
  &-enter {
    max-height: 0;
    opacity: 0;
    transform: scaleY(0);
    &-done {
      transform-origin: top;
    }
    &-active {
      max-height: 140px;
      opacity: 1;
      transform: scaleY(1);
      transition: all 300ms;
    }
  }
  &-exit {
    max-height: 140px;
    opacity: 1;
    transform: scaleY(1);
    &-done {
      transform-origin: top;
    }
    &-active {
      max-height: 0;
      opacity: 0;
      transform: scaleY(0);
      transition: all 300ms;
    }
  }
}

.current {
  width: 100%;
  padding: 15px;

  &-modal {
    .modal {
      &__content {
        width: 45%;
        .modal__header {
          background-color: #{$rigo-white-color};
        }
        //  .modal__content-body{
        //      .paginate{
        //          .MuiFlatPagination-root{
        //              display: flex;
        //              button{
        //                  padding: 5px 10px!important;
        //                  height: 30px;
        //              }
        //          }
        //      }
        //  }
      }
    }
  }

  &-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &-label {
    @include bodyText(1);
    margin-right: 10px;
  }
  &-link {
    margin-right: 10px;
    &__anchor {
      @include link;
    }
  }
}

.assign {
  &-job {
    width: 100%;
    &-contain {
    }
    &__row {
      padding: 10px 0px;
      &__selected {
        // padding: 10px;
        display: flex;
        position: relative;
      }
      &__title {
        padding: 8px;
        cursor: pointer;
        margin: 0px 15px;
        width: 50%;
        &:hover {
          background-color: #f4f5f7;
          border-color: #cdd0d5;
          border-radius: 3px;
          border-width: 1px;
          border-style: solid;
        }
      }
      &__block {
        display: flex;
        flex-direction: column;
      }
    }
    &__selectedjob {
      padding: 0px 0px 50px 0px;
      color: #0364a5;
      font-size: 18px;
    }
    &__trash {
      margin-left: 15px;
      font-size: 13px;
      padding: 4px;
      color: #9c9292;
    }
  }
}

.job-description {
  &-modal {
    .modal {
      &__header {
        border-bottom: 2px solid #f1f3f5;
        background-color: #{$rigo-white-color};
      }
      &__content {
        background-color: #{$rigo-white-color};
        &-body {
          overflow: auto;
        }
      }
    }
  }
}

.MuiPopover-root {
  z-index: 999999999 !important;
}
