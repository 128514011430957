@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

$stage-things-to-do: #0068d4;
$stage-in-progress: #fdab3d;
$stage-completed: #02af67;
$stage-on-hold: #586269;
$stage-cancelled: #784bd1;

.tasks {
  min-height: calc(100vh - 55px);
  flex-direction: row;
  box-sizing: border-box;
  // display: flex;
  // flex: auto;
  display: grid;
  grid-template-columns: 275px 1fr;

  &-main {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex: auto;
    // background: #{$rigo-white-color};
    background-color: #e3e6ed;
    min-height: 0;
    // padding: 20px;
    padding: 20px 20px 20px 30px;
  }
}

.task {
  width: 100%;
  &-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  &-duedate {
    margin-left: 10px;
    background-color: #0875e1;
    color: #fff;
    padding: 4px 8px;
    border-radius: 20px;
    height: 24px;
    font-size: 13px;
    align-items: center;
    display: flex;
  }

  &-titles {
    &-section {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      h2 {
        @include heading(2);
        color: #444;
      }
    }
    display: flex;
    flex-direction: column;

    p {
      @include bodyText(1);
      color: #b1b1b1;
    }
  }
  &-menus {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &-menu {
    &__nav {
    }
    &__list {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__item {
      height: 36px;
      position: relative;
      background-color: #fdfdfd;
      color: #333;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      border: 1px solid #cfcfcf;
      border-left: none;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background-color: #f1f1f1;
      }

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-left: 1px solid #cfcfcf;
      }
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    &__icon {
      font-size: 16px;
      color: #676767;
    }
    &__title {
      font-size: 14px;
      line-height: 18px;
      margin: 0 10px;
    }
    &__badge {
    }
  }
  &-ellipse {
    position: relative;
    &-button {
      font-size: 20px;
      cursor: pointer;
      margin: 0 10px;

      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s ease;
      &:hover {
        background: #fff;
      }
    }
    li {
      padding: 10px;
      cursor: pointer;
      font-size: 13px;
    }
    li:hover {
      background-color: #f3f3f3;
    }
  }

  &-view {
    width: 100%;
    padding: 10px 0;
    margin-top: 10px;
    &__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    &__menu {
      margin-right: 10px;
    }
    &__nav {
    }
    &__list {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__item {
      height: 36px;
      background-color: #fdfdfd;
      color: #333;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 10px;
      border: 1px solid #{$rigo-primary-color};
      border-left: none;
      cursor: pointer;
      color: #{$rigo-primary-color};
      transition: all 0.2s;
      &:hover,
      &.active {
        background-color: #{$rigo-primary-color};
        color: #{$rigo-white-color};
      }

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-left: 1px solid #{$rigo-primary-color};
      }
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    &__icon {
      font-size: 18px;
      line-height: 1;
    }
    &__title {
      font-size: 14px;
      line-height: 18px;
      margin: 0 10px;
    }
    &__new {
      margin-right: 10px;
    }
    &__search {
      min-width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-team {
        &-icon {
          cursor: pointer;
          padding: 6px;
          background: #0875e1;
          border-radius: 50%;
          color: #fff;
          &:hover {
            box-shadow: 0px 0px 3px 0px #0875e1;
          }
        }
        &-container {
          padding: 10px;
        }
        &-clear {
          display: flex;
          justify-content: flex-end;
        }
        &-clear-btn {
          font-size: 13px;
          margin-bottom: 10px;
          cursor: pointer;
          padding: 4px;
          color: #0875e1;
        }
      }
    }
  }
  &-body {
    padding: 20px 0;
  }
}

@mixin taskInput {
  input {
    width: 100%;
    height: 100%;
    border: none;
    // border-top: 1px solid #d7d7d7;
    padding: 10px;
    font-size: 14px;
    color: #444;

    @include placeholder {
      color: #cfcfcf;
      font-weight: 500;
      font-size: 14px;
    }
    &:focus {
      outline: none;
    }
  }
}

.taskBlock {
  &-avatar-group {
    padding-top: 6px;
    // & ul {
    //   display: flex !important;
    //   flex-direction: row-reverse;
    // }
    & ul {
      margin-left: 12px;
    }
    & li {
      display: inline;
      &:nth-last-child(2) {
        margin-right: 12px;
      }
    }
    .no-margin {
      margin: 0px;
    }
    .grid {
      margin-right: 5px;
    }
    .stack {
      margin-left: -8px;
    }
    .avatar {
      background-color: #fff;
      &-more {
        text-align: center;
        background-color: #e4e5e7;
        color: #707070;
        vertical-align: middle;
        // padding: 5px;
        display: inline-block;

        height: 25px;
        width: 25px;

        border-radius: 100%;
        border: 1px solid #e3e6ed;
        &-div {
          display: table;
          table-layout: fixed;
          width: 100%;
          height: 100%;
          font-size: 10px;
          span {
            display: table-cell;
            vertical-align: middle;
            white-space: nowrap;
          }
        }
        &-add {
          margin-top: 1px;
        }
      }
    }
  }
  &-table {
    width: 100%;
    box-shadow: 1px 1px 0px 0px #38212114;
    border-collapse: separate;
    border-spacing: 0;
    .td {
      //  width: 100px;
      background-color: #fff;
      &:first-child {
        width: 100%;
      }
      &-smd {
        background-color: #f5f6f8 !important;
      }
      &-team {
        max-width: 150px;
        min-width: 100px;
      }
      &-label {
        max-width: 250px;
        min-width: 110px;
        ::-webkit-scrollbar {
          height: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 12px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        .taskBlock-item {
          overflow-x: hidden;
          position: relative;
          // overflow-x: auto;
        }
      }
      &-priority {
        min-width: 110px;
      }
    }

    & th {
      background-color: #f5f6f8;
      border-top: none !important;
      &:not(:first-child) {
        width: 100px;
      }
      &:first-child {
        min-width: 200px;
        border-left: none;
      }
    }
    tr {
      &:not(:first-child) {
        td {
          border-top: 1px solid #e3e6ed;
        }
      }
    }
    & td,
    & th {
      // border: 1px solid #e3e6ed;
      // border-bottom: 2px solid #ebeced;
      &:not(:last-child) {
        border-right: 1px solid #e3e6ed;
      }
      &:last-child {
        padding: 3px 6px;
        min-width: 40px;
        width: 40px;
        font-size: 17px;
        cursor: pointer;
        background-color: #f5f6f8;

        border: none;
      }
    }
  }
  &-container {
    width: 100%;
    overflow: auto;
    padding: 4px;
  }

  // width: 100%;
  // min-width: 1000px;
  // overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  margin-bottom: 10px;

  &-things-to-do {
    .taskBlock-title {
      color: $stage-things-to-do;
      padding: 5px;
    }
    .taskBlock-border {
      // background-color: #579bfc;
      background-color: $stage-things-to-do;
    }
    .light {
      .taskBlock-border {
        background-color: rgba($stage-things-to-do, 0.5);
      }
    }
  }
  &-in-progress {
    .taskBlock-title {
      color: $stage-in-progress;
      padding: 5px;
    }
    .taskBlock-border {
      // background-color: #579bfc;
      background-color: $stage-in-progress;
    }
    .light {
      .taskBlock-border {
        background-color: rgba($stage-in-progress, 0.5);
      }
    }
  }
  &-completed {
    .taskBlock-title {
      color: $stage-completed;
      padding: 5px;
    }
    .taskBlock-border {
      // background-color: #579bfc;
      background-color: $stage-completed;
    }
    .light {
      .taskBlock-border {
        background-color: rgba($stage-completed, 0.5);
      }
    }
  }
  &-on-hold {
    .taskBlock-title {
      color: $stage-on-hold;
      padding: 5px;
    }
    .taskBlock-border {
      // background-color: #579bfc;
      background-color: $stage-on-hold;
    }
    .light {
      .taskBlock-border {
        background-color: rgba($stage-on-hold, 0.5);
      }
    }
  }
  &-cancelled {
    .taskBlock-title {
      color: $stage-cancelled;
      padding: 5px;
    }
    .taskBlock-border {
      // background-color: #579bfc;
      background-color: $stage-cancelled;
    }
    .light {
      .taskBlock-border {
        background-color: rgba($stage-cancelled, 0.5);
      }
    }
  }
  &-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1px;
    background-color: #f5f6f8;
  }
  &-title {
    flex-grow: 1;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    cursor: pointer;
    color: #{$rigo-primary-color};
    &__icon {
      font-size: 22px;
      line-height: 27px;
      margin-right: 6px;
      svg {
        transition: all 0.2s;
      }
      &-collapsed {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
    h3 {
      font-size: 17px;
      font-weight: 500;
      line-height: 34px;
    }
  }
  // &-sections {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: flex-end;
  //   background-color: #e2445c;
  //   //background-color: #f5f6f8;
  // }
  &-title-section {
    display: flex;
    align-items: center;
    justify-items: center;
    height: 45px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    // background-color: #f5f6f8;
    cursor: pointer;
    // border-right: 2px solid #ebeced;
    min-width: 120px;
    width: 120px;
    text-align: center;
    &.sm {
      padding: 3px 6px;
      min-width: 40px;
      width: 40px;
      font-size: 17px;
    }
    &.md {
      min-width: 100px;
      width: 100px;
    }
  }
  &-section {
    height: 36px;
    // padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    // background-color: #f5f6f8;
    cursor: pointer;
    // border-left: 2px solid #ebeced;
    // min-width: 120px;
    // width: 120px;
    //  text-align: center;
    // &:hover{
    //     background-color: #e8e9ea;
    // }

    &.sm {
      padding: 3px 6px;
      min-width: 40px;
      width: 40px;
      font-size: 17px;
    }
    &.smd {
      background-color: #f5f6f8 !important;
    }
    &__icon {
      line-height: 0;
      transition: background-color 0.2s, transform 0.2s;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        transition: all 0.2s;
      }
      &-open {
        background-color: #{$rigo-primary-color};
        color: #{$rigo-white-color};
        transform: rotate(180deg);
        svg {
          transform: rotate(225deg);
        }
      }
    }
    &.md {
      min-width: 100px;
      width: 100px;
    }
    &-team {
      &.child {
        padding: 6px 10px;
      }
      svg {
        color: #a5a5a5;
        font-size: 22px;
      }
    }
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-item {
    overflow: hidden;
    height: 36px;
    display: flex;
    flex-direction: row;
    .taskBlock-section {
      align-items: center;
      display: flex;
      overflow: hidden;
      .btn-add {
        background-color: #e4e5e7;
        color: #707070;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin: 0px 4px;
        display: flex;
        justify-content: center;
        padding-top: 4px !important;
        svg {
          fill: #707070;
        }
      }

      &-cell {
        margin: 0px 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 10px 2px;
        font-size: 13px;
        white-space: nowrap;
        display: block;
        text-align: center;
        max-width: 200px;
      }

      &-priority {
        color: #{$rigo-white-color};
        cursor: pointer;
        width: 100%;
        &-title {
          padding: 4px 8px;
          font-size: 12px;
          margin-left: 4px;
          border-radius: 50px;
        }
      }
      &-team {
        display: block;
      }
      &-label {
        &-title {
          background-color: #707070;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 4px 8px;
          font-size: 12px;
          margin: 0px 2px;
          border-radius: 25px;
          color: #fff;
          position: relative;

          .label-remove {
            position: absolute;
            top: 3px;
            right: 2px;
            background-color: #ce1010;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            display: none;
            align-items: center;
            justify-content: center;
          }
          &:hover {
            .label-remove {
              display: flex;
            }
          }
        }
      }
      &__input {
        padding: 0px 1px;
        @include taskInput;
      }
    }
    &__status {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }
    &__completed {
      font-size: 14px;
      color: #666;
      padding: 10px;
      span {
        font-weight: 400;
        margin: 0 5px;
        font-size: 14px;
      }
    }
    &__progress {
      padding: 10px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    &__comments {
      padding: 10px;
      font-size: 22px;
      color: #d4d5dd;
      position: relative;
      &.active {
        color: #0068d4;
      }
      &-count {
        background-color: #0068d4;
        font-size: 8px;
        position: absolute;
        bottom: 14px;
        color: #fff;
        right: 11px;
        border-radius: 100%;
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &-status {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #bebebe;
  }
  &-border {
    width: 14px;
    background-color: #579bfc;
    height: 100%;
  }
  &-task {
    min-width: 200px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 100%;
    // border-right: 2px solid #{$rigo-white-color};
    &__input {
      padding: 0px 1px;
      @include taskInput;
    }

    &__text {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__taskName {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
      max-width: 40em;
      height: 30px;
      display: flex;
      align-items: center;
    
      &-duedate {
        margin-left: 10px;
        color: #000;
        font-size: 11px;
        border: 1px solid #a7a7a7;
        padding: 3px 8px;
        border-radius: 15px;
      }
      // max-width: 300px;
    }
    &__edit {
      display: none;
      cursor: pointer;
      color: #bbbbbb;
      font-size: 11px;
      margin-left: 10px;
      padding: 4px;
    }
    &__text:hover &__edit {
      display: block;
    }
    &__text-input {
      height: 100%;
      flex-grow: 1;
      input {
        width: 100%;
        height: 100%;
        background-color: inherit;
        border: none;
        border-radius: 4px;
        padding: 5px;
        font-size: 13px;
        box-shadow: none;
        &:focus-visible {
          outline: none;
        }
        &:focus {
          border: 1px solid #c6c6c6;
          border-color: #0074e9;
          border-width: 0.5px;
        }
        &::-webkit-input-placeholder {
          text-align: center;
          color: #c9c9c9;
          font-size: 13px;
        }
      }
    }
    &__item {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #333;
      font-weight: 500;
      font-size: 14px;

      .genericForm-group {
        width: unset;
        margin: 0;
        height: 22px;
        .custom-check {
          // margin-bottom: 16px;
          margin-bottom: 0;
          padding-top: 0;
          padding-right: 20px;
          .checkmark {
            height: 20px !important;
            width: 20px !important;
            position: relative !important;
            &:after {
              left: 5px;
              top: 1px;
            }
          }
        }
      }
    }
  }
}

.task-priority {
  width: 120px;
  &-high {
    background-color: #cb1e52 !important;
  }
  &-medium {
    background-color: #0087c5 !important;
  }
  &-low {
    background-color: #35ceff !important;
  }
  &-none {
    background-color: #c4c4c4 !important;
  }

  &__list {
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
  }
  &__item {
    margin-bottom: 10px;
    display: flex;
  }
  &__box {
    background-color: #{$rigo-primary-color};
    display: flex;
    align-items: center;
    padding: 5px 15px;
    color: #{$rigo-white-color};
    border-radius: 15px;
    cursor: pointer;
    font-size: 13px;

    svg {
      margin-right: 5px;
    }
  }
  &__footer {
    padding: 10px;
    border-top: 1px solid #efefef;
    color: #{$rigo-primary-color};
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
}

.bg-task {
  &-red {
    background-color: #e2445c;
    color: #{$rigo-white-color};
  }
  &-yellow {
    background-color: #fdab3d;
    color: #{$rigo-white-color};
  }
  &-blue {
    background-color: #579bfc;
    color: #{$rigo-white-color};
  }
}

.task-board {
  width: 100%;
  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
    padding: 20px 0;
  }
}

@mixin colorSetup($color) {
  .board {
    &-header {
      border-bottom-color: #{$color};
      &__title {
        h3 {
          color: #{$color};
        }
      }
      &__icon {
        border-bottom-color: #{$color} !important;
        &::before {
          background-color: #{$color} !important;
        }
      }
    }
    &-task {
      &__block {
        // border-left-color: #{$color};
      }
    }
  }
}

.board {
  &-things-to-do {
    @include colorSetup($stage-things-to-do);
  }
  &-in-progress {
    @include colorSetup($stage-in-progress);
  }
  &-completed {
    @include colorSetup($stage-completed);
  }
  &-on-hold {
    @include colorSetup($stage-on-hold);
  }
  &-cancelled {
    @include colorSetup($stage-cancelled);
  }
}

.board {
  width: 350px;
  max-width: 350px;
  flex: 0 0 350px;
  // height: 500px;
  // max-height: 500px;
  min-height: 500px;
  transition: all 0.2s;
  &:not(:last-child) {
    margin-right: 20px;
  }

  &-block {
    width: 100%;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 500px;
  }
  &-collapsed {
    width: 50px;
    max-width: 50px;
    flex: 0 0 50px;

    .board-header {
      height: 100%;
      width: 100%;
      flex-direction: column;
      position: relative;
      border-bottom: none;

      &__title {
        align-items: center;
        text-align: center;
        overflow: hidden;
        height: 50px;
        line-height: 50px;
        width: 460px;
        position: absolute;
        left: 0;
        transition: transform 0.2s ease-in-out;

        top: 0;
        transform: rotate(90deg);
        transform-origin: bottom left;
        justify-content: flex-start;

        //TO flip
        // top: 100%;
        // transform: rotate(270deg);
        // transform-origin: top left;
        // justify-content: flex-end;
      }

      &__icon {
        width: 100%;
        display: flex;
        // align-items: center;
        justify-content: center;
        width: 50px;
        height: 40px;
        border-bottom: 2px solid #0875e1;
        padding-top: 5px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          background-color: #{$rigo-primary-color};
          border-radius: 100%;
          z-index: 1;
        }
        svg {
          z-index: 2;
          color: #{$rigo-white-color};
          transform: rotate(180deg);
          transition: all 0.22s;
        }
      }
    }
  }
  &-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 3px solid #{$rigo-primary-color};
    padding: 5px 10px;
    cursor: pointer;
    height: 40px;
    align-items: center;
    &__title {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      transition: none 0.2s ease-in 0.1s;
      h3 {
        color: #{$rigo-primary-color};
        font-size: 17px;
        font-weight: 500;
        margin-right: 5px;
        line-height: 1.5;
      }
      span {
        font-size: 14px;
        color: #888;
        line-height: 2;
      }
    }
    &__icon {
      line-height: 0;
      svg {
        color: #888;
        font-size: 25px;
      }
    }
  }

  &-body {
    width: 100%;
    margin-top: 15px;
    height: calc(100% - 40px);
    min-height: 445px;
    overflow-y: auto;
    @include customScroll;
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
  }
  &-task {
    width: 100%;
    background-color: #{$rigo-white-color};
    margin-bottom: 15px;

    &-add {
      width: 100%;

      &__input {
        width: 100%;
        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #dfdfdf;
          font-size: 14px;
          border-radius: 3px;
          @include placeholder {
            @include placeholderText;
          }
          &:focus {
            @include inputFocusStyle;
          }
        }
      }
      &__buttons {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 10px 0;
      }
    }

    &__block {
      width: 100%;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      // box-shadow: 0px 2px 2px 0px #00000029;
      cursor: pointer;
      // border-left: 3px solid #{$rigo-primary-color};
      &:hover {
        background-color: #f4f5f7;
      }
    }
    &__header {
      position: relative;
      cursor: pointer;
    }

    &__edit {
      height: 30px;
      width: 30px;
      display: none;
      align-items: center;
      justify-content: center;
      background-color: #ebecf0;
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
      svg {
        fill: #17305c;
      }
      &:hover {
        box-shadow: 0px 2px 2px 0px #00000029;
      }
    }
    &__header:hover &__edit {
      display: flex;
    }
    &__priority {
      height: 10px;
      background-color: #e2445c;
      width: 50px;
      border-radius: 20px;
      margin-bottom: 8px;
    }
    &__title {
      margin-bottom: 10px;
      h4 {
        font-size: 14px;
        color: #4d5c6d;
        font-weight: 500;
      }
    }
    &__status {
      span {
        padding: 6px 12px;
        background-color: #{$rigo-danger-color};
        font-size: 14px;
        color: #{$rigo-white-color};
        min-width: 70px;
        text-align: center;
        display: block;
        margin-right: 10px;
        border-radius: 3px;
      }
    }
    &__section {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    &__date {
      font-size: 13px;
      color: #fff;
      word-spacing: 1px;
      background-color: #61bd4f;
      padding: 6px;
      margin-right: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 5px;
      }
      &.overdue {
        background-color: #ff0c8b;
      }
    }
    &__day {
      font-size: 13px;
      color: #405471;
      word-spacing: 1px;
      margin-right: 10px;
    }
    &__due {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__info {
      margin-right: 10px;
      font-size: 13px;
      color: #6b778c;
      display: flex;
      text-align: center;
      justify-content: center;
      svg {
        margin-right: 3px;
        font-size: 12px;
      }
    }

    &__tags {
      display: flex;
      flex-direction: row;
      span {
        margin-right: 4px;
        padding: 6px 15px;
        height: 30px;
      }
    }
    &__progress {
      display: block;
      padding: 10px 0;
    }
    &__label-teams {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__label {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      gap: 3px;
      &-title {
        font-size: 12px;
        // margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: max-content;
        background: #878787;
        color: #fff;
        border-radius: 35px;
        padding: 2px 6px;
        line-height: 1;
        height: 24px;
      }
    }
    &__team {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      &-avatar {
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }
    &__teammember {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.task-modal {
  .modal__content-body {
    max-height: unset;
    height: calc(100vh - 80px);
  }
  &__body {
    // padding: 20px;
    //background-color: #{$rigo-white-color};
    background-color: #f4f5f7;
  }
}

.taskmodal {
  // padding:10px;
  &__mask {
    position: absolute;
    background-color: rgb(29, 28, 28, 0.4);
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
  h4 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #515f70;
    margin-right: 15px;
  }
  &__left,
  &__right {
    width: 100%;
    height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #cfcfcf;
    padding: 10px 25px;
    &-flex {
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: space-between;
    }
    .scroll-content {
      padding-right: 18px;
    }
  }
  &__left {
    .scroll-content {
      padding-left: 10px;
    }
  }

  &__header {
    padding: 10px 0;
    border-bottom: 1px solid #cfcfcf;
    h3 {
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 5px;
      color: #{$rigo-primary-color};
    }
    p {
      font-size: 14px;
      color: #888;
      line-height: 17px;
      padding-left: 10px;
    }

    input {
      border: none;
      background-color: inherit;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 5px;
      color: #3a96f1;
    }
    textarea {
      border: none;
      background-color: inherit;
      font-size: 14px;
      color: #888;
      line-height: 16px;
      width: 100%;
      padding-left: 10px;
      resize: none;
      &:focus {
        padding: 10px;
        // outline: none;
        // border: none;
        // box-shadow: none;
        // background: none;
      }
    }
  }

  &__assigned {
    padding: 10px 0px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    &-title {
      font-size: 15px;
    }
    &-images {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 25px;
      color: #bcc8d8;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
  &__checklist {
    padding: 10px 0;
    margin-bottom: 10px;
    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        font-size: 20px;
        color: #515f70;
        margin-right: 10px;
      }
    }
    &-progress {
      padding: 10px 0;
    }
    &-checks {
      display: flex;
      flex-direction: row;
      padding: 5px 0;
      .genericForm-group {
        width: inherit;
      }
      .genericForm-group__label {
        display: none;
      }
      &:hover &-action {
        display: flex;
      }
      &-action {
        display: none;
        font-size: 13px;
        justify-content: center;
        align-items: center;
        span {
          margin-left: 10px;
          cursor: pointer;
          color: #637188;
        }
      }
      .inactive {
        display: none !important;
      }
    }

    &-input {
      padding: 5px;
      .genericForm-group {
        input {
          // background-color: #fbfbfb;
          border: none;

          @include placeholder {
            color: #a7b5c5;
          }
        }
      }
    }
  }
  &__tags {
    padding: 10px 0;
    &-list {
      display: flex;
      flex-direction: row;
      padding: 5px 0;
      .badges {
        margin-right: 10px;
        height: 30px;
        svg {
          cursor: pointer;
        }
      }
    }
    &-add {
      // margin-left: 10px;
      width: 30px;
      height: 30px;
      border-radius: 3px;
      font-size: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #bfcbda;
      color: #bfcbda;
      cursor: pointer;
      position: relative;
      &:hover {
        color: darken(#bfcbda, 10);
      }

      .popup {
        left: 35px;
        padding: 8px;
        &-btn {
          span {
            line-height: 1;
          }
        }
        input {
          border: 1px solid #cfcfcf;
          width: 100%;
          padding: 5px;
          border-radius: 3px;
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
  &__date {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    column-gap: 8px;
    span.input-label {
      font-size: 14px;
      color: #515f70;
      margin-bottom: 20px;
    }
    .genericForm-group__date {
      border: none;
      input {
        height: 100%;
      }
      &:focus-within {
        border: 1px solid #0074e9;
        box-shadow: 0px 0px 1rem rgba(#0074e9, 0.1);
        //  display: none;
      }
    }
    .genericForm-group {
      input {
        // background-color: #fbfbfb;
        border: none;

        @include placeholder {
          color: #a7b5c5;
        }
      }
    }
    .genericForm-group {
      margin: 10px 0;
      margin-right: 10px;
      min-width: 80px;
      &__date {
        max-width: 183px;
        min-width: 80px;
        &.custom-date__active {
          input:disabled {
            background: #fff !important;
          }
        }
        input {
          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }
  &__recurring {
    display: flex;
    width: 100%;
    margin-top: 20px;

    &-section {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      align-items: center;
    }
    .recurring {
      &-right-section {
        width: 180px;
        margin-right: 10px;
        align-items: center;
      }
      &-text-group {
        input {
          border: none;
          width: 60px;
          height: 30px;
          margin-right: 10px;
          border-radius: 5px;
          padding-left: 10px;
          &:focus {
            @include inputFocusStyle;
          }
        }
      }

      &-label {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        margin-right: 10px;
      }
      &-label-1 {
        font-size: 14px;
        margin-right: 10px;
      }
      &-type {
        background-color: #e2e2e2;
        border-radius: 15px;
        font-size: 13px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
        min-width: 60px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      &-period {
        background-color: #e2e2e2;
        font-size: 13px;
        height: 25px;
        margin-right: 6px;
        margin-bottom: 6px;
        cursor: pointer;
        min-width: 60px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      &-radiogroup {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
    }
    .active {
      background-color: #007aff;
      color: #fff;
    }

    .genericForm-group {
      width: unset !important;
      input {
        border: none;
        @include placeholder {
          color: #a7b5c5;
        }
      }
    }
    .genericForm-group__date {
      border: none;
      input {
        height: 100%;
      }
      &:focus-within {
        border: 1px solid #0074e9;
        box-shadow: 0px 0px 1rem rgba(#0074e9, 0.1);
        //  display: none;
      }
    }
    .form__radio-group {
      width: 100px;
    }
  }

  &__attachment {
    padding: 10px 0;
    overflow-x: auto;
    overflow-y: hidden;
    &-title {
      color: #515f70;
      padding: 0px 0px 10px 0px;
      h3 {
        font-size: 17px;
        line-height: 18px;
        font-weight: 500;

        margin-right: 15px;
      }
      svg {
        font-size: 17px;
        margin-right: 10px;
      }
    }
    &-body {
      // margin-left: 30px;
      .btn-attachment {
        font-size: 13px;
        height: 35px;
        padding: 0px 10px;
        background-color: #ebedf0;
        border-radius: 10px;
        color: #172b4d;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: 1px solid #cfcfcf;
      }
      .attachments {
        display: flex;
        flex-wrap: wrap;
        &-item {
          position: relative;
          align-items: center;
          height: 35px;
          margin: 4px;
          border: 1px solid #cfcfcf;
          border-radius: 3px;
          display: flex;
          padding: 8px;
          cursor: pointer;
          background-color: #fff;
          color: #172b4d;
          img {
            object-fit: cover;
            border: 1px solid #cfcfcf;
          }
          &:hover {
            background-color: #fff;
          }
          &:hover .btn-remove {
            display: flex;
          }
          &-btn {
            background-color: #ebedf0;
            margin: 0;
            border-radius: 4px;
          }
          :first-child {
            margin-right: 6px;
          }
          .genericForm-group__upload-input {
            &:hover {
              box-shadow: unset;
              background-color: #fff;
              border-radius: 10px;
              // z-index: -1;
              & + span {
                z-index: 111;
              }
            }
          }
          .item-label {
            max-width: 120px;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    &-upload {
      & > div {
        width: 100%;
        box-shadow: none;
        // background-color: #f9f9fa;
        // border: 2px dashed #d7dee7;
        text-align: center;
      }
    }
  }
  &__activities {
    flex-grow: 1;
    overflow: hidden;
    padding: 10px 0;
    //change
    // padding: 20px 10px 20px 20px;
    // background-color: #fff;

    &-title {
      color: #515f70;
      margin-bottom: 10px;

      h3 {
        font-size: 17px;
        line-height: 18px;
        font-weight: 500;

        margin-right: 15px;
      }
      svg {
        font-size: 17px;
        margin-right: 10px;
      }
    }
    &-content {
      height: 100%;
      .scroll-content {
        margin-bottom: 30px;
      }
    }
    // &-editor {
    //   // flex-grow: 1;
    //   // background-color: #fff;
    //   // padding: 10px;
    //   // border: 1px solid #d5ddea;
    // }
    &-comment {
      display: flex;
      margin-top: 8px;
      // margin-top: 20px;
      .avatar {
        margin-right: 10px;
      }
      .editor {
        flex-grow: 1;
        background-color: #fff;
        padding: 10px;
        border: 1px solid #d5ddea;
        .ql-container.ql-snow {
          border: inherit;
        }
      }
      .comment {
        background-color: #f9fafb;
        border-radius: 12px;
        border: 1px solid #d5ddea;
        padding: 8px 12px;

        &-header {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &-title {
          padding: 3px 0px;
          font-size: 14px;
          color: #172b4d;
          display: flex;
          flex-grow: 1;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          span {
            // color: #637188;
            color: #808792;
            font-size: 12px;
            margin-left: 10px;
          }
        }

        &-body {
          display: flex;
          div {
            // min-width: 100px;
            line-height: 1.6;
            margin: 10px 0px;
            // padding: 5px 10px;
            // background-color: #f9fafb;
            // border-radius: 20px;s
            // border: 1px solid #d5ddea;
            font-size: 13px;
          }
        }
        &-footer {
          display: flex;
          align-items: center;
          padding: 10px 0 0 0;
          border-top: 1px solid #ccc;
          font-size: 13px;
          color: #637188;
          .emoji-selector {
            > span {
              height: 30px;
              padding: 2px 6px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.3s ease;
              &:hover {
                background: #ececec;
              }
            }
          }
          .reaction {
            padding: 2px 4px 2px 4px;
            color: #005bc0;
            border: 1px solid #005bc0;
            background-color: #dcedff;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: 8px;
            border-radius: 8px;

            span:first-child {
              margin-right: 5px;
            }
            span:nth-last-child(2) {
              margin-right: 5px;
            }

            &-tooltip {
              display: flex;
              flex-direction: column;
              text-align: center;
              justify-content: center;
              :first-child {
                font-size: 30px;
                margin-bottom: 5px;
              }
            }
          }
          &-action {
            margin-left: 10px;

            span {
              padding: 0 10px;
              height: 30px;
              line-height: 30px;
              display: inline-block;
              border-radius: 4px;
              transition: 0.3s ease;
              &:hover {
                background: #ececec;
              }
            }
          }
          span {
            // margin-left: 10px;
            cursor: pointer;
            position: relative;
          }
          svg {
            cursor: pointer;
          }
        }
      }
      &-tools {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        &-right {
          display: flex;
        }
        span {
          margin-left: 10px;
          cursor: pointer;
          color: #405471;
          font-size: 18px;
          position: relative;
        }
      }
    }

    &-area {
      .border {
        border: 1px solid #cfcfcf;
      }
      margin: 10px 0px 10px 35px;

      background-color: #fff;

      &__input {
        width: 100%;
        outline: none;
        padding: 8px 12px;
        background: #{$rigo-white-color};
        font-size: 14px;
        height: $input-height;
        overflow: hidden;
        border: inherit;
        background-color: $rigo-input-bg-color;
        transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
        resize: none;
        &:focus {
          box-shadow: none;
        }
        &::-webkit-input-placeholder {
          @include placeholderText();
        }

        .colon {
          margin: 0 4px;
          font-weight: 600;
        }
      }
      .ql-container {
        &.ql-snow {
          border: none;
        }
      }
      .ql-toolbar {
        &.ql-snow {
          border: none;
        }
      }
    }

    .shadow {
      box-shadow: 1px 2px 2px 0px #38212121;
    }
  }

  &__comments {
    padding: 10px 0;
    &-title {
      color: #{$rigo-primary-color};
      padding: 5px 0;
      h3 {
        font-size: 17px;
        line-height: 18px;
        font-weight: 500;

        margin-right: 15px;
      }
      svg {
        font-size: 17px;
      }
      span {
        color: #515f70;
        font-size: 14px;
        font-weight: 500;
        margin-left: 5px;
      }
    }
    &-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid #e6ebf2;
      border-radius: 3px;
      margin-bottom: 10px;
    }
    &-text {
      width: 100%;
      border-bottom: 1px solid #e6ebf2;
      textarea {
        width: 100%;
        border: none;
        height: 100px;
        font-size: 14px;
        color: #434343;
        padding: 10px;
        resize: none;
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
    &-toolbar {
      padding: 10px 20px;
      display: flex;
      flex-direction: row-reverse;
    }
    &-icon {
      line-height: 0;
      cursor: pointer;
      font-size: 20px;
      color: #676767;
      margin-left: 10px;
    }
    &-buttons {
      display: flex;
      flex-direction: row-reverse;
      button {
        margin: 0;
      }
    }
  }
  &__customFields {
    padding: 10px 0;
    &-controls {
      display: grid;
      grid-template-columns: repeat(3, 33%);
      column-gap: 8px;
      span.input-label {
        font-size: 14px;
        color: #515f70;
        margin-bottom: 20px;
      }
      .genericForm-group {
        margin: 10px 0;
        margin-right: 10px;
        input {
          // background-color: #fbfbfb;
          border: none;

          @include placeholder {
            color: #a7b5c5;
          }
        }
      }
    }
  }
}
.flex-editor {
  display: flex;
  justify-content: space-between;

  background-color: #fff;
  border: 1px solid #d5ddea;
  .quill {
    width: calc(100% - 130px);
  }
  .ql-container.ql-snow {
    border: inherit;
  }
}

.quill-toolbar {
  &-tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    &-right {
      display: flex;
    }
    &-icon {
      margin-left: 4px;
      cursor: pointer;

      line-height: 0;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &:last-child {
        svg {
          width: 10px;
          height: 12px;
        }
      }
      &.selected {
        background-color: #005bc0;
        color: #fff;
        border-radius: 50%;

        &:hover {
          span:hover {
            background-color: darken(#005bc0, 10%);
          }
        }
      }
      &.disabled {
        color: rgb(202, 200, 200);
        cursor: not-allowed;
        span:hover {
          background: none;
        }
      }
      span {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease;
        &:hover {
          background: #ececec;
        }
      }
    }

    // span {
    //   margin-left: 10px;
    //   cursor: pointer;
    //   color: #405471;
    //   font-size: 18px;
    //   position: relative;
    // }
  }
}
.uploadBlock {
  .MuiPaper-root {
    background-color: inherit !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: inherit !important;
    padding: 10px;
    cursor: pointer;
  }
}
.member {
  margin: 4px;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 100%;
  // box-shadow: 0px 0px 1px 0px #0f0e0e57;
  &:hover {
    // box-shadow: 0px 0px 3px 1px #0f0e0e3d;
    .member-add {
      background-color: #dcdcdc;
    }
  }
  &-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 2px;
    border-radius: 100%;
    background-color: #ebedf0;
  }

  &:hover .btn-remove {
    display: flex;
  }
  &-modal {
    &-body {
      padding: 20px;
      background-color: #fff;
    }
  }
  &-employee {
    padding: 5px 0px;
    height: 360px;
    overflow: auto;

    border-bottom: 2px solid #efefef;
    li {
      cursor: pointer;
    }
  }
  &-footer {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
  }
}
.btn-remove {
  display: none;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: -6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  border: 1px solid #c70505;
  background-color: #ce1010;
  color: #fff;
  font-size: 30px;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.33);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.33);
  sv {
    margin: 0px;
  }
}
.task-select {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  border-radius: 4px;
  background: #{$rigo-white-color};
  z-index: 111;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.2);
  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  &__side {
    width: 60px;
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;

    &.side-primary {
      color: #{$rigo-white-color};
      font-size: 22px;
      background-color: #{$rigo-primary-color};
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.side-cancel {
      background-color: #{$rigo-white-color};
      border-left: 1px solid #cfcfcf;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &__close {
    &:hover {
      cursor: pointer;
      color: darken($rigo-danger-color, 5);
      svg {
      }
    }
  }
  &__title {
    margin: auto 10px;
    font-size: 17px;
    color: #666;
  }
  &__center {
    flex: 0 0 calc(100% - 120px);
    padding: 10px;
    &-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__actions {
    &-list {
      display: flex;
      flex-direction: row;
    }
    &-item {
    }
  }
  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    cursor: pointer;
    justify-content: space-around;
    height: 40px;
    color: #1c1d1f;
    &:hover {
      color: darken($rigo-warning-color, 5);
    }
    svg {
      font-size: 18px;
      margin-bottom: 4px;
    }
    span {
      font-size: 14px;
    }
  }
  .popup {
    display: flex;
    top: unset;
    bottom: 60px;
    left: 0;
    transform: translateX(-50%);
    padding: 0px;
  }
  &__move {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-item {
      width: 100%;
      padding: 5px;
      // border-bottom: 1px solid #cfcfcf;
      // margin-bottom: 3px;
      cursor: pointer;
      border-radius: 3px;
      &:hover {
        background-color: #efefef;
      }

      &.stage-active {
        background: #0875e1;
        color: #{$rigo-white-color};
      }
      &.stage-hide {
        display: none;
      }
    }
  }
}

.task-popup {
  position: absolute;
  width: 300px;
  background: #{$rigo-white-color};
  right: 0px;
  top: 100%;
  box-shadow: 0 3px 6px rgba(40, 40, 40, 0.22);
  border-radius: 3px;
  z-index: 11111;
  &-status {
    left: 50%;
    right: unset;
    transform: translateX(-50%);
    margin-top: 10px;

    .task-popup__container {
      padding: 0;
    }

    &:after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      height: 15px;
      width: 15px;
      z-index: -1;
      background-color: #{$rigo-white-color};
      border: 1px solid #e5eaed;
      border-bottom: 1px solid transparent;
      border-right: 1px solid transparent;
      top: -3px;
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
    }
  }
  &-add {
    position: relative;
  }
  &__title {
  }
  &__input {
    padding: 0 8px;
    &-members {
      margin-top: 10px;
      height: 300px;
      overflow: auto;
    }
    input {
      background-color: #f4f5f7;
      border: none;
    }
  }
  &__container {
    padding: 10px 0;
    width: 100%;
  }
  &__list {
    min-width: 200px;
    display: flex;
    flex-direction: column;
  }
  &__item {
    width: 100%;
    padding: 5px 0;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }
  .hr {
    margin: 5px 0;
    background-color: #efefef;
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    color: #405471;
  }

  &__check {
    flex: 0 0 40px;
    font-size: 18px;
    color: #e1e1e1;

    .genericForm-group {
      margin: 0;

      .custom-check {
        margin: 0;
        padding: 0;
        .checkmark {
          top: -12px;
          left: 10px;
        }
      }
      .checkmark {
        width: 20px;
        height: 20px;

        &:after {
          left: 5px;
          width: 6px;
          height: 10px;
        }
      }
    }
  }
  &__lock {
    margin: 0px 10px;
    color: #e1e1e1 !important;
  }
  &__icon {
    flex: 0 0 30px;
    line-height: 1;
    font-size: 16px;
  }
  &__title {
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    text-align: left;

    .btn-close {
      position: absolute;
      top: 2px;
      right: 5px;
    }
  }

  &__custom-field {
    padding: 12px 10px;
    text-align: center;
    background-color: #f7f7f7;
    color: #333333;
    font-size: 13px;
    cursor: pointer;
  }
  &__emplist {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px 0;
  }

  &__empitem {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    color: #444;
    border-radius: 3px;
    position: relative;
    &:hover {
      background-color: #efefef;
    }
    &.active {
      background-color: #{$rigo-primary-color};
      color: #{$rigo-white-color};
    }

    &-image {
      margin-right: 10px;
    }
    &-name {
      flex-grow: 1;
      font-size: 14px;
      text-align: left;
    }
    &:hover &-close {
      display: block;
    }
    &-close {
      display: none;
      position: absolute;
      top: 10px;
      right: 12px;
      cursor: pointer;
      background-color: #6f6e6e;
      border-radius: 50%;
      padding: 5px;
    }
  }
}
.task-activity-popup {
  width: 420px;

  padding: 10px 0px;
  overflow: hidden;
  .activity {
    &__list {
      max-height: 500px;
      overflow-y: auto;
      &-item {
        padding: 10px;
        &-title {
          color: #172b4d;
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 12px;
          padding-left: 10px;
          text-transform: uppercase;
        }
        &-body {
          &:hover {
            background-color: #ebedf0;
          }
          display: flex;
          padding: 10px;
          &-image {
            margin-right: 15px;
            .member-image {
              &.sb-avatar__text {
                background-color: #dfe1e6 !important;
                span {
                  font-size: 12px;
                  color: #5e6c84;
                }
              }
            }
          }
          &-desc {
            // flex-grow: 1;
            min-width: 250px;
            margin-right: 10px;
            .desc {
              font-size: 14px;
              color: #172b4d;
              &-name {
                font-weight: bold;
              }
              &-sub-text {
                color: #5e6c84;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          &-time {
            color: #7d7d7d;
            font-size: 13px;
            width: 107px;
          }
        }
      }
    }
  }
}

.search-team {
  padding: 0 8px;
  &-members {
    margin-top: 10px;
    height: 300px;
    overflow: auto;
  }
  .border-bottom {
    border-bottom: 1px solid #d7d7d7;
  }

  input {
    background-color: #f4f5f7;
    border: none;
  }
  &__section {
    // text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  &__text {
    font-size: 12px;
    padding: 8px;
    size: 12px;
    color: #5e6c84;
    margin-bottom: 10px;
  }
  &__emplist {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px 0;
  }

  &__empitem {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    color: #444;
    border-radius: 3px;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #efefef;
    }
    &.active {
      background-color: #{$rigo-primary-color};
      color: #{$rigo-white-color};
    }

    &-image {
      margin-right: 10px;
    }
    &-name {
      flex-grow: 1;
      font-size: 14px;
      text-align: left;
    }
    &:hover &-close {
      display: block;
    }
    &-close {
      display: none;
      position: absolute;
      top: 10px;
      right: 12px;
      cursor: pointer;
      background-color: #6f6e6e;
      border-radius: 50%;
      padding: 5px;
    }
  }
}

.current-status {
  width: 100%;
  &__list {
    width: 100%;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
  }
  &__item {
    width: 100%;
    margin-bottom: 10px;
  }
  &__box {
    width: 100%;
    height: 34px;
    background-color: #{$rigo-primary-color};
    text-align: center;
    padding: 7px;
    color: #{$rigo-white-color};
    border-radius: 3px;
    cursor: pointer;
  }
  &__footer {
    padding: 10px;
    border-top: 1px solid #efefef;
    color: #{$rigo-primary-color};
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
}

.status-label {
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &__tabs {
    .tabs-content {
      padding: 0 !important;
    }
  }
  &__container {
    width: 100%;
    padding: 20px;
  }
  &__colors {
    width: 100%;
    background-color: #f7f7f7;
    padding: 10px 20px;

    &-list {
      display: flex;
      width: 200px;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
    &-item {
      cursor: pointer;
      margin: 0 0 7px 7px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #333;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__item {
    display: grid;
    grid-template-columns: repeat(2, 275px);
    grid-gap: 20px;
  }
  &__col {
    width: 100%;
    margin-bottom: 15px;
  }
  &__name {
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
  }
  &__box {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #f7f7f7;
    border-radius: 4px;
    background-color: #f7f7f7;
  }
  &__color {
    width: 20px;
    height: 100%;
    border-radius: 4px 0 0 4px;
  }
  &__title {
    flex: 0 0 calc(100% - 20px);
    height: 100%;
    padding: 10px;
    color: #434343;
  }
  &__footer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;

    button {
      margin-left: 10px;
    }
  }
}

.status-color {
  &-1 {
    background-color: #ff44c8;
  }
  &-2 {
    background-color: #ff0c8b;
  }
  &-3 {
    background-color: #cb1e52;
  }
  &-4 {
    background-color: #865144;
  }
  &-5 {
    background-color: #ff5601;
  }
  &-6 {
    background-color: #ffca00;
  }
  &-7 {
    background-color: #cdb618;
  }
  &-8 {
    background-color: #8ad700;
  }
  &-9 {
    background-color: #008247;
  }
  &-10 {
    background-color: #0087c5;
  }
  &-11 {
    background-color: #35ceff;
  }
  &-12 {
    background-color: #ae54e3;
  }
  &-13 {
    background-color: #808080;
  }
  &-14 {
    background-color: #333333;
  }
  &-15 {
    background-color: #ff9af6;
  }
  &-16 {
    background-color: #ffa8ab;
  }
  &-17 {
    background-color: #88338e;
  }
  &-18 {
    background-color: #96adbf;
  }
  &-19 {
    background-color: #56a2c0;
  }
  &-20 {
    background-color: #0f5096;
  }
  &-21 {
    background-color: #5754e7;
  }
  &-22 {
    background-color: #48109a;
  }
}

.taskpop {
  &-enter {
    opacity: 0;
    transform: scaleY(0);
    transition: all 300ms;
    transform-origin: top right;
    &.task-popup-status {
      transform: translateX(-50%) scaleY(1);
    }
    &-done {
      opacity: 1;
      transform: scaleY(1);
      &.task-popup-status {
        transform: translateX(-50%) scaleY(1);
      }
    }
    &-active {
      opacity: 1;
      transform: scaleY(1);
      transition: all 300ms;
      &.task-popup-status {
        transform: translateX(-50%) scaleY(1);
      }
    }
  }
  &-exit {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top right;
    &.task-popup-status {
      transform: translateX(-50%) scaleY(1);
    }
    &-done {
      opacity: 0;
      transform: scaleY(0);
      transition: all 300ms;
      &.task-popup-status {
        transform: translateX(-50%) scaleY(0);
      }
    }
    &-active {
      opacity: 0;
      transform: scaleY(0);
      transition: all 300ms;
      &.task-popup-status {
        transform: translateX(-50%) scaleY(0);
      }
    }
  }
}

// .emoji-selector {
// position: relative;
.emoji {
  &-header {
    padding: 10px;
    border-bottom: 1px solid #cfcfcf;

    &-close {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      font-size: 24px;
      cursor: pointer;
      color: #767676;
      :hover {
        color: #0b0b0b;
      }
    }
  }
  &-container {
    display: flex;
    height: 300px;
    width: 280px;
    padding: 10px;
    overflow-y: auto;
    flex-direction: row;
    flex-wrap: wrap;

    &-emoji {
      width: 40px;
      height: 40px;
      font-size: 23px !important;
      margin: 4px;
      padding: 4px;
      cursor: pointer;
      &:hover {
        background-color: #dad7d7;
      }
    }
  }
  &.active {
    display: block;
  }
}
.modal__header-close-noheader {
  position: absolute;
  top: 11px;
  right: 12px;
}
.board-label {
  &-modal {
    & .modal__header-close {
      display: none;
    }
  }
  width: 100%;
  padding: 20px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    &-title {
      font-size: 14px;
      color: #333333;
      font-weight: bold;
    }
    &-button {
      color: #c3c3c3;
      cursor: pointer;
    }
  }
  &__item {
    background-color: #f7f7f7;
    display: flex;
    padding: 5px;
    margin: 0px 15px;
    border-radius: 4px;
    margin-bottom: 10px;
    &:focus-within {
      border: 1px solid #0074e9;
      box-shadow: 0px 0px 1rem rgba(#0074e9, 0.1);
      //  display: none;
    }
    &-color {
      background-color: #c3c3c3;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      cursor: pointer;
      text-align: center;
      vertical-align: middle;
      text-align: center;
      font-size: 13px;
      padding-top: 3px;
      color: #707070;
    }
    &-title {
      flex-grow: 1;
      margin: 0px 5px;
      color: #333333;
      font-size: 14px;
      padding: 2px;

      input {
        width: 100%;
        background-color: inherit;
        border: none;
        // border: 1px dotted #c6c6c6;
        //  border-radius: 4px;
        //  padding: 5px;
        font-size: 14px;
        box-shadow: none;
        &:focus-visible {
          outline: none;
        }
      }

      // input:focus &-input {
      // }
    }
    &-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      color: #c3c3c3;
    }
    &-input {
      width: 100%;

      input {
        width: 100%;
        background-color: inherit;
        border: none;
        border: 1px dotted #c6c6c6;
        border-radius: 4px;
        padding: 5px;
        font-size: 13px;
        box-shadow: none;
        &:focus-visible {
          outline: none;
        }
        &:focus {
          border-color: #0074e9;
          border-width: 0.5px;
        }
        &::-webkit-input-placeholder {
          text-align: center;
          color: #c9c9c9;
          font-size: 13px;
        }
      }
    }
    &.item-input {
      padding: 0px;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 10px;
  }

  &__colors {
    width: 100%;
    background-color: #f7f7f7;
    padding: 10px 20px;

    &-list {
      display: flex;
      width: 200px;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
    &-item {
      cursor: pointer;
      margin: 0 0 7px 7px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #333;
    }
  }
}

.label-popup {
  &__section {
    padding: 10px;
    li {
      padding-left: 10px;
    }
    .li-label {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &-delete {
        color: #5e6c84;
        // display: none;
      }
    }

    &-no-labels {
      color: #c3c3c3;
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &-add-labels {
      color: #0076c8;
      font-size: 13px;
      cursor: pointer;
      svg {
        margin-right: 4px;
      }
    }
    &-item {
      &-disabled {
        cursor: not-allowed;
      }

      &-input {
        margin-bottom: 5px;
        input {
          height: 30px;
        }
      }
      padding: 5px 10px;
      color: #{$rigo-white-color};
      font-size: 12px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      cursor: pointer;
      background-color: #c3c3c3;
      border-radius: 10px;
      svg {
        margin-right: 5px;
      }
      &:hover {
        // box-shadow: inset 1px -1px 1px 0px #5f5d5d42;
        background-color: #676767;
      }

      &-active {
        background-color: #0068d4;
        &:hover {
          background-color: #0068d4;
        }
      }
    }
    .li-stage {
      &:hover {
        background-color: #f5f6f8;
      }
    }
    & .stage-item {
      &-label {
        font-size: 13px;
        color: #5e6c84;
      }
      &-stage {
        &-label {
          color: #172b4d;
        }
        height: 16px;
        width: 16px;
        margin-right: 8px;
        border-radius: 50%;
      }
      &-things-to-do {
        background-color: $stage-things-to-do;
      }
      &-in-progress {
        background-color: $stage-in-progress;
      }
      &-completed {
        background-color: $stage-completed;
      }
      &-on-hold {
        background-color: $stage-on-hold;
      }
      &-cancelled {
        background-color: $stage-cancelled;
      }
      padding: 5px 0px;
      font-size: 14px;
      display: flex;
      cursor: pointer;
    }
    &-footer {
      cursor: pointer;
      display: flex;
      font-size: 14px;
      color: #0076c8;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 10px;
        font-size: 13px;
      }
    }
  }
  &__divider {
    // margin-bottom: 10px;
    border-bottom: 1px solid #cfcfcf;
    // margin-top: 10px;
  }
}

.cell {
  &-due-date {
    width: 100%;
    cursor: pointer;
    padding: 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
  &-custom-field {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    &-text {
      padding: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 250px;
    }
    input {
      width: 100%;
      height: 100%;
      background-color: inherit;
      border: none;
      border-radius: 4px;
      padding: 5px;
      font-size: 13px;
      box-shadow: none;
      &:focus-visible {
        outline: none;
      }
      &:focus {
        border: 1px solid #c6c6c6;
        border-color: #0074e9;
        border-width: 0.5px;
      }
      &::-webkit-input-placeholder {
        text-align: center;
        color: #c9c9c9;
        font-size: 13px;
      }
    }
  }
}
