.audit-history {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(224, 224, 224, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(224, 224, 224, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(224, 224, 224, 0.75);
  background: #{$rigo-white-color};
  &__header,
  &__body {
    padding: 1rem;
    border-bottom: 1px solid #d4d4d4;
  }
  .history-grid {
    display: grid;
    grid-row-gap: 1rem;
    font-size: 0.8rem;
    overflow-y: scroll;
    max-height: 635px;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    &__row {
      display: grid;
      grid-row-gap: 0.4rem;
      border-bottom: 1px solid #d4d4d4;
      padding-bottom: 0.5rem;
      .date {
        color: #0c80ff;
        font-weight: 500;
      }
      .editor {
        color: #0c80ff;
      }
      .old-value {
        color: #d64353;
        text-decoration: line-through;
      }
      .new-value {
        color: #84b527;
        padding-left: 1rem;
      }
    }
  }
}
