.loan-appliedloan {
  padding: 28px;
  width: 1298px;
  &-body {
    background-color: #ffffff;
    padding: 28px;
  }
  &__button {
    display: flex;
    justify-content: end;
    padding: 8px;
    .button {
      padding: 0px 8px !important;
    }
  }
  .button.with-icon svg,
  .button.with-icon i {
    margin: unset !important;
  }
  .table-container .table-table .td {
    display: flex;
    align-items: center;
    padding: 4px 20px !important;
  }
  .table-container .table-table .th {
    display: flex;
    align-items: center;
    padding: 16px 20px !important
  }
  .table-container .table-table .row:last-child {
    display: flex;
    text-align: right;
  }
  // action button column
  .table-container .table-table .td:last-child {
    display: flex;
    justify-content: end;
  }
  // amount column
  .table-container .table-table .td:nth-child(3) {
    display: flex;
    justify-content: end;
  }
  // amount header
  .table-container .table-table .th:nth-child(3) {
    display: flex;
    justify-content: end;
  }
  .table-container .table-table.sticky .table-header {
    z-index: 0 !important;
  }
}
