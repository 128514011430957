.avatar{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;


    &-md{
        width: 60px;
        height: 60px;
    }

    &-sm{
        width: 40px;
        height: 40px;
    }

    &-bg{
        width: 100px;
        height: 100px;
    }
}

.img-circle{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border:1px solid #fafafa;
    // background: #555;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;

        &:after { 
            content: attr(alt);
            font-size: 13px;
            color: rgb(200, 200, 200);
            display: block;
            position: absolute;
            z-index: 2;
            @include absCenter;
          }
    }
    &-10{
        width: 10px;
        height: 10px;
    }
    &-20{
        width: 20px;
        height: 20px;
    }
    &-30{
        width: 30px;
        height: 30px;
    }
    &-40{
        width: 40px;
        height: 40px;
    }
    &-60{
        width: 60px;
        height: 60px;
    }
}