@import "../../../scss/abstracts/variables";
$Black: #444;

.jobInformation {
  &-container {
    padding: 20px;
  }
  &-route {
    &-title {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
      .btn-back {
        background: transparent;
        color: #2680eb;
        border: 2px solid #2680eb;
        border-radius: 3px;
        padding: 2px;
        font-size: 17px;
      }
      .left-arrow {
        font-size: 2rem;
        color: $blue-background;
      }
      .right-arrow {
        color: $blue-background;
      }
      .module {
        font-size: 16px;
        color: $blue-background;
        cursor: pointer;
      }
      .path-name {
        font-weight: 600;
        font-size: 16px;
      }
      span {
        padding: 0 0.5rem;
      }
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  color: #2C2D2C;
  font-size: 17px;
  font-weight: 500;
}

.office_and_position {
  display: flex;
  flex-direction: row;
  // width: 80%;

  &-block {
    flex: 1;
    background: #{$rigo-white-color};
    padding: 20px;
    margin-right: 10px;

    &-section {
      padding: 5px 0;
      display: flex;
      flex-direction: row;
      .label {
        flex: 1;
        color:rgba(#2C2D2C,.75);
        span {
          color: $grey-dark;
          
        }
      }
      .detail {
        flex: 2;
        font-size: 13px;
        font-weight: 500;
        color: #000;
      }

    }
  }
}

.section {
  display: flex;
  flex-direction: row;
  // width: 80%;
  margin-top: 20px;
}

.job_profile {
  width: 50%;
  &-block {
    flex: 1;
    background: #{$rigo-white-color};
    padding: 20px;
    margin-right: 10px;
    font-size: 14px;

    &-section {
      padding: 5px 0;
      display: flex;
      flex-direction: row;
      .label {
        flex: 1;
        color:rgba(#2C2D2C,.75);
        span {
          color: $grey-dark;
        }
      }
      .detail {
        flex: 2;
        font-size: 13px;
        font-weight: 500;
        color: #000;
      }
      .detail-icon{
        font-size: 24px;
        color:#666;
        cursor: pointer;
      }
      .icon {
        cursor: pointer;
        flex: 2;
        color: #999999;
        font-size: 30px;
      }
    }
  }
}

.manager {
  width: 50%;
  &-block {
    display: flex;
    background: #{$rigo-white-color};
    padding: 10px;
    flex-direction: row;

    .sb-avatar{
        margin-top: 18px;
    }

    &-detail {
      display: flex;
      flex-direction: column-reverse;
      margin-left: 10px;
      &-name {
        color: $color-blue;
        margin: 0px 0;
        font-size: 14px;
        font-weight: 500;
      }
      &-designation {
        color: #666;
        font-size: 14px;
      }
    }
  }
}

.myteam {
  width: 100%;
  &-block {
    display: flex;
    background: #{$rigo-white-color};
    padding: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    &-photo {
      padding: 5px;
    }
  }
}

.history {
  width: 100%;
  &-block {
    display: flex;
    flex-direction: column;
    background: #{$rigo-white-color};
    padding: 10px;

    &-section {
      padding: 5px 0;
      display: grid;
      align-items: center;
      grid-template-columns: repeat(1, 40px 1fr 0.8fr 0.8fr 2fr);
      font-size: $default-new-fontSize;
      .count {
        color: $color-grey;
      }
      .nepDate {
        color: $color-grey;
      }
      .engDate {
        color: #999999;
      }
      .description {
        display: flex;
        flex-direction: row;
        color: $color-blue;

        &-item {
          margin-right: 10px;
        }
        &-pdf {
          cursor: pointer;
          font-size: 20px;
          color: #ea6362;
        }
      }
    }
  }
}
