@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/variables';

.task-todo{
    width: 100%;
    &-header{
        width: calc(100% + 40px);
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 20px;
        align-items: center;
        border-bottom: 2px solid #e6e6e6;
        margin-left: -20px;
        
        h3{
            @include heading(3,#414141)
        }
    }
    &-dates{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &-date{
        display: inline-block;
        padding: 8px 16px;
        position: relative;
        height: 36px;
        font-size: 14px;
        font-weight: 400;
        border:1px solid #d3dde8;
        margin: 0 5px;
        background-color: #fafbfc;
        color: #858585;
        border-radius: 3px;
        text-transform: capitalize;
        cursor: pointer;
        &:hover{
            background-color: #f2f3f4;
        }
        &.active{
            background-color:#{$rigo-primary-color};
            color: #{$rigo-white-color};
        }

        &.with-left-icon,&.with-right-icon{
            display: flex;
            flex-direction: row;
            align-items: center;

            svg{
                font-size: 18px;
            }
        }
        &.with-left-icon{
            padding-left: 8px;
            i{
                margin-right: 8px;
            }
        }
        &.with-right-icon{
            padding-right: 8px;
            i{
                margin-left: 8px;
            }
        }
        &__icon{
            line-height: 1;
        }
    }
    &-body{
        width: 100%;
        padding:20px 10px;
        &__title{
            @include heading(3, $rigo-primary-color);
        }
        &__num{
            @include bodyText(1, #787878)
        }
    }
    &-assignments{
        padding: 15px 0 ;
        width: 100%;
        display: flex;
        flex-direction: column;

        &__block{
            width: 100%;
            padding: 10px 0 ;
            &-header{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
            }
            &-icon{
                line-height: 1;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #e6e6e6;
                border-radius: 50%;
                margin-right: 5px;
                svg{
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    color: #a9a9a9;
                }
            }
            &-title{
                color: #676767;
                font-size: 17px;
                font-weight: 500;

            }
        }
        &__body{
            width: 100%;
            padding: 10px 0 ;

        }
        &__list{
            display: flex;
            flex-direction: column;
            padding: 0 25px;
        }
        &__item{
            width: 100%;
            max-width: 625px;
            padding: 5px 0;
        }

    }
    &-assignment{
        width: 100%;
        border: 2px solid #f5f5f5;
        border-radius: 5px;
        // padding: 15px;
        display: flex;
        box-sizing: border-box;

        &-main{
            flex-grow: 1;
            padding: 15px;
            background-color: #{$rigo-white-color};
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
        }
        &-titles{
            display: flex;
            flex-direction: column;
        }
        &-title{
            font-size: 14px;
            font-weight: 500;
            color: #333;
            margin-bottom: 5px;
        }
        &-status{
            font-size: 14px;
            font-weight: 400;
            color: #616161;
        }
        &-date{
            width: 100px;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            font-weight: 500;
            background-color: #efefef;
            color: #54657f;
        }
    }
}