.modal {
  height: 100%;
  width: 100%;
  position: fixed;
  // display: block;
  top: 0;
  left: 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-color: rgba($color-black, 0.7);
  z-index: 100310000;
  // opacity: 0;
  // visibility: hidden;
  opacity: 1;
  visibility: visible;
  transition: all 0.24s;
  &.flexify {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .modal__content {
      transform: none !important;
      top: unset !important;
      left: unset !important;
    }
  }

  &__container {
    // width: 100%;
    // height: 100%;
    max-height: 90vh;
    max-width: 90vw;
    overflow: auto;
  }

  &__content {
    @include absCenter;
    // height: 20rem;
    // min-height: 20rem;
    max-height: 90vh;
    max-width: 90vw;
    width: 60%;
    background-color: $color-white;
    box-shadow: 0 2rem 4rem rgba($color-black, 0.2);
    border-radius: 3px;
    display: block;
    overflow: hidden;
    // opacity: 0;
    opacity: 1;
    visibility: visible;
    // transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(0.8);
    // transition: all 0.25s;
  }
  &__content-body {
    background-color: #f7f8fc;
    max-height: calc(90vh - 50px);
    overflow: auto;
    width: 100%;
    max-width: 90vw;
  }
  &__header {
    width: 100%;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
    height: 50px;
    &-close-noheader {
      position: absolute;
      top: 20px;
      right: 40px;
    }
    &-close {
      &,
      &:link,
      &:visited {
        z-index: 11;
        //color: #525252;
        color: #adacac;
        font-size: 1.7rem;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        transition: all 0.2s;
        line-height: 1;

        @include respond(med-desktop) {
          font-size: 2rem;
        }
        @include respond(big-desktop) {
          font-size: 2.2rem;
        }
      }

      &:hover {
        color: #{$color-red};
      }
    }
  }
  &__heading {
    h2 {
      font-size: 17px;
      margin-bottom: 0;
      color: #444;
      text-transform: capitalize;
      font-weight: 400;
    }
  }
  &__close {
    &,
    &:link,
    &:visited {
      color: #{$rigo-white-color};
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      font-size: 2.5rem;
      cursor: pointer;
      text-decoration: none;
      display: inline-block;
      transition: all 0.2s;
      line-height: 1;
    }

    &:hover {
      color: #efefef;
    }
  }
  &__confrim,
  &__confrimations {
    @include absCenter;
    height: 13.5rem;
    width: 20rem;
    background-color: #{$rigo-white-color};
    box-shadow: 0 2rem 4rem rgba($color-black, 0.2);
    border-radius: 3px;
    display: table;
    padding: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include respond(med-desktop) {
      height: 15rem;
      width: 22rem;
    }
    @include respond(big-desktop) {
      height: 17.5rem;
      width: 27rem;
    }
  }

  &__confrimations {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.confirm {
  &.success {
    background-color: lighten($color-green-fade, 20);
    .confirm-icon {
      i,
      svg {
        color: lighten($color-green, 4);
      }
    }
    button {
      padding: 10px 25px;
    }
  }
  &.danger {
    background-color: lighten($color-red-fade, 10);
    .confirm-icon {
      i,
      svg {
        color: lighten($color-red, 4);
      }
    }
    button {
      padding: 10px 25px;
    }
  }
  &-icon {
    color: #{$color-yellow};
    font-size: 3rem;
    display: flex;
    justify-content: center;

    @include respond(med-desktop) {
      font-size: 3.5rem;
    }
    @include respond(big-desktop) {
      font-size: 4.5rem;
    }
  }
  &-title {
    color: #646464;
    font-size: 1.4rem;
    margin: 0.25rem 0;

    @include respond(med-desktop) {
      font-size: 1.5rem;
      margin: 0.5rem 0;
    }
    @include respond(big-desktop) {
      font-size: 1.8rem;
      margin: 0.75rem 0;
    }
  }
  &-content {
    margin: 10px;
  }
  p {
    color: #454545;
    font-size: 0.9rem;
    margin: 0.4rem 0;
    flex-grow: 1;
    line-height: 1.5;

    @include respond(med-desktop) {
      font-size: 0.9rem;
      margin: 0.5rem 0;
    }
    @include respond(big-desktop) {
      margin: 0.75rem 0;
      font-size: 1rem;
    }
  }
  &-buttons {
    display: flex;
    // justify-content: space-evenly;
    justify-content: center;

    button {
      cursor: pointer;
      text-decoration: none;
      text-transform: capitalize;
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
      border-radius: 3px;
      width: 50%;
      transition: all 0.2s;
      outline: none;
      border: none;

      @include respond(med-desktop) {
        font-size: 0.9rem;
      }
      @include respond(big-desktop) {
        font-size: 1rem;
      }
    }

    &__confirm {
      // margin-right: 1rem;
      color: #{$rigo-white-color};
      background-color: #{$color-primary};

      &:hover {
        background-color: lighten($color-primary, 7);
        // transform:translateY(-3px);
        // box-shadow: 0 4px 7px rgba(0,0,0,0.2);
      }
      // &:active{
      //     transform:translateY(-1px);
      //     box-shadow: 0 2px 3px rgba(0,0,0,0.12);
      // }
    }
    &__cancel {
      color: #666;
      background-color: #e6e6e6;

      &:hover {
        background-color: lighten(#e6e6e6, 4);
        // transform:translateY(-3px);
        // box-shadow: 0 4px 7px rgba(0,0,0,0.2);
      }
      // &:active{
      //     transform:translateY(-1px);
      //     box-shadow: 0 2px 3px rgba(0,0,0,0.12);
      // }
    }
  }
}

.modal-warn {
  .modal__content {
    width: 17%;
    min-height: 15rem;
  }
}
.con {
  position: relative;
  z-index: 0;
}

.primary-modal {
  .modal {
    &__header {
      background-color: #0068d4;

      h2 {
        color: #{$rigo-white-color};
      }
      &-close {
        color: #{$rigo-white-color};
      }
    }
    &__content {
      width: 50%;
      background-color: #{$rigo-white-color};
      &-body {
        background-color: #{$rigo-white-color};
      }
    }
  }
  &-body {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
  }
  &-footer {
    width: 100%;
    background-color: #f5f7fa;
    .button {
      &-group {
        margin-top: 0;
      }
      &-primary {
        padding: 8px 36px;
      }
    }
    // padding: 10px;
  }
  &-container {
    flex-grow: 1;
    padding: 10px 30px;
    overflow-y: auto;
  }
}
.defaultModal {
  &-body {
    width: 100%;
    min-height: 200px;
    display: flex;
    background-color: #{$rigo-white-color};
    flex-direction: column;
  }
  &-footer {
    width: 100%;
    background-color: #f5f5f5;
    .button {
      &-group {
        margin-top: 0;
      }
    }
    // padding: 10px;
  }
  &-container {
    flex-grow: 1;
    padding: 10px 30px;
    overflow-y: auto;
  }
}

.popup {
  position: absolute;
  // top: 100%;
  // left: 100%;
  top: -75%;
  left: 110%;
  z-index: 11111;
  background: #{$rigo-white-color};
  padding: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  min-width: 200px;
  display: none;
  flex-direction: column;
  transition: all 0.2s;
  transform: scale(0.25);
  opacity: 0;
  &-body {
    flex-grow: 1;

    .MuiInputBase-input {
      background-color: #f4f5f7;
      border: 1px solid #f4f5f7;
    }
  }
  &-footer {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;

    .popup-btn {
      width: 30px;
      height: 25px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.17);
      background: #{$rigo-white-color}f;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      border-radius: 4px;
      color: #707070;
      margin-left: 10px;
      cursor: pointer;
    }
    .popup-add {
      &:hover {
        background-color: lighten($color-green-fade, 10);
        color: #676767;
      }
    }
    .popup-remove {
      &:hover {
        background-color: #{$color-red-fade};
        color: #676767;
      }
    }
  }
  &-open {
    display: flex;
    transform: scale(1);
    opacity: 1;
  }
  &-animate {
    animation: popUp 0.3s ease-in-out;
  }
  &-close {
    animation: popOut 0.3s ease-in-out;
  }
}

@keyframes popUp {
  from {
    transform: scale(0.25);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes popOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.25);
    opacity: 0;
  }
}
