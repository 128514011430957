@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/variables';

.reset{
    width: 100%;
    display: grid;
    // grid-template-columns: 50% 50%;
    grid-template-columns: 40% 60%;
    min-height: 100vh;

    &-right{
        width: 100%;
        height: 100%;
    }
    &-left{
        position: relative;
        width: 100%;
        height: 100%;
    }
    &-image{
        width: 100%;
        height: 100%;
        padding: 10px;
        svg{
            width: calc(100% - 20px);
            height: calc(100% - 20px);
        }
    }
    &-container {
        width: 70%;
        padding: 15px;
        margin: 0 auto;
        // padding: 2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        // background-color: #{$color-white};
        // box-shadow: 0 2px 5px rgba(0,0,0,0.2);

        @include respond(med-desktop){
            width: 60%;
        }
        @include respond(big-desktop){
            width: 55%;
        }
        .form-group{
            margin: 20px 0 ;
        }
    }
    &-form{
        .genericForm-group{
            margin-bottom: 15px;
        }
        &__forgot{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .forgot-link{
                cursor: pointer;
                color: #565656;
                font-size: 13px;
                padding: 4px 8px;
                &:hover{
                    color: #333;
                }
            }
        }
    }
    &-submit {
        &,
        &:link,
        &:visited {
            text-transform: capitalize;
            margin: 20px 0;
            text-decoration: none;
            padding: 12px;
            width: 100%;
            // display: block;
            border-radius: 4px;
            transition: all 0.2s;
            position: relative;
            font-size: 18px;
            font-weight: 400;
            background-color: #{$color-blue};
            color: #fafafa;
            //Change for the <button> element
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &-heading {
        color: #444;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 20px;
    }

}

input:disabled{
    background:#e6e6e6;
}


#lock{
    transform-box: fill-box;
    transform-origin: center;
    animation: spins 1.5s infinite linear;

}

#lock-insert{
    animation: 1.5s spins-reverse infinite linear;
    transform-box: fill-box;
    transform-origin: center;
}

#handleft{
    transform-box: fill-box;
    transform-origin: bottom;
    // animation: hand .2s infinite both;
}

#leaf-01{
    transform-box: fill-box;
    transform-origin: bottom;
    animation: leaf 1.25s infinite both;
}

#leaf-16,#leaf-17,#leaf-18,#leaf-19,#leaf-20,#leaf-21,#leaf-22,#leaf-23,#leaf-24,#leaf-25,#leaf-26,#leaf-27{
    transform-box: fill-box;
    transform-origin: bottom;
    animation: leaf-left 1.25s infinite both alternate;
}
#leaf-01,#leaf-02,#leaf-03,#leaf-04,#leaf-05,#leaf-06,#leaf-07,#leaf-08,#leaf-09,#leaf-10,#leaf-11,#leaf-12,#leaf-13,#leaf-14,#leaf-15{
    transform-box: fill-box;
    transform-origin: bottom;
    animation: leaf-right 1.25s infinite both alternate;
}

#letter1{
    transform-box: fill-box;
    transform-origin: center;
    animation:.4s 1.1s fade-in linear both alternate, .55s 1.21s fade-in infinite linear both alternate;
}
#letter2{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s 1s fade-in linear both;
}
#letter3{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s .9s fade-in linear both;
}
#letter4{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s .8s fade-in linear both;
}
#letter5{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s .7s fade-in linear both;
}
#letter6{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s .6s fade-in linear both;
}
#letter7{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s.5s fade-in linear both;
}
#letter8{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s .4s fade-in linear both;
}
#letter9{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s .3s fade-in linear both;
}
#letter10{
    transform-box: fill-box;
    transform-origin: center;
    animation: .4s .2s fade-in linear both;
}


.reset-old{
    width: 100%;

    &__container{
        padding: 24px;
        width: 100%;
    }
    &__heading{
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
    }
    &__para{
        margin: 20px 0;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
    }

    &__strong{
        margin: 16px 0;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
    }

    &__form{
        margin: 12px 0;
        .genericForm-group{
            margin-bottom: 12px;
        }
    }
    &__validations{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin: 4px 0;
    }
    &__validation{
        position: relative;
        padding-left: 24px;

        &.active{
            color: #018200;
            &:before {
                content:'';
                position: absolute;
                left: 0px;
                top: 4px;
                width: 8px;
                height: 15px;
                border-radius: 1px;
                border: solid #018200;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

    }
}