.card{
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #{$rigo-white-color};
    display: block;
    border-radius: 4px;
    max-width: 300px;
    padding: 10px;
    margin: 10px;
    perspective: 300px;
    transition: all .3s ease-in;

    &:hover{
        box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    }

    &.with-image{
        padding:0 !important;

        &:hover{
            .card-image{
                img{
                    transform: translateZ(30px);
                }
            }
        }
    }
    &-image{
        width: 100%;
        height: 200px;
        max-height: 200px;
        overflow: hidden;
        transition: all .3s ease-in-out;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .3s ease-in-out;
        }
    }

    &-title{
        display: inline-block;
        /* border-bottom: 1px solid #c3c3c3; */
        width: 100%;
        padding: 10px;
        
        h3{
            font-size: 16px;
            font-weight: 600;
            color:#{$color-primary};
        }
    }
    &-body{
        width: 100%;
        padding:10px;
    }
    &-link{
        display: block;
        padding: 5px;
        text-align: center;

        a{
            color: #4098d7;
            text-decoration: none;
            font-size: 1.1rem;
            position: relative;
            transition: all .3s;

            &:hover{
                font-size: 1.15rem;

                &::after{
                    width: 100%;
                }
            }

            &::after{
                content: '';
                position: absolute;
                width: 50%;
                height: 2px;
                background-color: #4098D7;
                bottom: -2px;
                left: 50%;
                transform: translateX(-50%);
                transition: all .3s;
            }
        }
    }
}

.card-sm{
    padding: 5px 15px;
    background: #e1e1e1;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);

    @include respond(med-desktop){
        padding:8px 20px;
    }

    &__title{
        font-weight: 400;
        // font-size: .95rem;
        font-size: 13px;
        // margin-bottom: .1rem;
        letter-spacing: .025rem;
        color: #555;
        @include respond(med-desktop){
            font-size:14px;
        }
    }
    &.closed{
        .card-sm__title{
            font-weight: 400;
        }
    }
    &__status{
        color: #777;
        font-size: 11px;
        @include respond(med-desktop){
            font-size:13px;
        }
    }

    &.green{
        background: #{$color-green};

        .card-sm__title{
            color:#{$rigo-white-color};
        }
        .card-sm__status{
            color:#fafafa;
        }
    }
    &.blue{
        background: #{$color-blue};

        .card-sm__title{
            color:#{$rigo-white-color};
        }
        .card-sm__status{
            color:#fafafa;
        }
    }
    &.red{
        background: #{$color-red};

        .card-sm__title{
            color:#{$rigo-white-color};
        }
        .card-sm__status{
            color:#fafafa;
        }
    }
    &.yellow{
        background: #{$color-yellow};

        .card-sm__title{
            color:#{$rigo-white-color};
        }
        .card-sm__status{
            color:#fafafa;
        }
    }
}