.assign {
  &-group {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    margin: 20px;
    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.managers {
  &-modal {
    .modal__content {
      width: 900px;
    }
  }
  &-type {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &__title {
      font-size: 14px;
      line-height: 1.4;
      font-size: 14px;
      font-weight: 700;
      color: #353f5a;
    }
    &__subtitle {
      font-size: 14px;
      margin-left: 5px;
      line-height: 1.4;
      font-weight: 400;
      color: #353f5a;
    }
  }
  &-input {
    .MuiFormControl-root .MuiInputBase-root {
      margin-top: 16px;
    }
  }
  &-date {
    width: 100%;
    margin-top: 15px;
    label {
      font-size: 14px;
      font-weight: 500;
      color: rgba(#000, 0.75);
      margin-bottom: 4px;
    }
    .MuiInputBase-root {
      background-color: #f4f5f7;
    }
  }
  &-position {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
    &__radio {
      .form-radio__group {
        width: 120px;
      }
    }
    &__select {
      margin-top: 4px;
      position: relative;
      &.align-down {
        margin-top: auto;
        margin-bottom: 7px;
      }
      .popup {
        top: unset;
        left: unset;
        right: 0;
      }

      a {
        color: #0052ab;
        cursor: pointer;
        border-bottom: 1px solid #0052ab;

        &:hover {
          color: lighten(#0052ab, 10);
          border-color: lighten(#0052ab, 10);
        }
      }
    }
  }
  &-count {
    margin: 20px 0;

    &__list {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      min-width: 200px;
      flex-wrap: wrap;
      margin-right: 10px;
      margin-bottom: 10px;

      &:nth-child(even) {
        .managers-count__num {
          color: #c00000;
        }
      }
    }
    &__block {
      width: 100%;
    }
    &__num {
      font-size: 17px;
      font-weight: 700;
      color: #2266e3;
    }
    &__name {
      font-size: 14px;
      color: #303130;
      font-weight: 400;
    }
  }
}
