// @import "../../../scss/abstracts/mixins";
// @import "../../../scss/abstracts/variables";

.modal-email-template {
  .modal__header {
    border-bottom: 1px solid #e0e0e0 !important;
    height: 55px;
  }
  .modal__content {
    background: #ffffff;
    width: 60%;
    .modal__header {
      border: 0;
    }
    .dashboard-compose-email {
      .form {
        .form-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding: 0 1rem;
          > div {
            // padding: 1.5rem;
            padding: 10px 15px;
          }
          .subject {
            width: 100%;
            border: 0;
            border-bottom: 1px solid #e4e4e4;
            // padding: 1.5rem;
            padding: 15px 15px;
            outline: none;
          }
          .body {
            height: 150px;
            border: 0;
            width: 100%;
            border-bottom: 1px solid #e4e4e4;
            padding: 1.5rem;
            resize: none;
          }
          .col-1 {
            width: 10%;
          }
          .col-2 {
            width: 40%;
          }
          .col-3 {
            width: 50%;
            display: flex;
            justify-content: flex-end;
          }
          .ck-editor {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            padding: 0;
            &__main {
              height: 150px;
              .ck-editor__editable {
                height: 150px;
                border: none;
              }
            }
            &__top {
              border-bottom: 1px solid #e4e4e4;
              .ck-toolbar {
                // padding: 1rem;
                padding: 5px 20px;
              }
            }
          }
        }

        .editor {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0.5rem 0.75rem 0 0.75rem;

          label {
            @include label;
            margin-right: 1rem;
            font-style: italic;
          }

          &-top {
            margin: 0 0 10px 0;
          }
        }

        .footer-row {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 1rem;
          .btn-schedule {
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
}
