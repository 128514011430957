.modal__content {
  width: 40%;
  background: #f0f2f5;
  .modal__header {
    padding: 0.7rem 1.5rem;
    border-bottom: 1px solid #757575;
    h2 {
      font-size: 1rem;
    }
  }
  .form-body {
    padding: 0.7rem 1.5rem;

    .MuiFormGroup-root {
      flex-direction: row;
      span {
        font-size: 0.8rem;
      }
      .MuiFormControlLabel-label {
        color: #74777b;
      }
    }
    .form-row {
      padding: 1rem 0 0 0;
      .form-group {
        &.med {
          max-width: 280px;
        }
        &__input {
          padding: 0.3rem 0.75rem;
        }
        &__message {
          margin: 0;
        }
      }
    }
    .footer-grp {
      justify-content: flex-end;
      border-top: 1px solid #dedede;
      .disabled {
        opacity: 0.5;
        cursor: unset;
      }
    }
  }
}

.travel-category,
.expense-allowance {
  .page-title {
    font-size: 14px;
    margin-right: 1rem;
    font-weight: 400;
  }
}
