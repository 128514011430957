@import "../../../../scss/abstracts/mixins";
@import "../../../../scss/abstracts/variables";

.time-request {
  &-title {
    margin-bottom: 10px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .approver_reviewer {
      .genericForm-group {
        width: unset;
      }
    }
    // th {
    // font-weight: 400;
    //   padding: 0 20px 10px 20px;
    // }

    // td {
    //   padding: 0 20px 5px 20px;
    // }
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #444;
    background-color: #{$rigo-white-color};
    font-size: 13px;
    border-radius: 5px;
    .heading {
      color: rgba(0, 0, 0, 0.7);
      padding: 0;
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      letter-spacing: 0.00938em;
      min-width: 90px;
      padding: 5px 10px 5px 5px;
    }
    .body {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // min-width: 90px;
      padding: 5px 10px 5px 5px;

      .genericForm-group {
        margin-right: unset;
      }
    }
    .date {
      width: 160px;
      min-width: 140px;
    }
    .time {
      min-width: 150px;
      width: 150px;
    }
    .device-time {
      width: 95px;
      min-width: 85px;
      display: flex;
      justify-content: center;
    }
    .note {
      width: 22%;
      min-width: 50px;
    }
    .delete-time-attendance {
      color: grey;
      padding: 0 5px 0 0;
    }
  }

  &-add {
    margin-top: 15px;
  }

  &-approvers {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    &-title {
      margin-bottom: 10px;
    }

    &-list {
      display: flex;
      flex-direction: row;
    }
    &-approver {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 15px;
      padding: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      max-width: 300px;

      .radio__btn {
        width: 20px;
        margin-right: 10px;
      }
      .name-designation {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        &__name {
          margin-bottom: 5px;
        }
        &__designation {
          color: grey;
        }
      }
    }
  }
}

.time-modal {
  .modal__content {
    width: 90%;
    // @include respond(med-desktop) {
    //   width: 90%;
    // }
    // @include respond(big-desktop) {
    //   width: 90%;
    // }
    overflow: visible;
    &-body {
      overflow: visible;
    }
  }
}
