.container {
  background-color: #f7f8fc;
  width: 100%;
}

.animated-form {
  display: flex;
  flex-direction: column;
  &__header {
    padding: 10px 25px;
    background-color: #e8eaf4;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 500;
      font-size: larger;
      color: #4e4e4e;
      font-size: 17px;
    }
    .close-icon {
      font-size: 28px;
      span {
        font-size: 1.6rem !important;
      }
      color: rgba(112, 107, 107, 0.7);
      cursor: pointer;
      line-height: 0;
      &:hover {
        color: #{$color-red};
      }
    }
  }
  &__body {
    padding: 25px;
    flex-grow: 1;
    .form-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5rem 0;
      .form-group {
        .pickers {
          margin: 0;
        }
        .upload {
          width: 170px;
          text-align: center;
          .document-no {
            color: #9e9e9e;
            font-size: 0.85rem;
            margin-bottom: 0.5rem;
            display: block;
          }
          .upload-btn {
            display: flex;
          }
        }
        textarea {
          width: 100%;
        }
        .form-group__label {
          display: block;
          // margin-bottom: 0.5rem;
        }
        .error__message,
        .form-group__message {
          margin: 0.5rem 0 0 0;
        }
      }
      .select {
        width: 50%;
        label {
          margin-bottom: 0.5rem;
          display: block;
        }
      }
      .error {
        .pickers {
          .MuiInputBase-input {
            border: 0 !important;
          }
        }
        &.autoselect {
          .MuiInputBase-root {
            border: 0 !important;
          }
        }
        .MuiInputBase-input,
        textarea {
          border: 1px solid #d64545;
        }
      }
    }
  }
  &__footer {
    padding: 1rem;
    background-color: #e9eff8;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    justify-content: center;
  }
}
