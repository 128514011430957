@import '../../../scss/abstracts/_variables';

.change-password{
    width: 100%;
    display: flex;
    flex-direction: column;

    .form-group,.genericForm-group{
        margin-bottom: 10px;
    }

    &__container{
        width: 100%;
        padding: 15px;
        flex-grow: 1;

        .form-group{
            margin-bottom: 10px;
        }
    }
    &__footer{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        padding: 10px;
        background-color: #dfdfdf;
    }
    &-modal{
        .modal__content{
            width: 450px !important;
        }
        .modal__header{
            background-color: #{$rigo-white-color};
        }
    }
}