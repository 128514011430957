@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.activity_billing_role_add {
  margin: 15px 0;
  .button.with-icon {
    max-width: unset;
  }
  &-modal {
    &-body {
      padding: 20px;
    }
    &-footer {
      display: flex;
      justify-content: flex-end;
      padding: 0 20px;
      margin-top: 20px;
    }
  }
}

.table-row {
  .role {
    &-choosed {
      display: inline-flex;
      justify-content: space-between;
    }
    &-link {
      cursor: pointer;
      color: $color-blue;
      text-decoration: underline;
    }
    &-position {
      // width: 100%;
      // display: flex;
      // flex-direction: row;
      // align-items: flex-start;
      // margin-top: 10px;

      &__select {
        // margin-top: 4px;
        position: relative;
        width: 300px;
        .popup {
          top: unset;
          left: 0;
          right: 0;
          &-open {
            margin-bottom: 10px;
          }
        }

        a {
          color: #0052ab;
          cursor: pointer;
          border-bottom: 1px solid #0052ab;

          &:hover {
            color: lighten(#0052ab, 10);
            border-color: lighten(#0052ab, 10);
          }
        }
      }
    }
  }
}
