@import '../../scss/abstracts/_variables';
.attendance{
    &-statistics{
        width: 100%;
        padding: 20px;
        color:#3e3d3e;

        .grid-half{
            // padding: 10px;
            width: 100%;
        }
    }
    &-label{
        font-size: 14px;
        // color:inherit;
        font-weight: 400;
        width: 150px;
        letter-spacing: 0.00938em;
    }
    &-list{
        display: flex;
        flex-direction:column;

        &__item{
            padding: 3px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .label{
                width: 150px;
            }
            .value{
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                span{
                    margin: 0 5px;
                }
            }
        }
    }
}

.flex-half{
    width: calc(50% - 20px);
    margin-right: 20px;

}

.employees-loading{
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.leave{
    &-count{
        // display: grid;
        width: 100%;
        // grid-template-columns: repeat(2,1fr);
        // grid-template-columns: 60% 40%;
        display: flex;
        flex-direction: row;

        &-left{
            margin-top: auto;
            margin-right: 20px;
            margin-bottom: 20px;
        }
        &-right{
            width: 100%;
        }
        &-diagram{
            width: 100%;
            height: 135px;
            max-height: 135px;

            svg{
                width: 100%;
                height: 100%;
            }
            .recharts-area-curve{
                fill: rgb(56, 136, 253) !important;
                fill-opacity: 1 !important;
                stroke: #{$rigo-white-color};
                stroke-width: 2px;
            }
        }

        &__list{
            display: flex;
            flex-direction: row;
            align-items: center;
    
        }
        &__item{
            margin-right: 20px;
        }
    }
    &-chart{
        width: 100%;
        position: relative;

        .unused{
            &-data{
                position: absolute;
                top: 170px;
                right: 25px;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                color:#444;
            }
            &-carry,&-lapse{
                margin-right: 10px;

                span span{
                    margin-right: 5px;
                }
            }
        }
    }

    &-time{
        &-block{
            display: flex;
            flex-direction: column;

            div{
                line-height: 25px;
                font-size: 14px;
            }
        }
        &-at{
            text-align: 17px !important;
            color: #454949;
            .head-text{
                font-size: 22px;
            }
            .second-text{
                color:rgb(189, 189, 189);;
            }
        }
        &-sub{
            font-size: 13px !important;
            color:#787878;
        }
    }
}

.alt-chart{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;

    .recharts-wrapper{
        width: 100% !important;
        overflow: hidden;

        svg{
            width: 350px;
            height: 100%;
        }
    }
}

.pie-chart-labels{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .cutom-label{
        font-size: 15px;
        color: #3d3e3e;
        word-spacing: 3px;
        line-height: 1.4;
        
        span{
            margin-right:5px;
            font-size: 22px;
            font-weight: 500;
        }
    }
}

.recharts-default-legend{
    text-align: left !important;
}