.pie-chart {
  width: 100%;

  .recharts-wrapper {
    width: 100% !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  .recharts-legend-wrapper {
    position: absolute;
    width: 200px;
    height: 100% !important;
    left: unset !important;
    right: 0px;
    top: 0px;
    background: #fafbfc;
    /* width: 100%; */
    height: 200px;
    display: flex;
    align-items: center;
    @include customScrollSm;

    .recharts-default-legend {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 150px;
      text-align: left !important;
      max-height: 130px;
      overflow-y: auto;
      

      .recharts-legend-item {
        svg {
          display: none !important;
        }
      }
    }
  }
}

.legend {
  &-label {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 6px;

    &-name {
      color: #454545;
      font-size: 12px;
      margin-left: 6px;
    }
    &-value {
      font-size: 12px;
      color: #454545;
      margin-left: 6px;
    }
  }
  &-bg {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: block;
    background-color: #666;
  }
}
