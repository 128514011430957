@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.renew {
  &-body {
    display: block;
    padding: 20px;

    &-section {
      // display: block;
      margin: 0 0 15px 0;

      &-projectName {
        font-weight: 500;
        font-size: medium;
      }
      &-expiryDate {
        font-weight: 500;
      }

      .form-group__outer {
        width: 80%;
      }

      .react-date-picker__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        border: thin solid #ccc0c0;
        border-radius: 4px;
        width: 200px;
        padding: 2px;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(2, 50%);
    }
  }
  &-footer {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.client {
  display: grid;
  grid-template-columns: 60% 40%;

  &-section {
    padding: 10px;
    display: block;

    &-horizontal {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 90%;
    }
    &-content {
      margin: 0 15px 15px 0;
      max-width: 90%;

      .form-group__message {
        margin-left: unset !important;
      }

      .error__message {
        margin-left: unset !important;
      }
      .genericForm-group__textarea {
        min-height: unset;
      }

      &-types {
        margin-top: 0.35rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &-chip {
          .badges {
            cursor: pointer;
            margin: 0 10px 10px 0;
          }
        }
      }

      &-multiselect {
        margin-top: 0.35rem;
        // display: grid;
        // grid-template-columns: 30%, 70%;

        .MuiInput-root {
          width: 300px;
        }
        .autoselect {
          .MuiFormControl-root {
            .MuiInputBase-root {
              margin-top: 1.2rem;
            }
          }
        }
      }
    }
  }

  &-document-count {
    min-width: 100px;
    // padding: 0 20px 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-number {
      // justify-content: center;
      // align-items: center;
      margin-left: 10px;
    }
  }
}

// .flex {
//   display: flex !important;
// }
