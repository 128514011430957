@import '../../../../scss/abstracts/_variables';
@mixin header {
  color: #afafaf;
  //font-weight: 500;
  font-size: 0.9rem;
}
@mixin footer {
  padding: 0.85rem 1rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dddcdc;
}
.disp-actions {
  &__title {
    margin: 1rem;
    .common-route-title {
      padding: 0;
      span{
        padding: 0;
      }
    }
  }
  &__header {
    display: flex;
    align-items: flex-start;
    background: #{$rigo-white-color};
    margin: 0 1rem;
    padding: 1.5rem;
    .emp-details {
      flex: 1;
    }
    .past-actions,
    .current-stage {
      padding: 1rem;
    }
    .past-actions {
      display: flex;
      &__text,
      &__count {
        font-size: 0.85rem;
        font-weight: 500;
        color: #474545;
      }
      &__count {
        color: #{$rigo-white-color};
        margin-left: 0.5rem;
        background: #5197ff;
        font-size: 0.85rem;
        border-radius: 50%;
        width: 20px;
        display: flex;
        height: 20px;
        align-items: center;
        justify-content: center;
      }
    }
    .current-stage {
      &__name {
        font-size: 0.85rem;
        font-weight: 500;
        color: #5197ff;
      }
    }
  }
  &__body {
    background: #{$rigo-white-color};
    margin: 0 1rem;
    .case-title {
      background: #dddcdc;
      padding: 0.5rem 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__text {
        font-size: 0.9rem;
        font-weight: 500;
        color: #555454;
      }
      .close-case-btn {
        padding: 0.5rem 1.2rem;
        border: 0;
        background: #817e7e;
        color: #{$rigo-white-color};
        border-radius: 2px;
        text-transform: none;
      }
    }
    .action-stage {
      padding: 1rem 1.5rem;
      display: flex;
      align-items: flex-start;
      &__name {
        font-size: 0.9rem;
        font-weight: 500;
        color: #474545;
      }
      &__icon {
        display: inline-block;
        background: #fcc26c;
        padding: 0.2rem;
        border-radius: 2px;
        svg {
          color: #{$rigo-white-color};
          font-size: 0.8rem;
        }
      }
      .current-stage {
        background: #2360e9;
      }
      .checked-stage {
        background: #8bc65d;
      }
      &__btn {
        display: block;
        padding: 0.5rem 1rem;
        border: 0;
        margin-top: 0.5rem;
        border-radius: 2px;
        color: #{$rigo-white-color};
        background: #1673ff;
        text-transform: none;
      }
      &__text-btn {
        padding-left: 1rem;
      }
    }
    .stage-details {
      webkit-box-shadow: 0px 0px 10px 0px rgb(228, 224, 224);
      -moz-box-shadow: 0px 0px 10px 0px rgb(228, 224, 224);
      box-shadow: 0px 0px 10px 0px rgb(228, 224, 224);
      border-radius: 2px;
      margin: 0 1.5rem 1.5rem 1.5rem;
      &__header {
        padding: 1rem;
        border-bottom: 1px solid #e8e8e8;
        @include header();
        display: flex;
        justify-content: space-between;
        align-items: center;
        .comment-block {
          display: flex;
          align-items: center;
          color: #5197ff;
          &__icon {
            font-size: 1.3rem;
          }
          &__text {
            padding-left: 0.2rem;
            cursor: pointer;
            &:hover {
              color: #2f71d2;
            }
          }
        }
      }
      &__body {
        padding: 1rem;
        .body-row {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 0.9rem;
          .stage-title {
            display: grid;
            grid-row-gap: 0.5rem;
            &__name {
              @include header();
            }
            &__value {
              //font-weight: 500;
              color: #4b4b4b;
            }
          }
          .actionby {
            display: grid;
            grid-row-gap: 0.5rem;
            padding-top: 1rem;
            &__title {
              @include header();
            }
            &__value {
              display: flex;
              .actionby-details {
                display: flex;
                align-items: center;
                .emp-detail {
                  padding: 0rem 3rem 0rem 1rem;
                  display: grid;
                  grid-row-gap: 0.2rem;
                  .emp-name {
                    //font-weight: 500;
                    color: #4b4b4b;
                  }
                  .emp-desg {
                    color: #818080;
                    font-size: 0.8rem;
                  }
                }
              }
            }
          }
          .createdby {
            // padding: 1rem;
            span {
              font-style: italic;
              font-size: 0.8rem;
              color: #888888;
              //font-weight: 500;
            }
          }
        }
        .response-medium {
          justify-content: flex-start;
          padding: 0.5rem 0;
          .action-mode {
            &__title {
              @include header();
            }
            &__value {
              padding-left: 0.5rem;
            }
          }
          .action-medium {
            &__mail,
            &__attachment {
              padding-left: 1rem;
            }
            &__mail {
              .mail-icon {
                font-size: 1.2rem;
              }
            }
            .mail-icon,
            .mail-text,
            .doc-name {
              color: #0072ff;
            }
            &__attachment {
              .doc {
                cursor: pointer;
              }
              .doc-icon {
                color: #f19f08;
              }
            }
          }
        }
      }
    }
  }
}
.dsp-action-modal {
  background-color: transparent;
  z-index: 1200;
  .modal__content {
    min-width: 50%;
    .modal__header {
      border-bottom: 1px solid #d8d8d8;
      background: #{$rigo-white-color};
      .modal__heading {
        h2 {
          font-weight: 500;
          color: #837f7f;
        }
      }
    }
    &-body {
      background: #{$rigo-white-color};
      box-shadow: 0px 0px 5px 0px #c7c5c5;
      .action-body {
        padding: 1rem;
        .form-row {
          padding: 0.5rem;
          display: grid;
          grid-row-gap: 0.5rem;
          .case-title {
            font-weight: 500;
          }
          .action-title {
            @include header();
          }
          .form-group {
            .form-group__label {
              display: block;
              margin-bottom: 0.5rem;
            }
            .pickers {
              margin: 0;
              .MuiFormControl-root {
                width: 300px;
              }
            }
            textarea {
              width: 100%;
            }
            .error__message,
            .form-group__message {
              margin: 0.5rem 0 0 0;
            }
            .upload {
              width: 170px;
              text-align: center;
              .document-no {
                color: #9e9e9e;
                font-size: 0.85rem;
                margin-bottom: 0.5rem;
                display: block;
              }
              .upload-btn {
                display: flex;
              }
              .document-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            textarea {
              border: 0.1px solid #dddcdc;
            }
            .css-yk16xz-control {
              height: auto;
            }
          }
          .select {
            width: 240px;
          }
          .error {
            .pickers {
              .MuiInputBase-input {
                border: 0 !important;
              }
            }
            &.autoselect {
              .MuiInputBase-root {
                border: 0 !important;
              }
            }
            .MuiInputBase-input,
            textarea {
              border: 1px solid #d64545;
            }
          }
          .actiontype-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .action-radio {
              flex-direction: row;
              span {
                font-size: 0.85rem;
                color: rgba(0, 0, 0, 0.7);
              }
            }
            .select {
              width: 170px;
            }
          }
        }
      }
      .action-footer {
        @include footer();
      }
    }
  }
}

.dsp-comment-modal {
  background-color: transparent;

  .modal__content {
    min-height: auto;
    &-body {
      .form-row {
        padding: 1rem;
        textarea {
          width: 100%;
        }
        .error__message,
        .form-group__message {
          margin: 0.5rem 0 0 0;
        }
        .error {
          textarea {
            border: 1px solid #d64545;
          }
        }
      }
      .dsp-comment-footer {
        @include footer();
      }
    }
  }
}

.iframe-modal {
  .modal__content {
    min-width: 60%;
    width: initial;
    &-body {
      overflow: hidden;
      .iframe-screen {
        .iframe {
          width: 100%;
          body {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
