// @import "../../../scss/abstracts/mixins";
// @import "../../../scss/abstracts/variables";

$font-family: "Roboto", Helvetica, sans-serif;

.header__title {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &-detail {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;
    .tertiary-title {
      color: #006df9;
      cursor: pointer;
      font-size: 21px;
      font-weight: 500;
      margin: 0 15px 0 0;
      &:hover {
      }
      // color: #006df9;
      // text-decoration: underline;
    }
    .light-para {
      font-size: 13px;
      margin: 0;
    }
  }

  &-edit {
    // margin-right: 20px;
    font-size: 15px;
    font-weight: 500;
    color: #006df9;
    cursor: pointer;
  }
}

.dashboard {
  width: 100%;
  padding: 20px;
  font-family: $font-family;

  // @include respond(med-desktop) {
  //   width: 90% !important;
  // }
  // @include respond(big-desktop) {
  //   width: 80% !important;
  // }

  main {
    padding: 10px 0px;
    // padding: 1rem 2rem !important;
  }

  &__header {
    .header-section {
      width: 70%;
    }
    // .panel-section {
    //   width: 100%;
    //   max-width: 1300px;
    //   margin: 0 0 10px 0;
    //   padding: 10px 20px;
    //   align-items: center;
    //   // margin: 10px 20px;

    //   .tertiary-title {
    //     margin-bottom: unset;
    //   }
    // }
  }
  &__body {
    .select-section {
      margin-bottom: 20px;
      label {
        font-size: 15px;
        font-weight: 500;
        color: #555;
        line-height: 1.75;
      }
    }
  }
  &-select {
    width: 250px;
    margin-left: 20px;
  }
  //#region dashboard-email
  &-email {
    &__header {
      padding: 1rem 0;
      // border-bottom: 2px solid #d4d4d4;

      margin: 0 1rem;
      span {
        font-size: 15px;
        color: #8d8d8d;
      }
    }
    &__body {
      padding: 0 1rem;
      max-height: 60vh;
      overflow-y: auto;
      .email-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1rem 0;
        border-bottom: 1px solid #e2e2e2;
        &:first-child {
          border-top: 1px solid #e2e2e2;
        }
        .img-content {
          width: 65px;
          .emp-image {
            width: 60px;
            border-radius: 10px;
          }
        }
        .email-content {
          width: calc(100% - 230px);
          span {
            display: block;
            // font-size: 0.9rem;
            // padding: 0.15rem;
          }
          .email-from {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 3px;
            color: #272727;
          }
          .email-subject {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 5px;
            color: #666;
          }
          .email-body {
            font-size: 12px;
            font-weight: 400;
            color: #383838;
          }
        }

        .email-date {
          width: 150px;
          .email-time {
            font-size: 13px;
            color: #646464;
          }
        }
      }
    }
  }

  &-profile-document {
    padding: 20px 30px 40px;
    .document-title {
      span {
        display: inline-block;
        border-bottom: 2px solid #003e87;
        color: #003e87;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;
      }
    }
    .document {
      display: inline-block;
      margin: 10px 15px 10px 0;
      width: calc(33% - 15px);
      &-border {
        padding: 6px 15px;
        border: 2px solid #d9d9d9 !important;
        border-radius: 30px;
        &:hover {
          background-color: #f5f5f5;
        }
      }

      &-icon {
        span {
          font-size: 16px !important;
        }
        color: $color-blue;
      }
      &__name {
        font-size: 14px;
        color: #666;
        padding: 10px;
      }
      &-image {
        display: block;
        img {
          max-width: 200px;
          max-height: 200px;
          margin-top: 5px;
        }
      }
      &-ext {
        text-transform: uppercase;
        padding: 7px;
        background-color: gray;
        color: white;
        font-size: 15px !important;
        border: 1px solid gray;
        border-radius: 6px;
        display: none;
      }
      .pdf {
        border-color: #e81d0f;
        background-color: #e81d0f;
      }
      .doc {
        border-color: #1c8af9;
        background-color: #1c8af9;
      }
      .xls {
        border-color: #179436;
        background-color: #179436;
      }
    }
    // .document-container {
    //   margin: 20px;
    //   &-title {
    //     padding: 5px;
    //     font-weight: 500;
    //   }
    //   &-body {
    //     display: flex;
    //     flex-wrap: wrap;
    //     .profile-document {
    //       position: relative;
    //       margin-bottom: 20px;
    //       flex-direction: column;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       padding: 5px;
    //       margin: 10px;
    //       //background-color: #f1f1f1;
    //       // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    //       //   0 6px 20px 0 rgba(0, 0, 0, 0.19);
    //       box-shadow: 0 4px 6px -5px rgba(0, 0, 0, 0.21),
    //         0 3px 11px -6px rgba(0, 0, 0, 0.17);
    //       width: 160px;
    //       height: 160px;
    //       border-radius: 4px;
    //       cursor: pointer;
    //       overflow: hidden;
    //       position: relative;
    //       text-align: center;
    //       cursor: pointer;
    //       &-icons {
    //         // width: 100%;
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         height: auto;
    //         padding: 12px 20px;
    //         border: 1px solid #e4e4e4;
    //         .profile-document__name {
    //           flex-grow: 1;
    //           text-align: left;
    //           margin: 0;
    //           font-size: 14px;
    //         }
    //       }
    //       &__icon {
    //         width: 35px;
    //         height: 35px;
    //       }
    //       &__name {
    //         margin-top: 10px;
    //         font-size: 13px;
    //         font-weight: 400;
    //         word-break: break-all;
    //         padding: 0 10px;
    //         color: #2680eb;
    //       }
    //       .document-icon {
    //         width: 150px;
    //         height: 150px;
    //       }
    //       .small-font {
    //         font-size: 11px;
    //         position: absolute;
    //         bottom: 0px;
    //         background-color: #00000036;
    //         width: 100%;
    //         left: 0px;
    //         color: white;
    //         text-overflow: ellipsis;
    //       }
    //     }
    //   }
    // }
  }
  //#endregion
}
.breadCrumb {
  margin-bottom: 20px;
}
.details {
  &-section {
    border-radius: 6px !important;
    background-color: #fff;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
  }
  &-time {
    svg,
    i {
      font-size: 20px;
      color: #{$color-green};
    }
    .icon-red {
      svg,
      i {
        font-size: 20px;
        color: #{$color-red} !important;
      }
    }
  }
  &-header {
    align-items: flex-start;
    // margin-bottom: 7px;
    p {
      margin-left: 10px;
    }
  }
  &-body {
    width: 100%;
    display: flex;
    padding: 5px 0 5px 0;
    flex-direction: row;
    align-items: center;

    &-add {
      width: 150px;
      color: #3f51b5;
      cursor: pointer;

      &:hover {
        color: lighten(#3f51b5, 12);
      }
    }
  }
  &-uncollapseable {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 15px;
    // justify-content: space-between;
    padding: 10px 0 5px 0;

    // @include respond(med-desktop) {
    //   width: 75%;
    // }
    // @include respond(big-desktop) {
    //   width: 70%;
    // }
  }
  &-collapseable {
    .display {
      padding: 0 !important;
    }
  }

  &__block {
    // margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 20px;
    border-radius: 6px;
    background: #fff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border: 2px solid transparent;
    flex: 1;
    font-size: 22px;
    font-weight: 500;
    &:hover {
      border: 2px solid rgba(#006df9, 0.25);
      background: rgba(#006df9, 0.05);
    }
    &.openings {
      flex-direction: column;
      background: none;
      margin-right: 20px !important;
      background-color: rgba(#006df9, 0.15);
      border: 2px solid rgba(#006df9, 0.15);
      display: block;
      align-self: stretch;
      color: #006df9;
      font-size: 22px;
      .details__block-num {
        color: #006df9;
      }
      h5 {
        color: #006df9;
      }
    }

    &.active {
      background: #006df9;
      // .job-details__block {
      //   &-num,
      //   &-title {
      //     color: #{$color-blue};
      //   }
      // }
      &:hover {
        background: lighten(#006df9, 7);
      }
      .active-num {
        color: #fff;
        background: transparent;
      }
      .active-title {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        background: transparent;
      }
    }

    &-num {
      color: #565656;
    }
    &-title {
      font-size: 13px;
      font-weight: 500;
      color: #565656;
    }
    &__num {
      margin-bottom: 5px;
    }
  }
}
.action-buttons {
  flex-direction: row-reverse;
  // padding: 20px 20px 0;
  button {
    padding: 6px 12px !important;
    margin-left: 12px;
    font-family: $font-family !important;

    svg,
    i {
      margin-right: 8px !important;
      font-size: 16px !important;
    }
  }
}

.display {
  display: flex;

  flex-direction: row;
  margin-left: auto;
  padding: 20px 25px 0;
  // margin-bottom: 8px;

  &-views {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 15px;
    color: #565b6b;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    padding: 8px 10px;
    transition: 0.3s ease;
    &:hover {
      background: rgba(#006df9, 0.1);
    }

    &-icon {
      margin-right: 6px;
      font-size: 18px;
      line-height: 0;
    }

    &.active {
      background: #006df9;
      color: #fff;
    }
  }
}
.jobsfilterbar {
  display: flex;
  align-items: flex-start;
  margin: 0 -15px;
}
.jobsfilter {
  padding: 0 15px;
  &-recent {
    flex: 1;
  }
}
.jobsfilter {
  margin-bottom: 15px;
}
.jobsaction-buttons {
  margin-bottom: 10px;
}
.jobsfilter-searchgrid {
  min-width: 330px;
  max-width: 330px;
}
.jobsfilter-recent-searchbar {
  outline: none;
  padding: 8px 12px;
  background: #fff;
  background: var(--color-white);
  font-size: 14px;
  color: #444;
  height: 36px;
  border-radius: 3px;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
}
.jobsfilter-tabs {
  display: flex;
  margin-left: auto;
}
.jobsaction-buttons {
  display: flex;
  .jobsaction {
    display: flex;
    &:not(:last-child) {
      margin-right: 20px;
    }
    &-stages {
      display: flex;
      align-items: center;
    }
    .button {
      margin: 0 10px 0 0;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 5px;
      }
    }
  }
}
.information {
  display: flex;
  flex-direction: row;
  // padding: 20px 20px 10px 20px;
  padding: 0 0 10px 0;
  justify-content: space-between;
  align-items: flex-start;

  &-block {
    display: grid;
    grid-template-columns: 280px 1fr;
    gap: 20px;
    // min-height: 60vh;
  }

  &-tabs {
    // display: flex;
    // justify-content: center;
    .MuiButtonBase-root {
      font-size: 14px;
    }
  }

  &-inputs {
    padding: 10px;

    input {
      margin-top: 0 !important;
      border-radius: 5px !important;
      padding: 10px !important;
    }
  }
  &-listing {
    height: 100%;
    width: 100%;
    // width: 20%;
    // padding: 5px;
    border: 1px solid #c3cfdd;
    background: #ffffff;
    border-radius: 4px;

    display: flex;
    flex-direction: column;

    &-scroll {
      flex-grow: 1;
      height: 100%;
    }

    //  .MuiTabs-flexContainer{
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         height:35px
    //       }
    .MuiTab-root {
      min-width: 120px;
    }

    .MuiTabs-indicator {
      width: 120px !important;
      min-width: 100px;
    }
    // .MuiTabs-indicator {
    //   width: 100px !important;
    // }

    .MuiTab-textColorPrimary.Mui-selected {
      color: #006df9;
      border-bottom: 2px solid;
    }

    .search {
      max-width: 100%;

      .form-group__input {
        padding: 5px;
      }
    }

    &-checkbox {
      // margin-top: 10px;
      // margin-bottom: 20px;
      padding: 5px 10px;
    }

    &-list {
      display: flex;
      flex: 2;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      // margin-bottom: 10px;
      border-bottom: 1px solid #e1e6ea;
      cursor: pointer;

      &.active {
        background: #fef6df;
      }
    }

    &-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      .user-avatar {
        margin: 0 !important;
      }
    }

    &-desc {
      display: flex;
      flex: 5;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 10px;
      padding-right: 5px;
      span {
        &:first-child {
          // margin-bottom: 3px;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .fonts-light_small {
        font-size: 12px;
        color: #616161;
        margin-right: 10px;
        margin-top: 3px;
      }
    }
  }

  &-wholeInfo {
    height: 100%;
    flex-grow: 1;
    // margin-left: 20px;
    // padding: 20px;
    border-radius: 8px;
    border: 1px solid #f0f2f5;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

    &-applicant {
      display: flex;
      flex: 1;
      flex-direction: row;
      padding: 15px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: #fff;

      &-left {
        display: flex;
        flex-direction: row;
        flex: 1;
        // padding: 10px;

        &-desc {
          display: flex;
          flex: 5;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 20px;
          padding-right: 5px;

          .fonts-dark_larger {
            color: #2680eb;
            font-size: 20px;
            font-weight: 500;
            text-transform: capitalize;
            // margin-bottom: 5px;
          }
        }

        &-tags {
          margin-top: 10px;
          border-radius: 10px;
          padding: 5px 10px 5px 10px;
          background: #eff1f2;
          display: inline-flex;
          font-size: small;
          &:hover {
            background: #c3c6c7;
          }
        }
      }

      &-right {
        display: flex;
        flex-direction: row;
        flex: 1;
        // padding: 10px;
        background: #f9f9f9;
      }
    }

    &-nav {
      // &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      background: #e6f0fb;
      border-bottom: 1px solid #d6d8db;
      &-container {
        padding: 5px 30px;
        position: relative;
      }

      &-tabs {
        max-width: 50%;
        .MuiTabs-root {
          border: none;
        }
        // .MuiTabs-flexContainer{
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   height:35px
        // }
        .MuiTab-root {
          min-width: 80px;
          font-weight: 500;
          padding: 12px 35px 10px 35px;
          margin: 0;
          border-bottom: 2px solid transparent;
        }

        .MuiTabs-indicator {
          width: 80px !important;
          display: none;
        }

        // .MuiTab-textColorPrimary.Mui-selected {
        //   color: #3f51b5;
        //   border-bottom: 2px solid;
        // }
        .MuiTab-textColorPrimary.Mui-selected {
          color: #006df9;
          border-bottom: 2px solid;
        }
      }

      &-extra {
        display: flex;
        // flex: 5;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &-chips {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          // padding: 5px 10px 5px 10px;
          // background: #f3f4f6;
          // border-radius: 10px;
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.3s;
          &-icon {
            color: #398cf6;
            margin-right: 5px;
            line-height: 0;
          }
          &:hover {
            background: rgba(57, 140, 246, 0.1);
          }
        }
      }
      // }
      &-block {
        // padding: 10px;

        padding: 20px 0 10px 0;
        &-title {
          display: flex;
          flex-direction: row;
          max-width: 50%;
          margin-bottom: 6px;
          justify-content: space-between;
          align-items: center;

          span {
            display: inline-block;
            border-bottom: 2px solid #003e87;
            color: #003e87;
            font-size: 18px;
            font-weight: 500;
            padding-bottom: 5px;
          }
          // span {
          //   &:first-child {
          //     font-size: 14px;
          //     font-weight: 500;
          //     color: #444;
          //   }
          // }
        }
        &-subtitle {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 0;
          line-height: 1;
          &:last-child {
            padding-bottom: 0;
          }
          &-left {
            flex: 1;

            span {
              // color: #88929e;
              color: #2c2c2c;
              font-size: 14px;
              font-weight: 400;
            }
          }
          &-right {
            display: flex;
            flex-direction: column;
            flex: 2.5;
            justify-content: center;
            align-items: flex-start;

            .fonts-dark_medium {
              font-size: 14px;
              font-weight: 500;
              color: #343e48;
            }
            .fonts-light_medium {
              font-size: 14px;
              font-weight: 500;
              color: #343e48;
              // color: #88929e;
            }
          }
          &-description {
            display: block;
            // flex-direction: row;
            // justify-content: flex-start;
            // align-items: center;
          }
        }
        &-section {
          display: flex;
          margin-top: 15px;
          align-items: flex-start;
          > div {
            .information-wholeInfo-nav-block-section-subtitle {
              &:not(:first-child) {
                padding: 10px 0;
                line-height: 1;
              }
              &:last-child {
                padding-bottom: 0;
              }
              > span {
                font-weight: 500;
                font-size: 14px;
                color: #343e48;
              }
              &.heading {
                margin-bottom: 5px;
                span {
                  color: #2c2c2c;
                  font-weight: 400;
                }
              }
            }
          }
          &-left {
            margin-right: 40px;
          }
          // justify-content: space-between;
          &-subtitle {
            span {
              font-size: 14px;
            }
          }
        }
      }
    }

    &-cv_milestone {
      width: 100%;
      display: inline-flex;
    }
    &-cv {
      width: 70%;
      border-right: 1px solid #c3cfdd;
    }

    &-milestone {
      width: 45%;
      background: #fff;
      height: auto;
      padding: 10px 15px;
      position: relative;
      button {
        padding: 10px 15px;
      }
      h5 {
        font-size: 18px;
        font-weight: 500;
        margin: 10px 0 15px 0;
        color: #2c2c2c;
      }
      .verticle_line {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 35px;
        left: 23px;
        background: #d9d9d9;
        height: calc(100% - 60px);
        width: 2px;
      }

      .MuiPaper-root {
        background-color: unset;
      }
      .MuiExpansionPanelSummary-root {
        min-height: auto;
        padding: 0 10px 0 10px;
      }

      .MuiExpansionPanelDetails-root {
        display: flex;
        flex-direction: column;
        padding: 0;
      }
      .MuiExpansionPanelSummary-content {
        margin: 0;
      }
      .MuiExpansionPanelSummary-root {
        padding: 10px 15px;
        &.Mui-expanded {
          min-height: auto;

          .MuiExpansionPanelSummary-content.Mui-expanded {
            margin: 0;
          }
          .information-wholeInfo-milestone-panel-icon_title {
            color: #006df9;
          }
          .MuiSvgIcon-root {
            color: #006df9;
          }
        }
      }
      .MuiExpansionPanel-root {
        margin-bottom: 15px;
        background-color: #e6f0fb;
        border-radius: 3px;
        margin-bottom: 15px;
      }
      .MuiExpansionPanel-root {
        position: unset;
      }
      .MuiPaper-elevation1 {
        box-shadow: none;
      }
      .MuiIconButton-root {
        padding: 0px;
      }
      .makeStyles-heading-129 {
        font-size: unset;
      }
      .MuiExpansionPanel-root:before {
        background: none;
      }

      .MuiCollapse-container {
        background-color: #f3f8fb;
        border-radius: 0 0 3px 3px;
        border-top: 1px solid #e1e6ea;
      }

      &-panel {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
        &-icon_title {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          color: #2c2c2c;
          font-weight: 500;
          font-size: 14px;
        }
        &-icon {
          margin-right: 10px;
          font-size: 1.25em;
          line-height: 0;
          color: #2680eb;
        }
      }
      &-common {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-move {
          flex-grow: 1;
          margin-right: 1rem;
          button {
            margin-bottom: 10px;
          }
          .MuiPaper-root {
            background: #ffffff !important;
          }
        }

        &-calendar {
          color: #2680eb;
          font-size: 1.2em;
          margin-left: 1rem;
        }
      }

      &-shortlist {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        background: #fff;
        color: #373737;
        font-size: medium;
        border-radius: 5px;
        padding: 15px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        font-size: 15px;
        &.no-bg {
          background: none;
          border-radius: none;
          box-shadow: none;
          display: flex;
          justify-content: space-between;
        }
      }
      &-interview-panel {
        display: flex;
        flex: 1;
        flex-direction: column;
        background: #fff;
        color: #646464;
        font-size: medium;
        border-radius: 5px;

        padding: 0 15px 15px 15px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        &.no-bg {
          background: none;
          border-radius: none;
          box-shadow: none;
          .button-secondary {
            background: none;
            color: #0065ff;
            border: 1px solid #0065ff;
            &:hover {
              background: #0065ff;
              color: #fff;
            }
          }
        }
        .panel-title {
          padding: 10px;
          border-bottom: 2px solid #f0f2f5;
          display: flex;
          justify-content: space-between;
        }
        .panel-container {
          padding: 15px 10px;

          &-subtitle {
            font-weight: 500;
            margin-bottom: 10px;
          }
          &-sub-item {
            div {
              margin-bottom: 10px;
              font-size: 14px;
            }
            &-interviewers {
              color: #398cf6 !important;
              font-weight: 500 !important;
              padding-bottom: 10px;
            }
            &-email {
              cursor: pointer;
              display: flex;
              align-items: center;
              color: #398cf6;
              display: flex;
              flex-flow: row-reverse;
              &-icon {
                color: #398cf6;
                margin-right: 5px;
                line-height: 0;
                font-size: 17px;
              }
            }
            .inactive {
              color: #d9d9d9;
            }
          }
        }
      }
    }
  }
}
.information-wholeInfo-milestone-common {
  padding: 15px 15px 10px 15px;
}
div:not(.information-wholeInfo-milestone-shortlist) {
  + .information-wholeInfo-milestone-common {
    padding: 15px;
  }
}
.date-schedule {
  color: #646464;
  padding: 10px 10px 20px;
}
.interview-header {
  .flex {
    margin-bottom: 12px;
  }
}

.interview-stage {
  background-color: #ffeade;
  padding: 20px;
  .body-accordion_title {
    font-size: 16px;
    padding-left: 6px;
    font-weight: 500;
    color: #2c2c2c;
  }
  .interviewdate {
    font-size: 14px;
    color: #646464;
  }
  .interviewers {
    margin-top: 20px;
    li {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .interviewer-personnel {
    color: #2c2c2c;
    font-size: 15px;
    padding-right: 6px;
    min-width: 120px;
  }
  .interviewer-process {
    font-size: 14px;

    &.primary {
      color: #006df9;
    }
    &.success {
      color: #418d00;
    }
  }
}
.genericForm-group__date.custom-date {
  background: #ececec;
}
.select-option-group-heading {
  padding: 5px 10px;
  color: gray;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
}
.select-option {
  padding: 7px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #dedede;
  }
  &-sub-group {
    margin-left: 15px;
  }
  &-group {
    // border: 2px dotted red;
    //  border-radius: 5px;
    // background: #f2fcff;
  }

  &-sub {
    margin: 0px 0px 0px 10px;
    display: flex;
  }

  span {
    margin-right: 10px;
  }
}
.blue {
  color: #398cf6;
}

.fonts {
  &-dark_larger {
    font-size: larger;
    font-weight: 500;
    margin-right: 10px;
  }
  &-dark_large {
    font-size: large;
    font-weight: 400;
    margin-right: 10px;
  }
  &-dark_medium {
    font-size: medium;
    margin-right: 10px;
  }
  &-dark_small {
    font-size: small;
    margin-right: 10px;
  }

  &-light_large {
    font-size: large;
    font-weight: 400;
    color: $color-grey;
    margin-right: 10px;
  }
  &-light_medium {
    font-size: medium;
    font-weight: 200;
    color: $color-grey;
    margin-right: 10px;
  }
  &-light_small {
    font-size: small;
    color: $color-grey;
    margin-right: 10px;
  }
}

// .modal-recruit-email {
//   //#region dashboard-compose-email
//   .modal__header {
//     border-bottom: 1px solid #e0e0e0 !important;
//     height: 55px;
//   }
//   .modal__content {
//     background: #ffffff;
//     .modal__header {
//       border: 0;
//     }
//     .dashboard-compose-email {
//       .compose-email-form {
//         .form-row {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           //padding: 1rem 0;
//           > div {
//             // padding: 1.5rem;
//             padding: 10px 15px;
//           }
//           .subject {
//             width: 100%;
//             border: 0;
//             border-bottom: 1px solid #e4e4e4;
//             // padding: 1.5rem;
//             padding: 15px 15px;
//             outline: none;
//           }
//           .body {
//             height: 150px;
//             border: 0;
//             width: 100%;
//             border-bottom: 1px solid #e4e4e4;
//             padding: 1.5rem;
//             resize: none;
//           }
//           .col-1 {
//             width: 10%;
//           }
//           .col-2 {
//             width: 40%;
//           }
//           .col-3 {
//             width: 50%;
//             display: flex;
//             justify-content: flex-end;
//           }
//           .ck-editor {
//             display: flex;
//             flex-direction: column-reverse;
//             width: 100%;
//             padding: 0;
//             &__main {
//               height: 150px;
//               .ck-editor__editable {
//                 height: 150px;
//                 border: none;
//               }
//             }
//             &__top {
//               border-bottom: 1px solid #e4e4e4;
//               .ck-toolbar {
//                 // padding: 1rem;
//                 padding: 5px 20px;
//               }
//             }
//           }
//         }
//         .email-from {
//           background: #e5e9ed;
//         }
//         .email-to {
//           border-bottom: 1px solid #e4e4e4;
//           &__name {
//             background: #e5e9ed;
//             padding: 0.5rem 1rem;
//             border-radius: 3px;
//           }
//         }
//         .footer-row {
//           display: flex;
//           justify-content: flex-end;
//           align-items: center;
//           padding: 1rem;
//           .btn-schedule {
//             padding: 0.5rem 1rem;
//           }
//         }
//       }
//     }
//   }
//   //#endregion
// }

.recruit {
  // .details-section{
  //   width: 100%;
  // }
  .breadCrumb {
    margin-bottom: 10px;
  }
  .MuiPaper-root {
    z-index: 1111;
  }
  &-tableview {
    padding: 15px 0 10px 0;
    &-topbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 5px 0;

      .filters {
        width: unset;
        margin: unset;
      }
    }

    .ag-cell {
      display: flex;
      align-items: center;
      overflow: visible;
      &:first-child,
      &:nth-child(2) {
        padding: 0 8px;
      }
    }
    .ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {
      z-index: 0;
    }
    .ag-react-container {
      overflow: unset !important;
    }
    .ag-header-viewport,
    .ag-floating-top-viewport,
    .ag-body-viewport,
    .ag-center-cols-viewport,
    .ag-floating-bottom-viewport,
    .ag-body-horizontal-scroll-viewport,
    .ag-virtual-list-viewport {
      overflow-y: unset !important;
    }
    // .ag-center-cols-clipper,.ag-root-wrapper,.ag-center-cols-viewport{
    //   overflow:unset !important;
    // }
    .ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus {
      z-index: 999;
    }
    .ag-row.ag-row-level-0.ag-row-position-absolute .ag-cell-focus {
      overflow: visible;
    }
    .ag-root,
    .ag-body-viewport,
    .ag-body-viewport-wrapper {
      overflow: visible !important;
    }
    .actions-button-cell {
      overflow: visible;
    }
    .ag-row {
      z-index: 0;
    }
    .ag-row.ag-row-focus {
      z-index: 1;
    }
    .filters-grid {
      margin: 0;
    }
    .drop-icon {
      line-height: 0;
    }
  }
}

.tableview {
  font-family: "Roboto", Roboto;
  &-grid {
    .e-columnheader {
      th {
        padding: 0 10px 0 !important;
        border-bottom: 1px solid #cfcfcf;

        .e-headercelldiv {
          .e-headertext {
            font-size: 14px;
            color: #444;
            font-weight: 400;
          }
        }
        .fav-header {
          margin-top: 5px;
          margin-left: 4px;
          svg,
          i {
            color: #646464;
            font-size: 26px;
          }
        }
      }
    }
    .favorite {
      vertical-align: middle;
      &-div {
        font-size: 27px;
        text-align: center;
        color: #e7e7e7;
        cursor: pointer;
        &:hover {
          color: #d1d1d1;
        }
        svg {
          margin: 0 auto;
        }
        &.active {
          color: #006df9;
          &:hover {
            color: lighten(#006df9, 8);
          }
        }
      }
    }
    .person {
      display: flex;

      &-image {
        margin-right: 10px;
      }
      &-details {
        display: flex;
        flex-direction: column;

        h4 {
          font-size: 14px;
          color: #006df9;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            color: lighten(#006df9, 15);
          }
        }
        p {
          color: #595959;
          font-size: 13px;
          font-weight: 400;
          margin-top: 5px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          hyphens: auto;
        }
      }
    }
    .education {
      &-div {
        display: flex;
        flex-direction: column;

        h4 {
          color: #444;
          font-size: 14px;
          font-weight: 400;
        }
        p {
          color: #595959;
          font-size: 12px;
          font-weight: 400;
          margin-top: 5px;
        }
      }
    }
    .currentstate-div {
      &-item {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        align-items: center;

        &.red {
          color: #d5351b;
        }
        &.blue {
          color: #006df9;
        }
      }
      svg {
        font-size: 18px;
        margin-right: 7px;
      }
    }
  }
  &-row {
    td {
      font-size: 13px;
      color: #333;
      vertical-align: top;
      padding: 15px 10px;
      width: 100%;
      border-bottom: 2px solid #e8e8e8;
    }
  }
}
.ag-react-container {
  .favorite {
    vertical-align: middle;
    &-div {
      font-size: 27px;
      text-align: center;
      color: #e7e7e7;
      cursor: pointer;
      &:hover {
        color: #d1d1d1;
      }
      svg {
        margin: 0 auto;
      }
      &.active {
        color: #006df9;
        &:hover {
          color: lighten(#006df9, 8);
        }
      }
    }
  }

  .person {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // padding: 12px 0px;
    line-height: 16px;
    &-image {
      margin-right: 10px;
      margin-top: 7px;
    }
    &-details {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: 14px;
        color: #006df9;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          color: lighten(#006df9, 15);
        }
      }
      span {
        color: #595959;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
  .education {
    &-div {
      display: flex;
      flex-direction: column;

      h4 {
        color: #444;
        font-size: 14px;
        font-weight: 400;
      }
      p {
        color: #595959;
        font-size: 12px;
        font-weight: 400;
        margin-top: 5px;
      }
    }
  }
  .currentstate-div {
    &-item {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      align-items: center;

      &.red {
        color: #d5351b;
      }
      &.blue {
        color: #006df9;
      }
    }
    svg {
      font-size: 18px;
      margin-right: 7px;
    }
  }
}
.link-dropdown {
  width: 175px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
  position: relative;
  &-link {
    flex-grow: 1;
    font-size: 13px;
    color: #006df9;
    padding: 0 3px;
    cursor: pointer;
    a,
    span {
      text-decoration: none;
      color: #006df9;
      cursor: pointer;
      &:hover {
        color: lighten(#006df9, 10);
      }
    }
  }
  &-dropdown {
    padding: 0 0px 0 6px;
    border-left: 2px solid #cecece;
    cursor: pointer;
    .drop {
      &-icon {
        line-height: 0;
        font-size: 20px;
        color: #006df9;
      }
      &-content {
        display: none;
        transition: all 0.2s;
        width: 170px;
        position: absolute;
        top: 110%;
        left: 5px;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
        z-index: 111;

        &.drop-active {
          display: block;
        }
      }
      &-list {
        white-space: break-spaces;
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        &-hover {
          &:hover {
            background-color: #d6d6d6;
          }
        }
        &__item {
          width: 100%;
          line-height: 1.75;
          padding: 8px;
          border-bottom: 1px solid #d5d5d5;
          font-size: 13px;
          color: #444;
          background-color: #f7f7f7;
          cursor: pointer;
          &.with-flex {
            display: flex;
            align-items: center;
          }
          span {
            margin-right: 10px;
            line-height: 0;
          }

          &-sub {
            padding: 8px 8px 8px 25px;
            &.flex {
              display: flex;
              align-items: center;
            }
            &:hover {
              background-color: #d6d6d6;
            }
          }
        }
      }
    }
  }
}
.custom-popup {
  .drop {
    &-icon {
      line-height: 0;
      font-size: 20px;
      color: #006df9;
    }
    &-content {
      transition: all 0.2s;
      width: 170px;
      top: 110%;
      left: 5px;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      z-index: 111;
    }
    &-list {
      white-space: break-spaces;
      list-style: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      &-hover {
        &:hover {
          background-color: #d6d6d6;
        }
      }
      &__item {
        width: 100%;
        line-height: 1.75;
        padding: 8px;
        border-bottom: 1px solid #d5d5d5;
        font-size: 13px;
        color: #444;
        background-color: #f7f7f7;
        cursor: pointer;
        &.with-flex {
          display: flex;
          align-items: center;
        }
        span {
          margin-right: 10px;
          line-height: 0;
        }

        &-sub {
          padding: 8px 8px 8px 25px;
          &.flex {
            display: flex;
            align-items: center;
          }
          &:hover {
            background-color: #d6d6d6;
          }
        }
      }
    }
  }
}

.blue-auto-select {
  margin-left: 15px;

  .genericForm-group {
    margin-right: unset;
  }

  .MuiInput-input {
    background: #2680eb;
    padding: 4px;
  }
  .MuiPaper-root {
    background: #ffffff !important;
  }
  .MuiTypography-root {
    color: #fff;
  }
  .makeStyles-singleValue-7 {
    color: #fff;
  }
  .css-tj5bde-Svg {
    color: #fff;
  }
  .MuiTypography-colorTextSecondary {
    color: #fff;
  }
}

.schedule {
  display: flex;
  flex-direction: column;

  &-header {
    background-color: #e5e9ed;
    padding: 20px;
    display: flex;
    label {
      font-size: 14px;
      color: #333;
      font-weight: 400;
    }
    h4 {
      font-weight: 500;
      font-size: 16px;
      margin-left: 15px;
      color: #333;
    }
  }
  &-block {
    padding: 20px;
    display: flex;
    flex-direction: column;
    &-checkBoxList {
      border: 2px solid #dfe1e6;
      padding: 10px;
    }
    &.type {
      flex-direction: row;
      align-items: center;
      a {
        color: #2680eb;
        cursor: pointer;
      }

      & > div {
        margin-right: 20px;
      }
    }
    &-input {
      padding: 15px 0px;
    }
    &-type {
      label {
        transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        transform-origin: top left;
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.8rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0.00938em;
        padding-bottom: 10px;
      }
    }
    .new-type {
      .form-group {
        label {
          transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          transform-origin: top left;
          color: rgba(0, 0, 0, 0.54);
          padding: 0;
          font-size: 0.8rem;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          line-height: 1;
          letter-spacing: 0.00938em;
          padding-bottom: 10px;
        }
        input {
          padding: 8px 12px !important;
        }
      }
    }
    .makeStyles-radioGroup-939 {
      padding: 20px 0 0;
      flex-direction: row;
    }
  }
  &-footer {
    width: 100%;
    border-top: 1px solid #d8d8d8;
    // padding:20px;

    .button-group {
      padding: 20px;
      margin-top: 0;
    }
  }
}
.MuiDialog-root {
  z-index: 1003100001 !important;
}

.date-time-picker {
  display: flex;
  max-width: 285px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 2px 5px;
  align-items: center;
  height: 34px;
  // div{

  //   &:nth-child(2){
  //     width: 100px;
  //     height: 28px;
  //   }
  // }
  & > div {
    &:first-child {
      border-right: 1px solid #dbdbdb;
      flex-grow: 1;
      width: 185px;
    }
  }
}

.no-candidates {
  display: flex;
  height: 460px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-icon {
    // animation: aniateLeft 1s ease-in-out infinite;
    font-size: 80px;
    color: #cfcfcf;
    margin-bottom: 20px;
  }
  &-title {
    font-size: 18px;
    font-weight: 500;
    color: #a7a7a7;
    text-rendering: optimizeLegibility;
    margin-bottom: 10px;
    letter-spacing: 0.03rem;
  }
}
.MuiExpansionPanel-rounded:first-child {
  border-radius: 3px !important;
}
.jobdetail-header {
  margin-bottom: 10px;
}
.buttoncolumn {
  text-align: right;
}
.buttonwrapper {
  display: flex;
  justify-content: flex-end;
}
@keyframes aniateLeft {
  0% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(10px);
  }
}

.flex-auto {
  flex: 1 0 auto;
}
