.loan {
  &-name {
    font-size: 32px;
  }
  &-employee {
    &-info {
      background-color: white;
      display: flex;
      padding: 28px;
    }
    &-image {
      padding-right: 20px;
    }
    &-details {
      &__id {
        color: #3e3e3e;
      }
      &__name {
        color: #3273dc;
        font-size: 24px;
        font-weight: 500;
      }
      &__position,
      &__department {
        color: #646464;
      }
    }
  }
  &-datatable {
    display: grid;
    grid-template-columns: 65% 35%;
    // :not(:first-child) {
    //     &__row{
    //         border-bottom: unset !important;
    //         border-right: unset !important;
    //     }
    // }
    // :last-child {
    //     &__row{
    //     border-bottom: 1px solid #D9D9D9;
    //     }
    // }
    &__row {
      color: #282828;
      padding: 12px;
      border: 1px solid #d9d9d9;
    }
    &__borderunset {
      color: #282828;
      padding: 12px;
      border: 1px solid #d9d9d9;
      border-top: unset;
    }
    &__headerText {
      border-left: unset !important;
      text-align: right;
      &__secondary {
        color: #007bff;
      }
    }
    &__header {
      &__primary {
        background: rgba(233, 240, 251, 0.3);
      }
      &__secondary {
        background: rgba(233, 240, 251, 1);
        color: #007bff;
      }
      &__gross {
        background: rgba(233, 240, 251, 1);
      }
    }
    &__body {
      height: auto;
      max-height: 270px;
      overflow: auto;
    }
  }
}
.consumer-loan {
  padding: 28px;
  width: 900px;
  &-body {
    background-color: #ffffff;
    padding: 28px;
  }
  &__commonborder {
    padding: 30px 0px;
    border-bottom: 1px solid #d9d9d9;
  }
  &__commonlabel {
    color: rgba(0, 0, 0, 0.7);
  }
  &__amount {
    background-color: white;
    padding-top: unset !important;
  }
  &__uploads {
    .subtext {
      font-size: 12px;
      color: #646464;
    }
  }
  &__payment {
    &__button {
      align-items: flex-end;
    }
  }
  .custom-check span {
    margin-left: 40px;
    color: #3e3e3e;
    font-size: 16px;
  }
  &__declaration {
    border: unset !important;
    .checkmark {
      position: absolute;
      background-color: #ffffff;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    .button {
      margin: unset !important;
    }
    // .button-primary {
    //     margin-right: 16px !important;
    // }
    .button-green {
      margin-left: 24px !important;
    }
    &__item {
      width: 260px;
    }
  }
  .jobstatus-hsk {
    border-radius: 25px;
    font-weight: 500;
    line-height: 1;
    padding: 3px 15px 4px 15px;
    cursor: pointer;
    font-size: 14px;
    background: #e9f0fb;
    color: #3273dc;
    height: 30px;
    height: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 124px;
  }
}
