@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

$width: 1200px;

.leave-block {
  // width: $width;
  max-width: 100% !important;
  border-radius: 5px;
  margin-bottom: 30px;
}

.my-leave {
  width: 100%;
  // max-width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;

  &-title {
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
    line-height: 18px;
    color: #2c2d2c;
  }
  &-body {
    display: flex;
    flex-direction: column;
    background-color: #{$rigo-white-color};
    // padding: 10px;

    &-row {
      //   min-width: 160px;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      // align-items: flex-start;
      color: #2c2d2c;
      border-bottom: 1px solid #e2e4ed;
      display: grid;
      grid-template-columns: minmax(100px, 120px) repeat(9, 125px) !important;
      &:hover {
        background-color: #f5f7fa;
      }
      // grid-template-columns: minmax(120px, 180px) repeat(9, 1fr);

      &:not(:first-child) {
        .cell {
          // color:#2C2D2C;
          font-weight: 400;
          &:first-child {
            border-right: 1px solid #e2e4ed;
          }
        }
      }
    }
    .row-header {
      font-weight: 420;
      background-color: #f5f7fa;
      border: 1px solid #e2e4ed;
    }
    .cell {
      width: 100%;
      min-width: 150px;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      text-align: center;
    }
    .cell:first-child {
      justify-content: flex-start;
    }
    .leave-used {
      color: #007aff;
    }
    .leave-under_review {
      color: #fd7e14;
    }
    .leave-remaining {
      color: #52ae0b;
    }
    // &-column {
    //   padding-right: 30px;
    //   display: flex;
    //   flex-direction: column;
    // }
    // .cell {
    //   margin-bottom: 0.4rem;
    // }
    // .head {
    //   font-weight: 400;
    // }
    // .value {
    //   font-weight: 200;
    // }
  }
}

.my-requests {
  width: $width;
  max-width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  &-title {
    margin-bottom: 10px;
  }

  .leave-request {
    margin-bottom: 10px;
    font-size: 14px;
    background-color: #{$rigo-white-color};
    border-radius: 5px;
    &-top {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      border-bottom: 1px solid #ddd;
      &-user__detail {
        display: flex;
        flex: 1;
        flex-direction: row;
        .name_designation {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          font-weight: 500;
          &-name {
            color: $color-blue;
            font-size: 17px;
          }
          &-designation {
            margin-top: 2px;
            color: #343e48;
            font-size: 14px;
          }
        }
      }
      &-leave__detail {
        display: flex;
        flex: 2;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &-type {
          flex: 2;
          display: flex;
          align-items: center;
          color: #343e48;
          font-size: 14px;
          font-weight: 500;
          &-name {
            margin-left: 10px;
          }
        }
        &-days {
          flex: 1;
          color: #343e48;
          font-size: 16px;
          font-weight: 500;
        }
        &-date {
          flex: 3;
          color: #343e48;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
    &-mid {
      display: flex;
      flex-direction: column;
      margin-left: 80px;
      padding: 20px;

      &-reason {
        color: #444;
        margin-bottom: 5px;
      }
      &-replacement {
        display: flex;
        flex-direction: row;
        align-items: center;
        &-name {
          color: $color-blue;
        }
        &-text {
          margin-left: 20px;
          color: #666;
          font-size: small;
        }
      }
    }
    &-bottom {
      padding: 10px;
      display: flex;
      flex-direction: row;
      background-color: #f5f7fa;

      // button{
      //   color: #007AFF;
      //   border-width: 2px;
      //   border-color: #007AFF;
      //   span,svg{
      //     font-weight: 500;
      //     color: #007AFF !important;
      //   }
      // }

      &-left {
        flex: 1;
      }
      &-right {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &-status {
          display: flex;
          justify-content: center;
          align-items: center;
          &-sender {
            color: $color-blue;
            padding: 0 5px;
          }
          &-arrow {
            color: #666;
            font-size: 20px;
            padding: 0 5px;
          }
          &-receiver {
            color: #666;
            padding: 0 5px;
          }
        }
        &-cancel {
        }
      }
    }
  }
}

.holiday {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;

  .calendar-block {
    width: 390px;
    margin-right: 25px;
    background-color: #{$rigo-white-color};
    border-radius: 5px;

    .calendar {
      box-shadow: 0 3px 4px rgba(100, 100, 100, 0.3);
    }
  }

  .holidays {
    width: calc(100% - 425px);

    &-title {
      margin-bottom: 10px;
    }

    &-body {
      display: flex;
      flex-direction: column;
      &-row {
        display: flex;
        flex-direction: row;
        padding: 5px 20px;
        font-size: 14px;

        &-date {
          font-weight: 500;
          // width: 15%;
          min-width: 120px;
          display: inline-block;
          color: #444;
          font-size: 14px;
          margin-right: 10px;
        }
        &-reason {
          color: #888;
          font-size: 14px;
        }
      }
    }
  }
}
