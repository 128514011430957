.sidebar {
  flex: 0 0 65px;
  max-width: 65px;
  min-width: 65px;
  width: 65px;
  z-index: 10;
  // -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 3px 10px 30px 3px rgba(0, 0, 0, 0.19);
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 3px 10px 30px 3px rgba(0, 0, 0, 0.19);
  transition: all 0.3s;
  position: relative;
  // background:#005897;
  background: #0068d4;
  padding: 0.6rem 0rem;
  &-sidetab {
    &.open {
      display: block;
    }
  }
  &-logo {
    text-align: center;
    padding: 0rem;
    // padding: 0rem 1.25rem;
    &-image {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: #85c5f4;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      line-height: 0;
      img {
        width: 23px;
        height: 23px;
      }
    }
    // img{
    //     width: 100%;
    // }
    &__heading {
      display: none;
      font-size: 1.4rem;
      color: #{$color-primary-light-3};
      margin-top: 0.6rem;
    }
  }

  &-link {
    list-style: none;
    margin-top: 40px;
    &__icon {
      display: block !important;
      width: 100%;
      text-align: center;
      padding: 10px;
      background-color: transparent;
    }
    .active-link {
      .sidebar-link__item {
        .sidebar-link__icon {
          background-color: #0052ab;
        }
      }
    }
    .not-link {
      .sidebar-link__item {
        .sidebar-link__icon {
          background-color: transparent;
        }
      }
    }
    &__item {
      // display: block;
      // padding: .6rem 1.2rem;
      // // height: 4rem;
      // font-size: 1.05rem;
      // color: #{$color-primary-light-2};
      // text-align: center;
      // cursor: pointer;
      // position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      // padding: .6rem 1.2rem;
      padding: 7px 10px;
      // height: 4rem;
      // font-size: 1.05rem;
      font-size: 15px;
      color: #{$color-primary-light-2};
      text-align: center;
      cursor: pointer;
      position: relative;

      &:hover {
        .sidebar-link__icon {
          background-color: #0077da;
          // .active{
          //     #0052ab
          // }
        }
        &:after {
          width: max-content;
          opacity: 1;
          padding: 7px;
          margin-left: 5px;
          height: 32px;
          color: #f1f1f1;
          visibility: visible;
        }
      }
      &:after {
        content: "" attr(name) "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        padding: 0;
        width: 0;
        font-size: 14px;
        font-weight: 300;
        height: 0;
        opacity: 0;
        margin-left: 0;
        z-index: 11;
        background-color: rgba(#333, 0.9);
        color: transparent;

        border-radius: 4px;
        visibility: hidden;
        // transition: all 0.2s 0.05s;
        // display: none;
      }

      // position: relative;

      // span{
      //     display: none;
      //     // vertical-align: text-bottom;
      //     margin-top: 5px;

      // }
      i,
      svg {
        display: inline-block;
        font-size: 25px;
        // font-size: 1.4rem;
      }

      &:hover {
        color: #{$color-primary-light-3};

        // span{
        //     display: block;
        //     position: absolute;
        //     left:115%;
        //     background-color: rgba(#333,0.5);
        //     top: 10%;
        //     padding: 0.5rem 1rem;
        //     border-radius: 0.25rem;

        //     &:before{
        //         content:'';
        //         position: absolute;
        //         left: -21%;
        //         top:5%;
        //         width: 0;
        //         height: 0;
        //         border-top: 1.25rem solid transparent;
        //         border-bottom: 1.25rem solid transparent;
        //         border-right: 1.25rem solid rgba(#333,0.5);
        //     }
        // }
      }
    }
    .active .sidebar-link__item,
    .active.sidebar-link__item {
      background: #{$color-primary};
      color: #{$color-primary-light-3};
      margin: 0.25rem;
      border-radius: 8px;
    }
  }
}

.right-side {
  width: 250px;
  height: 100%;
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  background: #f4f5f8;
  z-index: 111;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);

  &-header {
    width: 100%;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #{$rigo-white-color};
    border-bottom: 1px solid #e2e6ec;
    &__icon {
      margin-right: 10px;
      img {
        width: 21px;
        height: 21px;
      }
    }
    &__name {
      font-size: 17px;
      color: #353f5a;
      text-transform: capitalize;

      h3 {
        font-weight: 500;
      }
    }
  }
  &-body {
    width: 100%;
    padding: 20px 10px;
  }
  &-links {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    &__item {
      padding: 20px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #c9d0de;
      }
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:hover {
          .right-side-links_name {
            color: #0068d4;
          }
          .right-side-links_icon svg {
            fill: #0068d4;
          }
          color: #0068d4;
        }
        .right-side-links_icon {
          margin-right: 10px;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .right-side-links_name {
          color: #1a2539;
          font-size: 14px;
        }

        &.active-link {
          .right-side-links_icon {
            img {
              filter: invert(18%) sepia(71%) saturate(2992%) hue-rotate(200deg)
                brightness(97%) contrast(102%);
            }
          }
          svg {
            fill: #0052ab;
          }
          .right-side-links_name {
            color: #0052ab;
          }
        }
      }
    }
  }
}

// .sidetab {
//   display: none;
//   position: absolute;
//   top: 0;
//   left: 100%;
//   background-color: #f8f8f8;
//   padding: 1rem 1rem;
//   height: calc(100% - 55px);
//   /* transform: translateY(-50%); */
//   width: 200px;
//   margin-top: 55px;

//   h2 {
//     font-size: 20px;
//     color: #232323;
//     font-weight: 500;
//     display: block;
//     margin: 1rem 0;
//   }

//   &-list {
//     list-style: none;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     // width: calc(100% - 4rem);

//     &__item {
//       width: 100%;
//       padding: 10px 15px;
//       cursor: pointer;
//       color: #333;

//       &:hover {
//         background-color: #e5e5e5;
//       }
//     }
//   }
// }
.sidebar-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  filter: invert(65%) sepia(100%) saturate(291%) hue-rotate(177deg) brightness(98%)
    contrast(96%);
}
