@import "node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      background-color: #fff,
      header-background-color: #f5f5f5,
      header-height: 35px,
      header-foreground-color: #0077da,
      secondary-foreground-color: #0077da,
      odd-row-background-color: #fff,
      row-group-indent-size: 10px,
      font-size: 14px,
      border-radius: 3px,
      selected-row-background-color: #ecf4ff,
    )
  );
}

.ag-root-wrapper {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.ag-header-viewport {
  background-color: "#f9f9f9";
}
.ag-header-cell {
  color: #353f5a;
  font-size: 12px;
  font-weight: 500;
}
.ag-cell {
  display: flex;
  align-items: center;
  border: unset !important;
  font-size: 13px;
  font-weight: 400;
  color: #303130;
  // &:hover{
  //   z-index:10000;
  //   overflow:visible !important;
  //   background-color:#d3e0ec;
  //   width:max-content!important;
  // }
}

.ag-center-cols-clipper,
.ag-center-cols-container {
  min-height: 300px !important;
}

.cash-header {
  .ag-header-cell-text {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.ag-react-container {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  .cash {
    display: flex;
    justify-content: flex-end;
  }

  button {
    height: 30px;
    border: unset;
    padding: 8px;
    font-size: 12px;

    svg {
      font-size: 15px !important;
    }
  }
}

.ag-row {
  // width:1600px;
}

// .ag-column-hover{
//   background:unset !important;
// }

.ag-theme-alpine .ag-root-wrapper {
  border: none;
}
.ag-theme-alpine .ag-header-cell {
  padding: 0 10px;
}
.ag-theme-alpine .ag-cell {
  padding: 0 10px;
  border-right: 1px solid #e1e6ea !important;
}
.ag-center-cols-container {
  // width: unset !important;
  height: unset !important;
}

.ag-root-wrapper-body.ag-layout-normal {
  height: 100%;
  min-height: 100%;
}

ag-root.ag-layout-normal,
.ag-root.ag-layout-auto-height {
  width: 100%;
}
