.employee-request {
  .request-details__header {
    display: grid;
    grid-template-columns: 30% 20% 10% 35% 20px;
  }

  padding: 0.5rem;
  .filters-chip {
    .leave-chipsFilter {
      .badges {
        border-radius: 25px;
        height: 1.8rem;
        margin-right: 0.75rem !important;
        cursor: pointer;
      }
    }
  }
  .employee-request-block {
    padding-top: 2rem;
    .review-type-icon {
      font-size: 1.5rem;
    }
    .time-table {
      &__header {
        color: #797681;
      }
      &__header,
      &__body-row {
        display: grid;
        grid-template-columns: 15% 10% 10% 12.5% 20% 12.5% 20%;
        padding: 0.7rem 0;
      }
      &__body-row {
        border-top: 1px solid #ebeef1;
        color: #443924;
        .requested-time {
          color: #3095ec;
        }
      }
    }
    .overtime-table {
      // width: 60%;
      &__header {
        color: #797681;
      }
      &__header,
      &__body-row {
        display: grid;
        grid-template-columns: 15% 10% 10% 12.5% 20% 12.5%;
        padding: 0.7rem 0;
      }
      &__body-row {
        border-top: 1px solid #ebeef1;
        color: #443924;
        .minutes {
          color: #3095ec;
        }
      }
    }
  }
}

.common-header-title-no-pd {
  .common-header-title {
    padding-bottom: 0;
  }
}

// .my-requeest-new{
//   width: 1300px;
//   max-width: 100%;
//   padding:10px 20px;

//   .my-request__block {
//     margin-top: 20px;

//   }
// }
