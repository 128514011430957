@import '../../../../scss/abstracts/_variables';

@mixin box-shadow {
    -webkit-box-shadow: 0px 0px 2px 0px #d6d6d6;
    -moz-box-shadow: 0px 0px 2px 0px #d6d6d6;
    box-shadow: 0px 0px 2px 0px #d6d6d6;
  }
  @mixin block {
    background: #{$rigo-white-color};
    //padding: 1rem;
    @include box-shadow();
  }
  @mixin emp-name {
    font-weight: 500;
    color: #4390df;
    padding: 0;
  }
  @mixin header {
    padding: 1rem;
    background: #f5f7fa;
    .header-title {
      font-weight: 500;
    }
  }
  .modal-allowance-details {
    .modal__content {
      background: #f5f7fa;
      width: 85%;
      .allowance-details {
        padding: 2rem;
        &__header {
          .emp-name {
            @include emp-name();
          }
        }
        .audit-history {
          margin: 1rem 0;
        }
        &__body {
          font-size: 0.9rem;
          .my-allowance-request {
            &__header {
              padding: 1rem 1rem 0 1rem;
              .request {
                &-type {
                  font-size: 1.2rem;
                  color: #2f2e2e;
                  font-weight: 500;
                }
              }
              .detailSection{
                align-items: unset;
              }
            }
            @include block();
            margin: 1rem 1rem 1rem 0;
            &__body {
              padding: 1rem;
              .emp-details {
                padding-left: 1.5rem;
                .desg-depart {
                  display: flex;
                  align-items: center;
                }
              }
              .library {
                .button {
                  border: 1px solid #0065ff !important;
                }
  
                svg {
                  color: #0065ff !important;
                }
              }
              .button-approve {
                background: #18bc15 !important;
                color: #{$rigo-white-color} !important;
              }
            }
            .checkmark-align {
              vertical-align: middle;
            }
          }
          .emp-history {
            &__header {
              padding: 1rem;
              @include header();
              .tab-title {
                cursor: pointer;
                font-weight: 500;
              }
              .active {
                color: #4390df;
              }
            }
            &__body {
              padding: 1rem;
              .date {
                padding-top: 0.1rem;
              }
              .thump-up {
                padding: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #f1f1f1;
                border-radius: 50%;
                svg {
                  color: #b9b8b8;
                }
              }
            }
            @include block();
            margin: 1rem 0;
          }
          .emp-details {
            .emp-name {
              @include emp-name();
              font-size: 0.9rem;
            }
            .desg-depart {
              padding-left: 0.5rem;
            }
          }
        }
      }
    }
  }
  