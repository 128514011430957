@import "../../../../scss/abstracts/mixins";
@import "../../../../scss/abstracts/variables";

.web-attendance {
  &-body {
    &-card {
      max-width: 600px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      color: #444;

      &-datetime {
        display: flex;
        align-items: center;
        flex-direction: row;
        background-color: #{$rigo-white-color};
        margin-bottom: 5px;
        padding: 8px;
        border-radius: 5px;
        &-time {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 25%;
          font-size: 14px;
          svg {
            margin-right: 5px;
          }
        }
        &-date {
          width: 75%;
          font-size: 14px;
        }
      }
      &-inOut {
        // padding-top: 10px;
        background-color: #{$rigo-white-color};
        margin-bottom: 5px;
        padding: 15px 10px;
        border-radius: 5px;

        button {
          margin-left: 1.5rem;
        }
        .with-icon {
          fill: #{$rigo-white-color};
        }

        .in_bg_color {
          color: $color-blue;
        }
        .in_color {
          color: $color-blue;
        }

        .out_bg_color {
          background-color: #ff643d;
        }
        .out_color {
          color: #ff643d;
        }

        &-text {
          display: flex;
          flex-direction: row;
          font-weight: 500;

          &-time {
            margin: 0 30px 0 5px;
          }
        }

        &-note {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 5px 0 5px 25px;
          color: $color-grey;

          button {
            border: unset;
            margin-left: 20px;
          }
        }

        &-fill_note {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          padding: 5px 0 5px 25px;

          &-check {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            margin-left: 5px;
            background-color: $color-blue;
            color: #{$rigo-white-color};
          }
        }
      }
    }
  }

  &-table {
    display: block;
    color: #2c2d2c;
    margin-top: 10px;
    width: 80%;
    &-top {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    &-title {
      font-weight: 500;
      margin-bottom: 10px;
    }

    &-date_filter {
      display: flex;
      align-items: flex-end;
      color: $color-green;

      button {
        background-color: unset;
        border: unset;
      }

      .button-drop__content {
        width: 140px;
      }
      .button-drop-list__item {
        display: flex;
      }
      span {
        cursor: pointer;
      }

      .selected {
        color: $color-blue;
      }

      .daterangepicker__range {
        transform: translateX(-55%);
        left: unset !important;
      }
    }

    &-container {
      // border: 1px solid #ccc;
      // border-top: 1px solid #ccc;
      // border-left: 1px solid #ccc;

      &-grid {
        display: grid;
        // grid-template-columns: 25% 18% 14% 14% 14% 15%;
        grid-template-columns: 25% 18% repeat(4, 1fr);

        span {
          align-items: center;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }

        &.data {
          span {
            font-weight: 400;
          }
        }

        :nth-child(n + 4) {
          display: flex;
          justify-content: center;
        }
        // :nth-child(n + 1) {
        //   border-right: 1px solid #ccc;
        //   border-bottom: 1px solid #ccc;
        // }
        // border-bottom: 1px solid #ccc;
      }

      .header {
        color: #222;
        background-color: #f5f7fa;
        border: 1px solid #e2e4ed;
        height: unset;
      }
      .data {
        color: #333;
        background-color: #{$rigo-white-color};
        border-bottom: 1px solid #e2e4ed;
        :first-child {
          border-right: 1px solid #e2e4ed;
        }

        &:hover {
          background-color: #f5f7fa;
        }
      }

      // &-data {
      //   display: grid;
      //   grid-template-columns: 20% 15% 15% 15% 15% 15%;
      //   padding: 10px 5px;
      //   background-color: #{$rigo-white-color};
      // }
    }
  }
}

.attendance-shortcut {
  .color-out {
    .button-text {
      // color: #ff643d !important;
    }
  }
  .button-text {
    display: flex;
    flex-direction: row;
  }

  .with-icon {
    fill: #8a8a8a;
  }

  button {
    max-width: unset !important;
  }
}
