@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.tasks {
  transition: all .2s;
  background-color: #e3e6ed;
  &-close{
    grid-template-columns: 0px 1fr !important;
  }
  &-sidebar {
    // flex: 0 0 275px;
    width: 100%;
    background: #{$rigo-white-color};
    border-right: 2px solid #dfdfdf;
    // width: 275px;
    transition: all 0.6s;
    .btn-collapse {
      top: 20px;
      width: 30px;
      height: 30px;
      z-index: 11;
      position: absolute;
      cursor: pointer;
      background-color: white;
      border-radius: 50%;
      box-shadow: 1px 1px 0px 0px #38212114;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 255px;
      transition: left 0.6s, width 0.6s;
      &:hover {
        background-color: #0875e1;
        border: none;
        width: 50px;
        border-radius: 20px;
        justify-content: end;
        left: 235px;
        svg {
          fill: #fff;
          transition: transform 0.5s;
        }
      }

      &-hide {
        color: #{$rigo-white-color};
        transform: rotate(180deg);
        svg {
          transform: rotate(360deg);
        }
        &:hover {
          left: 255px;
        }
      }
    }
    &-container {
      position: absolute;
      transition: all 0.6s;
      background: #{$rigo-white-color};

      padding: 20px 10px;
      width: 270px;
      left: 0px;
      &-hide {
        left: -275px;
      }
    }
    &-hide {
      width: 0px;
      flex: 0 0 0px;
    }

    &__title {
      @include heading(3);
      padding: 0 10px;
    }
    &__search {
      display: flex;
      align-items: center;
      height: 30px;
      margin-top: 10px;
      padding: 0 10px;
      svg {
        color: #808080;
        margin-right: 10px;
        font-size: 20px;
      }

      input {
        flex-grow: 1;
        border: none;
        font-size: 14px;
        height: 100%;
        color: #333;

        @include placeholder {
          color: #cfcfcf;
          font-size: 14px;
          font-weight: 400;
        }

        &:focus,
        &:active {
          outline: none;
          border: none;
        }
      }
    }
  }
  &-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    border-top: 1px solid #ebebeb;
    padding: 10px 0 0;
    &__primary {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      padding: 10px;

      &-title {
        flex-grow: 1;
        text-align: left;
        font-size: $default-fontSize-head;
        font-weight: 500;
        color: #445455;
      }

      &-icon {
        width: 22px;
        height: 22px;
        background-color: #{$rigo-primary-color};
        border-radius: 100%;
        margin-right: 10px;
        color: #{$rigo-white-color};
        @include flexCenter;
        font-size: 18px;
        cursor: pointer;

        img {
          max-width: 18px;
          max-height: 18px;
          width: 18px;
          height: 18px;
          filter: invert(1);
          padding: 3px;
        }
      }
    }
    &__secondary {
      padding: 0 0 15px;
      display: flex;
      flex-direction: column;
      width: 100%;
      &-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        // align-items: flex-end;
        line-height: 1.5;
        padding: 7px 10px;
        cursor: pointer;
        color: #565656;
        &.active {
          background-color: #{$rigo-primary-color};
          color: #{$rigo-white-color};
          border-radius: 3px;
        }
      }
      &-icon {
        line-height: 1;
        margin-right: 10px;
        margin-top: 2.5px;
      }
      &-title {
        line-height: 1.5;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.board-add {
  width: 100%;
  display: flex;
  flex-direction: column;

  &-modal {
    .modal__content {
      background: #{$rigo-white-color};
    }
  }
  &__row {
    margin-bottom: 10px;
    &-flex {
      display: flex;
      &-date {
        width: 200px;
        margin-right: 20px;
      }
      &-day {
        margin-top: 30px;
        font-size: 14px;
        color: #000000a7;
      }
    }
  }

  .flex-row {
    display: flex;
    margin-bottom: 10px;
    div:last-child {
      margin-right: 0px;
    }
  }
  &__form {
    flex-grow: 1;
    padding: 20px;
  }
  &__footer {
    padding: 10px 20px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    background-color: #f5f5f5;
  }
}
