.horizontalGenericForm {
  display: inline-flex;
  align-items: center;

  .genericForm {
    &-group {
      &__label {
        min-width: 30%;
        margin-bottom: unset;
        margin-right: 0.5rem;
      }
      &__message {
        margin-left: 1rem;
      }
    }
  }
}
.isSingle {
  .css-yk16xz-control {
    min-height: unset !important;
    height: 2.2rem;
    background-color: $rigo-input-bg-color !important;
    color: #444;
  }
  .css-1wa3eu0-placeholder {
    line-height: 1;
  }
  .css-tlfecz-indicatorContainer {
    padding: 7px;
  }
}
.css-1pahdxg-control {
  box-shadow: 0 0 0 0.2px #{$btn-primary-color},
    0 0 0 2.5px rgba($btn-primary-color, 0.4) !important;
}
.isMulti {
  .css-1hb7zxy-IndicatorsContainer {
    align-items: flex-start !important;
    padding-right: 2px;

    div {
      padding: 7px 4px;
    }
  }
}

.genericForm {
  &-group {
    width: 100%;
    margin-right: 1rem;

    // //For removing autoselect inside icon padding
    //.css-tlfecz-indicatorContainer {
    //   padding: unset;
    // }
    &__stepper {
      width:116px;
      height: 36px;
      // box-shadow: 0px 1px 6px #00000029;
      // border:1px solid #E1E6EA;
      // border-radius: 4px;
      border:none;

      &-alt{
        height: 38px;
        width: 90px;
      }
    }
    &__upload {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      // margin: 10px 0;
      position: relative;

      padding: 15px;
      background: #edf0f4;
      border: 2px solid #fff;
      border: none;

      .error__message {
        margin-left: unset !important;
      }
      &-text-section {
        display: flex;
        flex-direction: column;

        .text1 {
          font-size: 14px;
          color: #4c4e53;
          font-weight: 500;
        }
        .text2 {
          font-size: 12px;
          color: #999;
          font-weight: 400;
          line-height: 16px;
        }
      }

      &-btn {
        padding: 8px 20px;
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        color: #595959;
        margin: 10px 0;
      }
      &-label {
        color: #000000d9;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        padding: 0 2px;
      }
      &-input {
        cursor: pointer;
        position: absolute;
        text-indent: -125px;
        color: transparent;
        z-index: 3;
        /* padding-top: 40px; */

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        font-family: "Open Sans", sans-serif;
        &:hover {
          box-shadow: 0 0.1rem 0.25rem rgba(#222, 0.2);
        }

        &:focus,
        &:active {
          outline: transparent;
        }
      }
    }
    &.error {
      .genericForm {
        &-group {
          &__input {
            border-color: #{$color-red};
          }
          &__hour_minutes {
            border-color: #{$color-red};
          }
          &__textarea {
            border-color: #{$color-red};
          }
          &__date {
            border-color: #{$color-red};
          }
          &-custom-time {
            border-color: #{$color-red};
          }
          &__time {
            border-color: #{$color-red};
          }
          &__label {
            color: #{$color-red};
          }

          &__message {
            color: #{$color-red};
            margin-top: 0.2rem;
            font-weight: 400 !important;
            display: block;
            font-size: 12px;
          }
        }
      }
      .bjPjlv {
        border-color: #{$color-red} !important;
      }
    }
    &__inner-label {
      padding: 0 2px 0 12px;
      height: 100%;
      font-size: 14px;
      color: #2c2d2c;
      display: grid;
      place-items: center;
    }
    &__select {
      border: none;
      outline: none;
      flex-grow: 1;
      height: 100%;
      padding: 0 12px 0 2px;
      font-size: 14px;
      margin-top: 2px;
      appearance: none;
      option {
        font-size: 14px;
        padding: 5px;
      }
      &-container {
        width: 100%;
        position: relative;
        // padding: 10px 12px;
        padding: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: thin solid $color-grey-light;
        border-radius: $default-border-radius;
        transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
        outline: none;
        background: #{$rigo-white-color};
        font-size: 14px;
        color: #2c2d2c;
        height: $input-height;
        background-color: $rigo-input-bg-color;
        &:focus-within {
          @include inputFocusStyle;
        }
      }
    }
    &__label {
      color: rgba(0, 0, 0, 0.7);
      padding: 0;
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      letter-spacing: 0.00938em;
      margin-bottom: 0.35rem;
    }

    &__input {
      width: 100%;
      outline: none;
      padding: 8px 12px;
      background: #{$rigo-white-color};
      font-size: 14px;
      color: #444;
      height: $input-height;
      border-radius: $default-border-radius;
      // border-width: 1px;
      // border-style: solid;
      // border-color: $rigo-input-border-color;
      border: thin solid $color-grey-light;
      background-color: $rigo-input-bg-color;
      transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
      &:focus {
        @include inputFocusStyle;
      }
      &::-webkit-input-placeholder {
        @include placeholderText();
      }

      .colon {
        margin: 0 4px;
        font-weight: 600;
      }
    }
    &__close {
      position: absolute;
      top: 8px;
      right: 12px;
      cursor: pointer;
    }
    &__hour_minutes {
      outline: none;
      padding: 8px 4px;
      background: #{$rigo-white-color};
      font-size: 14px;
      color: #444;
      border-radius: $default-border-radius;
      border: thin solid $color-grey-light;
      background-color: $rigo-input-bg-color;
      transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
      &:focus {
        @include inputFocusStyle;
      }
      &::-webkit-input-placeholder {
        @include placeholderText();
      }

      .colon {
        font-weight: 600;
      }

      &-inputs {
        input {
          background-color: unset;
          padding: 9.5px 0;
          max-width: 40px;
          border: unset;
          outline: unset;
          text-align: center;
          overflow: visible;
        }
      }
    }

    &__textarea {
      width: 100%;
      min-height: 100px;
      padding: 10px 12px;
      border-radius: 4px;
      outline: none;
      border: 1px solid #cacaca;
      background: $rigo-input-bg-color;
      font-size: 1rem;
      color: #444;
      font-family: $rigo-font-stack;
      //   &:focus {
      //     outline: none;
      //     // box-shadow: 0 .5rem 1rem rgba(#000, .1);
      //     border: 1px solid #40c5ff;
      //   }
      &:focus {
        @include inputFocusStyle;
      }
      &::-webkit-input-placeholder {
        @include placeholderText();
      }
      &:disabled {
        background-color: $disabled-background-color !important;
      }
    }

    &__date {
      max-width: 500px;
      // min-width: 200px;
      @include flexRow;
      align-items: center;
      height: $input-height;
      border-radius: $default-border-radius;
      border-width: 1px;
      border-style: solid;
      border-color: $rigo-input-border-color;
      background-color: $rigo-input-bg-color;
      position: relative;
      transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;

      &:focus-within {
        @include inputFocusStyle;

        .form-group__date-icon {
          color: lighten($btn-primary-color, 15);
        }
      }
      &-customInput {
        border: none;
        font-size: $default-fontSize;
        background-color: transparent;
        flex: 1;
        height: 32px;
        &:focus {
          outline: none;
        }
      }
      &-input {
        border: none;
        font-size: $default-fontSize;
        background-color: transparent;
        flex: 1;
        height: 34px;
        width: 100%;
        padding: 8px;
        &:focus {
          @include inputFocusStyle;
        }
        &:disabled {
          background-color: $disabled-background-color !important;
        }
      }
      &-icon {
        position: absolute;
        right: 0;
        cursor: pointer;
        width: 25px;
        font-size: 20px;
        color: $rigo-icon-color;
        transition: color ease 0.2;
      }
    }

    &__datepicker {
      z-index: 11111;
      position: absolute;
      top: 45px;
      width: 390px;
      // transform: translateX(-50%);
      left: 0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
      // &:before{
      //   content:'';
      //   @include overlay(rgba(0,0,0,0.3));
      // }
    }

    &__custom-time {
      color: #222;
      @include placeholder {
        @include placeholderText();
      }
      @include hideInputNumberArrows;
      &-box {
        width: 25px;
        align-items: center;
        padding: 6px 4px;
        margin-right: 2px;
        // height: $input-height;
        border-radius: $default-border-radius;
        border-width: 1px;
        border-style: solid;
        border-color: $rigo-input-border-color;
        background-color: $rigo-input-bg-color;

        &:focus {
          @include inputFocusStyle;
        }
        &:disabled {
          background-color: $disabled-background-color !important;
        }
      }
      &-colon {
        font-size: larger;
        // margin: 0 8px;
        margin-right: 2px;
      }
      &-meridiem {
        align-items: center;
        // padding: 7px 0;
        margin-left: 5px;
        // height: $input-height;
        border-radius: $default-border-radius;
        background-color: $rigo-input-bg-color;
        transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;

        &-name {
          padding: 6px 4px;
        }

        .active {
          background-color: $color-blue;
          color: #{$rigo-white-color};
        }

        button {
          border: unset;
          // height: $input-height;
          outline: none;
          cursor: pointer;
        }
      }
    }
    &__time {
      max-width: 300px;
      min-width: 175px;
      // width: 175px;
      @include flexRow;
      align-items: center;
      padding: 8px;
      height: $input-height;
      border-radius: $default-border-radius;
      border-width: 1px;
      border-style: solid;
      border-color: $rigo-input-border-color;
      background-color: $rigo-input-bg-color;
      transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;

      .react-time-picker {
        &__wrapper {
          border: unset;
          color: red;
        }

        &__clock {
          border: thin solid $rigo-input-border-color;
          top: 130% !important;
          left: -10px !important;
        }

        &__inputGroup {
          &__input {
            outline-color: grey;
            color: #444;
          }
        }

        &__button {
          outline: unset;
        }

        svg {
          stroke: grey;
        }
      }

      &:focus-within {
        @include inputFocusStyle;

        .form-group__time-icon {
          color: lighten($btn-primary-color, 15);
        }
      }
    }

    // &__select {
    //   width: 100%;
    //   // height: 2rem;
    //   // padding: .35rem;
    //   padding: 0.6rem 0.75rem;
    //   border-radius: 3px;
    //   background: "#f1f1f1";
    //   outline: none;
    //   border: 1px solid #d9d9d9;
    //   margin-top: 0.35rem;

    //   &:focus {
    //     @include inputFocusStyle;
    //   }
    // }
    &__search {
      display: flex;
      align-items: center;
      border: 1px solid $rigo-input-border-color;
      background: $rigo-input-bg-color;
      border-radius: $default-border-radius;
      height: $input-height;
      width: 100%;
      max-width: 400px;
      padding: 0 10px;
      &:focus-within {
        @include inputFocusStyle;
        .genericForm-group__search-icon {
          svg {
            color: #{$btn-primary-color};
          }
        }
      }
      &-input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: #444;
        font-size: 13px;
        @include placeholder {
          @include placeholderText();
        }
      }
      &-icon {
        width: $input-height;
        height: $input-height;
        display: flex;
        margin: 0 5px;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 18px;
          color: #7f7f7f;
        }
      }
    }
  }
}

.ydm-input {
  &-group {
    // display: grid;
    // grid-template-columns: repeat(3,1fr);
    // max-width: 450px;
    // grid-gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
    max-width: 362px;
    width: 362px;
    border: 1px solid #cfcfcf;
    border-radius: 3px;
    &-1 {
      grid-template-columns: repeat(1, 1fr);
      max-width: unset;
      width: 362px;
    }
    &-2 {
      grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
      max-width: unset;
      width: 242px;
    }

    &:focus-within {
      @include inputFocusStyle;

      .ydm-input-col {
        border-width: 2px;
        border-color: rgba($btn-primary-color, 0.5);
      }
    }
  }
  &-col {
    width: 100%;

    &__label {
      color: rgba(0, 0, 0, 0.7);
      padding: 0;
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      letter-spacing: 0.00938em;
      margin-bottom: 0.35rem;
    }

    &:not(:last-child) {
      border-right: 1px solid #cfcfcf;
    }

    .css-yk16xz-control,
    .css-1pahdxg-control {
      border-radius: 0 !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      height: 2.2rem !important;
      min-height: 2.2rem !important;
    }
  }
}

.custom-date {
  input {
    &:focus {
      box-shadow: none;
    }
  }
  &.custom-date__active {
    .custom-date__overlay {
      display: block;
    }
  }
  &__overlay {
    display: none;
    @include overlay(rgba(0, 0, 0, 0.2));
    position: fixed;
    z-index: 1;
  }
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  height: 100%;
  &:after,
  &:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(#2c2d2c, 0.5);
    top: 35%;
  }
  &:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(#2c2d2c, 0.5);
    top: 65%;
  }
}

//Switch
.genericForm-group {
  &__left,
  &__right {
    font-size: 14px;
    font-weight: 400;
    color: #2c2d2c;
    cursor: pointer;
    margin: 0 5px;
    span {
      line-height: 0;
      display: grid;
      place-items: center;
    }

    svg {
      font-size: 16px;
    }
  }
  &__switch {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-label {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 24px;
      cursor: pointer;
      outline: none;
      user-select: none;
      box-sizing: border-box;
      margin: 0 6px;
      border-radius: 10px;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .genericForm-group__switch-circle:before {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
        &:focus + .genericForm-group__switch-circle {
          box-shadow: 0 0 1px #{$rigo-primary-color};
        }
        &:checked + .genericForm-group__switch-circle {
          background-color: #{$rigo-primary-color};
        }
      }
    }

    &-circle {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      height: 100%;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      border-radius: 34px;
      &::before {
        position: absolute;
        content: "";
        width: 19px;
        height: 19px;
        transition: 0.3s;
        // width: 20px;
        // height: 20px;
        margin: 1px;
        background-color: #{$rigo-white-color};
        border-radius: 100%;
        // box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
        top: 1px;
        // top: -2px;
        left: 1px;
        // transition: margin 0.4s;
      }
    }
  }
}
.dark-mode-form {
  & > div {
    width: 100%;
  }
}

input {
  &:disabled {
    background-color: $disabled-background-color !important;
  }
}

.uploaded-files {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 0 0px;
  margin-top: unset;
  &.up-col {
    flex-direction: row;
  }
}

.uploaded-file {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: unset;
  height: unset;
  background: transparent;
  padding: 7px 0;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    // min-width: 200px;
    // min-height: 175px;
    background-color: #f2f2f2;
    width: 200px;
    height: 170px;
    border-radius: 8px;
    margin-left: 5px;
  }

  &-pdf {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &-name {
    flex-grow: 1;
    margin-right: 10px;
    font-size: 14px;
    color: #0066ff;
    text-decoration: underline;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s;
    border-radius: 8px;
  }
  .name {
    color: #fff;
    font-size: 11px;
    flex-grow: 1;
    width: 88%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    background: #222;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #{$color-red};
    }
  }
  .actions {
    position: absolute;
    // visibility: hidden;
    width: 100%;
    // height: 100%;
    // height: 35px;
    top: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-image: linear-gradient(180deg, #0066ff, transparent);
    display: flex;
    // align-items: center;s
    // justify-content: space-between;
    padding: 5px;

    .modal__content {
      width: 80% !important;
    }
  }
  // &:hover {
  //   .actions {
  //     visibility: visible;
  //     background-color: #00000066;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     .icon {
  //       padding: 2px;
  //       margin: 0 5px;
  //       background-color: #ddd;
  //       border-radius: 5px;
  //       cursor: pointer;
  //       color: $color-blue;
  //     }
  //   }
  // }
}


.stepper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  // border: 1px solid #cbd4ef;
  border:1px solid #E1E6EA;
  border-radius: 4px;
  background: #fff;

  &-alt{
    .stepper-input{
      border-left: none;
    }
    &__btns{
      display: flex;
      flex-direction: column;
      height: 38px;
      padding: 0px 2px;
      button{
        height: 18px;
        flex: unset;
        &:first-child{
          border-bottom: 1px solid #E1E8F0;
        }
      }
    }
  }

  &-btn {
    border: none;
    background: transparent;
    outline: none;
    width: 36px;
    max-width: 36px;
    min-width: 36px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    flex: 1;
    cursor: pointer;
    color: #B3BFCB;
    font-size: 18px;
    &:hover {
      background-color: #f1f1f1;
      border-radius: 4px;
    }
  }
  &-input{
    flex-grow: 1;
    height: 100%;
    width: 35px;
    border-left: 1px solid #E1E6EA;
    border-right: 1px solid #E1E6EA;
    flex: 1;
    input{
        border: none;
        outline: none;
        font-size: 16px;
        background: transparent;
        flex-grow: 1;
        height: 100%;
        width: 100%;
        flex: 1;
        color: #4F6066;
        padding: 6px;
        text-align: center;
    }
}
}


//Switch
// .genericForm-group {
//   &__left,
//   &__right {
//     font-size: 14px;
//     font-weight: 400;
//     color: #2c2d2c;
//     cursor: pointer;
//     margin: 0 5px;
//     span {
//       line-height: 0;
//       display: grid;
//       place-items: center;
//     }

//     svg {
//       font-size: 16px;
//     }
//   }
//   &__switch {
//     height: 24px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     &-label {
//       position: relative;
//       display: inline-block;
//       width: 36px;
//       // width: 38px;
//       height: 17px;
//       // height: 18px;
//       cursor: pointer;
//       outline: none;
//       user-select: none;
//       box-sizing: border-box;

//       border-radius: 10px;

//       input {
//         opacity: 0;
//         width: 0;
//         height: 0;

//         &:checked + .genericForm-group__switch-circle:before {
//           -webkit-transform: translateX(22px);
//           -ms-transform: translateX(22px);
//           transform: translateX(22px);
//         }
//         &:focus + .genericForm-group__switch-circle {
//           box-shadow: 0 0 1px #{$rigo-primary-color};
//         }
//         &:checked + .genericForm-group__switch-circle {
//           background-color: #{$rigo-primary-color};
//         }
//       }
//     }

//     &-circle {
//       position: absolute;
//       cursor: pointer;
//       top: 0;
//       left: 0;
//       height: 100%;
//       right: 0;
//       bottom: 0;
//       background-color: #ccc;
//       -webkit-transition: 0.3s;
//       transition: 0.3s;
//       border-radius: 34px;
//       &::before {
//         position: absolute;
//         content: "";
//         width: 17px;
//         height: 17px;
//         transition: 0.3s;
//         // width: 20px;
//         // height: 20px;
//         margin: 1px;
//         background-color: #{$rigo-white-color};
//         border-radius: 100%;
//         box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
//         top: -1px;
//         // top: -2px;
//         left: -2px;
//         // transition: margin 0.4s;
//       }
//     }
//   }
// }