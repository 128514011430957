@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/variables';

.clusters{
    &-container{
        width: 100%;
        // padding: 20px 0;
    }
    &-block{
        width: 800px;
    }
    &-modal{
        .permission{
            min-height: 150px;
        }
    }
    &-list{
        width: 100%;
        background-color: #fff;
        border: 1px solid #E1E1E1;
        display: flex;
        flex-direction: column;
        &__item{
            width: 100%;
            background-color: #fff;
            &:not(:last-child){
                border-bottom: 1px solid #E1E1E1;
            }

            .cluster{
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 10px 15px;
                align-items: center;
                height: 52px;

                &-icon{
                    font-size: 20px;
                    color: #B7C4D5;
                    margin-right: 15px;
                    cursor: pointer;

                    &:hover{
                        color: darken(#B7C4D5,12);
                    }
                }
                &-region{
                    flex-grow: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                &-letter{
                    width: 22px;
                    height: 22px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #405471;
                    color: #fff;
                    font-weight: 500;
                    border-radius: 100%;
                    margin-right: 10px;

                    &.orange{
                        background-color: #FE674A;
                    }
                    &.pink{
                        background-color: #F50DE3;
                    }
                }
                &-name{
                    color: #333333;
                    font-size: 14px;
                    font-weight: 500;
                }
                &-counts{
                    margin: 0 20px;
                    font-size: 14px;
                    font-weight: 500;
                    color:#526D93;
                    min-width: 80px;

                    .cluster-num{
                        margin-right:8px;
                    }
                }
                &-type{
                    font-size: 14px;
                    font-weight: 500;
                    color: #405471;
                    margin:0 10px;
                    width: 60px;
                    text-align: right;
                    min-width: 60px;
                }
                &-btn{
                    margin: 0 10px;
                    min-width: 100px;

                    button{
                        height: 32px;
                        padding: 7px 8px;
                        width: 80px;
                    }
                }
            }
        }
    }
    
}

.cluster{
    &-sub{
        width:100%;
       
        &__list{
            width: 100%;
            .cluster-icon{
                margin-right: 5px;
            }
        }
        &__item{
            border-top: 1px solid #E1E1E1;
            .cluster{
                width: 750px;
                margin-left: auto;
            }
            .cluster-sub__item{
                .cluster{
                    width: 600px;
                    margin-left: auto;
                    margin-right: 80px;
                    &-icon{
                        color: #717171;
                    }
                }
            }
            &:not(:last-child){
                border-top: 1px solid #E1E1E1;
            }
        }
    }
}

.cluster{
    &-enter{
        max-height: 0;
        opacity:0;
        transform: scaleY(0);
        transition: all 300ms;
        &-done{
            max-height: 400px;
            opacity: 1; 
            .cluster-sub{
                max-height: 350px;
                overflow-y:auto;
            }
        }
        &-active {
            max-height: 400px;
            opacity: 1;
            transform: scaleY(1);
            transition: all 300ms;
        }
    }
    &-exit{
        max-height: 400px;
        opacity: 1;
        transform: scaleY(1);
        &-done{
            max-height: 0px;
            opacity: 0;
        }
        &-active {
            max-height: 0;
            opacity: 0;
            transform: scaleY(0);
            transition: all 300ms;
        }
    }
}
.clusterChild{
    &-enter{
        max-height: 0;
        opacity:0;
        transform: scaleY(0);
        transition: all 300ms;
        &-done{
            max-height: 52px;
            opacity: 1;
        }
        &-active {
            max-height: 52px;
            opacity: 1;
            transform: scaleY(1);
            transition: all 300ms;
        }
    }
    &-exit{
        max-height: 52px;
        opacity: 1;
        transform: scaleY(1);
        &-done{
            max-height: 0px;
            opacity: 0;
        }
        &-active {
            max-height: 0;
            opacity: 0;
            transform: scaleY(0);
            transition: all 300ms;
        }
    }
}


.clusters-move{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;

    &__branch{
        padding: 15px 0;
        h3{
            @include heading(3,#333)
        }
    }
    &__cluster{
        display: flex;
        flex-direction: row;
        align-items: center;

        .clusters-name{
            width: 220px;
            margin-right: 10px;
            h4{
                @include heading(4)
            }
            
        }
    }
    &__icon{
        color: #{$rigo-primary-grey};
        // width: 25px;
        // height: 25px;
        font-size: 25px;
    }
    &__select{
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .MuiInputBase-input {
            margin-top: 0;
            background-color: #F5F7FB;
            // border: none;
            border-radius: 3px;
        }
    }
}