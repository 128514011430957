@import "../../../../scss/abstracts/variables";
@import "../../../../scss/abstracts/mixins";

.disciplinary-case {
  // position: absolute;
  // left: 0;
  // top: 0;
  background: #{$rigo-white-color};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dsp-form {
  &__header {
    padding: 15px 25px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 500;
      font-size: 21px;
      color: #282828;
    }
    .close-icon {
      font-size: 2rem;
      color: rgba(112, 107, 107, 0.7);
      cursor: pointer;
      &:hover {
        color: #{$color-red};
      }
    }
  }
  &__body {
    padding: 15px 25px;
    .form-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      .form-group {
        .pickers {
          margin: 0;
          width: 300px;
          .MuiFormControl-root {
            width: 100%;
          }
        }
        .upload {
          width: 170px;
          text-align: center;
          .document-no {
            color: #9e9e9e;
            font-size: 0.85rem;
            margin-bottom: 0.5rem;
            display: block;
          }
          .upload-btn {
            display: flex;
            &-wrapper {
              input[type="file"] {
                font-size: 1rem;
              }
            }
          }
          .document-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        textarea {
          width: 100%;
        }
        .form-group__label {
          display: block;
          margin-bottom: 0.5rem;
        }
        .error__message,
        .form-group__message {
          margin: 0.5rem 0 0 0;
        }
      }
      .select {
        width: 50%;
        label {
          margin-bottom: 0.5rem;
          display: block;
        }
      }
      .error {
        .pickers {
          .MuiInputBase-input {
            border: 0 !important;
          }
        }
        &.autoselect {
          .MuiInputBase-root {
            border: 0 !important;
          }
        }
        .MuiInputBase-input,
        textarea {
          border: 1px solid #d64545;
        }
      }
    }
  }
  &__footer {
    padding: 1rem;
    border-top: 1px solid #e4e4e4;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }
}
