.admin {
  width: 100%;
  &-container {
    width: 100%;
    padding: 15px 25px;
  }
  &-title {
    &,
    h1 {
      font-size: 18px;
      color: #{$color-title};
      font-weight: 500;
      margin: 10px 0 20px;
    }
  }
}

.count {
  &-current {
    display: block;
    width: 250px;
    padding: 16px 24px;
    background-color: #{$rigo-white-color};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    margin: 0px 10px 0px 0;
    // margin: 10px 10px 10px 0;

    p {
      font-size: 13px;
      color: #040404;
      margin-bottom: 3px;
      font-weight: 400;
    }
    h3 {
      font-size: 15px;
      color: #{$color-blue-2};
      margin-bottom: 5px;
      font-weight: 500;
    }
    button {
      margin: 0;
    }
  }
  &-block {
    display: block;
    width: 100%;
    padding: 20px;
    background-color: #{$rigo-white-color};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    // margin: 10px 0;
    &__list {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 65px;
    }
    &__item {
      margin-right: 15px;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        transition: all 0.2s;
        @include clearfix;
        &:hover {
          // background: #efefef;
          // border-radius: 4px;
          // box-shadow: 0 1px 2px 4px rgba(97,97,97,.24);
          // background: #d6ecff;
          // border-radius: 4px;
          // box-shadow: 0 1px 2px 4px #cee8ff52;
          transform: scale(1.08);
          .count-number {
            color: #0068d4;
            // font-size: 21px;
          }
          .count-name {
            color: #0068d4;
            // font-size: 15px;
          }
        }
      }
    }
  }
  &-number {
    color: #{$color-blue-2};
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 7px;
    transition: all 0.24s linear;
  }
  &-name {
    color: #{$color-title};
    font-weight: 400;
    transition: all 0.24s linear;
    font-size: $default-fontSize;
  }
}

.fav {
  &-button {
    &-block {
      padding: 24px 0;
      display: block;
      width: 100%;
    }

    &-group {
      width: calc(100% - 30px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px 5px;
    }
  }
  &-icon {
    svg {
      margin-right: 0 !important;
    }
  }
}
.employee-reports {
  .admin-container {
    padding: 0 25px 15px;
  }
}
.btn-fav {
  &,
  &:active,
  &:link {
    text-transform: capitalize;
    font-size: 12px;
    color: #666;
    border-radius: 4px;
    background-color: #{$rigo-white-color};
    min-width: 140px;
    max-width: 180px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s;
    margin: 5px 10px 5px 0px;
    border: 1px solid #efefef;
  }
  &:hover {
    background-color: #f5f5f5;
  }
  &__icon {
    font-size: 18px;
    margin-right: 10px;
  }

  &-add {
    min-width: 40px;
    width: 40px;

    .btn-fav__icon {
      margin-right: 0px;
    }
  }
}

.drag-block {
  width: 100%;

  &__container {
    width: 100%;
  }
  &__items {
    display: grid;
    grid-template-columns: calc(35% - 24px) calc(35% - 24px) calc(30% - 24px);
    grid-gap: 24px;

    &-list {
      display: flex;
      flex-direction: column;
    }
  }
  &__item {
    margin-bottom: 24px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 5px 5px -3px rgba(0, 0, 0, 0.04);
    }
  }
}

.drag-box {
  width: 100%;
  background-color: #{$rigo-white-color};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  &__header {
    padding: 10px 15px;
    border-bottom: 1px solid #e4e6ee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f7fa;
  }
  &__title {
    font-size: 14px;
    color: #0a1530;
    h4 {
      font-weight: 500;
    }
  }
  &__footer {
    background-color: rgb(250, 251, 252);
    padding: 15px;
    color: #{$color-blue};
    font-size: 12px;
    border-top: 1px solid #e4e6ee;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    a {
      color: #{$color-blue};
      padding: 6px 8px;
      border-radius: 3px;
      transition: 0.3s ease;
      &:hover {
        background: rgba($color-blue, 8%);
      }
    }
  }
  &__icons {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    svg,
    i {
      color: rgb(183, 191, 204);
    }

    img {
      filter: invert(86%) sepia(17%) saturate(175%) hue-rotate(178deg) brightness(88%) contrast(86%);
      transition: 0.3s ease;
    }
  }
  &__icon {
    margin-left: 8px;
    cursor: pointer;

    svg {
      transition: 0.3s ease;
    }
    &:hover {
      svg {
        color: #9da5b3;
      }
    }
    &.drag {
      &:hover {
        img {
          filter: invert(66%) sepia(17%) saturate(175%) hue-rotate(178deg) brightness(88%) contrast(86%);
        }
      }
    }
  }
  &__body {
    flex-grow: 1;
    // padding: 10px;
  }
}

.no-found {
  width: 100%;
  text-align: center;
  padding: 20px;
  display: block;
  font-size: 17px;
  color: #d6d6d6;
}

.employees {
  &-tabs {
    width: 100%;
    display: block;
    display: flex;
    flex-direction: row;
    background-color: rgba(251, 252, 253, 1);
    padding: 10px 10px;
    margin-bottom: 10px;
  }
  &-tab {
    padding: 5px;
    color: #3d3e3e;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #666;
    }
    &.active-tab {
      color: #{$color-primary};
      font-weight: 500;
    }
  }
  &-list {
    width: 100%;

    display: flex;
    flex-direction: column;

    .employee {
      display: flex;
      flex-direction: row;
      padding: 15px 18px;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.065);
      }
      &:hover {
        background-color: #f5f5f5;
      }
      &-avatar {
        margin-right: 10px;
        width: 40px;
        height: 40px;

        &.avatar-50 {
          width: 50px;
          height: 50px;
        }
      }
      &-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 3px;
        span {
          margin-right: 7px;
        }
      }
      &-value {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        color: rgb(44, 45, 44);
        > .branch {
          font-size: 16px;
          margin-bottom: 5px;
        }
        a {
          color: #{$rigo-primary-color};
        }

        .position {
          margin-right: 15px;
        }
      }
      &-name {
        font-size: 14px;
        color: #{$color-blue};
        font-weight: 400;
        margin-bottom: 3px;
      }
    }
  }
}

.pending {
  padding: 15px;
  display: block;

  &-list {
    display: flex;
    flex-direction: column;

    a {
      &:hover {
        .pending-name {
          color: #{$color-blue};
        }
      }
    }

    &__item {
      display: flex;
      flex-direction: row;
      padding: 5px 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  &-name {
    width: 150px;
    font-size: 13px;
    color: #3d3e3c;
  }
  &-value {
    font-size: 13px;
    color: #{$color-blue};
    margin-left: auto;
    padding-left: 15px;
  }
}

// admin Common Task
.admin {
  &-common {
    background-color: rgb(233, 239, 248);
    height: 100%;
  }
  &-contain {
    height: 100%;
    width: 100%;
  }
  &-modules {
    width: 100%;
    max-width: 100%;
    min-height: 80vh;
    // background-color: #{$rigo-white-color};
    // box-shadow: 0 2px 4px rgba(0,0,0,0.12);

    &__container {
      width: 100%;
    }
    &__block {
      width: 100%;
    }
    &__search {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .genericForm-group {
        width: 350px;
      }
    }
    &__contain {
      margin-top: 20px;
      width: 100%;
    }
    &__list {
      width: 100%;
      display: grid;
      display: grid;
      grid-template-columns: repeat(2, 500px);
      // grid-template-columns: repeat(auto-fill, 500px);
      grid-gap: 30px;

      &-fav {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    &__item {
      width: 100%;
    }
  }
  &-module {
    width: 100%;

    &__main {
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;

      &-fav {
        .admin-module__title {
          color: #{$rigo-primary-color};
        }
      }
    }
    &__title {
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }

    &__links {
      width: 100%;
      // border: 1px solid #eff1f1;
      background-color: #{$rigo-white-color};
      border-radius: 3px;
      max-width: 500px;
      box-shadow: 0 3px 5px rgba(70, 70, 70, 0.15);

      &-list {
        width: 100%;
        margin: 5px 0;
      }
      &-item {
        &:not(:last-child) {
          border-bottom: 1px solid #eff1f1;
        }
        cursor: pointer;
        height: 40px;
        padding: 0 10px;
        &:hover {
          background-color: rgb(242, 243, 244);
        }
        // a{
        //     width: 100%;
        //     height: 100%;
        //     display: block;
        //     &:hover{
        //         background-color: #fafafa;
        //     }
        // }
      }
    }
    &__link {
      // width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 40px;
      align-items: center;
      &-main {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10px 0;
        flex-grow: 1;
        color: #{$color-title};
      }
      &-click {
        flex: 1;
      }
      &-icon {
        font-size: 20px;
        margin-right: 10px;
      }
      &-title {
        font-size: 15px;
        color: #333;
        margin-left: 10px;
      }
      &-fav {
        padding: 0 20px;
      }
      &-favicon {
        font-size: 25px;
        cursor: pointer;
        color: #{$rigo-icon-hover};
        line-height: 1;
        width: 25px;
        height: 25px;
        margin-right: 10px;

        &:hover {
          color: #{$rigo-primary-color};
        }

        &.active-icon {
          color: #{$rigo-primary-color};
        }
      }
    }
  }
}
.company-header-title {
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  color: #0052ab;
}
.module-exp {
  &-enter {
    max-height: 0;
    opacity: 0;
    // transform: scaleY(0);
    transition: all 300ms;
    &-done {
      max-height: 5000px;
      opacity: 1;
      // transform: scaleY(1);
    }
    &-active {
      max-height: 5000px;
      opacity: 1;
      // transform: scaleY(1);
      transition: all 300ms;
    }
  }
  &-exit {
    max-height: 5000px;
    opacity: 1;
    // transform: scaleY(1);
    &-done {
      max-height: 0;
      opacity: 0;
      // transform: scaleY(0);
      transition: all 300ms;
    }
    &-active {
      max-height: 0;
      opacity: 0;
      // transform: scaleY(0);
      transition: all 300ms;
    }
  }
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
