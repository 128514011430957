.layout {
  min-height: 100vh;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  flex: auto;
  background: #f0f2f5;

  &-body {
    // overflow-x: hidden;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: auto;
    flex: auto;
    background: #e6f0fb;
    min-height: 0;
    //position: relative;
    padding-bottom: 100px;
    max-width: calc(100% - 65px);
    // width: 100%;
  }
  &-employee{
    max-width: 100%;
  }
}

.App {
  position: relative;
}

.dark-mode {
  $rigo-white-color: #eee;
  filter: invert(0.9) hue-rotate(180deg);

  .invert,
  img {
    filter: invert(0.9) hue-rotate(180deg);
  }

  .navbar {
    filter: invert(0.9) hue-rotate(180deg);
    background-color: #005bc0 !important;
  }
  #sidebar {
    background-color: lighten(#005bc0, 20) !important;
  }
  .tabs {
    background: #e1e1e1;
  }
  .section-login {
    background-color: #dadada;
  }
}
.links {
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: repeat(6, 200px);

  .link {
    border: 1px solid #6e6e6e;
    background: #{$rigo-white-color};
    &:not(:last-child) {
      border-right: none;
    }
    &-header {
      padding: 7px;
      text-align: center;
      font-size: 16px;
      background: #4098d7;
      color: #{$rigo-white-color};
    }
    &-list {
      display: flex;
      flex-direction: column;
      list-style: none;

      &__item {
        padding: 10px;
        &:not(:last-child) {
          border-bottom: 1px solid #6e6e6e;
        }
        &:first-child {
          border-bottom: 1px solid #6e6e6e;
        }

        a {
          font-size: 14px;
          text-decoration: none;
          cursor: pointer;
          color: #646464;
          &:hover {
            color: #333;
          }
        }
      }
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px;
  height: 90px;
  position: absolute;
  bottom: 0;

  &-image {
    width: 35px;
    height: 35px;
    background-color: #{$rigo-white-color};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
  }

  &-title {
    margin-left: 10px;
    h1 {
      font-size: 18px;
      font-weight: 500;
      color: #666;
    }
  }
}

.page {
  &-enter {
    opacity: 0;
    transform: scale(1.1);
    &-done {
      // animation:$animate;
    }
    &-active {
      opacity: 1;
      transform: scale(1);
      transition: opacity 300ms, transform 300ms;
    }
  }
  &-exit {
    &-done {
    }
    &-active {
    }
  }
}
// .slide-enter,
// .slide-exit {
//   transition: transform 400ms ease-out;
// }

// .slide-enter {
//   opacity: 0.7;
// //   transform: scale(1.1);
// //   transform: translateX(100%);
// }

// .slide-enter.slide-enter-active {
//     //   transform: scale(1);
//     opacity: 1;
// }

// // .slide-exit {
// //   position: absolute;
// //   top: 0;
// //   left: 0;
// //   width: 100%;
// //   transform: scale(1);
// // }
// .slide-exit{
//     // opacity: .7;
// }
// .slide-exit-active {
//     opacity: 1;
// //   transform: scale(.9);
// }
