.payroll{
    &-drag{
        .drag-block__items{
            grid-template-columns: calc(45% - 30px) calc(30% - 30px) calc(25% - 30px);
        }
    }
}

.pay{
    &-changes{
        width: 100%;
        margin-left: 10px;

        &__list{
            display: flex;
            flex-direction: column;
            padding: 10px;
        }
        &__item{
            display: flex;
            flex-direction: row;
            margin-bottom: 15px;

        }
        &__title{
            display: flex;
            flex-direction: row;
            // align-items: center;
            width: 150px;
            justify-content: space-between;
            margin-right: 15px;
            font-size: 13px;
            color: #6c6c6c;
        }
        &__price{
            display: flex;
            flex-direction: column;
            font-size: 13px;
            color: #6c6c6c;

            &-item{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 5px;
                font-size: 13px;
                color: #6c6c6c;
            }
        }
        &__icon{
            margin-right: 8px;
        }
    }
}

.piecharts{
    display: grid;
    grid-template-columns: repeat(2,1fr);

    &-this{
        width: 100%;
        border-right: 1px solid #e4e6ee;

    }
    &-last{
        width: 100%;
    }
    &-header{
        width: 100%;
        text-align: center;
        padding: 10px 5px;
        font-size: 12px;
        color:#3e3e3e;
    }
    &-data{
        padding: 10px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 5px;
        // text-align: center;
        &__item{
            margin-bottom: 8px;
            margin-left: 20px;
        }

        &__title{
            font-size: 12px;

        }
        &__price{
            font-size: 15px;
            font-weight: 600;
        }
    }
    &-chart{
        // margin-bottom: 110px;
        margin-bottom: 90px;
        .recharts-wrapper{
            width: 100% !important;
            border-bottom: 1px solid #e4e6ee;
            svg{
                width: 100% !important;
                height: 100% !important;
                
            }
        }

        .recharts-legend-wrapper{
            width: 100% !important;
            padding: 15px;
            position: unset !important;
            background: #fafbfc;

            .recharts-default-legend{
                display: flex;
                flex-direction: column;
                // align-items: center;
                margin-left: 20px !important;

                .recharts-legend-item{
                    display: flex;
                    align-items: center;

                    svg{
                        display: none !important;
                    }
                }
            }
        }
    }
}


.stopPayment{
    .pending-name{
        width: 200px !important;
    }
}