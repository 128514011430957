.loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  //background-color: rgba($color-black, 0.8);
  z-index: 999999999999;
  // opacity: 0;
  // visibility: hidden;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  &-text {
    color: #{$rigo-white-color};
    padding: 1rem 0;
    letter-spacing: 1.5;
  }
}
.loader-icon {
  background: transparent !important;
  // width: 75px;
  // height: 75px;
  border-radius: 100%;
  border: 2px solid;
  border-color: #52a7fd;
  border-bottom-color: transparent;
  display: inline-block;
  -webkit-animation: spin 0.75s 0s infinite linear;
  animation: spin 0.75s 0s infinite linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  &.abs-position-center {
    position: absolute;
    right: 50%;
    top: 50%;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  50% {
    -webkit-transform: rotate(180deg) scale(0.8);
    -ms-transform: rotate(180deg) scale(0.8);
    transform: rotate(180deg) scale(0.8);
  }

  100% {
    -webkit-transform: rotate(360deg) scale(1);
    -ms-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

.loader {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include overlayBefore(rgba(0, 0, 0, 0.2));
  }
  &-container {
    width: 130px;
    height: 130px;
    background: #{$rigo-white-color};
    z-index: 111;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  &-spinner {
    background: transparent !important;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 4px solid;
    border-color: #52a7fd;
    border-bottom-color: transparent;
    border-top-color: transparent;
    display: inline-block;
    -webkit-animation: spin 0.75s 0s infinite linear;
    animation: spin 0.75s 0s infinite linear;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  &-name {
    font-size: 20px;
    color: #7a7a7a;
    font-weight: 500;
    margin-top: 10px;

    span {
      animation: blink 1.4s infinite both;
      font-size: 30px;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.skeleton-loading {
  svg {
    // width: 100%;
  }
}
