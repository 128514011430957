.scheduler {
  width: 100%;

  &-tabs {
    width: 100%;

    &__btns {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-right: 16px;
    }
    &__btn {
      // width: 25px;
      // height: 25px;
      padding: 8px;
      background-color: #fff;
      border: 1px solid #bec1c4;
      border-radius: 3px;
      color: #656f78;
      line-height: 0;
      cursor: pointer;

      &:hover {
        background-color: #f3f5f6;
      }
      svg {
        line-height: 0;
      }
    }

    &__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: #ecf5ff;
      border: 1px solid #c9d6e2;
      height: 60px;
    }
    &__list {
      height: 100%;
      display: flex;
      flex-direction: row;
    }
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 12px 16px;
      // font: normal normal medium 16px/19px 'Roboto';
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      letter-spacing: 0px;
      color: #1f2021;
      &:hover {
        color: #343536;
      }
    }
    &__active {
      color: #0075f6;
    }
    &__body {
      padding: 8px;
    }
  }
}

.schedule {
  width: 100%;
  padding: 12px;
  &-no {
    width: 100%;
    padding: 12px;
    text-align: center;
    font-size: 17px;
    color: #666;
  }
  &-filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 12px 0;
    &__label {
      letter-spacing: 0px;
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 500;
      margin-right: 1rem;
    }
    &__col {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;
      .genericForm-group {
        margin-right: 0.25rem;
      }
    }
  }
  &-action {
    &__dates {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #0075f6;
    }
    &__btns {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        margin: 0;
        background-color: #fff;
        border-color: #bec1c4;
        border-radius: 0px;
        padding: 0 8px;
        &.button.with-icon svg {
          margin-right: 0;
          color: #656f78 !important;
          font-size: 24px;
        }
      }
    }
    &__btn {
      &:first-child {
        button {
          // border-right: none;
          border-radius: 3px 0px 0 3px;
        }
      }
      &:last-child {
        button {
          border-left: none;
          border-radius: 0 3px 3px 0;
        }
      }
    }
  }

  &-contain {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px 0;
  }
  &-sidebar {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 225px;
    min-width: 225px;
    &__users {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &-list {
    width: 100%;
    overflow-y: auto;
    background-color: #f9f9fb;
    border: 1.5px solid #bec1c4;
    border-radius: 3px;
    &__assign {
      padding: 8px;
    }
    &__container {
      background-color: #f9f9fb;
      height: 100%;
    }
    &__times {
      display: flex;
      flex-direction: column;
      border-right: 1px solid #bec1c4;
      height: 100%;
    }
    &__btn {
      button {
        min-width: 100%;
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0;
        svg {
          margin-right: 0 !important;
        }
      }
    }
    &__time {
      width: 100%;
      // padding: 8px;
      background-color: #f9f9fb;
      &-body {
        padding: 12px 8px;
        .user_card-name {
          font-size: 14px;
        }
      }
      &-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        background-color: #e5f8e8;
        // justify-content: space-between;
        gap: 12px;
        padding: 6px 4px;

        &-total {
          display: flex;
          gap: 3px;
          font-weight: 500;
          color: #3d4247;
          align-items: center;
          // span{
          //   margin-right: 3px;
          // }
          svg {
            color: #49aa00;
            font-size: 18px;
            margin-right: 3px;
          }
        }
        &-err {
          background-color: #f7ecef;
          svg {
            color: #ca3659;
          }
        }
        &-open {
          display: flex;
          gap: 2px;
          color: #4e5c68;
          span {
            color: #0075f6;
          }
        }
        &-error {
          display: flex;
          gap: 2px;
          color: #4e5c68;
          span {
            color: #ca3659;
          }
        }
      }
      &-block {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        &-heading {
          font-size: 15px;
          font-weight: 500;
          color: #3d4247;
          margin-bottom: 4px;
        }
        &-cards {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }
      }

      &-header {
        width: 100%;
        padding: 8px;
        // display: grid;
        // place-content: center;
        background-color: #4e5c68;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
    }
    &__blocks {
      width: 100%;
      height: 100%;
      // border: 1.5px solid #BEC1C4;
      border-radius: 3px;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      &-7 {
        grid-template-columns: repeat(7, 1fr);
      }
      &-28 {
        grid-template-columns: repeat(28, 1fr);
      }
      &-29 {
        grid-template-columns: repeat(29, 1fr);
      }
      &-30 {
        grid-template-columns: repeat(30, 1fr);
      }
      &-31 {
        grid-template-columns: repeat(31, 1fr);
      }
      &-item {
        min-width: 200px;
      }
      &-header {
        background-color: #f1f4f5;
        color: #2c343b;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        font-size: 14px;
        position: relative;
        font-weight: 500;
        &:not(:last-child) {
          border-right: 1.5px solid #bec1c4;
        }

        &-icon {
          cursor: pointer;
          font-size: 18px;
          // margin-left: auto;
          position: absolute;
          right: 5px;
          &:hover {
            color: #666;
          }
        }
      }
      &-body {
        height: calc(100% - 37px);
      }
    }
  }

  &-modal {
    .modal__header-close {
      // display: none;
    }
    &__container {
      padding: 24px 24px 4px;
    }
    &__title {
      font-size: 16px;
      font-weight: 500;
      color: #1c1c1c;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }
}

.shift {
  &-basic {
    width: 100%;
    &__time {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-top: 8px;
    }
    &__duration {
      .genericForm-group__label {
        min-width: unset;
      }
      .inline-form-group {
        border: 1px solid #bec1c4;
        max-width: max-content;
        border-radius: 4px;

        .badges {
          margin-right: 0 !important;
          background-color: #f7f7f7;
          border-radius: 0;
          padding: 0.25rem 1rem !important;
          border-radius: 0 2px 2px 0;
          cursor: pointer;
          &:first-child {
            border-right: 1px solid #bec1c4;
            border-radius: 2px 0 0px 2px;
          }
        }
        .badges-primary {
          background-color: #fff;
          color: #0075f6;
          svg {
            display: none;
          }
        }
      }
    }
    &__row {
      padding: 6px 0;
      &.space-between {
        margin-top: 16px;
        button {
          margin-left: 0;
          margin-right: 8px;
        }
      }

      &-2 {
        display: grid;
        grid-template-columns: 2fr 1fr;
      }
    }
    &__shift-time {
      margin: 10px 4px;
      color: #0074e9;
      font-size: 13px;
      display: flex;
      align-items: center;
    }
    &__col {
      .genericForm-group__label {
        font-size: 14px;
        color: #1c1c1c;
        font-weight: 500;
      }
      .checkbox-group {
        gap: 12px;
        .approver {
          border-color: #bec1c4;
          background-color: #fff;
          width: 220px;
          margin: 0;
        }
      }
    }
  }
}

.user_card {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #bec1c4;
  background-color: #fff;
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  &-open {
    max-width: 100%;
  }
  &-large {
    border: none;
  }
  &-danger {
    background-color: #ca3659;
    border: none;
    position: relative;
    &:hover {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
    &::after {
      content: "" attr(datatype) "";
      width: max-content;
      background-color: rgba(#333, 0.9);
      color: #fff;
      position: absolute;
      bottom: 100%;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      border: 1px solid #bfbfbf;
      padding: 6px;
      left: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.1s ease-in;
      // min-width: max-content;
    }
    .user_card-name {
      color: #fff;
    }
  }
  &-large {
    align-items: flex-start;
  }
  &-open {
    // background-color: #FCF1FB;
    background-color: #fff;
    border: 1px solid #bec1c4;
    &:hover {
      background: #efefef;
    }
    .user_card-name {
      // color:#E64DDB ;
      color: #0075f6;
    }
    .user_card-image {
      width: 24px;
      height: 24px;
      background-color: #e0efff;
      border-radius: 50%;
    }
  }
  &-name {
    color: #495057;
    font-size: 15px;
    font-weight: 400;
    // line-height: 20px;
    font-family: "Roboto";
  }
  &-designation {
    color: #495057;
    font-size: 15px;
    font-weight: 400;
    margin: 4px 0;
  }
  &-times {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 400;
    color: #5a7188;
  }
  &-time {
    &:first-child {
      &::after {
        content: "|";
        margin: 0 4px;
      }
    }
  }
}

.sched-modal {
  &__container {
    padding: 12px;
  }
  &__title {
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
  }
  &__body {
    padding: 8px 0;
    .genericForm-group {
      margin-bottom: 12px;
    }
  }
  &__btns {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 2px;
  }
}

.assign-modal {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  &__col {
    flex: 1;
    padding: 24px 16px;
    &-left {
      max-width: 175px;
      min-width: 175px;
      width: 175px;
      border-right: 1px solid #bec1c4;
      background-color: #f1f4f5;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  &__emp {
    margin-bottom: 40px;
    &-name {
      color: #130f27;
      font-size: 15px;
      font-weight: 500;
      margin: 12px 0 2px;
      line-height: 20px;
    }
    &-designation {
      color: #495057;
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__work {
    &-col {
      margin-bottom: 20px;
      div {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        &:first-child {
          font-weight: 500;
          font-size: 15px;
          color: #495057;
        }
        &:last-child {
          color: #495057;
        }
      }
    }
  }
  &__head {
    color: #130f27;
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
  }
  &__sub {
    display: flex;
    align-items: center;
    gap: 12px;
    &-head {
      color: #363b40;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    &-time {
      color: #363b40;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  &__form {
    margin-top: 40px;
    &-pattern {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    &-label {
      color: #363b40;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
    }
    &-value {
      color: #0075f6;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
    &-btns {
      margin-top: 16px;
    }
    &-item {
      margin-bottom: 20px;
      .form__radio-horizontal {
        display: inline-flex;
        flex-direction: row;
        border-radius: 4px;
        border: 1px solid #bec1c4;
      }
      .form__radio-group {
        width: unset;
        &:first-child {
          .form__radio-label {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
        &:last-child {
          .form__radio-label {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
        &:not(:last-child) {
          .form__radio-label {
            border-right: 1px solid #bec1c4;
          }
        }
      }
      .form__radio-label {
        background-color: #e0e2e4;
        color: #656f78;
        padding-left: 0;
        padding: 8px 12px;
        height: 100%;
      }
      .form__radio-button {
        display: none;
      }
      .form__radio-input:checked ~ .form__radio-label {
        background-color: #fff;
        color: #0075f6;
      }
    }
  }
}

.role {
  &-form {
    padding: 12px;
  }
  &-formitems {
    .genericForm-group {
      &:last-child {
        margin-top: 12px;
      }
    }
  }
  &-btns {
    margin-top: 24px;
  }
}

.copy-schedule {
  &-modal {
  }
  &__container {
    padding: 24px;
  }
  &__title {
    text-align: left;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0px;
    color: #24282b;
    margin-bottom: 16px;
    font-weight: 600;
  }
  &__body {
  }
  &__col {
    margin-bottom: 12px;
  }
  &__val {
    font-size: 14px;
    color: #0075f6;
    line-height: 18px;
    font-weight: 500;
  }
  &__caption {
    padding: 12px 0 16px;
    font-size: 14px;
    color: #130f27;
  }
  &__label {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #130f27;
    margin-bottom: 6px;
    font-weight: 500;
  }
  &__btns {
    margin-top: 24px;
  }
  &__radio {
    .form__radio-group {
      width: 300px;
    }
    .form__radio-input:checked ~ .form__radio-label .form__radio-button {
      background: #0062d9;
      border-color: #0062d9;
    }
    .form__radio-button {
      border-color: #a6a9b0;
      &::after {
        height: 8px;
        width: 8px;
        background-color: #fff;
      }
    }
  }
  &__chips {
    margin-top: 6px;
    .inline-form-group {
      flex-wrap: wrap;
      gap: 8px;
    }
    .badges {
      min-width: max-content;
      margin-right: 0rem !important;
      cursor: pointer;
    }
  }
  &__divider {
    width: 100%;
    height: 1px;
    background-color: #dadcde;
  }
}
.team {
  &-body {
    &-form {
      margin-bottom: 36px;
      .genericForm-group {
        margin-bottom: 12px;
      }
    }
  }
}
.scheduler {
  .table-contain {
    max-width: fit-content;
  }
  .table-container {
    .table-table {
      .th,
      .td {
        min-width: 60px;
      }
    }
  }
  .shift-table {
    .tr {
      > div {
        &:nth-child(2) {
          max-width: 350px;
          min-width: 350px;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(5) {
          max-width: 250px;
          min-width: 250px;
        }
        &:nth-child(5) {
          max-width: 150px;
          min-width: 150px;
        }
      }
    }
  }
}
