@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

.new-dashboard {
    margin: 20px 0;
    &-heading {
        margin-bottom: 10px;
    }

    &-group {
        display: flex;
        flex-direction: row;
    }
    &-waiting_review {
        margin-right: 1.5rem;
        background-color: #{$rigo-white-color};
        width: 220px;
        display: flex;
        border-radius: 3px;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
        flex-direction: column;
        &-top {
            background-color: #a6cdff;
            padding: 10px 15px;
            border-radius: 3px 3px 0 0px;
            font-size: 14px;
            color: #2c2d2c;
        }

        &-body {
            padding: 10px 15px;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 0 0 3px 3px;
            .count {
                font-size: 25px;
                color: $color-blue;
            }
            .count_text {
                font-size: small;
                color: #888;
            }
        }
    }

    &-running_clock {
        margin-right: 1.5rem;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        background-color: #{$rigo-white-color};
        width: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size:13px;
        &-image {
            // padding:5px;
            margin-top: 5px;
        }

        &-name {
            color: $color-blue;
            margin: 10px 0 5px;
            // font-size: smaller;
        }

        &-project {
            margin-bottom: 15px;
            
        }

        &-time {
            background-color: rgb(0, 122, 255);;
            color: #{$rigo-white-color};
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius:3px;
        }
    }

    &-project_review {
        margin-right: 1.5rem;
        background-color: #{$rigo-white-color};
        width: 220px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        &-top {
            font-size: 14px;
            background-color: #a6cdff;
            padding: 10px 15px;
            color: #2c2d2c;
            border-radius: 3px 3px 0 0px;
        }

        &-body {
            padding: 10px;
            height: 80px;
            // display: flex;
            border-radius: 0 0px 3px 3px;
            // flex-direction: row;
            // justify-content: center;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap:5PX;

            &-block {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 5px;
                .count {
                    font-size: 20px;
                    color: $color-blue;
                }
                .count_text {
                    font-size: small;
                    color: #888;
                }
                .expired {
                    color: #fa6418;
                }
            }
        }
    }
}

.activity {
    &_hand_on_hover {
        cursor: pointer;
    }
    &-title {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    &-clock {
        width: 60%;
        margin-top: 20px;
        display: block;
        &-body {
            // padding:20px;
            display: block;
            background: white;
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
            border-radius: 3px;
            margin-top: 10px;
            span {
                //    margin-right:10px
            }
            &-footer {
                padding: 15px 20px;
            }
            &-list {
                padding: 0 20px;
            }
            &-content {
                display: flex;
                padding: 15px 0px;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                // border-bottom: thin dashed lightgrey;
                border-bottom: 1px solid #ebebeb;
                // margin-bottom:20px;

                .button-grey {
                    color: #bbb;
                    svg {
                        color: #bbb !important;
                    }
                }

                &-detail {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    &-time {
                        display: inline-flex;
                        margin-bottom: 10px;
                        .hours {
                            margin-left: 20px;
                        }
                        span {
                            margin-right: 10px;
                        }
                    }
                    &-tender {
                        display: inline-flex;
                        // margin-bottom:10px;
                        .tproject {
                            color: $color-blue;
                        }
                        .tclient {
                            color: $color-grey;
                        }
                        span {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    &_activities {
        margin: 20px 0;
        &_column {
            width: 100%;
            // width: 50%;
        }
        // justify-content: space-between;
        // display: flex;
        display: grid;
        grid-template-columns: repeat(2, 460px);
        gap: 20px;
        max-width: 1000px;
        &_one {
            // display: flex;
            margin-top: 15px;
            display: grid;
            grid-template-columns: repeat(2, 220px);
            gap: 15px;
        }

        &_this_month {
            display: flex;
            flex-direction: column;
            background: white;
            padding: 10px;
            font-size: 13px;
            border-radius: 0 0 3px 3px;

            div {
                margin-bottom: 10px;

                .count {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
        &_body {
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
            width: 100%;
            // width: 70%;
            // width: 250px;
            background: #{$rigo-white-color};
            // height: 80%;
            border-radius: 3px;
        }
        &_title {
            border-bottom: 1px solid #e0d5d5;
            background-color: rgb(166, 205, 255);
            padding: 10px 15px;
            border-radius: 3px 3px 0 0px;
            font-size: 14px;
            color: rgb(44, 45, 44);
        }
    }

    &_calendar {
        margin-top: 20px;
        &_body {
            background: #{$rigo-white-color};
            display: flex;
            // width: 95%;
            margin-top: 12px;
        }
        &_member {
            display: flex;
            flex-direction: column;
            width: 20%;
            margin: 50px 0 10px 5px;

            .members {
                box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
            }

            &_title {
                padding: 12px;
                font-size: 14px;
            }
            &_input {
                padding: 0 12px;
            }
            &_selection {
                .MuiFormControl-root {
                    width: 260px;
                    margin-left: 10px;
                }
                .MuiInputBase-root {
                    margin-top: 20px;
                }
            }
        }
        &_calendar {
            width: 80%;
            margin: 10px;
        }
    }
    &_active {
        background: #{$rigo-hover-bg-color};
        font-weight: 500;
    }
    &_searched {
        cursor: pointer;
        padding: 5px 10px;
        margin-bottom: 5px;

        .name {
            margin-left: 10px;
            font-size: 13px;
        }
    }
}

.add-content {
    color: #45474a;
    &_work {
        height: 26px;
        background: #f0f2f5;
        margin: 0px;
        color: #45474a;
        padding: 6px 20px;
    }
}
.first_col {
    // margin-right: 60px;
}
.month {
    margin-left: 10px;
    font-size: 12px;
}
.count {
    color: #{$rigo-primary-color};
    font-size: $default-new-fontSize;
    font-weight: 500;
    padding: 6px;
}
.reviews {
    color: #6f6666;
    font-size: 13px;
    padding: 0px;
}

.activity-reviews {
    color: #{$rigo-primary-color};
    font-size: 13px;
    padding: 0px;
}
.mr-15 {
    margin-right: 15px;
}
.mr-25 {
    margin-right: 25px;
}
.mr-20 {
    margin-right: 20px;
}
.nactivity {
    &_container {
        width: 84%;
        background: #{$rigo-white-color};
        margin: 10px 8%;
        box-shadow: 0 0 10px #868585;
    }
    &_title {
        border-bottom: 2px solid #e6e3e3;
        h3 {
            padding: 15px;
            font-weight: 400;
        }
    }
    &_date {
        display: flex;
        margin-top: 12px;
        &_body {
            padding: 25px 15px;
        }
        &_days {
            display: grid;
            grid-template-columns: repeat(1, 1fr 2fr 1fr);
            padding: 7px;
            text-align: center;
            text-decoration: underline;
            color: #4973ea;
        }
    }
    &_projects {
        display: flex;
        flex-direction: column;
        border: 1px solid #d8d6d6;
        border-top: 3px solid #8b8b8c73;
        padding: 15px 20px;
    }
    &_work_nature {
        display: grid;
        grid-template-columns: repeat(1, 3fr 0.5fr 0.5fr 0.5fr);
        column-gap: 10px;
    }
    &__footer {
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
    }
}

.timer {
    width: 40%;
    background: #{$rigo-white-color};
    padding: 20px;
    border-radius: 10px;
    &_body {
        display: flex;
        flex-direction: column;
    }
    &_row {
        display: grid;
        grid-template-columns: repeat(1, 1fr 3fr 1fr);
        margin: 15px 0;
    }
    &_name {
        display: flex;
        flex-direction: column;
        h4 {
            font-size: 14px;
        }
    }
    &_sub {
        font-size: 12px;
    }
}

.pickers {
    margin-left: 0px !important;
}
.mt-12 {
    margin-top: 12px;
}
.mb-12 {
    margin-bottom: 12px;
}
.tx-r {
    text-align: right;
}

//was affecting in all the places
// .makeStyles-root-12 {
//     width: auto !important;
//     margin-right: 0px !important;
//     margin-top: 10px;
// }
.select_member {
    padding: 16px;
    font-size: 17px;
    font-weight: 450;
    &_input {
        width: 85%;
        padding-left: 12px;
        margin-bottom: 20px;
    }
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
    color: #{$rigo-white-color};
    /* background-color: #1a252f; */
    border-color: unset;
}
.fc-today-button.fc-button.fc-button-primary {
    // background-color: ss#ff7245 !important;
}
.fc-button-primary:not(:disabled).fc-button-active {
    color: #{$rigo-white-color};
    background-color: #007da0 !important;
    border-color: unset;
}
.fc-button-primary:not(:disabled).fc-button-active {
    color: #{$rigo-white-color};
    background-color: #007da0 !important;
    border-color: unset;
}
.fc-button-primary:disabled {
    border-color: unset !important;
}
// .fc-button-primary:not(:disabled).fc-button-active {
//     background-color: #76b2eb !important;
//     border-color: #1f84e8 !important;
//     width: 175px !important;
// }
.fc-button-primary {
    background-color: #177edc !important;
    border-color: #1f84e8 !important;
    // width: 175px !important;
}
.fc-toolbar h2 {
    font-size: 1em !important;
}
.fc-toolbar.fc-header-toolbar {
    margin-bottom: 5px;
}
