@import "../../../../../scss/abstracts/mixins";
@import "../../../../../scss/abstracts/_variables";
$label-color: rgba(0, 0, 0, 0.7);
@mixin form-row {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
@mixin attach-bill-expenses {
  .bill-amount {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    //width: 60%;
  }
  .upload {
    padding-left: 1rem;
    display: grid;
    align-items: center;
    text-align: center;
    grid-row-gap: 0.5rem;
    .document-no {
      font-size: 0.8rem;
    }
    .upload-btn {
      display: flex;
      padding: 10px 15px;
    }
  }
}
@mixin form-footer {
  .form-footer {
    justify-content: flex-end;
    background: #d3d1d1;
  }
}
.settlement-form {
  padding: 15px 20px;
  background: #e8e8e8;
  border: 1px solid #e5e5e5;
  //margin: 2rem;
  // font-family: $font-stack;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(204, 204, 204, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(204, 204, 204, 0.75);
  box-shadow: 0px 0px 2px 0px rgba(204, 204, 204, 0.75);
  .main-header {
    display: flex;
    justify-content: space-between;
    .form-header {
      .header-title {
        display: block;
        padding: 0 0 0.5rem 0;
        font-weight: 600;
        font-size: 1.4rem;
        color: #626262;
      }
      .header-date {
        padding: 0.5 0 1rem 0;
        display: block;
        color: #3c78bc;
        font-weight: 500;
      }
    }
    .form-close {
      svg {
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
  }

  .section {
    margin: 1rem 0;
    background: #f6f6f6;
    display: block;
    width: 100%;
    &__header {
      padding: 1rem;
      border-bottom: 2px solid #e2e0e0;
      span {
        font-weight: 500;
        color: #474747;
      }
    }
    &__body {
      padding: 15px 25px;
      .form-row {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .grid {
          display: grid;
          span:not(.bill-count) {
            padding: 0.5rem;
            font-family: $font-stack;
            font-weight: 500;
            color: #4a4747;
          }
          .date {
            color: #000;
            font-weight: 700;
          }
          .pickers {
            margin: 0;
            .Mui-disabled {
              background: #e6e6e6;
              button:disabled {
                border: none;
                background-color: transparent !important;
                color: #5a5a5a !important;
                pointer-events: none !important;
                cursor: not-allowed !important;
              }
            }
          }

          .genericForm-group {
            &__date {
              &-icon {
                margin-right: 10px;
              }
            }
          }
        }

        .grid {
          &__header {
            padding: 1rem 0;
            display: grid;
            border-bottom: 2px solid #e0dfdf;
            font-family: $font-stack;
            &__name {
              font-weight: 500;
            }
            &__tag {
              font-style: italic;
              font-size: 0.9rem;
              color: #444444;
            }
          }
          &__body {
            font-family: $font-stack;
            font-size: 0.85rem;
            color: #4c4747;
            &__row {
              display: grid;
              grid-template-columns: 190px 190px 85px;
              border-bottom: 2px solid #e0dfdf;
              padding: 0.8rem 0;
              .employee-name {
                font-weight: 600;
                color: #000;
              }
              .actions {
                font-weight: 500;
                color: #2768b5;
              }
            }
          }
          .amount {
            text-align: right;
          }
        }
        .makeStyles-root-87 {
          width: 300px;
          padding-top: 1rem;
        }
        label {
          color: $label-color;
        }
        .bill-grid {
          @include respond(desktop) {
            width: 80%;
          }
          @include respond(med-desktop) {
            width: 80%;
          }
          @include respond(big-desktop) {
            width: 70%;
          }

          .grid__header {
            grid-template-columns: 23vw 4.3vw 5.3vw 5.3vw auto;
            //grid-column-gap: 10px;
            background: #efefef;
            padding: 1rem;
            -webkit-box-shadow: 0px 0px 1px 0px rgba(125, 111, 125, 1);
            -moz-box-shadow: 0px 0px 1px 0px rgba(125, 111, 125, 1);
            box-shadow: 0px 0px 1px 0px rgba(125, 111, 125, 1);
          }
          .grid__body {
            &__row {
              grid-template-columns: 23vw 4.3vw 5.3vw 5.3vw auto;
              padding: 1rem;
              // grid-column-gap: 25px;
              span:not(.bill-count) {
                padding-left: 1rem;
              }
            }
          }
          .grid__footer {
            border-top: 1px solid #a9a9a9;
            border-bottom: 1px solid #a9a9a9;
            &__row {
              display: grid;
              grid-template-columns: 23vw 4.3vw 5.3vw 5.3vw auto;
              padding: 1rem;
              //grid-gap: 25px;
              font-family: $font-stack;
              border-bottom: 2px solid #e0dfdf;
              align-items: center;
              span {
                color: #474747;
                font-size: 0.85rem;
                font-weight: 500;
              }
              .other-expense-name {
                color: #1574e6;
                &:not(.disabled) {
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
              .other-expense-amount {
                color: #1574e6;
                cursor: pointer;
              }
              .text-bg {
                font-size: 1.2rem;
              }
              .otherexpense-close-icon {
                //font-size: 1rem;
                //margin-left: 5rem;
                cursor: pointer;
                &:hover {
                  color: #e64d4d;
                }
              }
            }
            .summary {
              grid-template-columns: 23vw 9.6vw 5.3vw auto;
              &__label,
              &__amount {
                display: grid;
                text-align: right;
                grid-row-gap: 5px;
                align-items: center;
              }
              .advance-label,
              .advance-amount {
                font-weight: 400;
                color: #333131;
                white-space: nowrap;
              }
              .claim-amount,
              .claim-label {
                font-weight: 600;
                white-space: nowrap;
              }
              .settle-amount,
              .settle-label {
                font-weight: 700;
                color: #2268c7;
                white-space: nowrap;
              }
            }
          }
        }
        .note {
          width: 55vw;
        }
        .form-label {
          padding-bottom: 0.5rem;
          color: $label-color;
        }
        .autoselect {
          display: grid;
          grid-row-gap: 0.5rem;
        }

        .upload {
          // .flex {
          //   width: 100px;
          // }
          &-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .badges-group {
            padding-top: 0.5rem;
          }
        }
        .ck-editor {
          width: 100%;
        }
      }
    }
    .travel-create__body {
      padding: 2rem;
      .badges-group {
        display: flex;
        .badges:not(:first-child) {
          margin-left: 1rem;
        }
        .badges {
          cursor: pointer;
        }
      }
    }
  }
  .first-section {
    .form-row {
      width: 70%;
    }
    .flex {
      width: 250px;
      justify-content: space-between;
      span {
        font-family: $font-stack;
        font-weight: 500;
        color: #2768b5;
        cursor: pointer;
      }
    }
  }
  .second-section {
    .form-row {
      width: 70%;
    }
    .badges:not(.disabled) {
      cursor: pointer;
    }
    .badges-group {
      display: flex;
      width: 450px;
      justify-content: space-between;
    }
    .ck-editor {
      width: 100%;
    }
  }
  .third-section {
    .form-row {
      width: 70%;
    }
    .ql-editor {
      min-height: 200px;
      background: white;
      padding: 16px;
    }
  }
  .fourth-section {
    .md-wd {
      width: 550px;
      .flex {
        width: unset;
        span {
          padding-left: 0.5rem;
        }
        .icon-check {
          color: #247cbb;
        }
      }
    }
    .btn {
      display: flex;
      padding: 0.6rem 0.5rem;
    }
    .icon-plus {
      width: 1.3em;
      font-size: 1rem;
    }
    .btn-grey-2 {
      font-size: 0.7rem;
    }
    .bill-amount {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bill-count {
        background: #e4e1e1;
        color: #e64141;
        font-weight: 700;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        font-family: $font-stack;
      }
      &__flex {
        flex: 33.33%;
        display: flex;
        justify-content: flex-end;
      }
    }
    .form-row {
      .makeStyles-root-272 {
        width: 250px;
      }
    }
  }
  .fifth-section {
    .form-row {
      padding: 1rem;
      display: flex;
      align-items: center;
    }
    .btn-row {
      justify-content: flex-end;
    }
  }
  .information-section {
    .travel-create {
      background: unset;
      box-shadow: unset;
      width: 100% !important;
    }
  }
}

.modal-settlement {
  .modal__content {
    border-radius: 0;
    border: none;
    box-shadow: none;
    .modal__header {
      background: #1a83d6;
      border: none;
      h2 {
        color: #{$rigo-white-color};
        letter-spacing: 0.5;
      }
      .modal__header-close {
        svg {
          color: #{$rigo-white-color};
        }
      }
    }
  }
  .attach-bill {
    font-size: 0.8rem;
    .form-row {
      @include form-row;
      .grid {
        display: grid;
        grid-template-rows: auto auto;
        padding: 1rem;
        width: 100%;
        &__header {
          display: grid;
          grid-template-columns: auto auto auto auto;
          padding: 1rem;
          background: #e2e2e2;
        }
        &__body {
          display: grid;
          grid-template-columns: auto auto auto auto;
          padding: 1rem;
          border-bottom: 2px solid #d9d9d9;
        }
      }
      @include attach-bill-expenses;
      .form-group__input {
        padding: 5px 12px;
      }
    }
    @include form-footer();
  }
  .add-other-expenses {
    font-size: 0.8rem;
    .form-row {
      @include form-row;
      @include attach-bill-expenses;
      .expense-name {
        max-width: 350px;
      }
      .form-group__input {
        padding: 5px 12px;
      }
      .note {
        width: 37vw;
      }
    }

    @include form-footer();
  }
}
