@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.tasktracker-title-section {
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expanded__tr {
  background-color: #f3f4f5;
  &-details {
    background-color: #f9f9f9;
  }
}

// .common-route-title {
//   padding: 5px 0 10px 0;
//   // margin-bottom: 25px;
//   span {
//     display: flex;
//     align-items: center;
//   }
//   .module {
//     font-size: 13px;
//     color: #0052ab;
//     cursor: pointer;
//   }
//   .path-name {
//     font-weight: 500;
//     font-size: 13px;
//   }
// }

.table {
  margin-top: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  // padding: 10px;

  .edit-button-drop {
    margin-left: 10px;
    padding: 0 0 0 10px;
    border-left: 1px solid $color-grey-light;
  }

  &-row {
    .e {
      &-custom-row {
        padding: 8px;
        white-space: initial;
        .button {
          height: 35px;
          &-drop {
            height: 35px;
          }
        }
      }
      &-rowcell {
        white-space: normal !important;
        overflow: hidden !important;
        font-size: 14px !important;
        &:hover {
          overflow: unset !important;
        }
        .status {
          display: flex;
          flex-direction: row;
          svg {
            margin-right: 8px;
          }
        }
      }
    }

    &__icon {
      color: $color-grey;
      span {
        font-size: 12px !important;
      }
      // line-height: 27px;
      // margin-right: 6px;
      svg {
        transition: all 0.2s;
      }
      &-uncollapsed {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  &-details {
    &.custom-row {
      display: none;

      &.row-expand {
        display: table-row;
      }
    }
    width: max-content;
    // margin-left: 21px;
    .detail {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 13px;
      color: #5f5f5f;
      padding: 8px !important;
      word-break: break-all;
      &-col {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .button {
        height: 30px;
        &-drop {
          height: 30px;
        }
        &-docCount {
          cursor: unset;
        }
      }

      .project {
        // // display: inline !important;
        // // max-width: 400px;
        // font-size: 0.85rem;
        // color: #5f5f5f;
        // padding: 5px 0;
        // display: flex !important;
        &-icon {
          color: white;
          padding: 2px 6px;
          background: $color-blue; // #6d7278;
          margin-right: 5px;
          border-radius: 3px;
          // max-height: 16px;
        }
      }

      .hoursAndMore {
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;

        span {
          margin-right: 10px;
        }

        .button {
          border-radius: 6px;
          padding: 0 10px;
          height: 30px;
          &-expired {
            cursor: unset;
            background: rgb(255, 136, 107);
          }
          &-renew {
            background: rgb(124, 180, 66);
          }
        }
      }

      .activities {
        display: flex !important;
        color: #6d7278;
        margin-top: 5px;
        &-icon {
          font-size: 20px;
          color: #d5d6d7;
          margin-right: 5px;
        }
      }
    }
  }
}

.add {
  margin: 15px 0;
  // &-content {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 5px 10px;
  //   color: $color-blue;

  //   &-icon {
  //     font-size: 20px;
  //     margin-right: 5px;
  //   }
  // }
}

.common-drop {
  &.active,
  &.button-drop__active {
    .common-drop-list {
      overflow: hidden;
    }
    .common-drop__content,
    .button-drop__content {
      width: 8rem;
      top: 40px;
      &:after {
        left: 75%;
      }
    }
  }
  &-name {
    font-size: 14px;
  }
}

.modal__content {
  width: 50%;
}

.btn-add {
  // display: flex;
  // justify-content: center;s
  // align-items: center;
  color: #444 !important;
  padding: 2px 5px !important;
  &-icon {
    cursor: pointer;
    font-size: 1.2rem;
    transition: 0.8s ease-in-out;
  }
}
