@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

$font-family: "Roboto", Helvetica, sans-serif;

@mixin grid($cols, $mgn) {
  //   float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $mgn;
  margin-bottom: $mgn;
  //   width: ((100% - (($cols - 1) * $mgn)) / $cols);

  &:nth-cild(#{$cols}n) {
    margin-right: 0;
  }
}

.mypage {
  // padding: 10px;
  &-section {
    &-title {
      margin-bottom: 10px;
      color: #2c2d2c;
      font-weight: 500;
      font-size: 14px;
    }

    &-grid {
      @include grid(5, 20);
    }
    &-block {
      width: 190px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      // width: 210px;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 15px;
      background: #{$rigo-white-color};
      border-radius: 3px;
      transition: all 0.2s;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:hover {
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
      }

      &-name {
        color: rgb(34, 102, 227);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 500;

        &-icon {
          font-size: 20px;
          .status-icon {
            width: 20px;
          }
          .text-green {
            fill: #49aa00 !important;
          }
          .text-orange {
            fill: #fd7e14 !important;
          }
        }
      }

      &-desc {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: -7px;
        //margin-top: 40px;
        // margin-top: 50px;
        &-icon {
          color: #828282;
          margin-left: 5px;
          // width: 30px;
          // font-size: 40px;
          .request-icon {
            fill: #000;
            // fill: #828282;
            width: 20px;
            height: 30px !important;
          }
        }
        &-detail {
          height: 70%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          // align-items: center;
          // align-items: flex-end;
          //width: 100%;
           &-int {
            font-weight: bolder;
            font-size: 28px;
            color: rgb(34, 102, 227);
          }
          &-text {
            font-size: 14px;
            font-weight: 400;
            color: #666;
            //margin-bottom: 4px;
            line-height: 1.3;

            &.text-title {
              margin-bottom: 5px;
              color: #{$rigo-primary-color};
            }
            &.text-time {
              color: #333;
              font-size: 13px;
            }
            &.text-place {
              color: #777;
              font-size: 14px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

.dashboard {
  padding: 20px;
  background-color: #eff3f9;
  // background-color: #e9eff8;
}

.employee-reports {
  .admin-common {
    background-color: transparent;
  }
}

.team {
  width: 100%;
  &-block {
    width: 80%;
    display: block;
    // padding: 20px;
    padding: 0 !important;
    background: #{$rigo-white-color};
    margin-top: 10px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    border-radius: 3px;

    &__tab {
      width: 100%;

      .tabs-list__item {
        &:nth-child(1) {
          margin-left: 20px;
        }
        &.tab-active {
          .badge {
            background: #{$rigo-primary-color};
            color: #{$rigo-white-color};
          }
        }
      }
      .tabs-content {
        padding: 25px 20px;
      }
    }
    &__tablist {
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: row;

      &-item {
        height: 40px;
        max-width: 200px;
        margin-right: 10px;
        cursor: pointer;

        &.active-tab {
          .team-block__tablist-content {
            background-color: #90d0ff;
            span {
              &:nth-child(1) {
                color: #444;
              }
            }
          }
        }
      }
      &-context {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          &:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
      &-content {
        background-color: #f5f5f5;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        justify-content: center;
        &:hover {
          background-color: #dfdfdf;
        }

        span {
          &:nth-child(1) {
            font-size: 14px;
            font-weight: 400;
            color: #666;
            margin-right: 10px;
          }
        }
      }
    }
    &__images {
      padding: 20px 0;
      width: 100%;
    }
  }
}

.team {
  &-images {
    &-list {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__item {
        width: 68px;
        height: 68px;
        position: relative;
        margin-right: 15px;
        margin-bottom: 15px;
        // &:hover{
        //   .team-card{
        //     display: block;
        //   }
        // }

        .active-card {
          display: block;
          animation: fade-in 0.2s both;
        }
        .inactive-card {
          display: block;
          animation: fade-out 0.2s both;
        }
      }
    }
  }
  &-image {
    width: 100%;
    height: 100%;
    // border: 1px solid #aaa;;
    .company-logo {
      div {
        font-size: 17px !important;
        font-weight: 500;
      }
      &-container {
        position: relative;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-card {
    display: none;
    padding: 15px;
    width: 240px;
    background-color: #{$rigo-white-color};
    position: fixed;
    top: 100%;
    left: 50%;
    // transform: translateX(-50%);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 2;
    margin-top: 10px;
    // margin-top: 20px;

    &-active {
      display: block;
      position: absolute;
      transform: translateX(0%);
      // transform: translateX(-50%);
      left: 0;
      transition: all 0.3s cubic-bezier(0.31, 0.07, 0.56, 1.03);
      // animation: fade-in .2s both cubic-bezier(.31,.07,.56,1.03);
    }
    // &:hover{
    //   border-top-style: hidden;
    //   display: block;
    // }

    &.active-card {
      display: block;
      transition: all 0.3s cubic-bezier(0.31, 0.07, 0.56, 1.03);
      animation: fade-in 0.2s both cubic-bezier(0.31, 0.07, 0.56, 1.03);
    }
    &.inactive-card {
      display: block;
      transition: all 0.3s ease-in-out;
      animation: fade-out 0.2s both;
    }

    &:after {
      // content: "";
      position: absolute;
      box-sizing: border-box;
      background-color: #{$rigo-white-color};
      border: 1px solid #e5eaed;
      border-bottom: 1px solid transparent;
      // border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      top: 0;
      left: 25%;
      // left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      width: 30px;
      height: 30px;
      z-index: -1;
      // background-color: #{$rigo-white-color};
    }

    &-image {
      margin: 0 auto 10px;
      text-align: center;
      // img {
      //   width: 100%;
      //   height: 100%;
      //   object-fit: cover;
      // }
    }
    &-content {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
    }
    &-title {
      h3 {
        color: #{$color-blue};
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
    p {
      font-size: 13px;
      color: #232323;
    }
    &-text {
      font-size: 13px;
      color: #42526e;
      font-weight: 400;
      margin-bottom: 2px;
    }
    &-link {
      margin-top: 15px;
      color: #{$color-blue};
      font-weight: 400;
      font-size: 13px;

      &:hover {
        color: lighten($color-blue, 10);
      }
    }
  }
}

@keyframes animw {
  0% {
    height: 300px;
  }
  100% {
    height: 100%;
  }
}

.messages {
  width: 80%;
  // margin-top: 20px;
  transition: all 0.3s;
  // height: 300px;

  &-div {
    width: calc(100% - 0px);
    height: 400px;
    margin-top: 20px;
  }
  &-back {
    padding: 20px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    background-color: #{$rigo-white-color};
    width: calc(80% - 20px);
    height: 350px;

    .messages-container {
      margin: 10px auto;
      box-shadow: unset;
    }
  }
  &-fixed {
    padding: 0px;
    z-index: 1111;
    .messages-container {
      width: 80%;
      overflow-y: auto;
      max-height: 85vh;
    }
    .mypage-section-title {
      padding: 10px 20px;
      border-bottom: 1px solid #cdcdcd;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .close-icon {
        font-size: 30px;
        color: #c0c0c0;
        display: block !important;
        cursor: pointer;
        line-height: 0;

        &:hover {
          color: #{$color-red};
        }
      }
    }
  }
  .mypage-section-title {
    .close-icon {
      display: none;
      
    }
  }
  &-container {
    width: 100%;
    padding: 20px;
    background-color: #{$rigo-white-color};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    margin-top: 10px;
    border-radius: 2px;

    // height: calc(100% - 40px);
  }
  .view-btn {
    padding: 20px 10px 10px;

    a {
      color: #2680eb;
      cursor: pointer;
      border-bottom: 1px solid #2680eb;
      &:hover {
        color: lighten(#2680eb, 10);
      }
    }
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;

    &__item {
      width: 100%;
      display: block;
      border-bottom: 1px solid #cfcfcf;

      &:not(:first-child) {
        .message {
          padding: 15px 10px;
        }
      }
    }
    .message {
      display: flex;
      flex-direction: row;
      // align-items: center;
      padding: 10px 10px 15px;

      &__image {
        width: 60px;
        margin-right: 10px;
      }
      &__notice {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        width: 125px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #de4c3c;
          margin-bottom: 6px;
        }
        .notice-time {
          font-size: 14px;
          font-weight: 400;
          color: #2c2d2c;
        }
      }
      &__data {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 5px;
      }
      &__icons {
        display: flex;
        flex-direction: row;

        &-item {
          margin-left: 10px;
        }
      }
      &-title {
        color: #2c2d2c;
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 6px;
      }
      &-content {
        color: #2c2d2c;
        font-weight: 400;
        font-size: 13px;
        p{
          overflow: hidden;
        }
        &-btn{
          font-size: 14px;
          font-weight: 500;
          color:#2680eb;
          margin-top: 15px;
          display: inline-block;
          border-bottom: 1px solid #2680eb;
          &:hover{
            cursor: pointer;
            color:lighten(#2680eb,10)
          }
        }
      }
    }
  }
}

.news {
  width: 100%;
  margin-top: 20px;

  &-container {
    width: 80%;
    padding: 20px 30px 0;
    background-color: #{$rigo-white-color};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    margin-top: 10px;
    border-radius: 2px;
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;

    &__item {
      width: 100%;
      display: block;
      border-bottom: 1px solid #cfcfcf;

      &:not(:first-child) {
        .newsItem {
          padding: 15px 10px;
        }
      }
    }
    .newsItem {
      display: flex;
      flex-direction: row;
      // align-items: center;
      padding: 10px 5px 15px;
      &__notice {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        min-width: 125px;

        p {
          font-size: 14px;
          font-weight: 400;
          color: #2680eb;
          margin-bottom: 6px;
        }
        .notice-time {
          font-size: 14px;
          font-weight: 400;
          color: #4e4e4e;
        }
      }
      &__data {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin: 0 10px;
      }
      &__icons {
        display: flex;
        flex-direction: row;

        &-item {
          margin-left: 10px;
        }
      }
      &-title {
        color: #4e4e4e;
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 6px;
      }
      &-content {
        color: #4e4e4e;
        font-weight: 400;
        font-size: 13px;

        p{
          overflow: hidden;
        }
        &-btn{
          font-size: 14px;
          font-weight: 500;
          color:#2680eb;
          margin-top: 15px;
          display: inline-block;
          border-bottom: 1px solid #2680eb;
          &:hover{
            cursor: pointer;
            color:lighten(#2680eb,10)
          }
        }
      }
    }
  }
}

.svg-icon {
  cursor: pointer;
  svg {
    width: 32px;
    height: 32px;
  }
}

.no-found {
  display: block;
  width: 100%;
  color: #b7b7b7;
  padding: 10px 0;
  font-size: 17px;
  font-weight: 400;

  &-container {
    width: 100%;
    display: block;
    background: #{$rigo-white-color};
    border-radius: 3px;
    height: 100px;
    margin: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.12);
    .content {
      color: #bbbaba;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
