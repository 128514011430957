.table-row {
  // display: block;
  // width: 97.3vw;
  font-size: 0.85rem;
  color: #5f5f5f;
  // height: 56px;
  // overflow: hidden;
  // transition: height 350ms ease-in-out;
  // .e-rowcell {
  //   //display: block;
  //   // width: 94.65vw;
  // }

  .e-rowcell {
    padding: 8px !important;
    overflow-wrap: break-word !important;
    > div {
      display: grid;
    }
    .approver {
      display: flex;
      // width: 13vw;
      .approvedDate {
        padding-left: 1rem;
      }
      svg {
        color: green;
        margin: 4px;
      }
      &__status {
        padding-right: 0.5rem;
      }
    }
    .btn-col {
      display: flex;
      justify-content: flex-end !important;
      align-items: center;
      &:not(:first-child) {
        padding-top: 0.5rem;
      }
      button {
        padding: 8px 10px;
      }
    }
    .btn-col {
      &__green {
        background: #7cb342;
      }
    }
    .icon-expand-collapse {
      svg {
        cursor: pointer;
        fill: #aaa;
      }
    }
    .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f16464;
      svg {
        font-size: 1.5rem;
      }
    }
    .emp-image {
      width: 45px;
      height: 45px;
      border-radius: 100vw;
    }
    .isChanged {
      font-size: 1.5rem;
      color: #ec3b3b;
    }
  }
}

.table-details {
  font-size: 0.85rem;
  height: 0;
  overflow: hidden;
  transition: height 350ms ease-in-out;
  // width: 50vw;
  box-sizing: unset;
  display: inline-block;
  .details {
    // padding: 0 1rem 1rem 1rem;
    display: grid;
    grid-row-gap: 0.5rem;
    box-sizing: unset;
    &__amount {
      width: 25vw;
      display: flex;
      justify-content: space-between;
    }
    &__otherExpenses {
      display: grid;
      grid-row-gap: 0.3rem;
      div {
        display: grid;
        width: 30vw;
        grid-template-columns: 120px 185px 105px auto;
      }
    }
  }
}
.light-color {
  color: #7b7a7a;
}

.MuiTableCell-root {
  border-bottom: 1px solid rgb(239, 239, 239);
}

.custom-grid {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  .e-gridheader {
    .e-columnheader {
      .e-headercell {
        background: #f5f5f8;
        padding: 8px;
        border-bottom: 2px solid #efefef;
        .e-headertext {
          color: "#353F5A";
          font-size: 13px;
        }
      }
    }
  }
  .e-gridcontent {
    min-height: 300px;
    // @include respond(med-desktop) {
    //   min-height: 400px;
    // }
    // @include respond(big-desktop) {
    //   min-height: 500px;
    // }

    .e-content {
      min-height: 300px;
      // @include respond(med-desktop) {
      //   min-height: 400px;
      // }
      // @include respond(big-desktop) {
      //   min-height: 500px;
      // }
    }
  }
  .e-table {
    .table-row {
      &:hover {
        background-color: #efefef;
      }
      td {
        font-size: 13px;
      }
    }
  }
}

.default-table {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
}
