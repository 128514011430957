@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/_variables";

.profile{
    width: 100%;
    // padding: 20px;
    font-size: 14px;
    font-weight: 400;
    color:#333;

    &-title{
        margin-left: 30px;
        .common-route-title{
            margin-bottom: 5px;
        }
    }

    &-bg{
        min-height: 100vh;
        // background: #d2e6f4;
        background-color: #e7eff8;
    }

    &-container{
        // width: calc(100% - 40px);
        background: #e7eff8;
        padding-top: 15px;
    }
    &-row{
        display: grid;
        grid-gap: 20px;
        // grid-template-columns: repeat(2,1fr);
        grid-auto-columns: 1fr 1fr;
        // margin-right: 21px;
    }
    &-column{
        width: 100%;
        // padding: 35px;
        padding: 10px 0px;
        margin-right: 10px;

        &.header-block{
            padding: 0px 30px;
        }

        &:nth-child(1){
            grid-column: 1;
        }
        &:nth-child(2){
            grid-column: 2;
        }
        // border-radius: 3px
    }
    &-block{
        display: flex;
        flex-direction: column;

        &__title{
            background-color: #{$rigo-white-color};
            margin-bottom: 1px;
            padding: 15px 20px;
            border-radius: 4px 4px 0px 0px;
            h2{
                font-size: 14px;
                color: #979797;
                font-weight: 500;
            };
        }
        &__body{
            background-color:#{$rigo-white-color};
            padding: 20px 30px;
            position: relative;
            border-radius: 0 0 4px 4px;
            display: flex;
            // flex-direction: row;
            // grid-gap: 20px;
        // grid-template-columns: repeat(2,1fr);
            // grid-auto-columns: 1fr 1fr;
        }
        &__div1 {
            margin-right: 200px;
        }   
        &__icon{
            position: absolute;
            top: 15px;
            right: 15px;

            svg,i{
                font-size: 20px;
                color:#eee
            }
        }
        &__div{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;
            color:#666;
        }
    }
    &-left{
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        padding: 20px;
    }
    &-right{
        padding: 10px;
        position: relative;

        .badges{
            margin-bottom: 10px;
        }
    }
    &-secondary{
        &__left{
            margin-right: 30px;
            .badges-group{
                margin: 10px 0 ;
                .badges{
                    margin-right: 10px;
                    min-width: 100px;
                    text-align: center;
                }
            }
            .flex-column{
                span{
                    font-size: 14px;
                    color:#465060;
                    strong{
                        margin-right: 3px;
                    }
                }
            }
        }
    }
    &-suboridinates{
        margin-top: auto;
        h3{
            color: #0068D4;
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 17px;
            span{
                font-weight: 400;
                font-size: 13px;
                margin-left: 10px;
            }
        }
    }
    &-field{
        display: flex;
        flex-direction: column;
        
        &:not(:last-child){
            margin-bottom: 20px;
        }

        &__label{
            font-weight: 500;
            color: #8F8F8F;
            font-size: 13px;
            margin-bottom: 4px;
        }
        &__item{
            font-weight: 400;
            color: #465060;
            font-size: 13px;
            margin-bottom: 4px;

            a{
                display: flex;
                flex-direction: row;
                align-items: center;

                svg{
                    font-size: 17px;
                    margin-left: 6px;
                }
            }
        }
    }
    &-info {
            background: white;
            display: flex;
            // padding: 20px;
            flex-direction: row;

            &__container{
                padding-top: 15px;
            }
            &__title{
                // padding: 10px 0;
                h2{
                    font-size: 20px;
                    font-weight: 500;
                }
                h4{
                    font-size: 14px;
                    color: #465060;
                    font-weight: 500;
                    margin:2px 0;
                }
                span{
                    font-size: 13px;
                    font-weight: 400;
                    color: #454545;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                // padding: 10px 22px 0px 22px;
                span {
                    margin: 4px 0px;
                }
                h2, a{
                    color: #0364A5;
                }
                h3 {
                    color: #0068D4;
                    margin-bottom: 10px;
                }
            }
            &__container{
                display: flex;
                flex-direction: row;
                align-items: flex-end;

                .profile-info__body{
                    &:nth-child(1){
                        padding-right: 40px;
                    }
                    &:nth-child(2){
                        padding-left: 40px;
                        border-left: 1px solid #c3c3c3;
                    }
                }
            }
    }
    &-tab{
        padding: 2px 30px 50px;
        .tabs-list__item{
            font-size: 13px;
            min-width: 75px;
        }
        & > .tabs{
            background: transparent;
            box-shadow: unset;
            & > .tabs-list{
                background: #{$rigo-white-color};
                
            }
            & > .tabs-content{
                background: transparent;
                padding: 15px 0 !important;

                .profile-column{
                    padding: 10px 0 ;
                }
            }
        }
    }

}

.addButton {
    color: blue;
    /* color: white; */
    border-radius: 100%;
    width: 3.5%;
    height: 2%;
    /* font-size: 97px; */
    /* font-weight: 600; */
    background: whitesmoke;
    box-shadow: 1px 1px #c1b4b4;
    position: absolute;
    right: 65px;
    top: 330px;
}
.profile-image {
    width: 200px;
    margin-right: 25px;
    // padding: 0 10px;
    img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
}
.healthInfo {
    display: flex;
    a {
        margin-right: 10px;
    }
    svg {
        color: #00adff;
    }
}

.badges{
    border-radius: 4px;

    &-blue{
        background-color: #c1e1ff;
        color:#3580b7;
    }
    &-grey-light{
        background-color: #ebecf0;
        color:#454545;
    }
    &-pink{
        background-color: #ffbaa9;
        color:#fafafa;
    }
    &-yellow{
        background-color: #ffe36e;
        color:#454545
    }
    &-grey-dark{
        background-color: #c9cfd7;
        color:#3E5270;
    }
    &-purple{
        background-color: #e7e2fc;
        color:#454545;
    }
}

.nodata{
    text-align: center;
    h2{
        color: #d3d3d3;
        font-size: 20px;
    }
}