.header {
    position: relative;
    width: 100%;
    // height: 4rem;
    flex: 0 0 auto;
    height: 55px;
    &-contain{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 3rem;
        height: 55px;
            // line-height: 60px;
        background-color: #{$rigo-white-color};
        // background-color: #0a7ed3;
        
        // -webkit-box-shadow: 0 4px 8px;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: all .5s cubic-bezier(.4,.02,.56,.92);
        
    }
    &-change-profile{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 5px;
        &__btn{
            height: 32px;
            border: none;
            background-color: rgb(233, 239, 248);;
            color: rgb(34, 102, 227);;
            padding: 0 16px;
            font-size: 12px;
            border-radius: 13rem;
            outline: none;

            &:hover{
                cursor: pointer;
                background:darken(rgb(233, 239, 248),10)
            }
        }
    }

    &-search{
        height: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #{$rigo-white-color};
        &__input{
            margin-top: 10px;
            display: flex;
            align-items: center;
            border: 1px solid #cfcfcf;
            background: #{$rigo-white-color};
            border-radius: 4px;
            width: 400px;
            height: 40px;
            padding: 0 10px;
            input {
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                color: #444;
                font-size: 13px;
                @include placeholder {
                    @include placeholderText();
                }
              }
        }
        &__icon{
            width: 30px;
            height: 30px;
            display: flex;
            margin: 0 5px;
            align-items: center;
            justify-content: center;
            svg {
              font-size: 18px;
              color: #7f7f7f;
            }
        }
        &-active{
            overflow: hidden;
            width: 100%;
            background: #{$rigo-white-color};
            z-index: 11;
            height: 350px;

            .header-search{
              
                 height: calc(350px - 65px);
            }

            &:before{
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                background-color: rgba(0,0,0,0.3);
                z-index: -1;
               }
        }
    }
    &-links{
        padding: 10px;
        border: 1px solid #cfcfcf;
        width: 400px;
        margin: 10px;
        overflow-y: auto;
        height: 215px;
    }
    &-list{
        margin-bottom: 15px;
        cursor: pointer;
       
    }
    &-app-version{
        font-size: 12px;
        color: #949494;
        text-align: center;
        padding-top: 10px;
        border-top: 1px solid #eae8e8;
    }
    &-link-section{
        border-bottom: 1px solid #cfcfcf;
        padding-bottom: 4px;
        &__title{
            display: flex;
            flex-direction: column;
            color: #cfcfcf;
            .link-title{
                font-size: 14px;
                color: #000;
                font-weight: 500;
            }
            .link-sub-title{
                font-size: 12px;
                color: #6f6f6f;
                line-height: 1.5;
            }
        }
    }

    &-bar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        position: relative;
        width: 100%;
        // min-height: 4rem;
        // max-height: 4rem;
        min-height: 55px;
        max-height: 55px;
        height: 70px;
        padding: 0 2rem;
        background-color: #{$rigo-white-color};
        border-bottom: 2px solid #dfdfdf;
    }
    &-menu {
        position: absolute;
        z-index: 9999;
        left: 1rem;
        top: 1rem;
        cursor: pointer;
        transition: all 0.7s ease-out;
        &__line {
            width: 1.25rem;
            height: 0.15rem;
            margin: 0 0 4px 0;
            background: #{$color-white};
            transition: all 0.5s ease-out;
        }

        &-close {
            // left: 11rem;
            transform: rotate(180deg);
            font-weight: bold;
            .header-menu__line:nth-child(1) {
                width: 1.05rem;
                // transform: rotate(45deg) translate(5px, 5px);
                transform: rotate(45deg) translate(5px, 13px);
            }
            .header-menu__line:nth-child(2) {
                width: 1.5rem;
                transform: translate(-4px, 0px);
                // opacity: 0;
            }
            .header-menu__line:nth-child(3) {
                width: 1.05rem;
                // transform: rotate(-45deg) translate(7px, -6px);
                transform: rotate(-45deg) translate(5px, -13px);
            }
        }
    }
    .left {
        .company-name {
            h2 {
                font-size: 20px;
                font-weight: 400;
                // color: #{$color-primary-light-3};
                color:#646464
            }
        }
    }
    .right {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 100%;
    }

    &-avatar {
        // width: 2.75rem;
        // height: 2.75rem;
        // border-radius: 50%;
        // background: #{$rigo-white-color};
        position: relative;
        padding: 2px;
        .company-logo {
            background: rgb(179, 179, 179) !important;
        }
        &__img {
            width: 100%;
            border-radius: 50%;
        }
        &__badge {
            position: absolute;
            margin-right: 0;
            // top: -4px;
            // right: -8px;
            top: -2px;
            right: 20px;
        }
    }
}
.header-search-icon{
    width: 40px;
    font-size: 20px;
    color:#878787;
    cursor: pointer;
    margin-right: 0px;
    height: calc(100% - 15px);
    display: grid;
    place-items: center;
    transition: all .2s;
    border-radius: 4px;
    &:hover{
        background-color: #efefef;
    }
}
// .header-search__input {
//     width: 300px;
//     height: 34px;
//     margin-right: 40px;
//     input {
//         width: 100%;
//         height: 100%;
//         padding: 10px;
//         border-radius: 2px;
//         outline: none;
//         border: 1px solid #cfcfcf;
//         font-size: 14px;
//         color: #3a3a3a;
//     }
// }

//dropdown
.header {
    &-down{
        &-caret{
            font-size: 23px;
            margin-left: 5px;
            color: #e3e3e3;
            line-height: 0;
        }
    }
    &-drop {
        height: 100%;
        display: grid;
        place-items: center;
        position: relative;
        .header-avatar__items{
            display: flex;
            flex-direction: row;
            transition: all .2s;
            height: calc(100% - 10px);
            padding: 5px 10px;;
            align-items: center;
            border-radius: 4px;
            &:hover{
                cursor: pointer;
                background-color: #efefef;
                .header-down-caret{
                    color: #aaa;
                }
            }
        }
        &.active {
            .header-drop__content {
                opacity: 1;
                visibility: visible;
                transform: translateY(0px);
            }
        }
        &__image{
            width: 70px;
            margin: 0px auto;
        }
        &__change{
            margin-top: 8px;
        }
        &__name{
            width: 100%;
            text-align: center;
            line-height: 1.5;
            margin: 10px 0;

            h2{
                color: #{$color-blue};
                font-size: 15px;
                font-weight: 500;
                // margin: 8px 0;
            }
            p{
                font-size: 13px;
                color:#666;
                font-weight: 400;
                // margin: 5px 0;
            }
        }
        &__content {
            @include dropDownContent;
            top: 48px;
            right: -0px;
            width: 210px;
            // width: 250px;
            padding: 10px 15px;
            transition: all .2s linear;
            transform: translateY(15px);
            &::after{
                display: none;
                top: -13px;
                width: 25px;
                height: 25px;
                left: 83%;
            }
        }
        &-list {
            list-style: none;
            width: 100%;
            &__item {
                width: 100%;
                padding: 10px;
                line-height: 1.5;
                cursor: pointer;
                display: flex;
                z-index: 11;
                align-items: center;

                &.with-hr {
                    border-top: 1px dashed #bfbfbf;
                }
                &:hover {
                    background-color: lighten($color-primary-light-3, 10);
                }
            }
        }
        &-icon {
            padding-right: 15px;
            line-height: 1;
            svg {
                font-size: 16px;
                color: #646464;
            }

            .drop-icon-img{
                width: 16px;
                height: 16px;

            }
        }
        &-name {
            font-size: 13px;
            color: #3c3e3e;
        }
    }
}
