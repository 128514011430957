@import '../../../scss/abstracts/variables';
@import '../../../scss/abstracts/mixins';

.travel{
    &-settings{
        width: 100%;

        &-header{
            padding: 10px 20px;
            margin-top: 10px;
            h2{
                font-size: 14px;
                color:#333;
                font-weight: 500;
            }
        }

        &-container{
            width: 100%;
            padding: 10px 20px;
        }
        &-footer{
            height: 55px;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            background: #f7f7f7;
    
            button{
                margin: 0;
            }
        }
    }
    &-check-group{
        padding: 10px 0;
        width: 150px;

        label{
            font-size: 14px;
        }
    }
    &-radio-group{
        display: flex;
        flex-direction: row;
        padding:5px 30px;
        .form__radio-group,.genericForm-group{
            width: 80px;
        }
    }

    &-form-group{
        padding: 10px 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        &.no-label{
            .genericForm-group{
                margin-left: 0;
                label{
                    display: none;
                }
            }
        }
        input[type="text"]{
            &:disabled{
                background-color: #{$color-yellow-fade};
            }
        }
        h4{
            font-size: 14px;
            font-weight: 500;
            color:#2c2d2e;
        }
        button{
            max-width: 150px;
            font-size: 14px;
            height: 35px;
            margin: 20px 0;
        }
        .genericForm-group{
            max-width: 300px;
            display: flex;
            align-items: center;
            margin-left: 30px;
            margin-bottom: 15px;
            &__label{
                margin-bottom: unset;
                label{
                    width: 150px;
                    font-size: 14px;
                    display: block;
                    margin-bottom: 0;
                }
            }
            
            // .genericForm-group__outer{
            //     width: 150px;
            //     input{
            //         height: 35px;
            //     }
            // }
            input{
                height: 35px;
                width: 150px;
            }
        }
    }
}