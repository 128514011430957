.payroll{
    .displayingMonths {
        cursor: pointer;
    }
    .filters{
        background: unset;
        border:unset;
        padding:unset;
        align-items: center;
        &-item{
            margin-right: 12px;
            
            
        }
        &-link{
            display: flex;
            margin:.5rem 5rem;
            font-size: 13px;

            @include respond(med-desktop){
                font-size: 15px;
            }
            &__label{
                color:#444;
                font-weight: 400;
                margin-right: 3rem;
            }
            &__item{
                color:#{$color-blue};
                text-decoration: none;
                margin-right: 1.25rem;

            }
        }
        &-input{
            display: flex;
            flex-direction: row;
            // align-items: center;
            align-items: flex-end;;
            flex-wrap: wrap;

            &__item{
                margin-bottom: 10px;

                .MuiSkeleton-root{
                    margin-right: 10px;
                }
                .MuiSkeleton-text{
                    margin-bottom: .35rem !important;;
                    border-radius: unset !important;
                    margin-top: 0 !important;
                }
                &.employees{
                    .makeStyles-root-11{
                        margin-top:1.2rem;
                    }
                    .css-1g48xl4-IndicatorsContainer{
                        display: none;
                    }
                    .makeStyles-input-12 {
                        border-radius: 0px;
                    }
                }
                .btn-submit{
                    background-color: #{$color-blue};
                    color: #{$rigo-white-color};
                    padding: 10px 30px;
                    text-transform: capitalize;
                    border: none;
                    outline: none;
                    text-decoration: none;
                    transition: all .2s;
                    position: relative;
                    font-size: .9rem;
                    font-weight: 400;
                    cursor: pointer;

                    &:hover{
                        background: lighten($color-blue,7);
                    }
                }
            }
        }
    }
    &-grid{
        background: #{$rigo-white-color};
        overflow: auto;
        max-height: 600px;
    }
    &-row{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: border-box;
        flex: auto;
        margin-top: 1rem;
    }
    .sideHover-collapsed{
        position: relative;
        flex: 0 0 50px;
        max-width: 50px;
        min-width: 50px;
        width: 50px;
        
        &:before{
            content:'';
            position: fixed;
            width: 100vw;
            height: 100vh;
            left:0;
            top:0;
            background-color: rgba(0,0,0,0.4);
            display: none;
            z-index: 10;
        }
        &.after{
            &:before{
                display: block;
            }
        }
        &:hover{

            .sidetab-collapse{
                
                flex: 0 0 16rem;
                max-width: 16rem;
                min-width: 16rem;
                width: 16rem;
                position: absolute;
    
                @include respond(med-desktop){
                    flex: 0 0 17rem;
                    max-width: 17rem;
                    min-width: 17rem;
                    width: 17rem;
                }
                @include respond(big-desktop){
                    flex: 0 0 18rem;
                    max-width: 18rem;
                    min-width: 18rem;
                    width: 18rem;
                }
    
                .sidetab-contain{
                    // width: 100%;
    
                    .sidetab-content{
                        width: 100%;
                        opacity:1;
                        visibility: visible;
                        padding:10px;
    
                        .sidetab-title{
                            display: block !important;
                        }
    
                        
                    }
                }
            }

        }
        
    }
    &-sidetab{
        flex: 0 0 16rem;
        max-width: 16rem;
        min-width: 16rem;
        width: 16rem;
        transition: all .3s;
        position: relative;
        z-index: 5;

        &.absolute{
            position: absolute;
        }

        @include respond(med-desktop){
            flex: 0 0 17rem;
            max-width: 17rem;
            min-width: 17rem;
            width: 17rem;
        }
        @include respond(big-desktop){
            flex: 0 0 18rem;
            max-width: 18rem;
            min-width: 18rem;
            width: 18rem;
        }
        &.sidetab-collapse{
            flex: 0 0 50px;
            max-width: 50px;
            min-width: 50px;
            width: 50px;
            
            z-index: 15;
            @include respond(med-desktop){
                flex: 0 0 55px;
                max-width: 55px;
                min-width: 55px;
                width: 55px;
            }

            .sidetab-contain{
                // width: 55px;

                .sidetab-content{
                    padding:0;
                    opacity:0;
                    visibility: hidden;
                    .sidetab-title{
                        display: none;
                    }
                }
            }
        }
    }
    &-body{
        overflow-x: hidden;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex: auto;
        flex: auto;
        background: #f0f2f5;
        min-height: 0;
        padding: 0 25px;
        position: relative;
    }
    &-panel{
        h4{
            color:#{$rigo-white-color};
            font-size: 14px;
            margin-right: 1rem;
            font-weight: 400;

            @include respond(med-desktop){
                font-size: 16px;
            }
            // @include respond(big-desktop){
            //     font-size: 18px;
            // }
        }
        p{
            color:#{$color-blue-fade};
            font-size: 12px;

            @include respond(med-desktop){
                font-size: 14px;
            }
            // @include respond(big-desktop){
            //     font-size: 16px;
            // }

        }
    }
}
.skeleton-table{
    .MuiSkeleton-root{
        border-bottom: 1px solid #dedede;
    }
}


.tax-row{
    td{
        padding:7px 14px !important;
    }
}
.income{
    &-table{
        .e-headercell{
            padding:15px 10px;
        }
    }
}
.leave{
    &-row{
        border-bottom: 1px solid #c4c4c4;

        // &.bordered{
        //     td{
        //         &:first-child{
        //             border-left: 1px solid rgba(228, 228, 228, 0.35) !important;
        //         }
        //         border-bottom: 1px solid #dedede;
        //         border-right: 1px solid rgba(228, 228, 228, 0.35);
        //     }
            
        // }
        &:hover{
            background-color:#f4f4f4;
        }
        &.active{
            background: #dcface;
        }
        &.active-blue{
            // background: #{$color-blue-fade};
            background: #e8f3ff;
        }
        &.active-row{
            // background: #{$color-blue-fade};
            // background: #{$rigo-white-color}3c4;
            box-shadow: 1px 3px 7px rgba(0,0,0,0.2);
            background: #f4f4f4;
        }
        &.adjusted{
            background:#{$color-primary-light-4};
        }
        td{
            padding:15px;
            font-size: 14px;
            color: #5f5f5f;
            overflow: hidden;
            border-bottom: 1px solid #dedede;
    
            a{
                color:#{$color-blue};
                cursor: pointer;
                text-decoration: none;;
            }

            &.leave-status{
                font-size: 14px;
                color:#646464;
                // display: flex;
                // align-items: center;

                &.success{
                    color:#{$color-green} !important;
                }
                &.danger{
                    color:#{$color-red} !important;
                }

                .leave-icon{
                    margin-right: 8px;
                    font-size: 16px;;
                    display: inline-block;
                }
                .leave-icon + span{
                    display: inline-block;
                    vertical-align: top;
                }
            }
    
        }
    }
    &-table{
        // .e-grid {
        //     border-color: transparent !important;
        // }
        // .e-grid.e-default {
        //     background-color: transparent !important;
        // }
        // .e-grid .e-gridcontent {
        //     background-color: transparent !important;
        // }
        // .e-grid .e-content {
        //     background-color: transparent !important;
        // }
        .e-grid .e-table {
            // background-color: transparent !important;

            thead{
                display: none;
            }
        }
    }
}



.arrear{
    &-btns{
        .btn{
            height: 3rem;
            margin-right: 1rem;
            padding:.5rem 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            div{
                font-size: .9rem;
                font-weight:400;
            }
            span{
                font-size: .75rem;
                color:#f7f7f7;
            }
            svg{
                font-size: 1.5rem;
            }
        }
    }
    &-block{
        width:100%;
        margin:.75rem 0;
        .badges-group{
            .badges{
                margin-right: 1rem;
            }
        }
    }
    &-title{
        font-size: 1.1rem;
        color:#{$color-blue};
        font-weight: 400;
        margin-bottom: 1rem;
        span{
            font-weight: 600;
        }
    }
    &-table{
        .e-grid{
            border:none !important;
            .e-headercontent{
                border:none !important;
            }
            
            .e-headercell{
                background:#{$color-primary-light-4};
                color: #666;

                .e-headercelldiv{
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            
        }
        
    }
}
.table-input{
    width: 100%;
    border: 1px solid #dcdcdc;
    padding: 3px;
    border-radius: 6px;
    height: 2rem;
    padding:5px 10px;

    &:focus{
        border-radius: 6px;
        outline: 1px solid lighten($color-blue,10);
    }
}


.area{
    width: 100%;

    &-header{
        width: 100%;
        padding: 8px;
        background: #2680eb;
        h3{
            color: #{$rigo-white-color};
            font-size: 1rem;
            font-weight: 400;
        }
    }
    &-body{
        width: 100%;
        background: #{$rigo-white-color};
        
        &__item{
            border-bottom: 1px solid #dfdfdf;
            padding: 8px;
            span{
                font-weight: 400;
                font-size: .9rem;
                color: #3e3e3e;
            }
        }
    }
}

.pickers{
    margin-left: 1rem;
    .MuiInputBase-root{
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding-left:5px;
        color:#444;

        &::before{
            content:unset
        }
        
    }
    .area-body__item span{
        color:#888 !important
    }
}

.income{
    &-tabs{
        margin-top:2rem;
    }
}

.tdDate{
    // @include flexCenter;
    border-right: 1px solid #dfdfdf;
    background: #dcdcdc;
    color: #efefef ;
    text-align: center;
    padding:15px 8px !important;

    &:first-child{
        border-left:1px solid #dfdfdf;
    }

    &.present{
        background: #a7d883;
    }
    &.absent{
        background: #707070;
        color: #{$rigo-white-color} !important;
    }
    &.holiday{
        background: #e4e4e4;
        
    }
    &.sick{
        background: #e6884a;
        color: #{$rigo-white-color} !important;
    }
    &.annual{
        background: #eb26d1;
        color: #{$rigo-white-color} !important;
    }
}
.tool{
    position: relative;
    &-icon{
        font-size:25px;
        color:#444;
    }

}
.tooltip{
    padding:5px;
    // background: rgba(#333,.9);
    display: flex;
    flex-direction: column;
    .name,.date{
        font-size: 12px;
        color:#efefef;
        font-weight: 400;;
    }
}

.archive-check{
    padding-top: 3px;
    padding-right: 10px;
    margin-bottom: 0px;
    color: rgb(255, 255, 255);
    font-size: 14px;

    span{
        margin-left: 5px !important;
    }

    .checkmark{
        background-color: #c1d8ff !important;
        height: 17px;
        border-radius: 2px;
        width: 17px;

        &::after{
            left: 5px;
            top: 2px;
            width: 6px;
            height: 11px;
            border: solid #616161;
            border-width: 0 2px 2px 0;
        }
    }
}

.editable-td{
    position: relative;
}

.editedText{
    width: 100%;
    height: 100%;
    // background-color: #{$color-yellow-fade};
    text-align: center;
    font-size: 14px;
    text-decoration: line-through;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    // filter: saturate(2);s
}

.editedCol{
    border-bottom: 3px solid #2680eb !important;
}