.emp-details {
  display: flex;
  align-items: center;
  .sb-avatar__image {
    border-radius: 50%;
    //border: 0.5px solid #edeaea;
    border: 1px solid #c6c6c6;
  }
  .desg-depart {
    padding-left: 1rem;
    .emp-name {
      font-size: 1.2rem;
      font-weight: 500;
      //color: #484747;
      color: #2266e3;
    }
    .emp-name,
    .desg,
    .depart {
      display: block;
      //padding: 0.1rem;
    }
    .desg {
      font-weight: 500;
      color: #484847;
    }
    .depart {
      font-size: 0.8rem;
      font-weight: 500;
      color: #656565;
    }
  }
  // .emp-image {
  //   margin-right: 0 !important;
  // }
}
