@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

$PlaceholderBackgroundColor: #f8f8f8;
$PlaceholderColor: rgba(0, 0, 0, 0.54);
$Black: #444;

.coming-soon {
  width: 100%;
  &__img {
    width: 450px;
    height: 355px;
    margin: 30px auto;
    img {
      width: 100%;
    }
  }
}

.no-data {
  font-size: 14px;
  color: #aaa;
  margin-top: 10px;
}

.payroll {
  &-container {
    padding: 20px;
  }
  &-route {
    &-title {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
      .btn-back {
        background: transparent;
        color: #{$rigo-primary-color};
        border: 2px solid #{$rigo-primary-color};
        border-radius: 3px;
        padding: 2px;
        font-size: 17px;
      }
      .left-arrow {
        font-size: 2rem;
        color: $blue-background;
      }
      .right-arrow {
        color: $blue-background;
      }
      span {
        padding: 0 0.5rem;
      }
    }
  }
}

.payslip {
  &-title_bar {
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      flex: 1;
      flex-direction: row;
      padding: 10px 10px 5px 20px;

      .fs {
        &-bo {
          font-weight: 500;
        }
        &-li {
          font-weight: 300;
        }
        &-bg {
          font-size: larger;
        }
        &-sm {
          font-size: small;
        }
      }

      .description {
        &-company {
          display: flex;
          flex-direction: column;
        }
        &-employee {
          display: flex;
          flex: 1.5;
          flex-direction: column;

          .name {
            margin-right: 2rem;
          }
        }
        &-slip {
          display: flex;
          flex: 1;
          flex-direction: column;
        }
      }
    }
  }
}

.table {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 10px;
  margin: 10px 0 15px 0;

  &-payslip {
    padding: 10px 20px 10px 20px;
    font-size: 14px;

    .row-heading {
      background-color: #f5f7fa;
      border-right: 1px solid #ccc;
      span {
        color: #444;
        font-weight: 500;
        border-right: unset;
      }
    }

    div:last-child {
      border-bottom: 1px solid #ccc;
    }
  }
  &-row {
    &-column_4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 8px 0;
      text-align: center;
    }
    &-scheme {
      display: grid;
      grid-template-columns: repeat(1, 100px 1fr 1fr 1fr 1fr 1fr 0.5fr);
      padding: 8px 0;
      text-align: center;
    }

    &-payslip {
      display: grid;
      grid-template-columns: repeat(1, 1.5fr 1.5fr 3fr);
      text-align: center;
      font-size: 14px;
      background-color: #fff;
      border-top: 0.5px solid #ccc;
      border-left: 1px solid #ccc;

      span {
        padding: 8px 10px 8px 10px;
        border-right: 1px solid #ccc;
      }
    }

    &-history {
      display: grid;
      grid-template-columns: repeat(1, 40px 1fr 1fr 1fr);
      padding: 8px 0;
      text-align: left;
    }

    &-line_top {
      border-top: 0.5px solid $grey-border;
    }
    &-line_bottom {
      border-bottom: 0.5px solid $grey-border;
    }
    &-link {
      text-decoration: underline;
      color: #{$rigo-primary-color};
      cursor: pointer;
    }
    &-blue {
      color: #{$rigo-primary-color};
    }

    span {
      color: $Black;
    }
  }
}

.yearly-payslip {
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-weight: 500;
  font-size: 14px;
  color: #222;

  .title {
    // color: #494a49;
    font-weight: 400;
    font-size: 14px;
  }

  &-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin: 0 1rem 1rem 0;
    border-radius: 5px;
    // min-width: 160px;

    .month-name {
      color: #374765;
      margin-top: 5px;
    }
    .small-font {
      font-size: 12px;
    }

    .addon-icon {
      position: absolute;
      top: 4px;
      right: 4px;
    }

    &:hover {
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
    }
  }
  .latest {
    background-color: #ecfcd7;
    width: 120px;
    height: 120px;
  }
  .earlier {
    background-color: #f2f6fb;
    width: 100px;
    height: 100px;
  }

  .no_payslip {
    font-size: 12px;
    color: $color-grey;
  }
  // &-modal {
  //   .modal__content-body {
  //     padding: 20px;
  //   }
  // }
}

span {
  &.bold {
    font-weight: 500;
  }
  &.align_left {
    text-align: left !important;
  }
  &.align_right {
    text-align: right !important;
  }
}

.segment {
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 5px 0;
    color: $Black;
  }

  &-full_width {
    width: 100%;
  }

  &-semi_width {
    width: 80%;
  }

  &-scheme,
  &-history {
    padding: 0 20px 20px;
    font-size: 14px;
    &-note {
      color: $Black;
      font-size: small;
    }
  }

  &-benefits {
    margin-top: 30px;
    padding: 0 20px 20px;
  }

  &-payslip {
    padding: 0 20px 20px;
  }
}
