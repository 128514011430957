// // @import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
// // @import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
// // @import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
// // @import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
// // @import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
// // @import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
// // @import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
// // @import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
// // @import "../../../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

// @import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
// @import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

// .ag-theme-alpine {
//   @include ag-theme-alpine((
//     background-color: #fff,
//     header-background-color:#f5f5f8,
//     header-height:40px,
//     header-foreground-color:#0077DA,
//     secondary-foreground-color:#0077DA,
//     odd-row-background-color: #fff,
//     row-group-indent-size:10px,
//     font-size: 14px,
//     // font-weight:500,
//     border-radius: 3px,
//     selected-row-background-color:#ecf4ff,
//   ));
// }

// .ag-header-cell {
//     color: #353F5A;
//     font-size: 13px;
//     font-weight: 400;
// }
// .ag-cell{
//     font-size: 13px;
//     font-weight: 400;
//     color:#303130;
// }
// .ag-theme-alpine .ag-root-wrapper{
//   border: none;
// }
// .ag-theme-alpine .ag-header-cell{
//   padding: 0 16px;
// }
// .ag-theme-alpine .ag-cell{
//   padding: 0 16px;
// }

// .ag-cell{
//   outline: none !important;
//   border: none !important;
// }
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after{

  position: absolute;
  left: 4px !important;
  top: 0px !important;
  width: 6px;
  height: 11px;
  border: solid #2684ff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after,.ag-theme-alpine .ag-checkbox-input-wrapper::after{
  content: '' !important;
}
.ag-theme-alpine .ag-checkbox .ag-input-wrapper{
  background: #f1f1f1;
  border: 1px solid #cec9c9;
}
