.modal-compose-email {
  //#region dashboard-compose-email
  .modal__header {
    border-bottom: 1px solid #e0e0e0 !important;
    height: 55px;
  }
  .modal__content {
    background: #{$rigo-white-color};
    .modal__header {
      border: 0;
    }
    .compose-email {
      label {
        @include label;
        min-width: 60px;
        margin-bottom: unset;
      }

      .email {
        &-reject-reason {
          padding: 10px 10px 10px 10px;
          .reason {
            padding: 10px;
            margin-bottom: 10px;
            background-color: #ffe7e7;
            color: #d60606;
          }
          .info {
            padding: 10px 0px;
            span {
              margin-right: 15px;
            }
            &-action {
              color: #0e86ff;
              cursor: pointer;
            }
          }
        }
        &-to__name {
          padding: 10px;
          background: #eee;
          margin-right: 10px;
          border-radius: 20px;
          cursor: pointer;
        }
        &-section {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px 5px 10px;
          border-bottom: 1px solid #eee;

          .genericForm-group {
            margin-right: unset;
          }
          .ck.ck-editor {
            width: 100% !important;
          }
          button {
            height: 32px;
          }
        }

        &-from {
          background-color: #eee;
        }
      }
      // .compose-email-form {
      //   .form-row {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     //padding: 1rem 0;
      //     > div {
      //       // padding: 1.5rem;
      //       padding: 10px 15px;
      //     }
      //     .subject {
      //       width: 100%;
      //       border: 0;
      //       border-bottom: 1px solid #e4e4e4;
      //       // padding: 1.5rem;
      //       padding: 15px 15px;
      //       outline: none;
      //     }
      //     .body {
      //       height: 150px;
      //       border: 0;
      //       width: 100%;
      //       border-bottom: 1px solid #e4e4e4;
      //       padding: 1.5rem;
      //       resize: none;
      //     }
      //     .col-1 {
      //       width: 10%;
      //     }
      //     .col-2 {
      //       width: 40%;
      //     }
      //     .col-3 {
      //       width: 50%;
      //       display: flex;
      //       justify-content: flex-end;
      //     }
      //     .ck-editor {
      //       display: flex;
      //       flex-direction: column-reverse;
      //       width: 100%;
      //       padding: 0;
      //       &__main {
      //         height: 150px;
      //         .ck-editor__editable {
      //           height: 150px;
      //           border: none;
      //         }
      //       }
      //       &__top {
      //         border-bottom: 1px solid #e4e4e4;
      //         .ck-toolbar {
      //           // padding: 1rem;
      //           padding: 5px 20px;
      //         }
      //       }
      //     }
      //   }
      //   .email-from {
      //     background: #e5e9ed;
      //   }
      //   .email-to {
      //     border-bottom: 1px solid #e4e4e4;
      //     &__name {
      //       background: #e5e9ed;
      //       padding: 0.5rem 1rem;
      //       border-radius: 3px;
      //     }
      //   }
      //   .footer-row {
      //     display: flex;
      //     justify-content: flex-end;
      //     align-items: center;
      //     padding: 1rem;
      //     .btn-schedule {
      //       padding: 0.5rem 1rem;
      //     }
      //   }
      // }
    }
  }
  //#endregion
}
