@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/variables';

.disciplinary-stages{
    width: 100%;
    padding: 20px;

    &-container{
        width: calc(100% - 40px);
        margin-top: 20px;

    }
    &-list{
        list-style: none;
        display: flex;
        flex-direction: column;
        max-width: 500px;
    }

    &-item{
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .stage{
        &-block{
            border-radius: 3px;
            display: flex;
            flex-direction: row;
            padding:10px 15px;
            background-color: #{$color-blue};
            color: #{$rigo-white-color};
            font-size: 14px;
            justify-content: space-between;
        }
        &-input{
            flex-grow: 1;
            padding-right: 10px;
            input{
                width: 100%;
                background: transparent;
                border: none;
                color: #{$rigo-white-color};
                padding-bottom: 6px;
                border-bottom: 1px solid #{$rigo-white-color};
                outline: none;
            }

            &-block{
                margin-top: 8px;
                background: #{$rigo-white-color};
                // padding: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-radius: 2px;
                box-shadow: 0 1px 3px rgba(0,0,0,0.12);

                input{
                    flex-grow: 1;
                    border: none;
                    outline: none;
                    padding: 7px;
                    font-size: 14px;
                    color: #333;
                    padding: 9px 20px;
                    background: #c4deff;
                    margin: 0 2px;
                }
            }
            &-icon{
                padding: 8px 15px;
                font-size: 20px;
                color: #9f9f9f;
                cursor: pointer;
                height: 100%;
                line-height: 1;
                &-add{
                    &:hover{
                        color: #{$rigo-success-color};
                    }
                }
                &-remove{
                    border-right: 1px solid #dfdfdf;
                    &:hover{
                        color:#{$color-red};
                    }
                }

                
            }
        }
        &-edit{
            .edit-icon,.remove-icon{
                padding: 2px;
                background: #{$rigo-white-color};
                color: #2680eb;
                border-radius: 2px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 17px;
            }
        }
        &-button{
            button{
                margin: 10px 0;
                padding: 0 15px;
                font-size: 14px;
                svg,i{
                    color: #0065ff;
                }
            }
        }
        &-subStages{
            display: flex;
            flex-direction: column;
            width: 100%;
            &__item{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background: #{$rigo-white-color};
                padding: 10px 16px;
                font-size: 14px;
                color: #787878;
                margin-top: 5px;
                font-weight: 500;

                
            }

        }
    }
}


.subStage{
    &-icons{
        display: flex;
        flex-direction: row;
        font-size: 18px;
    }
    &-icon{
        margin-left: 10px;
        cursor: pointer;
        font-size: 18px;
        &:nth-child(2){
            &:hover{
                color:#{$color-red}
            }
        }
        &:nth-child(1){
            &:hover{
                color:#{$color-blue}
            }
        }
    }

    &-input{
        flex-grow: 1;
        input{
            width: 100%;
            border: none;
            outline: none;
            padding: 4px;
            background: #{$rigo-white-color};
        }
    }
}