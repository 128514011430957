@import '../../../../scss/abstracts/variables';

.email-icon{
    #top{
      transform-box: fill-box;
      transition: all .3s;
    }
  
    &:hover{
      #top{
        transform: scaleY(-1);
      }
    }
  }
  
  .trash-icon{
    cursor: pointer;
    svg{
      transition: all .3s;
      #cap,#capped #Vector_9,#capped #Vector_10,#Vector_8,#Vector_7,#Vector_6,#Vector_5,#Vector_4,#Vector_3,#Vector_2,#Vector_1{
        transition: all .3s;
      }
    }
    &:hover{
      #cap{
        transform: translateX(-31px) rotate(-10deg);
      }
      #bottom{
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      }
      path{
        fill:lighten($color-red,10);
      }
      #lines{
        path{
          fill:#ff9898;;
        }
        
      }
      // #capped #Vector_9{
      //   fill:#ff9898;
      // }
      // #capped {
      //   fill:#{$color-red}
      // }
      // #Vector,#Vector_7,#Vector_9{
      //   fill:#ff9898;
      // }
      // #Vector_2,#Vector_10{
      //   fill:#{$color-red}
      // }
      // #Vector_4,#Vector_5,#Vector_3{
      //   fill:darken(#ff9898,10);
      // }
      // #Vector_6,#Vector_8{
      //   fill:darken($color-red,10);
      // }
    }
  }


.email-open-icon{
    #mail{
        transition: all .3s;
    }

    &:hover{
        #mail{
            transform: translateY(-22%);
        }
    }
}


#tri{
  // stroke-dasharray: 422;
  // stroke-dashoffset: 422;
  // animation: line-anim 2s ease forwards 3s;
}