.section {
    &-login {
        background-color: #{$rigo-white-color};
        width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        position: relative;
        display: grid;
        grid-template-columns: 33% 67%;
    }
}
.login {
    &-left {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    &-right {
        width: 100%;
        position: relative;
    }
    &-message{
        padding: 10px;
        background: #{$rigo-success-color};
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #{$rigo-white-color};
        &__text{  
            font-size: 14px;
        }
        &__close{
            cursor: pointer;
            font-size: 16px;

            &:hover{
                color: #f1f1f1;
            }
        }
    }
    &-company {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        &__logo{
            position: absolute;
            top: 75px;
            left: 50%;
            transform: translateX(-50%);
            // width: 80px;
            // height: 80px;
            width: auto;
            height: auto;
            max-height: 100px;
            max-width: 250px;
            z-index: 12;
            img{
                max-height: 100px;
                max-width: 250px;
                zoom: unset !important;
            }
        }
        &__image {
            width: 100%;
            height: 100%;
            z-index: 10;
            max-height: 100vh;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &-footer {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        &__logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                width: 30px;
                height: 30px;
            }
            h5 {
                font-weight: 400;
                font-size: 16px;
                color: #666;
            }
        }
    }
    &-container {
        width: 400px;
        padding: 15px;
        margin: 0 auto;
        // padding: 2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        // background-color: #{$color-white};
        // box-shadow: 0 2px 5px rgba(0,0,0,0.2);

        @include respond(med-desktop){
            width: 35%;
        }
        @include respond(big-desktop){
            width: 30%;
        }
        .form-group,.genericForm-group{
            margin: 20px 0 ;
        }
    }
    &-form{
        &__forgot{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .forgot-link{
                cursor: pointer;
                color: #565656;
                font-size: 13px;
                padding: 4px 8px;

                &:first-child{
                    border-right: 1px solid #d8d8d8;
                }
                &:hover{
                    color: #333;
                }
            }
        }
    }
    &-submit {
        &,
        &:link,
        &:visited {
            text-transform: capitalize;
            margin: 20px 0;
            text-decoration: none;
            padding: 12px;
            width: 100%;
            height: 40px;
            // display: block;
            border-radius: 4px;
            transition: all 0.2s;
            position: relative;
            font-size: 18px;
            font-weight: 400;
            background-color: #{$rigo-primary-color};
            color: #fafafa;
            //Change for the <button> element
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:hover{
            background-color:lighten($rigo-primary-color,7)
        }

        &.login-loading{
            font-size:0;
            width:40px;
            height:40px;
            margin: 20px auto;
            border-radius:25px;
            padding:0;
            border:3px solid #{$rigo-primary-color};
            border-bottom:3px solid rgba(255,255,255,0.0);
            border-left:3px solid rgba(255,255,255,0.0);
            background-color:#{$rigo-primary-color} !important;
            // background-color:transparent !important;
            // animation-name: rotateAnimation;
            // -webkit-animation-name: wk-rotateAnimation;
            // animation-duration: 1s;
            // -webkit-animation-duration: 1s;
            // animation-delay: 0.2s;
            // -webkit-animation-delay: 0.2s;
            // animation-iteration-count: infinite;
            // -webkit-animation-iteration-count: infinite;
        }
    }

    &-header {
        width: 100%;
        padding: 1.5rem 0;
    }
    &-heading {
        color: #444;
        font-weight: 400;
        font-size: 18px;
    }
}

.forgot{
    &-div{
        .modal__content{
            width: 500px !important;
            min-height: 13rem !important;
        }
    }
    &-error{
        position: relative;
        &::after{
            content: "" attr(title) "";
            position: absolute;
            bottom: 83%;
            z-index: 11;
            /* left: 102%; */
            background: rgba($color-red, 0.8);
            color: #{$rigo-white-color};
            padding: 6px;
            border-radius: 12rem;
            font-size: 12px;
            transform: translateY(-50%);
            transition:all .2s;
            width: 150px;
            text-align: center;
        }
        input{
            background-color: #{$color-red-fade};
        }
        button{
            background-color: #{$color-red} !important;
        }
    }
    &-container{
        padding: 10px;
        &__text{
            font-size: 13px;
            color: #444;
            line-height: 1.6;
            width: 86%;
            margin: 0 auto;
        }
    }
    &-input{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 2.5rem;
        border-radius: 4px;
        box-shadow: 0 3px 5px rgba(0,0,0,0.23);
        transition:all .2s;
        input{
            padding: 10px;
            flex-grow: 1;
            font-size: 13px;
            color:#444;
            border: none;
            outline: none;
            height: 100%;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            transition:all .2s;
        }
        button{
            border-radius: unset;
            height: 100%;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            transition:all .2s;
        }
    }
    &-form{
        width: 85%;
        margin: 2rem auto 1rem;

        button{
            margin: .5rem 0;
            float: right;
            padding: .6rem 1.5rem !important;
        }

    }
}




//upload icons

.login-upload{
    position: fixed;
    bottom:20px;
    right: 20px;
    display: flex;
    flex-direction: column;
}

// .upload{
//     &-button{
//         display: block;
//         margin-bottom: 20px;
//     }
//     &-btn{
//         &-wrapper{
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: flex-end;

//             label{
//                 color:rgba(0,0,0,0.65);
//                 font-size: 15px;
//                 font-weight: 500;
//                 margin-right: 15px;
//             }
//             input{
//                 font-size: 50px;
//             }
//             button{
//                 border-radius: 100%;
//                 width: 50px;
//                 height: 50px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 padding: 0;

//                 svg,i{
//                     font-size: 25px;
//                 }
//             }
//         }
//     }
// }



.login-overlay{
    display: block;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 100%;
    height: 100%;
    background-color: #efefef;
    // position: relative;
    z-index: 111111;
    text-align: center;

    &-container{
        @include absCenter;
        width: 200px;
        height: 150px;
        // border:1px solid #e6e6e6;
        // box-shadow: 0 3px 6px rgba(0,0,0,0.23);
        display: flex;
        flex-direction: column;
        // background: #{$rigo-white-color};
        background-color: transparent;
        border-radius: 4px;

        header{
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #c3c3c3;
            padding: 10px;

            .login-overlay__img{
                width: 100%;
                text-align: center;
                img{
                    width: 50px;
                    height: 50px;
                }
            }

            h2{
                color:#545454;
                font-size: 16px;
                font-weight: 500;

            }
        }
        main{
            flex-grow: 1;
            padding: 10px;
            p{
                color:#636363;
                margin-bottom: 4px;
                font-weight: 500;
                font-size: 15px;
            }
            h2{
                color: #6d6d6d;
                font-size: 18px;
                font-weight: 500;
            }
        }
        
    }
    &__loading{
        position: relative;
        padding: 30px 0;
        .spinner {animation: loadingI 2s linear infinite;}
        .bubble-1, .bubble-2 {animation: bounce 2s ease-in-out infinite;}
        .bubble-2 {animation-delay: -1.0s;}
    }
}

.spinner {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
}


.bubble-1,
.bubble-2 {
    position: absolute;
    top: 0;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #4b9cdb;
}

.bubble-2 {
    top: auto;
    bottom: 0;
}

@keyframes loadingI {
    100% {transform: rotate(360deg);}
}

@keyframes bounce  {
    0%, 100% {transform: scale(0.0);}
    50% {transform: scale(1.0);}
}