@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.activity-report-modal {
  .modal__content {
    width: 60%;
  }
}

 .taskwise-block {
    padding: 20px 20px 20px 20px;
    box-shadow: 0 0px 1.6px grey;    
    margin-bottom: 1.5rem;
    background-color: #{$rigo-white-color};
    border-radius:5px;
    elevation:10px;
  }

.create {
 padding:0 20px 0 20px;

  &-content {
    display: flex;
    flex-direction: column;
    margin: 0 0 15px 0;

    &-activity_date {
      display: inline-flex;
      align-items: center;
      margin-top:20px;
    }
    &-target_type {
      display: flex;
      flex-direction: row;
      // margin-left:20px;
      &-radio {
        display: flex;
        align-items: center;
        width: 400px;
        .form__radio-group {
          width: 180px;
        }
      }
      &-dropdown {
        width: 250px;
      }
    }

    // .autoselect {
    //   .MuiFormControl-root {
    //     .MuiInputBase-root {
    //       margin-top: 1.2rem;
    //     }
    //   }
    // }

    // .react-date-picker__wrapper {
    //   height: 35px;
    //   color: #444;

    //   input:disabled {
    //     background: unset;
    //   }

    //   button:disabled,
    //   button[disabled] {
    //     border: unset;
    //     background-color: unset;
    //   }
    // }

    label {
      color: rgba(0, 0, 0, 0.7);
      font-size: 0.85rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
    }

    &-clientProject {
      .error__message {
        margin-left: unset;
      }
      // label {
      //   color: rgba(0, 0, 0, 0.7);
      //   font-size: 0.85rem;
      //   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      //   font-weight: 400;
      // }
      .MuiFormControl-root {
        width: 570px;
      }
      .MuiInputBase-input {
        margin-top: 0.3rem;
      }
      .MuiPaper-root {
        width: 570px;
      }
    }
    &-work {
      display: grid;
      grid-template-columns: 50% 19% 19% 27% 6%;
      max-width: 68.5%;
      .MuiFormControl-fullWidth {
        width: 300px;
      }
      .MuiPaper-root {
        width: 300px;
      }
      &-now {
        margin-top: 0.35rem;
        .css-yk16xz-control {
          height: auto;
        }
      }
      &-time_field {
        display: block;
        margin-left: 10px;

        input {
          margin-top: 0.35rem;
          width: 100% !important;
          height: 35px;
          padding: 0 10px;
          border: thin solid $color-grey-light;
          border-radius: 4px;
          color: #444;
          &:focus {
            outline: none;
            border: none;
            border: thin solid $rigo-primary-color;
            box-shadow: 0 0 0 0.2px #{$btn-primary-color},
              0 0 0 1.5px rgba($btn-primary-color, 0.4);
          }
        }

        .error__message {
          margin-left: unset;
        }
      }
      .hasError {
        input {
          border: thin solid $color-red;
        }
      }
      .error_tooltip {
        position: relative;
        display: inline-block;
        // border-bottom: 1px dotted black;
      }
      .error_tooltip .tooltiptext {
        visibility: hidden;
        width: 150px;
        background-color: #f1f1f1;
        color: red;
        text-align: center;
        border-radius: 5px;
        font-size: small;
        padding: 5px;

        /* Position the tooltip */
        position: absolute;
        left: 80;
        z-index: 1;
      }

      .error_tooltip:hover .tooltiptext {
        visibility: visible;
      }

      &-remove {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        font-size: 20px;
        color: $color-red;
      }
    }
    .time-period {
      cursor: pointer;
      margin-left: 20px;
      text-decoration: underline;
      color: $color-blue;
      margin-top: 20px;
    }

    .react-date-picker {
      width: 250px;
      margin-top: 0.35rem;
      &__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        border: thin solid #ccc0c0;
        border-radius: 4px;
        width: 200px;
        padding: 2px;
      }
    }
  }
  &-btn-more {
    margin: 10px 0 15px 0;
  }

  .animated-form__footer {
    background-color: #e9eff8;
    justify-content: flex-end;
  }
}

.flex {
  display: flex !important;
}
