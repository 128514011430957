$emp-name-color: #006ee0;
.request-details {
  background: #{$rigo-white-color};
  font-size: 0.85rem;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #d2d2d2;
  margin-bottom: 1.5rem;
  color: #464544;
  @include containerWidth;
  // @include respond(desktop) {
  //   width: 100%;
  // }
  // @include respond(med-desktop) {
  //   width: 80%;
  // }
  // @include respond(big-desktop) {
  //   width: 70%;
  // }
  &__header,
  &__body,
  &__footer {
    padding: 1rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid #e4e4e4;
    padding: 0.7rem 1.5rem;
    .emp-details {
      .emp-image {
        margin-right: 0.75rem;
      }
      .emp-name-desg {
        display: grid;
        grid-row-gap: 0.2rem;
        .emp-name {
          color: $emp-name-color;
          font-size: 1rem;
        }
      }
      .desg-depart {
        padding: 0;
        .emp-name {
          font-size: 1rem;
          font-weight: 400;
          color: #2266e3;
        }
        .desg {
          font-weight: 400;
        }
        .depart {
          display: none;
        }
      }
    }
    .comment {
      &__icon {
        font-size: 1.3rem;
        .first-line {
          width: 10px;
          height: 3px;
          background: #000000;
        }
        .second-line {
          width: 5px;
          height: 3px;
          background: #000000;
        }
      }
    }
    .requested-on {
      color: #a2a0a0;
      font-style: italic;
    }
    .review-type,
    .review-days {
      //font-size: 1rem;
      font-weight: 500;
    }
  }
  &__body {
    padding:.7rem 1.5rem;
    .body-row {
      //padding: 1rem;
      padding: 0 1rem;
      margin-left: 2.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .body-col {
        .reason {
          padding-bottom: 1rem;
        }
        .dest {
          &__row {
            &:nth-child(1) {
              padding-bottom: 0.5rem;
            }
            padding: 0.2rem 0;
            display: flex;
            align-items: center;
            .travel-people {
              display: flex;
              align-items: center;
              padding-left: 1rem;
              color: $emp-name-color;
              svg {
                margin-right: 0.5rem;
              }
            }
            .hotel-name {
              padding-left: 1rem;
            }
            .divider {
              padding: 0 0.5rem;
            }
            .district {
              padding-right: 0.5rem;
            }
          }
        }
        .amount-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 180px;
          padding: 0.2rem;
        }
      }
      .substitute {
        &__name {
          color: #006ee0;
        }
        &__type {
          color: #8c8888;
        }
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    background: #f5f7fa;
    justify-content: space-between;
    padding: 0.7rem 1rem;
    border-radius: 0 0px 10px 10px;
    .approver {
      flex: 1;
      justify-content: flex-end;
      //width: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      flex-shrink: 0;
      //width: 100vw;
      &__details {
        padding-right: 0.5rem;
        .emp-img {
          margin-right: 0.5rem;
        }
        .approved,
        .approved-icon {
          color: #63c943;
        }
        .rejected-icon,
        .cancelled-icon,
        .approved-icon {
          font-size: 1rem;
          margin-right: 0.2rem;
        }
        .arrow-right {
          margin-left: 0.5rem;
          font-size: 1.2rem;
          color:#cccc;
        }
      }
      .current-approver {
        color: #0a5fde;
      }
      .rejected,
      .cancelled {
        color: #e20000;
      }
      &__more {
        position: relative;
        cursor: pointer;
        padding: 0 1rem;
        &__list {
          display: none;
          top: 30;
          position: absolute;
          background: #{$rigo-white-color};
          right: 0;
          width: 225px;
          padding: 0.5rem 1rem;
          border-radius: 3px;
          box-shadow: 0px 0px 5px #dddcdc;
          .approver-forward-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 1rem;
          }
          &:before {
            bottom: 100%;
            right: 10;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:before {
            border-color: rgba(194, 225, 245, 0);
            border-bottom-color: #{$rigo-white-color};
            border-width: 10px;
          }
        }
        .arrow_box {
          position: relative;
          background: #88b7d5;
          border: 4px solid #c2e1f5;
        }
      }
      .active {
        .approver__more__list {
          display: grid;
        }
      }
    }
    .cancel {
      align-items: center;
      color: #f58484;
      display: flex;
      svg {
        font-size: 1.5rem;
      }
    }
  }
}

.layout-employee{
  .travel-header{
    @include containerWidth;
  }
  .travel__filter-body{
    @include containerWidth;
  }
}