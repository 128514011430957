@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.tabs-content {
  .form-row {
    align-items: unset;
  }
}
.tabs {
  &-content {
    padding: 10px 16px !important;
  }
}

.new_project {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  &-column {
    .error__message {
      margin-left: unset;
    }

    width: 80%;
    display: block;
    margin-right: 20px;
    &-section {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      .daterangepicker__range {
        left: 100% !important;
      }

      .add-document {
        display: flex;
        align-items: center;
      }
      .saved-document__count {
        color: $color-blue;
        font-style: italic;
        margin-left: 2.5rem;
        cursor: pointer;
      }
    }
  }
}

.fees {
  display: block;

  // .genericForm-group__input {
  //   width: unset;
  // }
  .genericForm-group__label {
    min-width: 140px;
  }
  &-row {
    width: 50%;
    margin: 0 0 15px 0;
    display: flex !important;
    align-items: flex-start;

    // .error__message {
    //   margin-left: unset;
    // }
    // .typeBadges {
    //   cursor: pointer;
    //   display: flex;
    //   margin: 0.35rem 0;
    // }
  }

  // &-marginless_row {
  //   display: flex;
  //   .hour-rate__label {
  //     padding: 18px 10px 10px 0px;
  //     margin-right: 62px;
  //     width: 161px;
  //   }
  // }
}

.team_contribution {
  display: block;
  &-row {
    display: flex;
    align-items: flex-end;
    width: 85%;
    margin: 0 0 15px 0;

    &-avatar {
      margin: 15px 20px 0 7px;
    }
    &-trash {
      padding: 10px 0;
      margin: 22px 0px 0 0;
      cursor: pointer;
      svg {
        color: #d4cdcd !important;
      }
    }
  }
}

.counter_parties {
  &__title {
    label {
      display: inline-block;
      min-width: 300px;
    }
  }
  display: block;
  &-row {
    display: flex;
    margin: 0 0 15px 0;
  }
  &-trash {
    padding: 10px 0;
    cursor: pointer;
    svg {
      color: #d4cdcd !important;
    }
  }
}
//Approval page

.approval-label {
  margin-left: 1.2rem;
  @include label;
}
.approval {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &_index {
    margin-right: 10px;
  }
}
.contract_files {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}

.contract_files:hover .tooltiptext {
  visibility: visible;
}
.contract_files .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #f1f1f1;
  border-radius: 5px;
  font-size: small;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  left: 8px;
  // top: -110px;
  z-index: 1;
}
.nobull {
  list-style-type: none;
}

//contract
.contract {
  &-block {
    display: grid;
    grid-template-columns: repeat(1, 0.5fr 0.5fr 0.5fr 0.5fr 1fr);
    padding: 10px 10px 10px 0;
    &-title {
      border-bottom: 1px solid #c1bcbc;
      // span {
      //   &:nth-child(1) {
      //     display: flex;
      //     svg {
      //       margin-left: 10px !important;
      //       color: #de3232;
      //     }
      //   }
      // }
    }
    &-body {
      border-bottom: 1px solid #d4c3c369;
      span {
        display: flex;
        svg {
          margin-left: 10px !important;
          color: #de3232;
        }
      }
    }

    .edit_contract {
      display: flex;
      margin-left: auto;
    }
  }
}

.bold_title {
  font-size: 17px;
  font-weight: 550;
  padding: 10px 0 5px 0;
  color: #444;
}

// .legal-calendar {
//   width: 18% !important;
//   &_text {
//     width: 36% !important;
//     margin-left: 141px !important;
//   }
// }

// .legalClientBadges {
//   cursor: pointer;
// }

// .typeLabel {
//   font-size: 17px;
//   font-weight: 550;
// }

// don't use common class as direct parent
//  {
//   padding: 1rem 2rem 1rem 1.25rem !important;
//   margin-right: 30px !important;
// }

// .team {
//   &_title {
//     padding: 26px 17px 15px 0px;
//   }
//   &_select {
//     margin-right: 152px;
//   }
//   &_member {
//     &_select {
//       width: 19% !important;
//     }
//     &_hours {
//       width: 9% !important;
//       margin-left: 40px;
//       margin-top: -10px;
//     }
//     &_trash {
//       padding: 10px 0;
//       svg {
//         color: #d4cdcd !important;
//       }
//     }
//   }
// }
// .sb-avatar--icon {
//   margin-right: 40px;
// }

// .add {
//   padding: 15px 0;
//   margin: -10px 0 0px !important;

//   &-content {
//     margin: 0px !important;

//     &_attachment {
//       margin: 28px 0px 0px 367px !important;
//     }

//     &_contract {
//       margin: 0 0 0 20px !important;
//       width: 120px !important;
//       &-icon {
//         color: #403d3d;
//         font-size: 14px;
//         padding: 0 0 0 18px;
//       }
//     }
//   }
// }

// // counter parties
// .scedule-block-type {
//   .makeStyles-radioGroup-236 {
//     padding: 6px 0px 0 !important;
//   }
// }

// add-content-icon {
//   svg {
//     font-size: 25px !important;
//     color: #1f1c1c !important;
//   }
// }

// .charges_body {
//   margin-top: 10px;
// }

// .mb-10 {
//   margin-bottom: 10px;
// }

// .counterParties_label {
//   width: 50%;
// }

// .animated-form__body .project {
//   &_document_row {
//     display: grid;
//     grid-template-columns: repeat(1, 1fr 1fr);
//   }
//   &_courtHearingDate {
//     width: 36%;
//   }
// }
// .project_document_row .renew-body-section {
//   margin: unset;
// }

// .animated-form__body .tabs-content .team_title {
//   padding: 26px 17px 15px 0px;
//   display: grid;
//   grid-template-columns: repeat(1, 1fr 2fr);
// }
// .tabs-content .team_member_selection {
//   display: grid;
//   grid-template-columns: repeat(1, 1fr 0.2fr 1.1fr 2fr);
// }
