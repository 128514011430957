.course-page{
    display: flex;
    flex-direction: row;
    background-color: #F2F5F7;
    min-height: 100vh;
    &__body{
        flex:1;
        padding: 16px 24px;
    }
    &__bookmark{
        color: #0050B3;
        display: flex;
        padding: 12px 0px;
        align-items: center;
        svg{
            line-height: 0;
            font-size: 22px;
        }
        &-item{
            color: #0050B3;
            display: flex;
            cursor: pointer;
            align-items: center;
            flex-direction: row;
            font-size: 16px;
            
            svg{
                margin-right: 6px;
            }
            
        }
        &-seperator{
            line-height: 0;
            margin: 0 8px;
            font-size: 20px;
            svg{
                color:#595959;
                font-size: 24px
            }
        }
    }
    &__title{
        margin: 18px 0;
        h2{
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
            color:#262626;
            margin:8px 0;
        }
        &-length{
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 16px;
        }
        &-status{
            display: flex;
            align-items: center;
            flex-direction: row;
            color:#8C8C8C;
            font-size: 14px;
            margin-right: 4px;
            gap: 8px;
            svg{
                font-size: 16px;
            }
        }
    }
    &__content{
        margin: 24px 0;

        .course-content{
            &__text{
                &-container{
                    background-color: transparent;
                    box-shadow: unset;
                    padding: 0;
                    // padding: 12px 0;
                    margin-bottom: 16px;
                    font-size: 14px;
                    line-height: 1.76;
                }
            }
            &__images{
                margin-bottom: 16px;
            }
        }
    }
    &__quiz{
        &-title{
            font-size: 22px;
            line-height: 30px;
            font-weight: 600;
            color: #262626;
            margin-bottom: 8px;
        }
        &-body{
            margin: 16px 0 24px;
        }
        &-radio{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border:1px solid #D9D9D9;

        }
        &-check{
            width: 19px;
            height: 19px;
            border-radius: 3px;
            border:1px solid #D9D9D9;
        }
        &-label{
            color: #262626;
            margin: 8px 4px;
            font-size: 16px;
            font-weight: normal;
            line-height: 22px;
        }

        &-list{
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin-bottom: 12px;
        }
        &-item{
            background-color: #fff;
            padding: 12px 24px;
            border-radius: 4px;
            color: #262626;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            &:hover{
                background-color: #f6f6f6;
            }
            &-disabled{
                background-color: #e8e8e8;
                &:hover{
                    background-color: #e8e8e8;
                    cursor:not-allowed;
                }
            }
            &-active{
                background: #e6f0fb;
                .course-page__quiz-radio{
                    border-color: #006DF9;
                    position: relative;

                    &::after{
                        content:'';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: #006DF9;
                    }
                }
                .course-page__quiz-check{
                    border-color: transparent;
                    position: relative;
                    background-color: #389E0D;
                    &::after{
                        content:'';
                        position: absolute;
                        left: 5px;
                        top: 0px;
                        width: 7px;
                        height: 13px;
                        border: solid #fff;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }
            
        }
        &-footer{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px 0;
            button{
                border: none;
                border-radius: 20px;
                padding: 0 32px;
                background-color: #40A9FF;
                color: #fff;
                height: 34px;
                font-size: 15px;
                cursor: pointer;
            }
        }
    }
    &__footer{
        width: 100%;
        display: flex;
        align-items: center;
        background-color: #E6F0FB;
        padding: 12px 16px;
        border-radius: 4px;

        button{
            padding: 0 32px;
            background-color: #006DF9;
            color: #fff;
            border-radius: 5px;
            border:none;
            height: 34px;
            font-size: 15px;
            cursor: pointer;
        }
        span{
            font-size: 14px;
            font-weight: normal;
            margin: 0 16px;
        }
    }
}