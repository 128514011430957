@import "../../../scss/abstracts/mixins";

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$background: #fafafa;
@mixin btn-apply {
  margin: 0.5rem 0 0;
  padding: 1rem 5rem;
  background-color: #82b53f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .left-arrow {
    margin-left: 10px;
    font-size: 1.2rem;
  }
}
@mixin deadline {
  display: flex;
  align-items: center;
  justify-content: center;
  &__header {
    color: #ff5f36;
    font-weight: 500;
  }
  &__value {
    color: #ff4515;
    font-weight: 500;
  }
}
@mixin right-col {
  @include respond(desktop) {
    width: 25vw;
  }
  @include respond(med-desktop) {
    width: 25vw;
  }
  @include respond(big-desktop) {
    width: 20vw;
  }
}
.block {
  -webkit-box-shadow: 0px 0px 8px 1px #d2d2d2;
  -moz-box-shadow: 0px 0px 8px 1px #d2d2d2;
  box-shadow: 0px 0px 8px 1px #d2d2d2;
  border-radius: 5px;
  padding: 2rem;
  margin: 2rem;
  background: $background;
  cursor: pointer;
  &__row {
    padding: 0.5rem;
    span {
      display: block;
      text-align: left;
    }
    button {
      background: #81b43f;
      padding: 0.5rem 2rem;
      border: 0;
      border-radius: 3px;
      color: #fff;
      font-weight: 500;
    }
    .grid {
      display: grid;
      grid-row-gap: 0.5rem;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.career {
  font-family: $font-family;
  &__header {
    padding: 1rem 2rem;
    span {
      font-size: 2rem;
      font-weight: 700;
      &:last-child {
        color: #82b53f;
      }
    }
  }
  &-details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__block {
      @include respond(desktop) {
        width: 400px;
      }
      @include respond(med-desktop) {
        width: 450px;
      }
      @include respond(big-desktop) {
        width: 450px;
      }
      &__header {
        font-size: 1.5rem;
        color: #2580eb;
        font-weight: 500;
      }
      &__position {
        font-weight: 500;
      }
      .remaining-days {
        color: #ff742f;
      }
      .contract-type {
        font-size: 1.4rem;
        color: #81b43f;
        font-weight: 500;
      }
      .grid {
        .apply-btn {
          cursor: pointer;
        }
      }
    }
  }
  &-additional {
    //padding: 2rem;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;
      background: $background;
      .comp-logo {
        padding: 1rem;
        img {
          width: 100px;
          // border-radius: 100vw;
        }
      }
      .header-left-info {
        flex: 1;
        .emp-status {
          color: #6f9419;
          font-size: 1rem;
          font-weight: 600;
        }
        .position {
          color: #0e5ab5;
          font-size: 1.6rem;
          font-weight: 600;
        }
        .publish-date {
          color: #2d2d2d;
          font-weight: 500;
        }
        .all-job {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 130px;
          color: #2d2d2d;
          font-weight: 500;
          .view-all-icon {
            cursor: pointer;
          }
        }
      }
      .header-left-info,
      .header-right-info {
        padding: 1rem;
        span {
          display: block;
          padding: 0.3rem;
        }
      }
      .header-right-info {
        @include right-col();
        .deadline {
          @include deadline;
        }
        .btn-apply {
          @include btn-apply;
        }
      }
    }
    &__body {
      padding: 2rem;
      .body-row {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        .body-col {
          padding: 0 1rem;
          &__header {
            padding-bottom: 1rem;
            span {
              font-weight: 700;
              font-size: 1.3rem;
            }
          }
        }
        .left-col {
          flex: 1;
        }
        .right-col {
          @include right-col();
        }
        .job-info {
          //width: 20vw;
          .body-col__body {
            background: $background;
            padding: 1rem;
            .col-row {
              padding-bottom: 1rem;
              display: flex;
              align-items: center;
              .req-icons {
                padding-right: 1rem;
                img {
                  width: 30px;
                }
              }
              .job-req {
                span {
                  display: block;
                }
                &__header {
                  font-weight: 600;
                }
                &__body {
                  color: #504444;
                  font-weight: 500;
                }
              }
            }
          }
        }
        .apply-info {
          float: right;
          width: 100%;
          .deadline {
            @include deadline;
            span:last-child {
              padding: 0.5rem;
            }
          }
          .btn-apply {
            @include btn-apply;
          }
        }
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      background: #fafafa;
      padding-left: 3rem;
      .items {
        padding: 0.5rem;
        span {
          font-size: 1.2rem;
          font-weight: 500;
        }
        img {
          width: 45px;
        }
        .social-icon {
          font-size: 2.5rem;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
