.payroll-tax {
    .recruit-header {
        border-bottom: 2px solid #1391e6 !important;
        margin-bottom: 10px;
        // height: 44px !important;
        .recruit-header-item.selected  {
            background-color: #1391e6 !important;
        }
        &-item {
            border-radius: 6px 6px 0px 0px;
            margin-right: 3px;
            padding: 6px;
            width: 125px !important;
            background: white !important;
        }
        &-title {
            text-align: center !important;
            font-size: 14px;
            color: #828a92;
            font-weight: 400;
        }
    }
    .button-group {
        margin-top: unset;
        padding: unset;
    }
    .button-group__right {
        flex-direction: row-reverse;
        margin-top: -25px;
        .btn {
            margin: unset;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
        }
    }
}
.control-pane {
    .bottom-bar {
        display: grid;
        grid-template-columns: repeat(1, 3fr 3.5fr);
        &-buttons {
            display: grid;
            grid-template-columns: repeat(1, 4fr 3fr 3fr 4fr);
            margin-top: 35px;
            .blue {
                height: 40px !important;
            }
        }
    }
}