.no-page{
    height: 100vh;
    width: 100vw;
    @include flexCenter;
    flex-direction: column;
    backface-visibility: hidden;

    &-four{
        font-size: 6rem;
        color:#{$color-red};
        text-transform: uppercase;
        font-weight: 700;
        display: inline-block;
        backface-visibility: hidden;
        background-image: linear-gradient(to right,lighten($color-red,10), $color-red);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: .2rem;
        transition: all .2s;
        animation-name: moveInBottom;
        animation-duration: .5s;
        animation-timing-function: ease-out;
        animation-fill-mode: backwards;
        &:nth-child(2){
                animation-delay: .25s;
        }
        &:nth-child(3){
            animation-delay: .5s;
        }
            &:hover {
                transform: skewY(2deg) skewX(15deg) scale(1.1);
                text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
            }
    }
    &-found{
        font-size: 4rem;
        color:#{$color-red-fade};
        animation-duration: .5s;
        animation-name: moveInRight;
        animation-timing-function: ease-out;
        animation-delay: 1s;
        animation-fill-mode: backwards;
    }
    .button-group{
       .button,a{
        animation-duration: .5s;
        animation-name: moveinTop;
        animation-timing-function: ease-out;
        animation-delay: 1.5s;
        animation-fill-mode: backwards;

        &:nth-child(2){
            animation-delay: 1.75s;
        }
       }
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translate(3rem,-3rem) skew(-15deg,-15deg);
    }

    100% {
        opacity: 1;
        transform: translate(0,0) skew(0,0);
    }
}
@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translate(3rem,0);
    }
    50% {
        opacity: 0.75;
        transform: translate(-2rem,0);
    }

    100% {
        opacity: 1;
        transform: translate(0,0);
    }
}

@keyframes moveinTop{
    0% {
        opacity: 0;
        transform: translate(0,3rem);
    }
    50% {
        opacity: 0.75;
        transform: translate(0,-1.5rem);
    }

    100% {
        opacity: 1;
        transform: translate(0,0);
    }
}

.page-not{
    width: 100%;
    height: 100%;
    // display: grid;
    min-height: 80vh;
    // grid-template-columns: repeat(2,400px);
    // place-items: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &-col{
        width: 400px;

        button{
            margin-left: 0;;
        }
    }
    &-title{
        font-size:36px;
        margin-bottom: 10px;
        font-weight: 600;
        letter-spacing: 5px;
        color:#{$rigo-icon-hover};
    }
    &-content{
        font-size:14px;
        margin-bottom: 10px;
        color:#{$rigo-icon-hover};
    }
    img{
        width: 100%;
    }
}