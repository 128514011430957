.roles .admin-title button {
  padding: 8px 24px;
  height: 36px;
  border: 1px solid #0581fe;
}

.authorized-modal {
  .permission-title {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .permission-body {
    position: relative;
  }
  .permission-block {
    margin: 10px 0 10px;
  }
  .permission-members {
    &-container {
      width: 150px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    &-block {
      width: 100%;
      padding: 15px;
      border: 1px solid #d7d7d7;
      background-color: #f5f7fa;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
    }
    &__number {
      display: flex;
      flex-direction: row;
      color: #006df9;
      align-items: flex-end;
      margin-bottom: 5px;
      h4 {
        font-size: 22px;
        font-weight: 600;
        margin-right: 5px;
        line-height: 1.2;
      }
      span {
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }
}
.seperate {
  border: 1px solid #e1e2e2;
  margin: 20px 0;
}
.people {
  &-pool {
    &__item {
      padding: 10px 0;

      button {
        margin: 0;
        min-width: 175px;
      }
    }
    &__title {
      color: #2c2d2c;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    &__add {
      button {
        min-width: 175px;
        margin: 0;
      }
    }
  }
}
.pool {
  width: 100%;
  display: grid;
  grid-template-columns: 220px calc(100% - 250px) 30px;
  // padding: 10px 0;
  margin-bottom: 15px;
  &-remove {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    cursor: pointer;
    color: #c3c3c3;
    &:hover {
      color: #a9a;
    }
  }
  .MuiInputBase-root {
    margin-top: 0 !important;
  }
  .MuiInputBase-input {
    background: #f4f5f7;
    border: 1px solid #d7d7d7;
    margin-top: 0 !important;
  }
  .select__dropdown-indicator {
    display: none;
  }
}
