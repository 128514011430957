@import '../../../../scss/abstracts/variables';
@import '../../../../scss/abstracts/mixins';

.profile-change{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__container{
        flex-grow: 1;
        height: calc(100% - 50px);
        padding: 20px;
    }
    &__modal{
        .modal__content-body{
            width: 100%;
            height: 350px;
        }
    }
    &__input{
        width: 100%;
        height: 100%;

        .MuiPaper-root{
            height: 250px;
            box-shadow: none;
            border: 2px dashed rgb(195, 207, 221);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgb(249, 249, 249);
            outline: none;
            position: relative;
            p{
                text-align: center;
                color: #777;
                font-weight: 500;
                font-size: 17px;
                line-height: 24px;
            }
        }
    }
    &__file{
        text-align: center;
    }
    &__images{
        display: flex;
        flex-direction: revert;
        align-items: flex-end;
        &-block{
            text-align: center;
            &:nth-child(2){
                margin-left: 20px;
            }
            h5{
                font-size: 14px;
                font-weight: 500;
                color: #5b5b5b;
                margin: 5px 0;
            }
        }
    }
    &__error{
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 20px);
        text-align: left;
        padding: 5px 10px;
        background: #{$rigo-danger-color};
        color: #{$rigo-white-color};
        border-radius: 3px;
        margin: 10px;
    }
    &__image{
        width: 100px;
        border-radius: 100%;
        height: 100px;
        border: 2px solid rgb(195, 207, 221);;
        text-align: center;
        margin: 0 auto;
        position: relative;

        &-remove{
            width: 24px;
            height: 24px;
            position: absolute;
            top: 4px;
            right: 0px;
            background: rgba($rigo-danger-color,.75);
            border-radius: 50%;
            color: #{$rigo-white-color};
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover{
                background-color: rgba($rigo-danger-color,.9);
            }
        }
        &-error{
            position: absolute;
            top: 50%;
            left: 100px;
            transform: translateY(-50%);
            display: inline-block;
            background: #ff9d9d;
            color: #{$rigo-white-color};
            border-radius: 3px;
            max-width: 250px;
            width: 150px;
            font-size: 13px;
            padding: 5px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12);
            &:before{
                content: "";
                box-sizing: border-box;
                position: absolute;
                height: 12px;
                width: 12px;
                z-index: -1;
                background-color: #ff9d9d;
                border-right: 1px solid transparent;
                transform: rotate(-45deg) translateY(-50%);
                top: 50%;
                right: calc(100% - 10px);
            }
        }
        img{
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
        }

        &.cropped{
            width: 85px;
            height: 85px;
            
        }
    }
    &__footer{
        height: 50px;
        display: flex;
        flex-direction: row-reverse;
        padding: 10px 15px;
        align-items: center;
        background-color: #e5e5e5;
    }
}

.crop{
    &__modal{
        .modal__content{
            width: 750px;
        }
    }
    &-profile{
        width: 100%;
        display: flex;
        flex-direction: column;

        &__container{
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            padding: 10px;
        }
        &__crop{
            flex-grow: 1;

            div{
                max-width: 100%;
            }
        }
        &__preview{
            width: 150px;
            padding: 10px;

            img{
                max-width: 100%;
            }
        }
        &__footer{
            height: 50px;
            padding: 10px 20px;
            display: flex;
            flex-direction: row-reverse;
            background-color: #dfdfdf;

        }
    }
}