.tooltip {
    position: absolute;
    left: 50px;
    top: 50px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    z-index: 11;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #141B2B;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.16);
    display: none;
​
    &.right {
        margin-left: 5px;
    }
​
    &.left {
        margin-left: -5px;
    }
​
    &.top {
        margin-top: -5px;
    }
​
    &.bottom {
        margin-top: 5px;
    }
​
    .tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -5px;
        border-width: 5px 5px 5px 0;
        border-right-color: rgba(245, 247, 250, 1);
    }
​
    &.right .tooltip-arrow {
        top: 50%;
        left: auto;
        margin-left: -5px;
        border-width: 5px 5px 5px 0;
        border-right-color: rgba(245, 247, 250, 1);
    }
​
    &.top .tooltip-arrow {
        top: auto;
        bottom: -5px;
        left: 50%;
        margin-left: -5px;
        border-width: 5px 5px 0;
        border-top-color: rgba(245, 247, 250, 1);
    }
​
    &.left .tooltip-arrow {
        top: 50%;
        margin-top: -5px;
        border-width: 5px 0 5px 5px;
        border-left-color: rgba(245, 247, 250, 1);
        right: -5px;
        left: auto;
    }
​
    &.bottom .tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: rgba(245, 247, 250, 1);
    }
​
    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-right-color: transparent;
        border-style: solid;
    }
​
    .tooltip-inner {
        max-width: 200px;
        padding: 6px 8px;
        // color: rgba(245, 247, 250, 1);
        color: #141B2B;
        text-align: center;
        background-color: rgba(245, 247, 250, 1);
        border-radius: 4px;
    }
​
    &.on {
        display: block;
    }
​
    &.off {
        display: none;
    }
}