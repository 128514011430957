@import "../../../scss/abstracts/mixins";
@import "../../../scss/abstracts/variables";

.modal-time-details {
  .modal__content {
    width: 70%;
    // @include respond(desktop) {
    //   width: 70%;
    // }
    // @include respond(med-desktop) {
    //   width: 50%;
    // }
    // @include respond(big-desktop) {
    //   width: 50%;
    // }
  }
}

.time-attendance {
  // max-width: 80%;
  padding: 20px;
  &-title {
    margin-bottom: 10px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    background-color: #{$rigo-white-color};
    padding: 1rem;
    border-radius: 5px;
    flex: 1;
    &__title {
      font-size: 1.2rem;
      color: #2f2e2e;
      font-weight: 500;
    }

    .audit-history .history-grid {
      max-height: 200px;
    }
  }

  &-punched {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    color: #444;

    &_date {
      flex: 1.6;
      min-width: 180px;
      white-space: nowrap;
    }
    &_time {
      flex: 2;
      display: flex;
      //margin: 0 20px;
      min-width: 175px;
      margin-left: 1rem;
      .bold {
        font-weight: 500;
      }
    }
    &_reason {
      flex: 3;
      min-width: 120px;
    }
  }

  &-requested {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #444;
    &_date {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
    &_employee {
      display: flex;
      flex: 1.5;
      align-items: center;
      .in-route {
        color: #ff83c4;
      }
      .emp-name {
        color: #8dca60 !important;
        font-size: unset !important;
        font-weight: unset !important;
        margin-right: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    &-left {
      display: flex;
      flex-direction: row;
    }
  }
  .right-col {
    min-width: 300px;
    &:empty {
      display: none !important;
    }
  }
}

.my-requests {
  max-width: 80%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  &-title {
    margin-bottom: 10px;
  }

  .time-request {
    margin-bottom: 10px;

    background-color: #{$rigo-white-color};
    border-radius: 5px;
    &-top {
      display: flex;
      flex-direction: row;
      padding: 10px;
      border-bottom: 1px solid #ddd;
      &-user__detail {
        display: flex;
        flex: 1;
        flex-direction: row;
        .name_designation {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          &-name {
            color: $color-blue;
            font-weight: 500;
          }
          &-designation {
            margin-top: 2px;
            color: #666;
          }
        }
      }
      &-time__detail {
        display: flex;
        flex: 2;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &-type {
          flex: 2;
          display: flex;
          align-items: center;
          color: #444;
          font-weight: 500;
          &-name {
            margin-left: 10px;
          }
        }
        &-days {
          flex: 1;
          color: #444;
          font-weight: 500;
        }
        &-date {
          flex: 3;
          color: #666;
          font-size: small;
          font-weight: 500;
        }
      }
    }
    &-mid {
      display: flex;
      flex-direction: column;
      margin-left: 100px;
      padding: 10px;

      &-in_out {
        display: flex;
        flex-direction: row;

        &-text {
          // margin-right: 10px;
          min-width: 40px;
        }
        &-value {
          margin-right: 30px;
          color: grey;
        }
      }
    }
    &-bottom {
      padding: 10px;
      display: flex;
      flex-direction: row;

      &-left {
        flex: 1;
      }
      &-right {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &-status {
          display: flex;
          justify-content: center;
          align-items: center;
          &-sender {
            color: $color-blue;
            padding: 0 5px;
          }
          &-arrow {
            color: #666;
            font-size: 20px;
            padding: 0 5px;
          }
          &-receiver {
            color: #666;
            padding: 0 5px;
          }
        }
        &-cancel {
        }
      }
    }
  }
}
