@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/variables';

.gender-ratio{
    width: calc(100% - 50px);
    padding: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-genders{
        display: flex;
        flex-direction: row;
        // margin-right: 50px;
        .gender{
            display: flex;
            flex-direction: row;
            align-items: center;

            &-male{
                color:#{$color-primary-dark};
                margin-right: 25px;
            }
            &-female{
                color:#{$color-red};
            }
            &-icon{
                font-size: 30px;
            }
            &-value{
                display: flex;
                flex-direction: column;
                align-items: center;
                color:#{$color-primary-dark-2};
                &__num{
                    font-weight: 600;
                    font-size: 17px;
                }
                &__name{
                    font-size: 14px;
                }
            }
        }
    }
    &-marital{
        display: flex;
        flex-direction: row;

        .marital-status{
            display: flex;
            flex-direction: column;
            align-items: center;

            &:first-child{
                margin-right: 25px;
            }

            &__num{
                font-size: 17px;
                font-weight: 600;
            }
            &__name{
                font-size: 14px;
            }
        }
    }
}


.absent{
    padding: 20px;
    &-list{
        display: flex;
        flex-direction: column;

        &__item{
            display: flex;
            flex-direction: row;
            
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }

        &__more{
            width: 140px;
            font-size: 13px;
            color: #444;

            .bold-500{
                margin: 0 6px;
            }
        }
        &__employee{
            font-size: 13px;
            color: #444;
        }
    }
}

.pending{
    padding: 15px;
    display: block;

    &-list{
        display: flex;
        flex-direction: column;

        &__item{
            display: flex;
            flex-direction: row;

            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
    &-name{
        width: 150px;
        font-size: 14px;
        color: #3d3e3c;
    }
    &-value{
        font-size: 14px;
        color:#{$color-blue}
    }
}