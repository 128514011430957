.conatiner {
  width: 95%;
  height: 100%;
  margin: 2rem auto;
}

.breadcrumbs {
  width: 100%;
  padding: 10px 20px;
  background-color: #eaeaea;
  list-style: none;
  display: flex;
  flex-direction: row;
  color: #333;
  font-size: 11px;

  & > li + li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
  }

  &__item {
    svg,
    i {
      font-size: 0.8rem;
    }

    // padding: 0 5px;

    // &:not(:last-child){
    //     border-right:1px solid #c4c4c4;
    // }
    a {
      color: #{$color-primary};
    }
  }
}

.header-text {
  font-size: 1.25rem;
  color: #4e5154;
  font-weight: 500;
  margin: 0.6rem;
}

.load {
  background-color: #{$color-primary};
  color: #{$rigo-white-color};
  margin-top: 15px;
  height: 2rem;
  padding: 0.6rem 1.5rem;
  margin-left: auto;
}

.grid-container {
  width: 100%;
  height: 50vh;
  background-color: #{$rigo-white-color};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: 1.5rem;

  .ag-row .ag-cell {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
  }
}

.grid-icon {
  background-color: #333;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #{$rigo-white-color};
  font-size: 12px;
}
.remove {
  // background-color: lighten($color-red,10);
  background: #98a2a9;

  &:hover {
    background-color: lighten(#98a2a9, 6);
    color: #efefef;
  }

  // &:hover{
  //     background-color: lighten($color-red,20);
  //     color:#efefef;
  // }
}
.edit {
  font-weight: normal;
  background: #98a2a9;

  &:hover {
    background-color: lighten(#98a2a9, 6);
    color: #efefef;
  }
  // background-color: lighten($color-primary,10);

  // &:hover{
  //     background-color: lighten($color-primary,20);
  //     color:#efefef;
  // }
}
.check {
  font-weight: normal;
  font-size: 15px;
  // background-color: darken($color-green,2);
  cursor: default;

  background: #98a2a9;

  &:hover {
    background-color: lighten(#98a2a9, 6);
    color: #efefef;
  }

  // &:hover{
  //     background-color: lighten($color-green,8);
  //     color:#efefef;
  // }
}
.cross {
  font-size: 15px;
  font-weight: normal;
  // background-color: darken($color-red,2);
  cursor: default;

  background: #98a2a9;

  &:hover {
    background-color: lighten(#98a2a9, 6);
    color: #efefef;
  }

  // &:hover{
  //     background-color: lighten($color-red,8);
  //     color:#efefef;
  // }
}
.grid-cell-flex {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  width: 100%;
  background-color: #{$rigo-white-color};
  border: 2px solid #dedede;
  border-top: none;
  display: flex;
  flex-direction: row;
  padding: 10px;

  .pages {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &-title {
    font-weight: 400;
    font-size: 0.8rem;
    margin-right: 5px;
  }
  &-item {
    border-right: 1px solid #efefef;
    padding: 2px 10px;
  }
  &-select {
    margin-right: 10px;
    select {
      width: 3rem;
      height: 1.3rem;
      border: 1px solid #e5e5e5;
      font-size: 0.9rem;
      color: #333;
    }
  }
  &-button {
    width: 1.75rem;
    height: 1.75rem;
    // background-color: #333;
    // padding:4px;
    border-radius: 50%;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
    .material-icons {
      font-size: 1.4rem;
    }
  }
  .page_no {
    font-weight: 500;
    font-size: 0.9rem;
    // color:#535353;
    padding: 2px 4px;
    width: 1.5rem;
    margin: 0 0.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #5e5e5e;
    color: #fafafa;
    @include flexCenter;
  }
}

// .ag-root.ag-layout-normal, .ag-root.ag-layout-auto-height{
//     overflow-x: scroll !important;
// }

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
.ag-theme-balham {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    // border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.image-tool {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 3px 0;
  position: relative;

  &:hover {
    .image-card {
      display: block;
    }
  }
}

.image-card {
  display: none;
  width: 20rem;
  background-color: #{$rigo-white-color};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  position: absolute;
  top: 0;
  left: 40%;

  &__image {
    width: 100%;
    height: 5rem;

    img {
      width: 50%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__details {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 1rem;
      color: #646464;
    }
    span {
      font-size: 1rem;
      color: #888;
    }
  }
  &__name {
    font-size: 1.2rem;
    color: #{$color-primary};
    font-weight: 600;
  }
}

.contain {
  width: 90%;
  padding: 10px;
  margin: 0 auto;
}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 10px 16px !important;
}

.hr-line {
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  margin: 2rem 0;
}

.page {
  &-contain {
    padding: 20px;
    width: 100%;
  }
  &-heading {
    font-size: 1.1rem;
    color: #{$color-primary-dark};
    font-weight: 500;
    margin-bottom: 1rem;
  }
}

.custom-grid.e-grid {
  border: none;
  .e-gridheader {
    border: none;
  }
  .e-rowcell {
    border-color: $table-border;
  }
}

.bloch__title {
  margin-bottom: 5px;
  &,
  h1,
  h2,
  h3,
  h4 {
    font-size: 14px;
    color: #2c2d2c;
    font-weight: 500;
    line-height: 24px;
    
  }
}

.fav-modal{
  .modal__content{
    background-color: #fff;
    overflow: visible;
    &-body{
      overflow: visible !important;
    }
  }
}
.favModal{
  width: 100%;
  background-color: #fff;
  &-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding:20px;

    .genericForm-group{
      margin-bottom: 10px;
    }

  }
  &-footer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 8px;
    background-color: #f7f7f7;

    button{
      margin-right: 5px;
    }
  }
}

.remove-icon{
  svg{
    color:rgba($rigo-danger-color,.5) !important;

    &:hover{
      color:$rigo-danger-color !important;
    }
  }
}